import { Pipe, PipeTransform } from '@angular/core';
import { MEETING_FORM_ID } from '../constants/dynamic-form.constants';
import { ActivityType } from '@wdx/clmi/api-models';

@Pipe({ name: 'activityTypeToFormName' })
export class ActivityTypeToFormNamePipe implements PipeTransform {
    transform(value: ActivityType): string {
        if (!value) {
            return '';
        }

        switch (value) {
            case ActivityType.Appointment:
                return MEETING_FORM_ID;
            default:
                return `${value}Form`;
        }
    }
}
