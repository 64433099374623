import { createSelector } from '@ngrx/store';
import * as fileDownloadReducer from './file-download.reducer';

export const getState = (state: {
    fileDownload: fileDownloadReducer.State;
}): fileDownloadReducer.State => state.fileDownload;

export const getFilePreviewIsLoading = createSelector(
    getState,
    (state: fileDownloadReducer.State, props: { id: string }) =>
        state.filePreview[props.id]?.isLoadingSingle
);

export const getFilePreviewHasLoadingError = createSelector(
    getState,
    (state: fileDownloadReducer.State, props: { id: string }) =>
        state.filePreview[props.id]?.hasLoadingSingleError
);

export const getFilePreview = createSelector(
    getState,
    (state: fileDownloadReducer.State, props: { id: string }) =>
        state.filePreview[props.id]?.single
);

export const getFilePreviews = createSelector(
    getState,
    (state: fileDownloadReducer.State) => state.filePreview
);
