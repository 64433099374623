import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PushModule } from '@ngrx/component';
import { AtomAddButtonModule } from '../../../components/atoms/atom-add-button/atom-add-button.module';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomInputNoneSelectedModule } from '../../../components/atoms/atom-input-none-selected/atom-input-none-selected.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeInputSearchModule } from '../molecule-input-search/molecule-input-search.module';
import { MoleculeInputWrapperModule } from '../molecule-input-wrapper/molecule-input-wrapper.module';
import { MoleculeInputLookupDumbComponent } from './components/molecule-input-lookup-dumb/molecule-input-lookup-dumb.component';
import { MoleculeInputLookupComponent } from './containers/molecule-input-lookup/molecule-input-lookup.component';

@NgModule({
    imports: [
        AtomAddButtonModule,
        AtomAsyncDataModule,
        AtomInputNoneSelectedModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeInfoCardModule,
        MoleculeInfoCardSearchModule,
        MoleculeInputSearchModule,
        MoleculeInputWrapperModule,
        OrganismModalModule,
        PipesModule,
        PushModule,
    ],
    declarations: [
        MoleculeInputLookupComponent,
        MoleculeInputLookupDumbComponent,
    ],
    exports: [MoleculeInputLookupComponent, MoleculeInputLookupDumbComponent],
})
export class MoleculeInputLookupModule {}
