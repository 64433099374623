import { Injectable, inject } from '@angular/core';

import { PendingChangeStatusType } from '@wdx/clmi/api-models';

import { IFormPendingChangeSubForm } from '@wdx/shared/infrastructure/form-framework';
import { IExtendedFieldDefinition } from '../../../../models/extended-field-definition-option.model';
import {
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../../../models/reactive-dynamic.model';
import { FormStaticService } from '../form-static/form-static.class';
import {
    PENDING_CHANGE_REG,
    PENDING_CHANGE_SPLIT,
    UPDATE_PENDING_CHANGE_COUNTER,
} from './form-pending-changes.helper';
import {
    ESubFormPendingChange,
    PENDING_CHANGE_LOCK_ALL,
} from './form-pending-changes.interface';

@Injectable()
export class FormPendingChangesService {
    subFormPendingChanges: {
        [key: string]: {
            [key: string]: any;
        };
    } = {};
    pendingChangesSubFormCounter: IFormPendingChangeSubForm = {};

    private formStaticService = inject(FormStaticService);

    setUpPendingChanges(
        layoutAndDefinition: ReactiveFormElement[],
        formBuilderFirstLevel
    ): void {
        this.updateSubFormPendingChanges();

        if (
            this.formStaticService?.formData?.pendingChanges &&
            layoutAndDefinition.length
        ) {
            layoutAndDefinition?.forEach((layoutAndDefinition) => {
                layoutAndDefinition?.layoutAndDefinition?.forEach(
                    (layoutAndDefinition) => {
                        const HAS_SUB_FORM_PENDING_CHANGE =
                            !!this.subFormPendingChanges[
                                layoutAndDefinition?.name
                            ];

                        if (HAS_SUB_FORM_PENDING_CHANGE) {
                            this.updateSubFormPendingChangeRows(
                                layoutAndDefinition,
                                formBuilderFirstLevel
                            );
                        }

                        if (!HAS_SUB_FORM_PENDING_CHANGE) {
                            this.updatePendingChange(layoutAndDefinition);
                        }
                    }
                );
            });
        }
    }

    updateSubFormPendingChanges(): void {
        const removeItems = [];
        const PENDING_CHANGES =
            this.formStaticService?.formData?.pendingChanges;

        PENDING_CHANGES?.forEach((pendingChange, index) => {
            // Check if sub form field is been updated
            if (pendingChange.field.match(PENDING_CHANGE_REG)) {
                removeItems.push(index);
                const PENDING = PENDING_CHANGE_SPLIT(pendingChange.field);

                this.addDataToSubFormPendingChanges(
                    PENDING[0],
                    parseInt(PENDING[1], 10),
                    { ...pendingChange, field: PENDING[2], name: PENDING[2] }
                );
            }
        });

        removeItems.reverse().forEach((index) => {
            this.formStaticService?.formData?.pendingChanges.splice(index, 1);
        });
    }

    addDataToSubFormPendingChanges(
        arrayName: string,
        index: number,
        field?: any
    ): void {
        const CHANGE_ENTIRE_ROW = [
            ESubFormPendingChange.Adding,
            ESubFormPendingChange.Deleting,
        ].includes(field.status);

        // These are the entire row is been changed
        if (CHANGE_ENTIRE_ROW) {
            this.subFormPendingChanges = {
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: field,
                },
            };
            this.updatePendingChangeCounter(arrayName, field.status);
        }

        // These are fields that are been updated in the row
        if (field.name) {
            this.subFormPendingChanges = {
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: field,
                },
            };

            this.updatePendingChangeCounter(
                arrayName,
                ESubFormPendingChange.Updating
            );
        }
    }

    updateSubFormPendingChangeRows(
        layoutAndDefinition: ReactiveFormLayoutAndDefinition,
        formBuilderFirstLevel
    ): void {
        const SUB_FORM_ROW =
            this.subFormPendingChanges[layoutAndDefinition?.name];

        Object.keys(SUB_FORM_ROW).forEach((key) => {
            const SUB_FORM_ROW_FIELD_DEFINITIONS = layoutAndDefinition
                .children[0].layoutAndDefinition[
                key
            ] as IExtendedFieldDefinition[];

            const INDEX_OBJ =
                this.subFormPendingChanges[layoutAndDefinition?.name][key];

            const UPDATE_ALL = PENDING_CHANGE_LOCK_ALL.includes(
                INDEX_OBJ.status
            );

            this.updatePendingChangeCounter(
                layoutAndDefinition?.name,
                null,
                layoutAndDefinition.label
            );

            SUB_FORM_ROW_FIELD_DEFINITIONS.forEach((field: any) => {
                if (UPDATE_ALL) {
                    field.pendingChange = {
                        name: field.label,
                        // this status has been set to Submitted to lock the form fields
                        status: PendingChangeStatusType.Submitted,
                    };
                }

                if (!UPDATE_ALL && field.name === INDEX_OBJ?.name) {
                    formBuilderFirstLevel[
                        layoutAndDefinition?.name
                    ].pendingChanges[key].status =
                        ESubFormPendingChange.Updating;
                    field.pendingChange = {
                        name: field.label,
                        status: INDEX_OBJ.status,
                    };
                }
            });
        });
    }

    updatePendingChange(
        layoutAndDefinition: ReactiveFormLayoutAndDefinition
    ): void {
        const INDEX =
            this.formStaticService?.formData?.pendingChanges.findIndex(
                (pendingChange) =>
                    pendingChange.field === layoutAndDefinition?.name
            );
        if (INDEX >= 0) {
            const pendingChange =
                this.formStaticService?.formData?.pendingChanges[INDEX];
            pendingChange.name = layoutAndDefinition.label;
            layoutAndDefinition.pendingChange = pendingChange;
        }
    }

    updatePendingChangeCounter(
        name: string,
        type: ESubFormPendingChange,
        label?: string
    ): void {
        this.pendingChangesSubFormCounter = UPDATE_PENDING_CHANGE_COUNTER(
            this.pendingChangesSubFormCounter,
            name,
            type,
            label
        );
    }
}
