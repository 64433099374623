import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-date-control',
    templateUrl: './form-date-control.component.html',
})
export class FormDateControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    public hideEnd?: boolean;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit() {
        this.hideEnd = !!this.formElement?.elementStyle?.includes('StartOnly');
    }
}
