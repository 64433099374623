import { TranslationKey } from '@wdx/clmi/api-services/services';
import { LookupSourceType, SystemEntity } from '@wdx/clmi/api-models';

export const ENTITY_TRANSLATION_KEY_MAP: {
    [key: string]: TranslationKey;
} = {
    [SystemEntity.Activity]: TranslationKey.EntityActivity,
    [SystemEntity.Adviser]: TranslationKey.EntityAdviser,
    [SystemEntity.Article]: TranslationKey.EntityArticle,
    [SystemEntity.Campaign]: TranslationKey.EntityCampaign,
    [SystemEntity.CampaignResponse]: TranslationKey.EntityCampaignResponse,
    [SystemEntity.Case]: TranslationKey.EntityCase,
    [SystemEntity.Client]: TranslationKey.EntityClient,
    [SystemEntity.ClientProduct]: TranslationKey.EntityClientProduct,
    [SystemEntity.Document]: TranslationKey.EntityDocument,
    [SystemEntity.DocumentTemplate]: TranslationKey.EntityDocumentTemplate,
    [SystemEntity.EdsMessage]: TranslationKey.EntityEdsMessage,
    [SystemEntity.EdsMessageBatch]: TranslationKey.EntityEdsMessageBatch,
    [LookupSourceType.EligibleClient]: TranslationKey.EntityClient,
    [SystemEntity.Export]: TranslationKey.EntityExport,
    [SystemEntity.ExternalUser]: TranslationKey.EntityExternalUser,
    [SystemEntity.Feed]: TranslationKey.EntityFeed,
    [SystemEntity.Intermediary]: TranslationKey.EntityIntermediary,
    [SystemEntity.Lead]: TranslationKey.EntityLead,
    [SystemEntity.List]: TranslationKey.EntityList,
    [SystemEntity.Opportunity]: TranslationKey.EntityOpportunity,
    [SystemEntity.Organisation]: TranslationKey.EntityOrganisation,
    [SystemEntity.Person]: TranslationKey.EntityPerson,
    [SystemEntity.ProductProvider]: TranslationKey.EntityProductProvider,
    [SystemEntity.QueueItem]: TranslationKey.EntityQueueItem,
    [SystemEntity.Task]: TranslationKey.EntityTask,
    [SystemEntity.Team]: TranslationKey.EntityTeam,
    [SystemEntity.User]: TranslationKey.EntityUser,
    [SystemEntity.WorkItem]: TranslationKey.EntityWorkItem,
};

export const ENTITY_TRANSLATION_KEY_PLURAL_MAP: {
    [key: string]: TranslationKey;
} = {
    [SystemEntity.Activity]: TranslationKey.EntityActivityPlural,
    [SystemEntity.Adviser]: TranslationKey.EntityAdviserPlural,
    [SystemEntity.Article]: TranslationKey.EntityArticlePlural,
    [SystemEntity.Case]: TranslationKey.EntityCasePlural,
    [SystemEntity.Campaign]: TranslationKey.EntityCampaignPlural,
    [SystemEntity.CampaignResponse]:
        TranslationKey.EntityCampaignResponsePlural,
    [SystemEntity.Client]: TranslationKey.EntityClientPlural,
    [SystemEntity.ClientProduct]: TranslationKey.EntityClientProductPlural,
    [SystemEntity.Document]: TranslationKey.EntityDocumentPlural,
    [SystemEntity.DocumentTemplate]:
        TranslationKey.EntityDocumentTemplatePlural,
    [SystemEntity.EdsMessage]: TranslationKey.EntityEdsMessagePlural,
    [SystemEntity.EdsMessageBatch]: TranslationKey.EntityEdsMessageBatchPlural,
    [LookupSourceType.EligibleClient]: TranslationKey.EntityClientPlural,
    [SystemEntity.Export]: TranslationKey.EntityExportPlural,
    [SystemEntity.ExternalUser]: TranslationKey.EntityExternalUserPlural,
    [SystemEntity.Feed]: TranslationKey.EntityFeedPlural,
    [SystemEntity.Intermediary]: TranslationKey.EntityIntermediaryPlural,
    [SystemEntity.Lead]: TranslationKey.EntityLeadPlural,
    [SystemEntity.List]: TranslationKey.EntityListPlural,
    [SystemEntity.Opportunity]: TranslationKey.EntityOpportunityPlural,
    [SystemEntity.Organisation]: TranslationKey.EntityOrganisationPlural,
    [SystemEntity.Person]: TranslationKey.EntityPersonPlural,
    [SystemEntity.ProductProvider]: TranslationKey.EntityProductProviderPlural,
    [SystemEntity.QueueItem]: TranslationKey.EntityQueueItemPlural,
    [SystemEntity.Task]: TranslationKey.EntityTaskPlural,
    [SystemEntity.Team]: TranslationKey.EntityTeamPlural,
    [SystemEntity.User]: TranslationKey.EntityUserPlural,
    [SystemEntity.WorkItem]: TranslationKey.EntityWorkItemPlural,
};
