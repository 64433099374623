import { NgModule } from '@angular/core';
import { EntityRouteOpenNewTabPipe } from '../pipes/entity-route-open-new-tab.pipe';
import { ReviewStatusIconPipe } from '../pipes/review-status-icon.pipe';
import { AbbreviateBytesPipe } from './abbreviate-bytes.pipe';
import { AbbreviateCurrencyPipe } from './abbreviate-currency.pipe';
import { ActivityStatusDatePipe } from './activity-status-date.pipe';
import { ActivityStatusLabelPipe } from './activity-status-label.pipe';
import { ActivityTypeToFormNamePipe } from './activity-type-to-form-name.pipe';
import { AddressValueStringPipe } from './address-value-string.pipe';
import { AppUrlPipe } from './app-url.pipe';
import { ArticleImageUrlPipe } from './article-image-url.pipe';
import { BtoAPipe } from './btoa.pipe';
import { ClientFeedsAsFirstFeedCountPipe } from './client-feeds-as-first-feed-count.pipe';
import { CompletedStatusIsCompletedPipe } from './completed-status-is-completed.pipe';
import { IncludeContactFilterTypesPipe } from './contact-filter-types.pipe';
import { DateComparePipe } from './date-compare.pipe';
import { DateDeadlinePipe } from './date-deadline.pipe';
import { DisplayDatePipe } from './display-date.pipe';
import { DocumentTypesAsActionButtonPipe } from './document-types-as-action-button.pipe';
import { DocumentsToDocumentDataPipe } from './documents-to-document-data.pipe';
import { EncodeURIComponentPipe } from './encode-uri-component.pipe';
import { EntityQueryParamPipe } from './entity-query-param.pipe';
import { EntityRoutePipe } from './entity-route.pipe';
import { EntitySearchRoutePipe } from './entity-search-route.pipe';
import { FileNamePipe } from './file-name.pipe';
import { FileTypeAsIconPipe } from './file-type-as-icon.pipe';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { FunctionPipe } from './function.pipe';
import { HasFeaturePipe } from './has-feature.pipe';
import { HasRolePipe } from './has-role.pipe';
import { HeatMapToMenuItemsPipe } from './heat-map-to-menu-items.pipe';
import { HighlightPipe } from './highlight.pipe';
import { HighlighterPipe } from './highlighter.pipe';
import { HistoryToChronologicalListGroupsPipe } from './history-to-chronological-list-groups.pipe';
import { IconFromFieldOptionPipe } from './icon-from-field-option.pipe';
import { IdentitiesToIdListPipe } from './identities-to-id-list.pipe';
import { IdentitiesToLockedByListPipe } from './identities-to-locked-by-list.pipe';
import { InstanceOfPipe } from './instanceof.pipe';
import { KebabCasePipe } from './kebab-case.pipe';
import { KeyStatsToTileInfos } from './key-stats-to-tile-infos.pipe';
import { LifecycleStageToCSSClass } from './lifecycle-stage-to-bg-class.pipe';
import { ListTypePipe } from './list-type.pipe';
import { NotesToNoteDataPipe } from './notes-to-note-data.pipe';
import { obfuscateTextPipe } from './obfuscate-text.pipe';
import { PluralStringPipe } from './plural-string.pipe';
import { PrioritisedCompletedStatusPipe } from './prioritised-completed-status.pipe';
import { RandomNumberPipe } from './random-number.pipe';
import { RandomStringPipe } from './random-string.pipe';
import { ReferenceToAvatarPipe } from './reference-to-avatar.pipe';
import { RgbaToHexPipe } from './rgba-to-hex.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { SavedViewQueryParamsPipe } from './saved-view-query-params.pipe';
import { SpreadPipe } from './spread.pipe';
import { StringifyComparePipe } from './stringify-compare.pipe';
import { TextSearchPipe } from './text-search.pipe';
import { TextToHexPipe } from './text-to-hex.pipe';
import { ThemeClassPipe } from './theme-class.pipe';
import { ThousandsSeparatorsPipe } from './thousands-separators.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { TruncatedSearchPipe } from './truncated-search.pipe';
import { TypeofPipe } from './typeof.pipe';
import { ViewsAsMenuItemsPipe } from './views-as-menu-items.pipe';

const allPipes = [
    AbbreviateBytesPipe,
    AbbreviateCurrencyPipe,
    ActivityStatusDatePipe,
    ActivityStatusLabelPipe,
    ActivityTypeToFormNamePipe,
    AddressValueStringPipe,
    AppUrlPipe,
    ArticleImageUrlPipe,
    BtoAPipe,
    ClientFeedsAsFirstFeedCountPipe,
    CompletedStatusIsCompletedPipe,
    DateComparePipe,
    DateDeadlinePipe,
    DisplayDatePipe,
    FileNamePipe,
    DocumentsToDocumentDataPipe,
    DocumentTypesAsActionButtonPipe,
    EncodeURIComponentPipe,
    EntityRouteOpenNewTabPipe,
    EntityQueryParamPipe,
    EntityRoutePipe,
    EntitySearchRoutePipe,
    FileTypeAsIconPipe,
    FormatCurrencyPipe,
    FunctionPipe,
    HasFeaturePipe,
    HasRolePipe,
    HeatMapToMenuItemsPipe,
    HighlightPipe,
    HistoryToChronologicalListGroupsPipe,
    IconFromFieldOptionPipe,
    IdentitiesToIdListPipe,
    IdentitiesToLockedByListPipe,
    IncludeContactFilterTypesPipe,
    InstanceOfPipe,
    KebabCasePipe,
    KeyStatsToTileInfos,
    LifecycleStageToCSSClass,
    ListTypePipe,
    NotesToNoteDataPipe,
    obfuscateTextPipe,
    PluralStringPipe,
    PrioritisedCompletedStatusPipe,
    RandomNumberPipe,
    RandomStringPipe,
    ReferenceToAvatarPipe,
    RgbaToHexPipe,
    SafeResourceUrlPipe,
    SavedViewQueryParamsPipe,
    SpreadPipe,
    StringifyComparePipe,
    TextSearchPipe,
    TextToHexPipe,
    ThemeClassPipe,
    ThousandsSeparatorsPipe,
    TitleCasePipe,
    TruncatedSearchPipe,
    TruncateTextPipe,
    TypeofPipe,
    ViewsAsMenuItemsPipe,
    HighlighterPipe,
    ReviewStatusIconPipe,
];
@NgModule({
    declarations: allPipes,
    exports: allPipes,
    providers: [allPipes],
})
export class PipesModule {}
