import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FileIndex } from '@wdx/clmi/api-models';
import { BaseReactiveFormControlClass } from '../../../../../features/reactive-forms/abstract-classes/base-reactive-form-control.class';
import { FilesFacadeService } from '../../../../../shared/features/files/services/files-facade/files-facade.service';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services';

@Component({
    selector: 'clmi-reactive-file-upload-control',
    templateUrl: './reactive-file-upload-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveFileUploadControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit, OnDestroy
{
    fileIndex: FileIndex;
    fileIndexesToDelete: string[] = [];
    initialContainerValue: FileIndex;

    get containerField() {
        return this.controlContainer.control.get(this.formElement.name);
    }

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService,
        private filesFacadeService: FilesFacadeService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        if (this.formControl.value?.length) {
            this.fileIndex = this.formControl.value[0];
        }

        this.setInitialContainerValue();
    }

    setInitialContainerValue() {
        this.initialContainerValue = this.containerField.value
            ? this.containerField.value[0]
            : null;
    }

    onFileUploaded(fileIndex: FileIndex) {
        this.fileIndex = fileIndex;
        this.formControl.setValue([fileIndex]);
    }

    onDeleteFile(fileIndex: FileIndex): void {
        this.fileIndexesToDelete.push(fileIndex.fileIndex);
        this.fileIndex = null;
        this.formControl.setValue(null);
    }

    /**
     * Perform file cleanup on files not saved to parent control
     */
    fileCleanup() {
        // if container is disabled don't attempt to cleanup
        if (this.containerField.disabled) {
            return;
        }

        const currentContainerValue = this.containerField.value;

        // delete all files removed from control using delete button if doesn't match container value
        if (this.fileIndexesToDelete.length) {
            this.fileIndexesToDelete
                .filter(
                    (fileIndex) =>
                        fileIndex !== this.initialContainerValue?.fileIndex
                )
                .forEach((fileIndex) => {
                    this.filesFacadeService.deleteFile(fileIndex);
                });
        }

        // if file index exists but inital value and current value is null, delete the file
        if (
            this.fileIndex &&
            this.initialContainerValue === null &&
            currentContainerValue === null
        ) {
            this.filesFacadeService.deleteFile(this.fileIndex?.fileIndex);
        }
    }

    ngOnDestroy(): void {
        this.fileCleanup();
        super.ngOnDestroy();
    }
}
