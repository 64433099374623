import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICON_WARNING } from '../../../../constants/icons.constants';
import {
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../../models/form-validation.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-validation-summary',
    templateUrl: './molecule-validation-summary.component.html',
    styleUrls: ['./molecule-validation-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoleculeValidationSummaryComponent {
    @Input() message: string;
    @Input() validationSummary:
        | ValidationSummarySection
        | ValidationSummarySection[];

    @Output() errorClicked = new EventEmitter<ValidationSummaryError>();

    readonly ICON_WARNING = ICON_WARNING.icon;

    onErrorClicked($event: ValidationSummaryError) {
        this.errorClicked.emit($event);
    }

    get isArrayOfSections(): boolean {
        return this.validationSummary
            ? Array.isArray(this.validationSummary)
            : null;
    }
}
