import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'wdx-pagination',
    templateUrl: './wdx-pagination.component.html',
    styleUrls: ['./wdx-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxPaginationComponent implements OnInit {
    @Input() dataSource!: string;
    @Input() page!: number;
    @Input() collectionSize!: number;
    @Input() maxSize = 5;
    @Input() size!: 'sm' | 'lg' | null;
    @Input() disabled!: boolean;
    @Input() scrollContainer!: HTMLElement;

    @Output() pageChange = new EventEmitter<number>();

    pages!: number[];
    lowerRotationIndex!: number;
    upperRotationIndex!: number;

    ngOnInit(): void {
        this.updatePages();
    }

    back(): void {
        if (this.page > 1) {
            this.page--;
            this.onPageChange();
        }
    }

    next(): void {
        if (this.page < this.collectionSize) {
            this.page++;
            this.onPageChange();
        }
    }

    first(): void {
        this.page = 1;
        this.onPageChange();
    }

    last(): void {
        this.page = this.collectionSize;
        this.onPageChange();
    }

    toPage(pageNumber: number): void {
        if (pageNumber === this.page) {
            return;
        }
        this.page = pageNumber;
        this.onPageChange();
    }

    updatePages(): void {
        if (this.collectionSize <= this.maxSize && this.pages?.length) {
            return;
        }
        const pages = Array.from(Array(this.collectionSize), (_, i) => i + 1);
        if (this.collectionSize <= this.maxSize) {
            this.pages = pages;
        } else {
            this.updateRotationIndexes();
            this.pages = pages.slice(
                this.lowerRotationIndex,
                this.upperRotationIndex
            );
        }
    }

    updateRotationIndexes(): void {
        const buffer = this.maxSize / 2;
        const lower = this.page - Math.ceil(buffer);
        const upper = this.page + Math.floor(buffer);

        if (lower < 1) {
            this.lowerRotationIndex = 0;
            this.upperRotationIndex = this.maxSize;
        } else if (upper > this.collectionSize) {
            this.lowerRotationIndex = this.collectionSize - this.maxSize;
            this.upperRotationIndex = this.collectionSize;
        } else {
            this.lowerRotationIndex = lower;
            this.upperRotationIndex = upper;
        }
    }

    scrollToTop(): void {
        if (this.scrollContainer) {
            this.scrollContainer.scroll(0, 0);
        }
    }

    onPageChange(): void {
        this.updatePages();
        this.scrollToTop();
        this.pageChange.emit(this.page);
    }
}
