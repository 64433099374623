import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { PipelineActionEntity } from '../pipeline/pipeline.constants';

export const URL_WELCOME = ['/welcome'];

export const ROUTE_PREFIX = ['/app'];

export const ROUTE_PREFIX_ADMINISTRATION = [
    ...ROUTE_PREFIX,
    'system-administration',
];

export const URL_ADMINISTRATION_APPEARANCE = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'appearance',
];
export const URL_ADMINISTRATION_FLEXI_LISTS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'flexi-lists',
];
export const URL_ADMINISTRATION_REFERENCE_DATA = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'reference-data',
];
export const URL_ADMINISTRATION_OVERVIEW = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'overview',
];
export const URL_ADMINISTRATION_PRODUCTS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'products',
];
export const URL_ADMINISTRATION_PRODUCT_TYPES = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'product-types',
];
export const URL_ADMINISTRATION_BENCHMARKS = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'benchmarks',
];
export const URL_ADMINISTRATION_MANDATES = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'mandates',
];
export const URL_ADMINISTRATION_MANDATE_GROUPS = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'mandate-groups',
];
export const URL_ADMINISTRATION_OBJECTIVES = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'objectives',
];
export const URL_ADMINISTRATION_RISK_LEVELS = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'risk-levels',
];
export const URL_ADMINISTRATION_FEE_STRUCTURES = [
    ...URL_ADMINISTRATION_PRODUCTS,
    'fee-structures',
];
export const URL_ADMINISTRATION_SALUTATION = [
    ...URL_ADMINISTRATION_REFERENCE_DATA,
    'salutation',
];
export const URL_ADMINISTRATION_ROLES_AND_RELATIONSHIPS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'roles-and-relationships',
];
export const URL_ADMINISTRATION_TAGS = [...ROUTE_PREFIX_ADMINISTRATION, 'tags'];
export const URL_ADMINISTRATION_GEOGRAPHIC_DATA = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'geographic-data',
];
export const URL_ADMINISTRATION_COUNTRIES = [
    ...URL_ADMINISTRATION_GEOGRAPHIC_DATA,
    'countries',
];
export const URL_ADMINISTRATION_CURRENCIES = [
    ...URL_ADMINISTRATION_GEOGRAPHIC_DATA,
    'currencies',
];
export const URL_ADMINISTRATION_TEMPLATES = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'document-generation',
];
export const URL_ADMINISTRATION_TEMPLATES_DOCUMENT_TYPES = [
    ...URL_ADMINISTRATION_TEMPLATES,
    'document-type',
];
export const URL_ADMINISTRATION_TEMPLATES_DOCUMENT_TEMPLATES = [
    ...URL_ADMINISTRATION_TEMPLATES,
    'document-template',
];
export const URL_ADMINISTRATION_TEMPLATES_DOCUMENT_REQUIREMENT_TYPES = [
    ...URL_ADMINISTRATION_TEMPLATES,
    'document-requirement-type',
];
export const URL_ADMINISTRATION_USERS_AND_TEAMS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'users-and-teams',
];
export const URL_ADMINISTRATION_USERS_AND_TEAMS_USERS = [
    ...URL_ADMINISTRATION_USERS_AND_TEAMS,
    'users',
];
export const URL_ADMINISTRATION_USERS_AND_TEAMS_TEAMS = [
    ...URL_ADMINISTRATION_USERS_AND_TEAMS,
    'teams',
];
export const URL_ADMINISTRATION_USERS_AND_TEAMS_ROLES = [
    ...URL_ADMINISTRATION_USERS_AND_TEAMS,
    'roles',
];
export const URL_ADMINISTRATION_WEB_HOOKS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'web-hooks',
];
export const URL_ADMINISTRATION_INTERFACE_CONSOLE = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'interface-console',
];
export const URL_ADMINISTRATION_INTERFACE_CONSOLE_BATCH = [
    ...URL_ADMINISTRATION_INTERFACE_CONSOLE,
    'batch',
];
export const URL_ADMINISTRATION_INTERFACE_TEST_FORMS = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'test-forms',
];
export const URL_ADMINISTRATION_REGIONS = [
    ...URL_ADMINISTRATION_REFERENCE_DATA,
    'regions',
];

export const URL_ADMINISTRATION_SYSTEM_LOG = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'system-log',
];

export const URL_ERROR = [...ROUTE_PREFIX, 'error'];
export const URL_ARTICLES = [...ROUTE_PREFIX, 'articles'];
export const URL_CASES = [...ROUTE_PREFIX, 'cases'];
export const URL_CASES_LIST = [...ROUTE_PREFIX, 'cases', 'listing'];
export const URL_CASES_CHARTS = [...ROUTE_PREFIX, 'cases', 'charts'];
export const URL_CASES_WORK_SUB_ITEM = ['work-list', 'work-item'];
export const URL_CASES_APP = ['app'];
export const URL_CASES_STAGE = ['review-stage'];
export const URL_CONTACTS = [...ROUTE_PREFIX, 'contacts'];
export const URL_CONTACTS_PEOPLE = [...URL_CONTACTS, 'people'];
export const URL_CONTACTS_PEOPLE_LISTING = [...URL_CONTACTS_PEOPLE, 'listing'];
export const URL_CONTACTS_PEOPLE_CHARTS = [...URL_CONTACTS_PEOPLE, 'charts'];
export const URL_CONTACTS_ORGANISATIONS = [...URL_CONTACTS, 'organisations'];
export const URL_CONTACTS_ORGANISATIONS_LISTING = [
    ...URL_CONTACTS_ORGANISATIONS,
    'listing',
];
export const URL_CONTACTS_ORGANISATIONS_CHARTS = [
    ...URL_CONTACTS_ORGANISATIONS,
    'charts',
];
export const URL_CONTACTS_INTERMEDIARIES = [...URL_CONTACTS, 'intermediaries'];
export const URL_CONTACTS_INTERMEDIARIES_LISTING = [
    ...URL_CONTACTS_INTERMEDIARIES,
    'listing',
];
export const URL_CONTACTS_ADVISERS = [...URL_CONTACTS, 'advisers'];
export const URL_CONTACTS_ADVISERS_LISTING = [
    ...URL_CONTACTS_ADVISERS,
    'listing',
];
export const URL_CONTACTS_PRODUCT_PROVIDERS = [
    ...URL_CONTACTS,
    'product-providers',
];
export const URL_CONTACTS_PRODUCT_PROVIDERS_LISTING = [
    ...URL_CONTACTS_PRODUCT_PROVIDERS,
    'listing',
];
export const URL_CONTACTS_LEADS = [...URL_CONTACTS, 'leads'];
export const URL_CONTACTS_LEADS_LISTING = [...URL_CONTACTS_LEADS, 'listing'];
export const URL_CONTACTS_LEADS_CHARTS = [...URL_CONTACTS_LEADS, 'charts'];
export const URL_CONTACTS_CLIENT_RELATIONSHIPS = [
    ...URL_CONTACTS,
    'client-relationships',
];
export const URL_CONTACTS_CLIENT_RELATIONSHIPS_LISTING = [
    ...URL_CONTACTS_CLIENT_RELATIONSHIPS,
    'listing',
];
export const URL_CONTACTS_CLIENT_RELATIONSHIPS_CHARTS = [
    ...URL_CONTACTS_CLIENT_RELATIONSHIPS,
    'charts',
];
export const URL_CONTACTS_EXTERNAL_USERS = [...URL_CONTACTS, 'external-users'];
export const URL_CLIENT = [...URL_CONTACTS, 'client'];
export const URL_CONTACT = [...URL_CONTACTS, 'contact'];
export const URL_DASHBOARDS = [...ROUTE_PREFIX, 'dashboards'];
export const URL_DASHBOARD = [...URL_DASHBOARDS, 'dashboard'];
export const URL_DOCUMENTS_CHECKLIST = ['documents-checklist'];
export const URL_ED_MESSAGE_BATCH = [
    ...ROUTE_PREFIX_ADMINISTRATION,
    'interface-console',
    'batch',
];
export const URL_FEED = [...ROUTE_PREFIX, 'feed'];
export const URL_HOME = [...ROUTE_PREFIX, 'home'];
export const URL_INBOX = [...ROUTE_PREFIX, 'inbox'];
export const URL_INBOX_CHARTS = [...ROUTE_PREFIX, 'inbox', 'charts'];
export const URL_MARKETING = [...ROUTE_PREFIX, 'marketing'];
export const URL_MARKETING_CAMPAIGN_RESPONSES = [
    ...URL_MARKETING,
    'campaign-responses',
];
export const URL_MARKETING_CAMPAIGNS = [...URL_MARKETING, 'campaigns'];
export const URL_LISTS = [...ROUTE_PREFIX, 'lists'];
export const URL_CALENDAR = [...ROUTE_PREFIX, 'calendar'];
export const URL_NOTIFICATION = [...ROUTE_PREFIX, 'notifications'];
export const URL_PIPELINE = [...ROUTE_PREFIX, 'pipeline'];
export const URL_PIPELINE_KANBAN = [...URL_PIPELINE, 'kanban'];
export const URL_PIPELINE_LIST = [...URL_PIPELINE, 'list'];
export const URL_PIPELINE_CHARTS = [...URL_PIPELINE, 'charts'];
export const URL_PIPELINE_OPPORTUNITY = [...URL_PIPELINE, 'opportunity'];
export const URL_PRODUCTS = [...ROUTE_PREFIX, 'products'];
export const URL_PRODUCTS_LISTING = [...URL_PRODUCTS, 'listing'];
export const URL_PRODUCTS_CHARTS = [...URL_PRODUCTS, 'charts'];
export const URL_QUEUES = [...ROUTE_PREFIX, 'queues'];
export const URL_TASKS = [...ROUTE_PREFIX, 'tasks'];
export const URL_TASKS_CHARTS = [...URL_TASKS, 'charts'];
export const URL_TEAMS = [...ROUTE_PREFIX, 'teams'];
export const URL_UNIMPLEMENTED = [...ROUTE_PREFIX];
export const URL_EXPORTS = [...ROUTE_PREFIX, 'exports'];
export const URL_IMPORTS = [...ROUTE_PREFIX, 'imports'];
export const URL_MY_ACCOUNT = [...ROUTE_PREFIX, 'my-account'];

export const ENTITY_ROUTE_MAP = {
    [UserActionEntity.Lead]: URL_CONTACT,
    [PipelineActionEntity.OpportunityItem]: URL_PIPELINE_OPPORTUNITY,
    [SystemEntity.Activity]: URL_INBOX,
    [SystemEntity.ActivityParty]: URL_UNIMPLEMENTED,
    [SystemEntity.Adviser]: URL_CONTACTS_ADVISERS,
    [SystemEntity.AmlReport]: URL_UNIMPLEMENTED,
    [SystemEntity.Appointment]: URL_INBOX,
    [SystemEntity.AppStatus]: URL_UNIMPLEMENTED,
    [SystemEntity.Article]: URL_ARTICLES,
    [SystemEntity.ArticleDistribution]: URL_UNIMPLEMENTED,
    [SystemEntity.BankAccount]: URL_UNIMPLEMENTED,
    [SystemEntity.Benchmark]: URL_UNIMPLEMENTED,
    [SystemEntity.Case]: URL_CASES,
    [SystemEntity.Client]: URL_CLIENT,
    [SystemEntity.ClientProduct]: URL_PRODUCTS,
    [SystemEntity.ClientProductRelationship]: URL_UNIMPLEMENTED,
    [SystemEntity.ContactPoint]: URL_UNIMPLEMENTED,
    [SystemEntity.DataHistory]: URL_UNIMPLEMENTED,
    [SystemEntity.DataLock]: URL_UNIMPLEMENTED,
    [SystemEntity.DocumentTemplate]: URL_UNIMPLEMENTED,
    [SystemEntity.EdsMessageBatch]: URL_ED_MESSAGE_BATCH,
    [SystemEntity.ElectronicAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.Email]: URL_INBOX,
    [SystemEntity.Engagement]: URL_INBOX,
    [SystemEntity.ExternalUser]: URL_CONTACT,
    [SystemEntity.Feed]: URL_FEED,
    [SystemEntity.FeedNudge]: URL_UNIMPLEMENTED,
    [SystemEntity.FeeStructure]: URL_UNIMPLEMENTED,
    [SystemEntity.GoalPoint]: URL_UNIMPLEMENTED,
    [SystemEntity.InsuranceAccount]: URL_PRODUCTS,
    [SystemEntity.Intermediary]: URL_CONTACTS_INTERMEDIARIES,
    [SystemEntity.InvestmentAccount]: URL_PRODUCTS,
    // [SystemEntity.Lead]: URL_CONTACT,
    [SystemEntity.LendingAccount]: URL_PRODUCTS,
    [SystemEntity.List]: URL_LISTS,
    [SystemEntity.Mandate]: URL_UNIMPLEMENTED,
    [SystemEntity.Message]: URL_INBOX,
    [SystemEntity.AmlRisk]: URL_UNIMPLEMENTED,
    [SystemEntity.Note]: URL_UNIMPLEMENTED,
    [SystemEntity.Notification]: URL_NOTIFICATION,
    [SystemEntity.Nudge]: URL_UNIMPLEMENTED,
    [SystemEntity.NudgeLog]: URL_UNIMPLEMENTED,
    [SystemEntity.Objective]: URL_UNIMPLEMENTED,
    [SystemEntity.Opportunity]: URL_PIPELINE,
    [SystemEntity.OpportunityTeamMember]: URL_PIPELINE_OPPORTUNITY,
    [SystemEntity.Organisation]: URL_CONTACT,
    [SystemEntity.Party]: URL_CONTACT,
    [SystemEntity.PartyRole]: URL_CLIENT,
    [SystemEntity.PartyRoleAccess]: URL_UNIMPLEMENTED,
    [SystemEntity.Person]: URL_CONTACT,
    [SystemEntity.PhoneAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.PhoneCall]: URL_INBOX,
    [SystemEntity.PostalAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.PotentialDuplicate]: URL_UNIMPLEMENTED,
    [SystemEntity.Process]: URL_UNIMPLEMENTED,
    [SystemEntity.Product]: URL_UNIMPLEMENTED,
    [SystemEntity.ProductGroup]: URL_UNIMPLEMENTED,
    [SystemEntity.ProductGroupMember]: URL_UNIMPLEMENTED,
    [SystemEntity.ProductProvider]: URL_CONTACTS_PRODUCT_PROVIDERS,
    [SystemEntity.QueueItem]: URL_QUEUES,
    [SystemEntity.Relationship]: URL_UNIMPLEMENTED,
    [SystemEntity.Reminder]: URL_UNIMPLEMENTED,
    [SystemEntity.RiskLevel]: URL_UNIMPLEMENTED,
    [SystemEntity.SocialAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.Tag]: URL_UNIMPLEMENTED,
    [SystemEntity.Task]: URL_TASKS,
    [SystemEntity.Team]: URL_TEAMS,
    [SystemEntity.TimeBooking]: URL_UNIMPLEMENTED,
    [SystemEntity.UserDashboard]: URL_DASHBOARDS,
    [SystemEntity.WebHook]: URL_UNIMPLEMENTED,
    [SystemEntity.WorkItem]: URL_CASES_WORK_SUB_ITEM,
};

export const ENTITY_LISTING_MAP = {
    [SystemEntity.Adviser]: URL_CONTACTS_ADVISERS_LISTING,
    [SystemEntity.Client]: URL_CONTACTS_CLIENT_RELATIONSHIPS_LISTING,
    [SystemEntity.Person]: URL_CONTACTS_PEOPLE_LISTING,
    [SystemEntity.ExternalUser]: URL_CONTACTS_EXTERNAL_USERS,
    [SystemEntity.Intermediary]: URL_CONTACTS_INTERMEDIARIES_LISTING,
    [SystemEntity.Lead]: URL_CONTACTS_LEADS_LISTING,
    [SystemEntity.Organisation]: URL_CONTACTS_ORGANISATIONS_LISTING,
    [SystemEntity.ProductProvider]: URL_CONTACTS_PRODUCT_PROVIDERS_LISTING,
};

export const ADMIN_ENTITY_ROUTE_MAP = {
    [SystemEntity.User]: URL_ADMINISTRATION_USERS_AND_TEAMS_USERS,
    [SystemEntity.Team]: URL_ADMINISTRATION_USERS_AND_TEAMS_TEAMS,
};
