import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';

export class BespokeValidators {
    static minArrayLength(
        min: number
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            return !Array.isArray(control.value) || control.value?.length >= min
                ? null
                : { minArrayLength: true };
        };
    }

    static maxArrayLength(
        max: number
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            return Array.isArray(control.value) && control.value.length <= max
                ? null
                : { maxArrayLength: true };
        };
    }

    static maxCurrencyAmount(
        max: number
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            return control.value?.amount > max ? { max: true } : null;
        };
    }

    static minCurrencyAmount(
        min: number
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            return control.value?.amount < min ? { min: true } : null;
        };
    }

    static dateMustBeBefore(
        baseDate: string | Date
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const controlDate = new Date(control.value);

            return controlDate < new Date(baseDate)
                ? null
                : { dateMustBeBefore: true };
        };
    }

    static dateMustBeAfter(
        baseDate: string | Date
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const controlDate = new Date(control.value);

            return controlDate > new Date(baseDate)
                ? null
                : { dateMustBeAfter: true };
        };
    }

    static datesMustBeValidRange(): (
        control: AbstractControl
    ) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const start = control?.value?.start;
            const end = control?.value?.end;
            const START_CONTROL = control?.get('start');
            const END_CONTROL = control?.get('end');

            if (start && end) {
                if (new Date(start) <= new Date(end)) {
                    START_CONTROL?.setErrors(null);
                    END_CONTROL?.setErrors(null);

                    return null;
                }
            }

            START_CONTROL?.setErrors({ invalid: true });
            END_CONTROL?.setErrors({ invalid: true });

            return { datesMustBeValidRange: true };
        };
    }

    static regex(
        pattern: string
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const matches = String(control.value).match(pattern);

            return matches ? null : { regex: true };
        };
    }

    static equalTo(
        formGroup: UntypedFormGroup,
        compareTo: string
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            const controlToCompare = formGroup.controls[compareTo];
            return control.value === controlToCompare.value
                ? null
                : { equalTo: true };
        };
    }

    static mustContainRequiredFields(
        requiredKeys: string[]
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }
            const hasIncompleteFields = requiredKeys.some(
                (requiredKey) => !control.value[requiredKey]
            );
            return hasIncompleteFields
                ? { mustContainRequiredFields: true }
                : null;
        };
    }

    static isTrue(): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: boolean } | null =>
            control.value ? null : { isTrue: true };
    }
}
