import { createAction, props } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/clmi/api-models';
import { ContextualDataContext } from '@wdx/clmi/api-services/models';

export const getSelect = createAction(
    '[Selects] Get Select',
    props<{
        selectSource: string;
    }>()
);

export const getSelectSuccess = createAction(
    '[Selects] Get Select Success',
    props<{
        selectSource: string;
        results: SelectFieldResult[];
    }>()
);

export const getSelectFailure = createAction(
    '[Selects] Get Select Failure',
    props<{
        selectSource: string;
        error: Error;
    }>()
);

export const getContextualSelect = createAction(
    '[Selects] Get Contextual Select',
    props<{
        selectSource: string;
        source: string;
        lookups: any[];
        context?: ContextualDataContext[];
    }>()
);

export const getContextualSelectSuccess = createAction(
    '[Selects] Get Contextual Select Success',
    props<{
        selectSource: string;
        results: SelectFieldResult[];
    }>()
);

export const getContextualSelectFailure = createAction(
    '[Selects] Get Contextual Select Failure',
    props<{
        selectSource: string;
        error: Error;
    }>()
);
