import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { BodyStyleTypes } from '../wdx-accordion.interface';

@Component({
    selector: 'wdx-accordion-panel',
    templateUrl: './wdx-accordion-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAccordionPanelComponent {
    @Input() open = false;
    @Input() removePadding = false;
    @Input() bodyStyles: BodyStyleTypes = null;

    @Output() toggle: EventEmitter<any> = new EventEmitter();

    toggleAccordion($event: any) {
        this.toggle.emit($event);
    }
}
