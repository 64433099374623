import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCurrency' })
export class FormatCurrencyPipe implements PipeTransform {
    transform(value: number, currencyCode: string): string {
        if (!value && value !== 0) {
            return '';
        }

        return new CurrencyPipe('en-GB').transform(
            value,
            currencyCode,
            'symbol',
            '1.0'
        );
    }
}
