import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { CubeBuilderDatasource } from '@wdx/charts/builders';
import {
    Dashboard,
    LookupFieldResult,
    SystemEntity,
    User,
} from '@wdx/clmi/api-models';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as dashboardsActions from '../../state/dashboards/dashboards.actions';
import * as dashboardSelectors from '../../state/dashboards/dashboards.selectors';
import { LookupsService } from '../lookups/lookups.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardsFacadeService {
    dashboardUpdated$ = new BehaviorSubject<Dashboard>(null);
    hasDashboards: boolean;

    private lookupsService = inject(LookupsService);
    private store$ = inject(Store<rootReducer.State>);

    getDashboards$(): Observable<Dashboard[]> {
        return this.store$.select(dashboardSelectors.getDashboards).pipe(
            tap(() => {
                if (!this.hasDashboards) {
                    this.loadDashboards();
                    this.hasDashboards = true;
                }
            }),
            filter((res) => Boolean(res))
        );
    }

    getDashboardById$(dashboardId: string): Observable<Dashboard> {
        return this.getDashboards$().pipe(
            switchMap(() => {
                return this.store$.select(dashboardSelectors.getDashboardById, {
                    id: dashboardId,
                });
            })
        );
    }

    getDashboardsIsLoading$(): Observable<boolean> {
        return this.store$.select(dashboardSelectors.getDashboardsIsLoading);
    }

    getDashboardsIsUpdating$(): Observable<boolean> {
        return this.store$.select(dashboardSelectors.getDashboardsIsUpdating);
    }

    createDashboard(body: any) {
        this.store$.dispatch(dashboardsActions.createDashboard({ body }));
    }

    updateDashboard(dashboardId: string, body: any) {
        this.store$.dispatch(
            dashboardsActions.updateDashboard({ dashboardId, body })
        );
    }

    shareDashboard(dashboardId: string, contactId: string) {
        this.store$.dispatch(
            dashboardsActions.shareDashboard({ dashboardId, contactId })
        );
    }

    unshareDashboard(dashboardId: string, contactId: string) {
        this.store$.dispatch(
            dashboardsActions.unshareDashboard({ dashboardId, contactId })
        );
    }

    deleteDashboard(dashboardId: string, entityType: SystemEntity) {
        this.store$.dispatch(
            dashboardsActions.deleteDashboard({ dashboardId, entityType })
        );
    }

    loadDashboards() {
        this.store$.dispatch(dashboardsActions.getDashboards());
    }

    setCubeBuilderDatasource(): CubeBuilderDatasource {
        return {
            getOwner: () => {
                return this.store$.select(userSelectors.getMeSelector).pipe(
                    map((me: User) => ({
                        id: me.id,
                        name: me.name,
                    }))
                );
            },
            getSharedWith: (params) => {
                return this.lookupsService
                    .getLookup('assignee', params?.searchText, null, null)
                    .pipe(
                        map((lookups: LookupFieldResult[]) =>
                            lookups.map((lookup) => ({
                                id: lookup.id,
                                name: lookup.name,
                            }))
                        )
                    );
            },
        };
    }
}
