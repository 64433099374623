import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiFunctionTriggerPayload } from '../../models/api-function-trigger-payload.model';
import { ConfigService } from '../../services/config.service';

import {
    FormCompletionSummary,
    FormDataResult,
    FormDefinition,
    FormFunctionResult,
    FormTemplate,
    FormTemplateInstance,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';

@Injectable()
export class DynamicFormsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getFormDefinitions(): Observable<FormDefinition[]> {
        return this.http.get<FormDefinition[]>(
            `${this.config.getConfiguration().API_BASE}/formDefinition`
        );
    }

    getFormDefinition(formId: string): Observable<FormDefinition> {
        return this.http.get<FormDefinition>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdefinition/${formId}`
        );
    }

    getFormData(
        formId: string,
        entityId: string,
        endpointPath?: string,
        queryParams?: KeyValueObject
    ): Observable<FormDataResult> {
        const finalEndpointPath = endpointPath
            ? endpointPath
            : `formdata/${formId}`;

        return this.http.get<FormDataResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/${finalEndpointPath}/${entityId}`,
            { params: queryParams || {} }
        );
    }

    getAppFormData(
        appId: string,
        queryParams?: KeyValueObject
    ): Observable<FormDataResult> {
        return this.http.get<FormDataResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/appstatus/${appId}/formdata`,
            { params: queryParams || {} }
        );
    }

    getAppFormDataWithContext(
        appId: string,
        formId?: string,
        entityId?: string
    ): Observable<FormDataResult> {
        return this.http.get<FormDataResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId.toLowerCase()}/${entityId}`,
            { params: { appcontextid: appId } }
        );
    }

    getAppFormDataHistorical(
        formId: string,
        entityId: string,
        formUuid: string
    ): Observable<FormDataResult> {
        return this.http.get<FormDataResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId.toLowerCase()}/${entityId}/history/${formUuid}`
        );
    }

    getFormInitialisationData(
        formId: string,
        initialisationParams: KeyValueObject
    ): Observable<FormDataResult> {
        return this.http.get<FormDataResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}/initialisation`,
            { params: initialisationParams || {} }
        );
    }

    getFormFunctionResult(
        formId: string,
        apiFunctionTriggerPayload: ApiFunctionTriggerPayload,
        subFormName?: string,
        subFormIndex?: number
    ): Observable<FormFunctionResult> {
        let query = '';

        if (subFormName && typeof subFormIndex === 'number') {
            query = `?subFormName=${subFormName}&subFormIndex=${subFormIndex}`;
        }

        return this.http.patch<FormFunctionResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}/function/${
                apiFunctionTriggerPayload.functionName
            }${query}`,
            apiFunctionTriggerPayload.formData
        );
    }

    getFormTemplates(
        entityType: SystemEntity,
        entityId: string
    ): Observable<FormTemplate[]> {
        return this.http.get<FormTemplate[]>(
            `${this.config.getConfiguration().API_BASE}/formtemplate`,
            {
                params: {
                    entityType,
                    entityId,
                },
            }
        );
    }

    getFormTemplateInstance(
        formTemplateId: string,
        entityType: SystemEntity,
        entityId: string
    ): Observable<FormTemplateInstance> {
        return this.http.post<FormTemplateInstance>(
            `${
                this.config.getConfiguration().API_BASE
            }/formtemplate/${formTemplateId}`,
            {
                entityType,
                entityId,
            }
        );
    }

    createNonStandardFormData(
        formData: any,
        endpointPath: string
    ): Observable<any> {
        return this.http.post<any>(
            `${this.config.getConfiguration().API_BASE}/${endpointPath}`,
            formData
        );
    }

    updateNonStandardFormData(
        entityId: string,
        formData: any,
        endpointPath: string
    ): Observable<any> {
        return this.http.put<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${endpointPath}/${entityId}`,
            formData
        );
    }

    updateStandardFormData(
        formId: string,
        entityId: string,
        formData: any,
        publish: boolean,
        ignoreDuplicates?: boolean,
        queryParam?: string
    ): Observable<any> {
        const QUERY_PARAM = queryParam ? `&${queryParam}` : '';
        const publishFlag = `?publish=${publish}${QUERY_PARAM}`;

        if (entityId) {
            return this.http.put<any>(
                `${
                    this.config.getConfiguration().API_BASE
                }/formdata/${formId}/${entityId}${publishFlag}`,
                formData
            );
        }
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}${publishFlag}`,
            formData,
            {
                headers: {
                    ...(ignoreDuplicates
                        ? { 'X-Clmi-Form-Options': 'IgnoreDuplicates' }
                        : {}),
                },
            }
        );
    }

    saveCompletionSummary(
        formId: string,
        entityId: string,
        completionSummary: FormCompletionSummary
    ): Observable<any> {
        return this.http.patch<FormFunctionResult>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}/${entityId}/completion`,
            completionSummary
        );
    }

    // This needs test
    fireFunction(
        formId: string,
        functionName: string,
        formData: any
    ): Observable<any> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}/function/${functionName}`,
            formData
        );
    }
}
