import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity, SystemEntity } from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { INBOX_FILTER_FORM_ID } from '../../../constants/dynamic-form.constants';
import { ActionButton } from '../../../models/action-button.model';
import { CardType } from '../../../models/card-type.model';
import { ListItem } from '../../../models/list-item.model';
import { ScrollMode } from '../../../models/scroll-mode.model';
import { ToDoItem } from '../../../models/to-do-item.model';
import { EntityRoutePipe } from '../../../pipes/entity-route.pipe';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-activities-list',
    templateUrl: './organism-activities-list.component.html',
})
export class OrganismActivitiesListComponent implements OnInit {
    @Input() activities: Activity[];
    @Input() activitiesPaging: Paging;
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() filterHandler: FilterHandler;
    @Input() activitiesPerRow: number;
    @Input() activityCreateActionButton: ActionButton;
    @Input() showFilters: boolean;
    @Input() showFilterBar = true;
    @Input() showSearchBar = true;
    @Input() showFavourite = false;
    @Input() scrollMode: ScrollMode;
    @Input() searchType = 'Activity';
    @Input() searchTypePlural = 'Activities';
    @Input() totalRecordCount: number;
    @Input() limit: number;
    @Input() addWidgetContainerPadding: boolean;

    @Output() infinityScrollFired = new EventEmitter();

    readonly INBOX_FILTER_FORM = INBOX_FILTER_FORM_ID;

    instanceId: string;

    cardType = CardType.Activity;
    filtersModalId = 'activities-list-filter';

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
        this.filtersModalId = new RandomStringPipe().transform();
    }

    onSearch(value: string): void {
        this.filterHandler.setSearchText(value);
    }

    activitiesAsToDoItems(activities: Activity[]): ToDoItem[] {
        return activities
            ? activities.map((activity): ToDoItem => {
                  return {
                      id: activity.id,
                      data: activity,
                      primaryInfo: activity.subject,
                      dueDate: new Date(activity.due),
                      tags: activity.tags?.length,
                      filedUnder: activity.regarding
                          ? activity.regarding.map((regarding) => {
                                return {
                                    label: regarding.name ?? regarding.type,
                                    data: regarding,
                                } as ListItem;
                            })
                          : [],
                      activityType: activity.activityType,
                      attachments: activity.files?.length,
                      avatars: activity.activityParties.map((activityParty) =>
                          new PartyToAvatarPipe().transform(
                              activityParty.party || activityParty.contactPoint
                          )
                      ),
                      remindDate: activity.reminders?.length
                          ? new Date(activity.reminders[0].dateReminder)
                          : null,
                      content: activity.content,
                      status: activity.status,
                      routingLink: {
                          routerLink: new EntityRoutePipe().transform(
                              SystemEntity.Activity,
                              activity.id
                          ),
                      },
                  };
              })
            : [];
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }
}
