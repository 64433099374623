<organism-modal [modalTitle]="!formTitle ? 'Loading...' : formTitle">
    <atom-async-data [isLoading]="!formTitle" [throbberContainerStyle]="false">
        <organism-reactive-form
            [formId]="formId"
            [entityId]="entityId"
            [isQuickCreate]="true"
            [initialisationParams]="initialisationParams"
            [modalInstanceId]="modalInstanceId"
            (formTitleReceived)="onFormTitleReceived($event)"
        ></organism-reactive-form>
    </atom-async-data>
</organism-modal>
