<ng-container [formGroup]="controlContainer?.control">
    <molecule-reactive-input-lookup
        *ngIf="formElement.fieldType === FORM_FIELD_TYPE.LookupSingle"
        [isMulti]="false"
        [formControlName]="formElement.name"
        [formInputData]="formElement"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage: formElement
        "
        [formData]="formData$ | ngrxPush"
        [formContext]="formContext"
    ></molecule-reactive-input-lookup>

    <molecule-reactive-input-lookup
        *ngIf="formElement.fieldType === FORM_FIELD_TYPE.LookupMulti"
        [isMulti]="true"
        [formControlName]="formElement.name"
        [formInputData]="formElement"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage: formElement
        "
        [formData]="formData$ | ngrxPush"
        [formContext]="formContext"
    ></molecule-reactive-input-lookup>

    <molecule-reactive-input-tags
        *ngIf="formElement.fieldType === FORM_FIELD_TYPE.TagListSingle"
        [isMulti]="false"
        [tags]="tags$ | ngrxPush"
        [formControlName]="formElement.name"
        [formInputData]="formElement"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage: formElement
        "
    ></molecule-reactive-input-tags>

    <molecule-reactive-input-tags
        *ngIf="formElement.fieldType === FORM_FIELD_TYPE.TagListMulti"
        [isMulti]="true"
        [tags]="tags$ | ngrxPush"
        [formControlName]="formElement.name"
        [formInputData]="formElement"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage: formElement
        "
    ></molecule-reactive-input-tags>
</ng-container>
