import { Pipe, PipeTransform } from '@angular/core';
import { NOTE_FORM_ID } from '../constants/dynamic-form.constants';
import { NoteData } from '../models/note-data.model';
import { ActivityNote } from '@wdx/clmi/api-models';
import { DateTime } from 'luxon';

@Pipe({ name: 'notesToNoteData' })
export class NotesToNoteDataPipe implements PipeTransform {
    transform(notes: ActivityNote[]): NoteData[] {
        return notes
            ? notes.map<NoteData>((note) => ({
                  dueDate: note.due,
                  formSetup: {
                      formId: NOTE_FORM_ID,
                      entityId: note.id,
                  },
                  isImportant:
                      (note.dateImportantUntil &&
                          DateTime.fromJSDate(note.dateImportantUntil) >
                              DateTime.local()) ||
                      false,
                  value: note.content,
              }))
            : [];
    }
}
