import { Pipe, PipeTransform } from '@angular/core';
import {
    ActivityHistory,
    ArticleHistory,
    FormDataHistory,
} from '@wdx/clmi/api-models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { KeyValueObject } from '@wdx/shared/utils';
import { ICON_APPOINTMENT } from '../constants/icons.constants';
import { ChronologicalListGroup } from '../models/chronological-list-group.model';
import { ChronologicalListItem } from '../models/chronological-list-item.model';
import { DisplayDatePipe } from './display-date.pipe';
import { TitleCasePipe } from './title-case.pipe';

@Pipe({ name: 'historyToChronologicalListGroups' })
export class HistoryToChronologicalListGroupsPipe implements PipeTransform {
    transform(history: {
        activityHistory?: ActivityHistory[];
        articleHistory?: ArticleHistory[];
        formDataHistory?: FormDataHistory[];
    }): ChronologicalListGroup[] {
        if (
            !history ||
            (!history.activityHistory?.length &&
                !history.articleHistory?.length &&
                !history.formDataHistory?.length)
        ) {
            return [];
        }

        let chronologicalListItems: ChronologicalListItem[];

        if (history.activityHistory?.length) {
            chronologicalListItems = history.activityHistory.map((action) => ({
                heading: action.description,
                subHeading:
                    action.action &&
                    new TitleCasePipe().transform(action.action),
                avatar:
                    action.recordedBy &&
                    new PartyToAvatarPipe().transform(action.recordedBy),
                prefix: new DisplayDatePipe().transform(action.dateRecorded, {
                    timeOnly: true,
                }),
                date: action.dateRecorded,
            }));
        }

        if (history.articleHistory?.length) {
            chronologicalListItems = history.articleHistory.map((action) => ({
                heading: action.description,
                subHeading:
                    action.action &&
                    new TitleCasePipe().transform(action.action),
                avatar:
                    action.recordedBy &&
                    new PartyToAvatarPipe().transform(action.recordedBy),
                prefix: new DisplayDatePipe().transform(action.recordedOn, {
                    timeOnly: true,
                }),
                date: action.recordedOn,
            }));
        }

        if (history.formDataHistory?.length) {
            chronologicalListItems = history.formDataHistory.map((action) => ({
                heading: action.status,
                subHeading: action.updatedBy,
                avatar: new PartyToAvatarPipe().transform(action.updatedBy),
                prefix: new DisplayDatePipe().transform(action.updatedOn),
                date: action.updatedOn,
            }));
        }

        return Object.entries(
            chronologicalListItems.reduce((previousListItem, listItem) => {
                const key = new Date(listItem.date).toDateString();
                if (!previousListItem[key]) {
                    previousListItem[key] = [];
                }
                previousListItem[key].push(listItem);
                return previousListItem;
            }, {}) as KeyValueObject
        ).map(
            (dateGroup) =>
                ({
                    groupByDate: new Date(dateGroup[0]),
                    groupByIcon: ICON_APPOINTMENT,
                    list: dateGroup[1].map(
                        (listItem: ChronologicalListItem) =>
                            ({
                                heading: listItem.heading,
                                subHeading: listItem.subHeading,
                                avatar: listItem.avatar,
                                prefix: listItem.prefix,
                            } as ChronologicalListItem)
                    ),
                } as ChronologicalListGroup)
        );
    }
}
