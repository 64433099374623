import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';
import { IFormDynamicData } from '../../../interfaces';
import { BespokeValidators } from '../../../validators/bespoke-validators';

@Component({
    selector: 'wdx-ff-boolean-control',
    templateUrl: './form-boolean-control.component.html',
})
export class FormBooleanControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        if (this.formElement.isRequired) {
            this.formControl?.addValidators([BespokeValidators.isTrue()]);
        }
    }
}
