import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessVisibility,
    Activity,
    ActivityFile,
    ActivityHistory,
    ActivityStatusType,
} from '@wdx/clmi/api-models';

import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ActivitiesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getAttachmentsForId(activityId: string): Observable<ActivityFile[]> {
        return this.http.get<ActivityFile[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/attachment`
        );
    }

    getDocumentsForId(
        paginationOptions: PaginationOptions,
        activityId: string
    ): Observable<PaginatedApiResponse<Document>> {
        return this.http.get<PaginatedApiResponse<Document>>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/document`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getLastActivityForParty(partyId: string): Observable<Activity> {
        return this.http.get<Activity>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/activities/last`
        );
    }

    getNextActivityForParty(partyId: string): Observable<Activity> {
        return this.http.get<Activity>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/activities/next`
        );
    }

    getForClientProduct(
        paginationOptions: PaginationOptions,
        clientProductId: string
    ): Observable<PaginatedApiResponse<Activity>> {
        return this.http.get<PaginatedApiResponse<Activity>>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/activities`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getForClient(
        paginationOptions: PaginationOptions,
        clientId: string
    ): Observable<PaginatedApiResponse<Activity>> {
        return this.http.get<PaginatedApiResponse<Activity>>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${clientId}/activities`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getForId(activityId: string): Observable<Activity> {
        return this.http.get<Activity>(
            `${this.config.getConfiguration().API_BASE}/activity/${activityId}`
        );
    }

    setStatusForId(
        activityId: string,
        status: ActivityStatusType
    ): Observable<any> {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/status/${status.toLowerCase()}`,
            {}
        );
    }

    getHistoryForId(activityId: string): Observable<ActivityHistory[]> {
        return this.http.get<ActivityHistory[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/history`
        );
    }

    setDueDate(activityId: string, date: Date): Observable<Activity> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/duedate`,
            {
                DueDate: date.toISOString(),
            }
        );
    }

    getVisibilityForId(id: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${id}/visibility`
        );
    }

    uploadAttachment(
        activityId: string,
        attachment: File
    ): Observable<HttpEvent<any>> {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/attachment`,
            attachment,
            {
                headers: {
                    'Content-Type': attachment.type,
                    'Content-Disposition': `attachment; filename="${attachment.name}"`,
                },
                reportProgress: true,
            }
        );
    }

    cancelActivity(activityId: string): Observable<Activity> {
        return this.http.patch<Activity>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/status/cancelled`,
            {}
        );
    }
}
