import { Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Tag } from '@wdx/clmi/api-models';
import { UploaderType } from '../../../../../models/uploader-type.model';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services/dynamic-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';

@Component({
    selector: 'clmi-reactive-wrapped-control',
    templateUrl: './reactive-wrapped-control.component.html',
})
export class ReactiveWrappedControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    @Input() formContext: any;

    tags$: Observable<Tag[]>;
    formData$ = new BehaviorSubject<UntypedFormGroup>(null);

    readonly UPLOADER_TYPE = UploaderType;

    constructor(
        public controlContainer: FormGroupDirective,
        public dynamicDataService: ReactiveFormDynamicDataService
    ) {
        super(controlContainer, dynamicDataService);

        this.controlContainer.form.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
                this.formData$.next(this.controlContainer?.form.getRawValue());
            });
    }

    ngOnInit(): void {
        if (this.formElement.tagCategory) {
            this.tags$ = this.dynamicDataService.getTagsForCategory(
                this.formElement.tagCategory
            );
        }

        if (this.formElement?.contextualValues?.length) {
            this.formData$.next(this.controlContainer?.form.getRawValue());
        }

        this.formElement.isDisabled = this.formControl?.disabled;
    }
}
