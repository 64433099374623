import { createAction, props } from '@ngrx/store';
import { PartyAvatarInfo } from '@wdx/clmi/api-models';

export const getForId = createAction(
    '[Avatar Info] Get For ID',
    props<{
        partyId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Avatar Info] Get For ID Success',
    props<{
        partyId: string;
        avatarInfo: PartyAvatarInfo;
    }>()
);

export const getForIdFailure = createAction(
    '[Avatar Info] Get For ID Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);
