import {
    Icon,
    IconStyle,
    UTILS_ICON_ADD,
    UTILS_ICON_CHECK,
    UTILS_ICON_CIRCLE,
    UTILS_ICON_MINUS,
    UTILS_ICON_USER,
} from '@wdx/shared/utils';

// eslint-disable-next-line max-len

export const ICON_STYLE_FAS = 'fas';
export const ICON_STYLE_FAL = 'fal';

export const ICON_ADD: Icon = {
    icon: UTILS_ICON_ADD,
};
export const ICON_ADD_CIRCLE: Icon = {
    icon: 'plus-circle',
};
export const ICON_ADD_TO_ENVELOPE: Icon = {
    icon: 'envelope-open-text',
};
export const ICON_ADVOCATE: Icon = {
    icon: 'bullhorn',
};
export const ICON_APPOINTMENT: Icon = {
    icon: 'calendar-alt',
};
export const ICON_ARCHIVE: Icon = {
    icon: 'archive',
};
export const ICON_ARTICLE: Icon = {
    icon: 'newspaper',
};
export const ICON_ARTICLE_OPEN: Icon = {
    icon: 'book-open',
};
export const ICON_BRIEFCASE: Icon = {
    icon: 'briefcase',
};
export const ICON_ASSIGN: Icon = {
    icon: 'arrow-right',
};
export const ICON_ASSIGN_TO_ME: Icon = {
    icon: 'user-check',
};
export const ICON_AUM: Icon = {
    icon: 'wallet',
};
export const ICON_BACK: Icon = {
    icon: 'arrow-left',
};
export const ICON_BACK_BRACKET: Icon = {
    icon: 'arrow-right-from-bracket',
};
export const ICON_BAN: Icon = {
    icon: 'ban',
};
export const ICON_BLOCKED: Icon = {
    icon: 'ban',
};
export const ICON_BROWSER: Icon = {
    icon: 'browser',
};
export const ICON_BURGER_MENU: Icon = {
    icon: 'bars',
};
export const ICON_CABINET_FILING_LIGHT: Icon = {
    icon: 'cabinet-filing',
    style: IconStyle.Light,
};
export const ICON_CANCEL: Icon = {
    icon: 'times-circle',
};
export const ICON_CASE: Icon = {
    icon: 'suitcase',
};
export const ICON_CHECK: Icon = {
    icon: UTILS_ICON_CHECK,
};
export const ICON_CHECK_CIRCLE: Icon = {
    icon: 'check-circle',
};
export const ICON_CHECK_DOUBLE: Icon = {
    icon: 'check-double',
};
export const ICON_CHEVRON_DOWN: Icon = {
    icon: 'chevron-down',
};
export const ICON_CHEVRON_UP: Icon = {
    icon: 'chevron-up',
};
export const ICON_CHEVRON_LEFT: Icon = {
    icon: 'chevron-left',
};
export const ICON_CHEVRON_RIGHT: Icon = {
    icon: 'chevron-right',
};
export const ICON_CIRCLE_SOLID: Icon = {
    icon: UTILS_ICON_CIRCLE,
};
export const ICON_CIRCLE_XMARK: Icon = {
    icon: 'circle-xmark',
};
export const ICON_CLIENT: Icon = {
    icon: 'handshake',
};
export const ICON_CLIPBOARD_LIST_CHECK: Icon = {
    icon: 'clipboard-list-check',
};
export const ICON_CLONE: Icon = {
    icon: 'clone',
};
export const ICON_CLOSE: Icon = {
    icon: 'times',
};
export const ICON_CODE: Icon = {
    icon: 'code',
};
export const ICON_COMMENT: Icon = {
    icon: 'comment',
    style: IconStyle.Solid,
};
export const ICON_COMMENT_ISSUE: Icon = {
    icon: 'comment-exclamation',
};
export const ICON_COMMENT_PLUS: Icon = {
    icon: 'comment-plus',
};
export const ICON_COMPLETE: Icon = {
    icon: 'check-circle',
    style: IconStyle.Light,
};
export const ICON_CONTENT: Icon = {
    icon: 'file',
};
export const ICON_CONTEXT_MENU: Icon = {
    icon: 'ellipsis-h',
    style: IconStyle.Solid,
};
export const ICON_CONTEXT_MENU_CIRCLE: Icon = {
    stack: [
        {
            icon: ICON_CONTEXT_MENU.icon,
            style: IconStyle.Light,
            scale: 0.8,
        },
        {
            icon: 'circle',
            style: IconStyle.Light,
        },
    ],
};
export const ICON_COPY: Icon = {
    icon: 'copy',
};
export const ICON_GEOGRAPHIC_DATA: Icon = {
    icon: 'globe',
};
export const ICON_COVERAGE: Icon = {
    icon: 'user-tie',
    style: IconStyle.Solid,
};
export const ICON_DANGER: Icon = {
    icon: 'exclamation-circle',
};
export const ICON_DASHBOARD: Icon = {
    icon: 'user-chart',
};
export const ICON_DELEGATE: Icon = {
    icon: 'user-tag',
};
export const ICON_DELETE: Icon = {
    icon: 'trash-alt',
};
export const ICON_DISQUALIFIED = {
    icon: 'ban',
};
export const ICON_DEACTIVATE = {
    icon: 'archive',
};
export const ICON_ACTIVATE = {
    icon: 'paper-plane',
};
export const ICON_DOCUMENT: Icon = {
    icon: 'file',
};
export const ICON_TEXT_DOCUMENT: Icon = {
    icon: 'file-lines',
};

export const ICON_TRIANGLE_EXCLAMATION: Icon = {
    icon: 'triangle-exclamation',
};

export const ICON_DOCUMENT_ASSOCIATION: Icon = {
    icon: 'folder-user',
};
export const ICON_DUE: Icon = {
    icon: 'alarm-clock',
};
export const ICON_GENERATE_DOCUMENT: Icon = {
    icon: 'file-plus',
};
export const ICON_DOCUMENT_SOLID: Icon = {
    icon: 'file',
    style: IconStyle.Solid,
};
export const ICON_EMPTY: Icon = {
    icon: null,
};
export const ICON_FILE_ALT: Icon = {
    icon: 'file-alt',
};
export const ICON_FILE_CHECK: Icon = {
    icon: 'file-check',
};
export const ICON_FILE_SIGNATURE: Icon = {
    icon: 'file-signature',
};
export const ICON_SIGNATURE: Icon = {
    icon: 'signature',
};
export const ICON_DOCUMENT_UPLOAD: Icon = {
    icon: 'file-upload',
};
export const ICON_DOWNLOAD: Icon = {
    icon: 'download',
};
export const ICON_DRAG_HANDLE: Icon = {
    icon: 'grip-vertical',
};
export const ICON_DROP: Icon = {
    icon: 'hand-pointer',
};
export const ICON_DROPDOWN: Icon = {
    icon: 'chevron-down',
};
export const ICON_EDIT: Icon = {
    icon: 'pen',
};
export const ICON_EMAIL: Icon = {
    icon: 'at',
};
export const ICON_ENGAGED: Icon = {
    icon: 'handshake',
};
export const ICON_ENVELOPE: Icon = {
    icon: 'envelope',
};
export const ICON_EQUALS: Icon = {
    icon: 'equals',
};
export const ICON_EXPORTS: Icon = {
    icon: 'file-export',
};
export const ICON_EXTERNAL_LINK: Icon = {
    icon: 'external-link',
};
export const ICON_EXTERNAL_USER: Icon = {
    icon: 'users-rectangle',
};
export const FULLSCREEN: Icon = {
    icon: 'expand-arrows-alt',
};

export const EXPAND: Icon = {
    icon: 'expand',
};

export const ICON_EYE: Icon = {
    icon: 'eye',
};

export const ICON_FAVOURITE_BASE: Icon = {
    icon: 'star',
    style: IconStyle.Solid,
};
export const ICON_FAVOURITE_LIGHT: Icon = {
    icon: ICON_FAVOURITE_BASE.icon,
    style: IconStyle.Light,
};
export const ICON_FEED: Icon = {
    icon: 'rss',
};
export const ICON_FILE: Icon = {
    icon: 'folder-open',
};
export const ICON_FILE_CSV: Icon = {
    icon: 'file-csv',
};
export const ICON_FILE_EXCEL: Icon = {
    icon: 'file-excel',
};
export const ICON_FILE_EXPORT: Icon = {
    icon: 'file-export',
};
export const ICON_FILE_IMPORT: Icon = {
    icon: 'file-import',
};
export const ICON_FILE_IMAGE: Icon = {
    icon: 'file-image',
};
export const ICON_FILE_CODE: Icon = {
    icon: 'file-code',
};
export const ICON_FILE_PDF: Icon = {
    icon: 'file-pdf',
};
export const ICON_FILE_POWERPOINT: Icon = {
    icon: 'file-powerpoint',
};
export const ICON_FILE_WORD: Icon = {
    icon: 'file-word',
};
export const ICON_FILE_SEARCH: Icon = {
    icon: 'file-search',
};
export const ICON_FILTER_BASE: Icon = {
    icon: 'filter',
    style: IconStyle.Solid,
};
export const ICON_FILTER_LIGHT: Icon = {
    icon: 'filter',
    style: IconStyle.Light,
};
export const ICON_FLAG_BASE: Icon = {
    icon: 'flag',
    style: IconStyle.Light,
};
export const ICON_FLAG_SOLID: Icon = {
    icon: 'flag',
    style: IconStyle.Solid,
};
export const ICON_FLIGHT_RISK = {
    icon: 'person-sign',
};
export const ICON_FOLDER = {
    icon: 'folder',
};
export const ICON_FOLDERS = {
    icon: 'folders',
};
export const ICON_GREATER_THAN: Icon = {
    icon: 'greater-than',
};
export const ICON_GREATER_THAN_EQUAL: Icon = {
    icon: 'greater-than-equal',
};
export const ICON_GRID = {
    icon: 'grip-horizontal',
    style: IconStyle.Solid,
};
export const ICON_HEATMAP = {
    icon: 'monitor-heart-rate',
};
export const ICON_HELP = {
    icon: 'question-circle',
};
export const ICON_HIDE = {
    icon: 'eye-slash',
};
export const ICON_HOME = {
    icon: 'home',
};
export const ICON_INBOX: Icon = {
    icon: 'inbox',
};

export const ICON_INBOX_IN = {
    icon: 'inbox-in',
};

export const ICON_INBOX_OUT = {
    icon: 'inbox-out',
};

export const ICON_INSIGHTS = {
    icon: 'chart-pie',
};
export const ICON_HOURGLASS_START = {
    icon: 'hourglass-start',
};
export const ICON_INFO: Icon = {
    icon: 'info-circle',
};
export const ICON_KANBAN: Icon = {
    icon: 'columns',
};
export const ICON_LEAD: Icon = {
    stack: [
        {
            icon: 'user',
            style: IconStyle.Light,
            scale: 0.6,
            offsetTop: -25,
        },
        {
            icon: 'funnel-dollar',
            style: IconStyle.Light,
            offsetTop: 20,
            scale: 0.9,
        },
    ],
};
export const ICON_LEFT: Icon = {
    icon: 'user-slash',
};
export const ICON_LESS_THAN: Icon = {
    icon: 'less-than',
};
export const ICON_LESS_THAN_EQUAL: Icon = {
    icon: 'less-than-equal',
};
export const ICON_LETTER: Icon = {
    icon: 'envelope',
};
export const ICON_LIKE_BASE: Icon = {
    icon: 'thumbs-up',
    style: IconStyle.Light,
};
export const ICON_LIKE_SOLID: Icon = {
    icon: 'thumbs-up',
    style: IconStyle.Solid,
};
export const ICON_LINK: Icon = {
    icon: 'link',
};
export const ICON_LIST: Icon = {
    icon: 'list',
};
export const ICON_LISTS: Icon = {
    icon: 'list',
};
export const ICON_CHART: Icon = {
    icon: 'chart-mixed',
};
export const ICON_LOCATION = {
    icon: 'map-marker-alt',
};
export const ICON_LOCK: Icon = {
    icon: 'lock',
    style: IconStyle.Solid,
};
export const ICON_MARKETING = {
    icon: 'megaphone',
};
export const ICON_MISSING: Icon = {
    icon: 'times-square',
};
export const ICON_MINUS = {
    icon: UTILS_ICON_MINUS,
};
export const ICON_MEETING: Icon = {
    icon: 'calendar-alt',
};
export const ICON_MESSAGE: Icon = {
    icon: 'comments-alt',
};
export const ICON_MONEY: Icon = {
    icon: 'coins',
};
export const ICON_NEW_TAB: Icon = {
    icon: 'up-right-from-square',
};
export const ICON_NOT_EQUAL: Icon = {
    icon: 'not-equal',
};
export const ICON_NOTE: Icon = {
    icon: 'sticky-note',
};
export const ICON_ONBOARDING: Icon = {
    icon: 'door-open',
};
export const ICON_OPPORTUNITY: Icon = {
    icon: 'funnel-dollar',
};
export const ICON_OPTIONS: Icon = {
    icon: 'wrench',
};
export const ICON_ORGANISATION: Icon = {
    icon: 'building',
    style: IconStyle.Light,
};
export const ICON_PASSWORD: Icon = {
    icon: 'key',
};
export const ICON_PERSON_BASE: Icon = {
    icon: UTILS_ICON_USER,
};
export const ICON_PERSON_SOLID: Icon = {
    icon: 'user',
    style: IconStyle.Solid,
};
export const ICON_PHONECALL: Icon = {
    icon: 'phone',
};
export const ICON_PHONECALL_OUTGOING: Icon = {
    stack: [
        {
            icon: 'phone',
            style: IconStyle.Light,
        },
        {
            icon: 'arrow-up',
            style: IconStyle.Light,
            rotate: 45,
            offsetTop: -15,
            offsetLeft: 15,
            scale: 0.5,
        },
    ],
};
export const ICON_PHONECALL_INCOMING: Icon = {
    stack: [
        {
            icon: 'phone',
            style: IconStyle.Light,
        },
        {
            icon: 'arrow-down',
            style: IconStyle.Light,
            rotate: 45,
            offsetTop: -15,
            offsetLeft: 15,
            scale: 0.5,
        },
    ],
};
export const ICON_PIPELINE: Icon = {
    icon: 'funnel-dollar',
};
export const ICON_PITCHING: Icon = {
    icon: 'chalkboard-teacher',
};
export const ICON_PORTFOLIO_360: Icon = {
    icon: 'chart-area',
};
export const ICON_PREVIEW: Icon = {
    icon: 'eye',
};
export const ICON_PRINT: Icon = {
    icon: 'print',
};
export const ICON_PROJECT_DIAGRAM_SOLID: Icon = {
    icon: 'project-diagram',
    style: IconStyle.Solid,
};
export const ICON_PROMOTION: Icon = {
    icon: 'level-up',
};
export const ICON_PROSPECTS: Icon = {
    icon: 'users',
};
export const ICON_PUBLISH: Icon = {
    icon: 'upload',
};
export const ICON_QUESTION: Icon = {
    icon: 'question',
    style: IconStyle.Solid,
};
export const ICON_QUEUES: Icon = {
    icon: 'poll-people',
};
export const ICON_REDO: Icon = {
    icon: 'redo',
};
export const ICON_REQUIRED: Icon = {
    icon: 'asterisk',
};
export const ICON_SAVE: Icon = {
    icon: 'save',
};
export const ICON_SCHEDULED: Icon = {
    icon: 'calendar',
};
export const ICON_SEARCH_BASE: Icon = {
    icon: 'search',
    style: IconStyle.Light,
};
export const ICON_SEARCH_SOLID: Icon = {
    icon: 'search',
    style: IconStyle.Solid,
};
export const ICON_SELECT: Icon = {
    icon: 'check-square',
};
export const ICON_SEND_INVITE = {
    icon: 'paper-plane-top',
};
export const ICON_SENSITIVE: Icon = {
    icon: 'shield-check',
};
export const ICON_SETTINGS: Icon = {
    icon: 'cog',
};
export const ICON_SHARE: Icon = {
    icon: 'share-alt',
};
export const ICON_SIGN_OUT: Icon = {
    icon: 'sign-out',
};
export const ICON_SORT_ARROWS: Icon = {
    stack: [
        {
            icon: 'chevron-up',
            style: IconStyle.Light,
            offsetLeft: 0,
            offsetTop: -15,
            scale: 0.5,
        },
        {
            icon: 'chevron-down',
            style: IconStyle.Light,
            offsetLeft: 0,
            offsetTop: 15,
            scale: 0.5,
        },
    ],
};
export const ICON_SORT_ARROW_DOWN: Icon = {
    icon: 'chevron-down',
};
export const ICON_SORT_ARROW_UP: Icon = {
    icon: 'chevron-up',
};
export const ICON_SORT_ASC: Icon = {
    icon: 'arrow-up',
    style: IconStyle.Light,
};
export const ICON_SORT_DESC: Icon = {
    icon: 'arrow-down',
    style: IconStyle.Light,
};
export const ICON_SORT_DOWN_BASE: Icon = {
    icon: 'sort-amount-down',
    style: IconStyle.Light,
};
export const ICON_SORT_DOWN_SOLID: Icon = {
    icon: 'sort-amount-down',
    style: IconStyle.Solid,
};

export const ICON_SORT_UP_BASE: Icon = {
    icon: 'sort-amount-up',
    style: IconStyle.Light,
};
export const ICON_SORT_UP_SOLID: Icon = {
    icon: 'sort-amount-up',
    style: IconStyle.Solid,
};
export const ICON_STATUS: Icon = {
    icon: 'check-double',
};
export const ICON_SUCCESS: Icon = {
    icon: 'check-circle',
};
export const ICON_SUMMARY_REPORT: Icon = {
    icon: 'memo-circle-info',
};
export const ICON_SWITCHED: Icon = {
    icon: 'arrows-retweet',
};
export const ICON_SYNC: Icon = {
    icon: 'sync',
};
export const ICON_TABLE_DATA: Icon = {
    icon: 'table-list',
};
export const ICON_TARGET: Icon = {
    icon: 'bullseye-arrow',
};
export const ICON_TASK: Icon = {
    icon: 'tasks',
};
export const ICON_TAG: Icon = {
    icon: 'tag',
};
export const ICON_TEAM: Icon = {
    icon: 'users',
};
export const ICON_TEXT_FORMATTING: Icon = {
    stack: [
        {
            icon: 'font',
            style: IconStyle.Light,
            scale: 0.8,
        },
        {
            icon: 'grip-lines',
            style: IconStyle.Light,
            offsetTop: 32,
            scale: 0.8,
        },
    ],
};
export const ICON_THEME_DARK: Icon = {
    icon: 'moon',
};
export const ICON_THEME_LIGHT: Icon = {
    icon: 'sun',
};
export const ICON_THEME_OS: Icon = {
    icon: 'moon-over-sun',
};
export const ICON_TIMELINE: Icon = {
    icon: 'timeline',
};
export const ICON_UNLINK: Icon = {
    icon: 'unlink',
};
export const ICON_UNWINDING: Icon = {
    icon: 'person-carry',
};
export const ICON_UPLOAD: Icon = {
    icon: 'upload',
};
export const ICON_USER_COG: Icon = {
    icon: 'user-cog',
};
export const ICON_USER_CROWN: Icon = {
    icon: 'user-crown',
};
export const ICON_USER_PLUS: Icon = {
    icon: 'user-plus',
};
export const ICON_VISIBILITY: Icon = {
    icon: 'eye',
};
export const ICON_WARNING: Icon = {
    icon: 'exclamation-triangle',
};
export const ICON_REMOVE: Icon = {
    icon: 'trash-alt',
};
export const ICON_USER: Icon = {
    icon: 'user',
};
export const ICON_PRODUCT: Icon = {
    icon: 'box-open',
};
export const ICON_PROFILE: Icon = {
    icon: 'person',
};
export const ICON_INTERFACE_CONSOLE: Icon = {
    icon: 'puzzle-piece',
};
export const ICON_REPLAY: Icon = {
    icon: 'redo',
};
export const ICON_FLUX_CAPACITOR: Icon = {
    icon: 'flux-capacitor',
};
export const ICON_POWERBI = {
    icon: 'chart-area',
};
export const ICON_NETWORK = {
    icon: 'chart-network',
};
export const ICON_BOLT = {
    icon: 'bolt',
};
export const ICON_SPINNER = {
    icon: 'circle-notch',
};

export const ICON_ENVELOPE_DOT: Icon = {
    icon: 'envelope-dot',
};

export const ICON_HISTORY: Icon = {
    icon: 'reply-time',
};
