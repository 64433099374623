export const UTILS_ICON_CLOSE = 'times';
export const UTILS_ICON_ACTIVATE = 'paper-plane';
export const UTILS_ICON_DEACTIVATE = 'archive';
export const UTILS_ICON_DELETE = 'trash-alt';
export const UTILS_ICON_REFRESH = 'refresh';
export const UTILS_ICON_DROPDOWN = 'chevron-down';
export const UTILS_ICON_MINUS = 'minus';
export const UTILS_ICON_ADD = 'plus';
export const UTILS_ICON_USER = 'user';
export const UTILS_ICON_CIRCLE = 'circle';
export const UTILS_ICON_CHECK = 'check';
export const UTILS_ICON_INFO_CIRCLE = 'exclamation-circle';
export const UTILS_ICON_CONTEXT_MENU = 'ellipsis-h';
export const UTILS_ICON_QUESTION = 'question';
export const UTILS_ICON_MISSING = 'times-square';
export const UTILS_ICON_PENCIL = 'pencil';
export const UTILS_ICON_FAVOURITE = 'star';
export const UTILS_ICON_SUCCESS = 'check-circle';
export const UTILS_ICON_WARNING = 'exclamation-triangle';
export const UTILS_ICON_DANGER = 'exclamation-circle';
