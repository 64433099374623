import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as assignActions from './assign.actions';
import { AssignService } from './assign.service';

@Injectable()
export class AssignEffects {
    private actions$ = inject(Actions);
    private assignService = inject(AssignService);
    private toastService = inject(WdxToastService);

    assignTo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(assignActions.assignTo),
            switchMap((action) =>
                this.assignService
                    .assignTo(
                        action.entityType,
                        action.entityId,
                        action.partyId
                    )
                    .pipe(
                        map(() => assignActions.assignToSuccess(action)),
                        catchError((error) =>
                            of(
                                assignActions.assignToFailure({
                                    entityId: action.entityId,
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    assignToSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(assignActions.assignToSuccess),
                tap((action) => {
                    this.toastService.show({
                        body: [`${action.entityType} successfully assigned`],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );
}
