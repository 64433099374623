import { createSelector } from '@ngrx/store';
import * as avatarInfoReducer from './avatar-info.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    avatarInfo: avatarInfoReducer.State;
}): avatarInfoReducer.State => state.avatarInfo;

/**
 * Reducer property selectors
 */
export const getIsLoadingSingle = createSelector(
    getState,
    (state: avatarInfoReducer.State) => state?.avatarInfo?.isLoadingSingle
);
export const getHasLoadingSingleError = createSelector(
    getState,
    (state: avatarInfoReducer.State) => state?.avatarInfo?.hasLoadingSingleError
);
export const getSingle = createSelector(
    getState,
    (state: avatarInfoReducer.State) => state?.avatarInfo?.single
);
