import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services/dynamic-data';
import { ThemeService } from '../../../../../shared/services/theme/theme.service';
import { BehaviorSubject, delay, takeUntil, tap } from 'rxjs';
import showdown from 'showdown';
import { RawEditorOptions } from 'tinymce';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';

@Component({
    selector: 'clmi-reactive-rich-text-control',
    templateUrl: './reactive-rich-text-control.component.html',
})
export class ReactiveRichTextControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    editorApiKey = 'hq75q3gj9wdn5of71vw6i0ly4et010pmlekas8oddc053jls';
    editorConfig: RawEditorOptions = {
        menubar: false,
        plugins: 'autolink link lists',
        toolbar: 'bold italic link | bullist numlist',
        branding: false,
        height: 200,
        statusbar: false,
        content_css: 'default',
        font_css:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap',
    };
    initialValue$ = new BehaviorSubject<string>(null);
    ready$ = new BehaviorSubject<boolean>(false);

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService,
        private themeService: ThemeService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.themeService.currentTheme$
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.editorConfig = {
                        ...this.editorConfig,
                        content_style: `
                        html {
                            font-size: ${this.themeService.getThemeValue(
                                'root-font-size'
                            )};
                        }
                        body {
                            color: ${this.themeService.getThemeValue(
                                'body-color'
                            )};
                            background-color: ${this.themeService.getThemeValue(
                                'body-bg'
                            )};
                            font-family: ${this.themeService.getThemeValue(
                                'body-font-family'
                            )};
                            font-size: ${this.themeService.getThemeValue(
                                'body-font-size'
                            )};
                        }
                    `,
                    };
                }),
                delay(50)
            )
            .subscribe(() => {
                this.initialValue$.next(
                    this.convertIn(this.formControl?.value)
                );
                this.ready$.next(true);
            });
    }

    convertOut(htmlValue: string): string {
        return new showdown.Converter().makeMarkdown(htmlValue || '');
    }

    convertIn(markdownValue: string): string {
        return new showdown.Converter().makeHtml(markdownValue || '');
    }

    onEditorChange(event: any) {
        const content = event.editor.getContent();
        this.formControl?.setValue(this.convertOut(content));
    }
}
