import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { WdxAccordionPanelComponent } from '../panel/wdx-accordion-panel.component';

@Component({
    selector: 'wdx-accordion',
    templateUrl: './wdx-accordion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAccordionComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(WdxAccordionPanelComponent)
    panels?: QueryList<WdxAccordionPanelComponent>;

    @Input() flush?: boolean;
    @Input() widget?: boolean;

    private destroyed$ = new Subject<boolean>();

    ngAfterContentInit() {
        this.panels?.toArray()?.forEach((panel: WdxAccordionPanelComponent) => {
            panel.toggle.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                this.openPanel(panel);
            });
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    openPanel(panel: WdxAccordionPanelComponent) {
        panel.open = !panel.open;
    }
}
