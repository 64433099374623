import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WdxSize } from '@wdx/shared/utils';

import { Icon, IconStyle, Severity } from '@wdx/shared/utils';
import { POPOVER_OPEN_DELAY } from '../../../constants/popover.constants';
import { FormSetup } from '../../../models/form-setup.model';
import { NotificationAlert } from '../../../models/notification.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-icon-button',
    templateUrl: './atom-icon-button.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block position-relative' },
})
export class AtomIconButtonComponent {
    @Input() btnClass = 'btn-outline-primary'; // classes to apply directly to button
    @Input() disabled: boolean;
    @Input() icon: Icon | string;
    @Input() altText: string;
    @Input() notification: NotificationAlert;
    @Input() size: WdxSize = 'md';
    @Input() cySelector?: string;
    @Input() formSetup: FormSetup;
    @Input() iconStyle = IconStyle.Light;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter<MouseEvent>();

    readonly NOTIFICATION_SEVERITY = Severity;
    readonly POPOVER_OPEN_DELAY = POPOVER_OPEN_DELAY;

    onIconButtonClicked($event: MouseEvent): void {
        this.onClick.emit($event);
    }
}
