import { createAction, props } from '@ngrx/store';
import {
    Document,
    Envelope,
    Signatory,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/shared/utils';

export const getAll = createAction('[Envelope] Get All');
export const getAllSuccess = createAction(
    '[Envelope] Get All Success',
    props<{
        envelopes: PaginatedApiResponse<Envelope>;
    }>()
);
export const getAllFailure = createAction(
    '[Envelope] Get All Failure',
    props<{
        error: Error;
    }>()
);

export const getForEntityId = createAction(
    '[Envelope] Get All For Entity Id',
    props<{
        entity: SystemEntity;
        entityId: string;
    }>()
);
export const getForEntityIdSuccess = createAction(
    '[Envelope] Get All For Entity Id Success',
    props<{
        entity: SystemEntity;
        entityId: string;
        envelopes: Envelope[];
    }>()
);
export const getForEntityIdFailure = createAction(
    '[Envelope] Get All For Entity Id Failure',
    props<{
        entity: SystemEntity;
        entityId: string;
        error: Error;
    }>()
);

export const getForId = createAction(
    '[Envelope] Get For Id',
    props<{
        id: string;
    }>()
);
export const getForIdSuccess = createAction(
    '[Envelope] Get For Id Success',
    props<{
        id: string;
        envelope: Envelope;
    }>()
);
export const getForIdFailure = createAction(
    '[Envelope] Get For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const clearActiveEnvelope = createAction(
    '[Envelope] Clear Active Envelope'
);

export const sendForId = createAction(
    '[Envelope] Send For Id',
    props<{
        id: string;
    }>()
);
export const sendForIdSuccess = createAction(
    '[Envelope] Send For Id Success',
    props<{
        id: string;
        envelope: Envelope;
    }>()
);
export const sendForIdFailure = createAction(
    '[Envelope] Send For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const resendForId = createAction(
    '[Envelope] Resend For Id',
    props<{
        id: string;
    }>()
);
export const resendForIdSuccess = createAction(
    '[Envelope] Resend For Id Success',
    props<{
        id: string;
        envelope: Envelope;
    }>()
);
export const resendForIdFailure = createAction(
    '[Envelope] Resend For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const deleteForId = createAction(
    '[Envelope] Delete For Id',
    props<{
        id: string;
    }>()
);
export const deleteForIdSuccess = createAction(
    '[Envelope] Delete For Id Success',
    props<{
        id: string;
    }>()
);
export const deleteForIdFailure = createAction(
    '[Envelope] Delete For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const getDocumentsForId = createAction(
    '[Envelope] Get Documents For Id',
    props<{
        id: string;
    }>()
);
export const getDocumentsForIdSuccess = createAction(
    '[Envelope] Get Documents For Id Success',
    props<{
        id: string;
        documents: Document[];
    }>()
);
export const getDocumentsForIdFailure = createAction(
    '[Envelope] Get Documents For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const getSignatoriesForId = createAction(
    '[Envelope] Get Signatories For Id',
    props<{
        id: string;
    }>()
);
export const getSignatoriesForIdSuccess = createAction(
    '[Envelope] Get Signatories For Id Success',
    props<{
        id: string;
        signatories: Signatory[];
    }>()
);
export const getSignatoriesForIdFailure = createAction(
    '[Envelope] Get Signatories For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const addDocumentForId = createAction(
    '[Envelope] Add Document For Id',
    props<{
        envelopeId: string;
        documentId: string;
    }>()
);
export const addDocumentForIdSuccess = createAction(
    '[Envelope] Add Document For Id Success',
    props<{
        envelopeId: string;
        documentId: string;
        document: Document;
    }>()
);
export const addDocumentForIdFailure = createAction(
    '[Envelope] Add Document For Id Failure',
    props<{
        envelopeId: string;
        documentId: string;
        error: Error;
    }>()
);

export const addSignatoryForId = createAction(
    '[Envelope] Add Signatory For Id',
    props<{
        envelopeId: string;
        partyId: string;
    }>()
);
export const addSignatoryForIdSuccess = createAction(
    '[Envelope] Add Signatory For Id Success',
    props<{
        envelopeId: string;
        partyId: string;
        signatory: Signatory;
    }>()
);
export const addSignatoryForIdFailure = createAction(
    '[Envelope] Add Signatory For Id Failure',
    props<{
        envelopeId: string;
        partyId: string;
        error: Error;
    }>()
);

export const deleteDocumentForId = createAction(
    '[Envelope] Delete Document For Id',
    props<{
        envelopeId: string;
        documentId: string;
    }>()
);
export const deleteDocumentForIdSuccess = createAction(
    '[Envelope] Delete Document For Id Success',
    props<{
        envelopeId: string;
        documentId: string;
    }>()
);
export const deleteDocumentForIdFailure = createAction(
    '[Envelope] Delete Document For Id Failure',
    props<{
        envelopeId: string;
        documentId: string;
        error: Error;
    }>()
);
