import { Injectable, inject } from '@angular/core';
import { FormArray, UntypedFormControl } from '@angular/forms';
import { INITIALISATION_MODE_MAP } from '../../../../constants/dynamic-form.constants';
import {
    IFormControlData,
    IReactiveStore,
    LayoutAndDefinitionEntry,
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../../../models/reactive-dynamic.model';
import {
    Action,
    FieldDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormFunctionResult,
    FormSectionLayoutDefinition,
    Trigger,
    UpdateField,
    UpdateLabel,
    UpdateSection,
} from '@wdx/clmi/api-models';
import { Observable, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FormLockService } from '../form-lock/form-lock.service';
import { FormStaticService } from '../form-static/form-static.class';
import { FormControlService } from './form-control.service';
import { FormTriggersService } from './form-triggers.service';
import { FormProcessConditionDataService } from './process-condition-data.service';

@Injectable()
export class FormConditionsService {
    data = [];
    layoutAndDefinition: LayoutAndDefinitionEntry[] = [];
    formSectionLayoutDefinitions: FormSectionLayoutDefinition[];
    pristine = true;
    conditionFunctions = [];
    conditionFunctions$: Observable<any>[] = [];

    private formStaticService = inject(FormStaticService);
    private formProcessService = inject(FormProcessConditionDataService);
    private formTriggersService = inject(FormTriggersService);
    private formLockService = inject(FormLockService);
    private formControlService = inject(FormControlService);
    combinedLayoutAndDefinition(): void {
        this.formSectionLayoutDefinitions = [
            ...this.formStaticService.formLayout.sectionLayoutDefinitions,
        ];

        this.formSectionLayoutDefinitions.forEach(
            (sectionLayoutDefinition: FormSectionLayoutDefinition) => {
                const STORE_DATA = {
                    section: sectionLayoutDefinition,
                    layoutAndDefinition: [],
                };
                let children = [];
                let ARR: IReactiveStore = {};

                sectionLayoutDefinition.elementLayoutDefinitions.forEach(
                    (element: FormElementLayoutDefinition) => {
                        children = [];

                        const FORM_CONTROLS_DATA =
                            this.formControlService.getFormControlData(
                                element.name
                            );

                        for (const FORM_CONTROL_DATA of FORM_CONTROLS_DATA) {
                            this.formProcessService.updateValidation(
                                FORM_CONTROL_DATA.formControl,
                                element
                            );
                        }

                        const DEFINITION = {
                            ...this.findDefinitionFromLayoutFieldElement(
                                element
                            ),
                        };

                        if (element.elementType === `${FormFieldType.Array}`) {
                            const ARR_RES =
                                this.loopSubFormLayoutAndDefinitionData(
                                    element,
                                    DEFINITION,
                                    [element.name]
                                );

                            ARR = ARR_RES;

                            if (Object.keys(ARR).length) {
                                children = [ARR];
                            }
                        }

                        STORE_DATA.layoutAndDefinition.push({
                            ...DEFINITION,
                            ...element,
                            ...((DEFINITION as any).options && {
                                originalOptions: (DEFINITION as any).options,
                            }),
                            children,
                        });
                    }
                );

                this.layoutAndDefinition.push({
                    ...STORE_DATA,
                    section: {
                        ...STORE_DATA.section,
                        ...((STORE_DATA.section.isHidden ||
                            STORE_DATA.layoutAndDefinition.every((def) =>
                                Boolean(def.summaryLevel)
                            )) && { isHidden: true }),
                    },
                });
            }
        );
    }

    findDefinitionFromLayoutFieldElement(
        element: FormElementLayoutDefinition
    ): FormElementLayoutDefinition {
        const MAX_COUNT = this.formStaticService.formDefinition.schema.length;
        let name = '';
        let count = 0;
        let definition = {};

        while (element.name !== name && count < MAX_COUNT) {
            const definitionElement =
                this.formStaticService.formDefinition.schema[count];
            if (definitionElement.name === element.name) {
                name = definitionElement.name;
                definition = definitionElement;
                count = MAX_COUNT;
            }

            count++;
        }

        return definition;
    }

    loopSubFormLayoutAndDefinitionData(
        elementLayout: FormElementLayoutDefinition,
        definition: FieldDefinition,
        subFormPath: string[]
    ): IReactiveStore {
        const STORE_DATA: IReactiveStore = {
            section: {},
            layoutAndDefinition: [],
        };
        const ARR = [];
        const SUB_FORM_ARRAY = [];

        const FORM_ARRAY = this.formProcessService.getFormArray(subFormPath, 0);

        // pass in FieldDefinition for subform array as we need to calculate appropriate min/max for schema and/or layout
        this.formProcessService.updateValidation(
            FORM_ARRAY,
            elementLayout,
            definition
        );

        elementLayout?.sectionLayoutDefinitions?.forEach((childSection) => {
            STORE_DATA.section = childSection;

            childSection.elementLayoutDefinitions.forEach((childElement) => {
                if (childElement.elementType === `${FormFieldType.Array}`) {
                    let childDefinition = {};
                    const updatedSubFormPath = [...subFormPath];
                    definition.childSchema.forEach((item) => {
                        if (childElement.name === item.name) {
                            updatedSubFormPath.push(childElement.name);
                            childDefinition = item;
                        }
                    });

                    ARR.push(
                        this.loopSubFormLayoutAndDefinitionData(
                            childElement,
                            childDefinition,
                            updatedSubFormPath
                        )
                    );
                }

                FORM_ARRAY?.controls?.forEach((formControl) => {
                    const FORM_CONTROL = formControl.get(
                        childElement.name
                    ) as UntypedFormControl;

                    this.formProcessService.updateValidation(
                        FORM_CONTROL,
                        childElement
                    );
                });

                const MAX_COUNT = definition.childSchema.length;
                let name = '';
                let count = 0;
                let definitionChild = {};

                while (childElement.name !== name && count < MAX_COUNT) {
                    const definitionElement = definition.childSchema[count];

                    if (definitionElement.name === childElement.name) {
                        name = definitionElement.name;
                        definitionChild = definitionElement;
                    }

                    count++;
                }

                SUB_FORM_ARRAY.push(
                    JSON.parse(
                        JSON.stringify({
                            ...definitionChild,
                            ...childElement,
                            ...((definitionChild as any).options && {
                                originalOptions: (definitionChild as any)
                                    .options,
                            }),
                            children: [...ARR],
                        })
                    )
                );
            });
        });

        STORE_DATA.layoutAndDefinition = [];
        FORM_ARRAY?.controls.map(() => {
            STORE_DATA.layoutAndDefinition.push(
                JSON.parse(JSON.stringify(SUB_FORM_ARRAY))
            );
        });

        return STORE_DATA;
    }

    applyTriggerCondition(): void {
        const TRIGGERS =
            this.formStaticService.formDefinition?.conditions?.triggers;
        const INITIALISATION: Action =
            this.formStaticService.formDefinition?.conditions?.initialisation;
        const INITIALISATION_BY_MODE =
            this.formStaticService.formDefinition?.conditions
                ?.initialisationByMode;

        if (INITIALISATION) {
            this.applyActions(INITIALISATION);
        }

        if (
            INITIALISATION_BY_MODE &&
            INITIALISATION_BY_MODE[
                INITIALISATION_MODE_MAP[
                    this.formStaticService.initialisationMode
                ]
            ]
        ) {
            this.applyActions(
                INITIALISATION_BY_MODE[
                    INITIALISATION_MODE_MAP[
                        this.formStaticService.initialisationMode
                    ]
                ]
            );
        }

        /**
         * Trigger fields can either be of context or data sources.
         * - 'context' fields are prefixed with the '$' symbol and values are read directly from context source.
         * - 'data' field values are read from the associated formControl.
         */

        TRIGGERS?.map((trigger) => {
            const USE_CONTEXT = this.formTriggersService.useContext(trigger);

            if (USE_CONTEXT) {
                const { context } = this.formStaticService.formData;

                const contextValue =
                    this.formTriggersService.getContextTriggerValue(
                        trigger,
                        context
                    );

                if (context) {
                    this.updateIsTrigger(trigger, contextValue);
                }
            }

            if (!USE_CONTEXT) {
                const FORM_CONTROLS_DATA =
                    this.formControlService.getFormControlData(trigger.field);

                for (const FORM_CONTROL_DATA of FORM_CONTROLS_DATA) {
                    this.applyConditions(trigger, FORM_CONTROL_DATA);
                    this.setFormControlListener(trigger, FORM_CONTROL_DATA);
                }
            }

            this.pristine = false;
        });
    }

    setFormControlListener(
        trigger: Trigger,
        formControlData: IFormControlData
    ): void {
        this.formControlService.setValueChangeListener(
            trigger,
            formControlData,
            this.applyConditions.bind(this)
        );
    }

    updateFunctionArray(action): void {
        if (!this.conditionFunctions.some((e) => e.name === action.name)) {
            this.conditionFunctions.push(action);
            this.conditionFunctions$.push(
                this.formProcessService.getApiFunctionTrigger(action.name)
            );
        }
    }

    applyActions(data): void {
        Object.keys(data).map((actions) => {
            const ACTIONS = data[actions];
            if (ACTIONS.length) {
                ACTIONS.map((action) => {
                    if (actions === 'functions') {
                        this.updateFunctionArray(action);
                    }

                    if (actions === 'recalculate') {
                        this.recalculateField(action);
                    }

                    if (actions === 'updateFields') {
                        this.updateField(action);
                    }

                    if (actions === 'updateSections') {
                        this.updateSection(action);
                    }
                });
            }
        });
    }

    applyConditions(trigger: Trigger, formControlData: IFormControlData): void {
        if (formControlData.formControl) {
            const formControl = formControlData.formControl;
            this.updateIsTrigger(trigger, formControl.value, formControlData);

            if (trigger.changed && !this.pristine && !formControl?.pristine) {
                trigger.changed?.recalculate?.forEach((field) => {
                    this.recalculateField(field);
                });

                trigger.changed?.functions?.forEach((field) => {
                    const NAME = this.formControlService.nameToArray(
                        trigger.field
                    );

                    if (this.pristine) {
                        this.updateFunctionArray(field);
                    }

                    if (!this.pristine) {
                        this.formProcessService
                            .getApiFunctionTrigger(
                                field.name,
                                NAME[0],
                                formControlData?.index
                            )
                            .pipe(
                                filter((res) => Boolean(res)),
                                take(1)
                            )
                            .subscribe((data: FormFunctionResult) => {
                                this.updateFunctionChanges(data);
                            });
                    }
                });
            }
        }

        if (this.conditionFunctions.length && this.pristine) {
            forkJoin(this.conditionFunctions$)
                .pipe(
                    filter((res) => Boolean(res)),
                    take(1)
                )
                .subscribe((data: FormFunctionResult[]) => {
                    data?.map((functionRes) => {
                        this.updateFunctionChanges(functionRes);
                    });
                });
        }
    }

    updateIsTrigger(
        trigger,
        triggerFieldValue,
        formControlData?: IFormControlData
    ): void {
        trigger.is?.forEach((is) => {
            const conditionIsTrue = this.formTriggersService.is(
                is,
                triggerFieldValue
            );
            const actionSource: any = conditionIsTrue ? is.then : is.else;

            this.updateSectionFromArray(actionSource);
            this.updateMessageDefinitionToShow(
                actionSource?.hideMessage,
                actionSource?.showMessage
            );

            actionSource?.updateFields?.forEach((updateField) =>
                this.updateField(updateField, formControlData)
            );

            actionSource?.updateLabels?.forEach((updateLabel) =>
                this.updateLabel(updateLabel)
            );
        });
    }

    updateFunctionChanges(functionRes): void {
        if (functionRes?.dataChanges) {
            Object.keys(functionRes?.dataChanges).map((item) => {
                const FORM_CONTROL = this.formStaticService.form.get(item);
                const IS_FORM_ARRAY = FORM_CONTROL instanceof FormArray;
                const DATA = functionRes.dataChanges[item];

                this.formProcessService.updateFormControlValue(
                    FORM_CONTROL as UntypedFormControl,
                    DATA,
                    this.pristine,
                    IS_FORM_ARRAY ? true : null
                );
            });
        }

        if (functionRes?.schemaChanges) {
            Object.keys(functionRes?.schemaChanges).forEach((propertyName) => {
                const FIELD_DATA = functionRes.schemaChanges[propertyName];
                this.updateField(FIELD_DATA);
            });
        }
    }

    recalculateField(field: string): void {
        const TOTAL_SUM = this.formProcessService.recalculate(field);
        this.updateField({
            name: field,
            value: TOTAL_SUM,
        });
    }

    updateSectionFromArray(sectionsArray): void {
        sectionsArray?.updateSections?.forEach((updateSection) =>
            this.updateSection(updateSection)
        );
    }

    updateMessageDefinitionToShow(
        hideMessage: { name: string }[],
        showMessage: { name: string }[]
    ): void {
        if (hideMessage?.length) {
            hideMessage.forEach((removeMessage) => {
                delete this.formStaticService.messageDefinitionToShow[
                    removeMessage?.name
                ];
            });
        }

        if (showMessage?.length) {
            showMessage.forEach((addMessage) => {
                this.formStaticService.messageDefinitionToShow[
                    addMessage?.name
                ] =
                    this.formStaticService.messageDefinitionToObject[
                        addMessage?.name
                    ];
            });
        }
    }

    updateSection(updateSection: UpdateSection): void {
        let count = 0;
        const FINAL = this.layoutAndDefinition.length;

        while (count < FINAL) {
            if (
                this.layoutAndDefinition[count].section.name ===
                updateSection.name
            ) {
                this.layoutAndDefinition[count].section = {
                    ...this.layoutAndDefinition[count].section,
                    ...updateSection,
                };

                count = FINAL;
            }

            count++;
        }
    }

    updateLabel(updateLabel: UpdateLabel): void {
        this.layoutAndDefinition = this.layoutAndDefinition.map((section) => {
            return {
                ...section,
                layoutAndDefinition: section.layoutAndDefinition.map(
                    (definition) => {
                        if (definition.name === updateLabel.name) {
                            return {
                                ...definition,
                                label: updateLabel.label,
                            };
                        }
                        return definition;
                    }
                ),
            };
        });
    }

    updateField(
        updateField: UpdateField,
        formControlData?: IFormControlData
    ): void {
        this.layoutAndDefinition.forEach(
            (sectionObject: ReactiveFormElement) => {
                let firstLevelCount = 0;
                const FIRST_LEVEL_LAYOUT_LENGTH =
                    sectionObject.layoutAndDefinition.length;
                const UPDATE_FIELD_NAME = this.formControlService.nameToArray(
                    updateField.name
                );

                while (firstLevelCount < FIRST_LEVEL_LAYOUT_LENGTH) {
                    const DEFINITION = sectionObject.layoutAndDefinition[
                        firstLevelCount
                    ] as ReactiveFormLayoutAndDefinition;

                    if (DEFINITION.name === UPDATE_FIELD_NAME[0]) {
                        const FORM_CONTROLS_DATA =
                            this.formControlService.getFormControlData(
                                updateField.name,
                                typeof formControlData?.index === 'number'
                                    ? [formControlData.index]
                                    : null
                            );

                        if (UPDATE_FIELD_NAME[1]) {
                            const hasFormGroupIndex = Boolean(
                                typeof formControlData?.index === 'number'
                            );

                            if (!hasFormGroupIndex) {
                                DEFINITION.children[0].layoutAndDefinition.map(
                                    (_, index) => {
                                        this.updateField(
                                            updateField,
                                            FORM_CONTROLS_DATA[index]
                                        );
                                    }
                                );
                            }

                            const SUB_FORM_LAYOUT_AND_DEFINITION = DEFINITION
                                .children[0].layoutAndDefinition[
                                formControlData?.index
                            ] as ReactiveFormLayoutAndDefinition[];
                            const SUB_FORM_MAX =
                                SUB_FORM_LAYOUT_AND_DEFINITION?.length;
                            let subFormItemCount = 0;

                            while (subFormItemCount < SUB_FORM_MAX) {
                                const SUB_DEFINITION =
                                    SUB_FORM_LAYOUT_AND_DEFINITION[
                                        subFormItemCount
                                    ];

                                if (
                                    SUB_DEFINITION.name === UPDATE_FIELD_NAME[1]
                                ) {
                                    this.updateFieldFormControls(
                                        FORM_CONTROLS_DATA,
                                        SUB_DEFINITION,
                                        updateField
                                    );

                                    (SUB_FORM_LAYOUT_AND_DEFINITION[
                                        subFormItemCount
                                    ] as FieldDefinition) = JSON.parse(
                                        JSON.stringify({
                                            ...SUB_DEFINITION,
                                            ...updateField,
                                            // This is very important as it will us the updateFieldName
                                            name: SUB_DEFINITION.name,
                                            ...((SUB_DEFINITION as any)
                                                .originalOptions && {
                                                options:
                                                    updateField.options
                                                        ?.length > 0
                                                        ? updateField.options
                                                        : (
                                                              SUB_DEFINITION as any
                                                          ).originalOptions,
                                            }),
                                        })
                                    );

                                    subFormItemCount = SUB_FORM_MAX;
                                }

                                subFormItemCount++;
                            }
                        }

                        if (!UPDATE_FIELD_NAME[1]) {
                            this.updateFieldFormControls(
                                FORM_CONTROLS_DATA,
                                DEFINITION,
                                updateField
                            );

                            (sectionObject.layoutAndDefinition[
                                firstLevelCount
                            ] as FieldDefinition) = JSON.parse(
                                JSON.stringify({
                                    ...DEFINITION,
                                    ...updateField,
                                    // This is very important as it will us the updateFieldName
                                    name: DEFINITION.name,
                                    ...((DEFINITION as any).originalOptions && {
                                        options:
                                            updateField.options?.length > 0
                                                ? updateField.options
                                                : (DEFINITION as any)
                                                      .originalOptions,
                                    }),
                                })
                            );

                            firstLevelCount = FIRST_LEVEL_LAYOUT_LENGTH;
                        }
                    }

                    firstLevelCount++;
                }
            }
        );

        this.layoutAndDefinition = [...this.layoutAndDefinition];
    }

    updateFieldFormControls(
        formControlsData: IFormControlData[],
        definition: ReactiveFormLayoutAndDefinition,
        updateField: UpdateField
    ): void {
        for (const formControlData of formControlsData) {
            const FORM_CONTROL = formControlData.formControl;
            // fields that are 'locked' should ignore disable and validation actions
            if (
                !this.formLockService.fieldIsLocked(
                    definition.name,
                    this.formStaticService.formData.lock
                )
            ) {
                this.formProcessService.updateFormControlDisabledStatues(
                    FORM_CONTROL,
                    updateField.isDisabled
                );

                this.formProcessService.updateFormControlValidation(
                    FORM_CONTROL,
                    {
                        ...definition,
                        ...updateField,
                    } as FieldDefinition
                );
            }

            this.formProcessService.findFormControlUpdateValue(
                FORM_CONTROL,
                updateField,
                definition,
                this.pristine
            );
        }
    }

    /**
     * Sets the isLocked status on definition fields
     * @param forceFullFormLock
     */
    updateLockStatuses(forceFullFormLock: boolean): void {
        this.layoutAndDefinition = this.layoutAndDefinition.map(
            (formElement) => {
                return {
                    ...formElement,
                    layoutAndDefinition: formElement.layoutAndDefinition.map(
                        (definition) => {
                            return {
                                ...definition,
                                isLocked:
                                    forceFullFormLock ||
                                    this.formLockService.fieldIsLocked(
                                        definition.name,
                                        this.formStaticService.formData.lock,
                                        false
                                    ),
                            };
                        }
                    ),
                };
            }
        );
    }

    reset() {
        this.data = [];
        this.layoutAndDefinition = [];
        this.formSectionLayoutDefinitions = [];
        this.pristine = true;
        this.conditionFunctions = [];
        this.conditionFunctions$ = [];
    }
}
