import { createAction, props } from '@ngrx/store';
import { Notification } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getPage = createAction(
    '[Notifications] Get Page',
    props<{
        reset?: boolean;
        pageSize: number;
    }>()
);

export const getPageSuccess = createAction(
    '[Notifications] Get Page Success',
    props<{
        reset?: boolean;
        notifications: PaginatedApiResponse<Notification>;
    }>()
);

export const getPageFailure = createAction(
    '[Notifications] Get Page Failure',
    props<{
        error: Error;
    }>()
);

export const view = createAction('[Notifications] View Notification');

export const viewSuccess = createAction(
    '[Notifications] View Notification Success'
);

export const viewFailure = createAction(
    '[Notifications] View Notification Failure',
    props<{
        error: Error;
    }>()
);

export const setUnreadNotifications = createAction(
    '[Notifications] Set Unread Notifications',
    props<{
        unreadNotifications: number;
    }>()
);
