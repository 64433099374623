import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { SignalrEvent } from '../../constants/signalr.constants';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as partiesRoleSelector from '../../state/party-role/party-role.selectors';
import * as partieyRoleActions from './party-role.actions';
import * as partyRoleActions from './party-role.actions';
import { PartyRoleService } from './party-role.service';

@Injectable()
export class PartyRoleEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private partyRoleService = inject(PartyRoleService);

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partyRoleActions.getForId),
            mergeMap((action) =>
                this.partyRoleService.getForId(action.partyRoleId).pipe(
                    map((partyRole) =>
                        partyRoleActions.getForIdSuccess({
                            partyRoleId: action.partyRoleId,
                            partyRole,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partyRoleActions.getForIdFailure({
                                partyRoleId: action.partyRoleId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
    getVisibilityForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partyRoleActions.getVisibilityForId),
            mergeMap((action) =>
                this.partyRoleService.getVisibilityForId(action.id).pipe(
                    map((visibility) =>
                        partyRoleActions.getVisibilityForIdSuccess({
                            id: action.id,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partyRoleActions.getVisibilityForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getTasksForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partyRoleActions.getAllTasksForPartyRole),
            withLatestFrom(
                this.store$.select(
                    partiesRoleSelector.getTasksForPartyRoleInfinityPaging,
                    {
                        partyRoleId: GLOBAL_STATE_INDEX_ID,
                    }
                )
            ),
            switchMap(([action, paging]) =>
                this.partyRoleService
                    .getAllTasksForPartyRole(
                        {
                            pageNumber: (paging?.page || 0) + 1,
                        },
                        action.partyRoleId
                    )
                    .pipe(
                        map((tasks) =>
                            partyRoleActions.getAllTasksForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                tasks,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partyRoleActions.getAllTasksForPartyRoleFailure(
                                    {
                                        partyRoleId: action.partyRoleId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    listenToPartyRoleUpdatedEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.PartyRoleUpdated).pipe(
                        map((id: string) =>
                            partieyRoleActions.partyRoleUpdatedSuccess({
                                partyRoleId: id,
                            })
                        )
                    )
                )
            )
        )
    );

    listenToPartyRoleCasesUpdatedEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.PartyRoleCasesUpdated).pipe(
                        map((id: string) =>
                            partieyRoleActions.partyRoleCasesUpdatedSuccess({
                                partyRoleId: id,
                            })
                        )
                    )
                )
            )
        )
    );
}
