import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity, URL_ERROR, URL_WELCOME } from '@wdx/shared/utils';
import { PRIMARY_ERROR_APIS } from '../../../constants/http.constants';
import { Environment } from '../../../models/environment.model';
import { ErrorDetails, ErrorEvent } from '../../../models/error.model';
import { ConfigService } from '../../../services/config.service';
import { EnvironmentService } from '../../../services/environment.service';
import { FeaturesService } from '../../../shared/services/features/features.service';
@Injectable({ providedIn: 'root' })
export class ErrorManagementService {
    /**
     * Stores the most recent error to be consumed by error pages
     */
    error: ErrorDetails;

    constructor(
        private toastService: WdxToastService,
        private featuresService: FeaturesService,
        private config: ConfigService,
        private router: Router
    ) {}

    handleError(req: HttpRequest<any>, event: ErrorEvent): void {
        /**
         * If 'user/me' (critical) api error, redirect to welcome page
         */
        if (this.isUserMeError(req.url)) {
            this.setError(req.url, event);
            this.router.navigate(URL_WELCOME, {
                queryParams: { error: true },
            });
            return;
        }
        /**
         * If this is a 'primary' error, redirect to error page
         */
        if (this.isPrimaryError(req.url)) {
            this.setError(req.url, event);
            this.router.navigate(URL_ERROR);
            return;
        }
        /**
         * Otherwise display toast message (if applicable)
         */
        this.displayErrorToast(event);
    }

    /**
     * Sets the global error property
     */
    setError(url: string, event: ErrorEvent) {
        this.error = {
            url,
            errorCode: event.status,
            detail: event.message || event.detail,
            traceId: event.traceId,
        };
    }

    /**
     * Returns true if error currently exists
     */
    hasError() {
        return Boolean(this.error);
    }

    /**
     * Resets the error property
     */
    resetError() {
        this.error = null;
    }

    /**
     * Returns true if the intercepted url is a 'primary' api
     */
    isUserMeError(url: string): boolean {
        const regex = new RegExp(
            `${this.config.getConfiguration().API_BASE}/user/me`
        );
        return regex.test(url);
    }

    /**
     * Returns true if the intercepted url is a 'primary' api
     */
    isPrimaryError(url: string): boolean {
        const errorRegEx = PRIMARY_ERROR_APIS.map(
            (key) =>
                new RegExp(
                    `${
                        this.config.getConfiguration().API_BASE
                    }/${key.toLocaleLowerCase()}/[^/]+[?/#]?$`
                )
        );
        return errorRegEx.some((regex) => regex.test(url));
    }

    /**
     * Display error toast message if feature and env restrictions are met
     */
    displayErrorToast(event: ErrorEvent): void {
        if (
            this.featuresService.hasFeature(FeatureFlag.ToastErrors) &&
            EnvironmentService.currentEnvironment() === Environment.Local
        ) {
            this.toastService.show({
                header: `An Error Has Occurred ${
                    event?.status ? `(Code: ${event?.status})` : ''
                }`,
                body: [
                    `Details: ${event.message || event.detail || 'None'}`,
                    `Trace ID: ${event.traceId || 'None'}`,
                ],
                severity: Severity.Danger,
                closeOthers: true,
            });
        }
    }

    getErrorMessage() {
        return [
            ...(this.error?.url ? [`URL: ${this.error.url}`] : []),
            ...(this.error?.errorCode
                ? [`Error code: ${this.error.errorCode}`]
                : []),
            ...(this.error?.detail ? [`Detail: ${this.error.detail}`] : []),
            ...(this.error?.traceId ? [`TraceId: ${this.error.traceId}`] : []),
        ];
    }
}
