import { NgModule } from '@angular/core';
import { FormSummaryValue } from './form-summary-value/form-summary-value.pipe';
import { LookupAvatarsPipe } from './lookup-avatars/lookup-avatars.pipe';
import { TelephonePatternPipe } from './telphone-pattern/telephone-pattern.pipe';

@NgModule({
    declarations: [FormSummaryValue, LookupAvatarsPipe, TelephonePatternPipe],
    exports: [FormSummaryValue, LookupAvatarsPipe, TelephonePatternPipe],
})
export class FormSummaryPipesModule {}
