import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownButtonStyle } from '../../../../models/dropdown-button-style.model';
import { MenuItem } from '../../../../models/menu-item.model';
import * as rootReducer from '../../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-context-menu-dropdown',
    templateUrl: './molecule-context-menu-dropdown.component.html',
})
export class MoleculeContextMenuDropdownComponent {
    @Input() menu: MenuItem[];
    @Input() menuTitle: string;
    @Input() buttonStyle: DropdownButtonStyle = DropdownButtonStyle.Result;
    @Input() placement = 'bottom-left';
    @Input() btnClass = 'btn-link';
    @Input() altText: string;
    @Input() isMulti: boolean;
    @Input() autoClose: boolean | 'outside' | 'inside' = true;
    @Input() hasSearch: boolean;
    @Input() highlightSelected = true;
    @Input() size = 'auto';
    @Input() parentContainer: string;
    @Input() hideDropdownChevron: boolean;
    @Input() initialValue: string | string[];
    @Input() defaultSelectFirst: boolean;
    @Input() disabled: boolean;
    @Input() isLoading: boolean;
    @Input() cySelector: string;

    @Output() singleItemSelected = new EventEmitter<MenuItem>();
    @Output() multipleItemsSelected = new EventEmitter<MenuItem[]>();
    @Output() toggled = new EventEmitter<boolean>();

    selectedMenuItems: MenuItem[] = [];
    allMenuItems: MenuItem[] = [];

    constructor(private store$: Store<rootReducer.State>) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menu && changes.menu.isFirstChange()) {
            this.allMenuItems = changes.menu.currentValue;
        }

        if (this.menu && (this.initialValue || this.defaultSelectFirst)) {
            this.emitInitialValue();
        }
    }

    onDropdownToggled(isOpen: boolean) {
        this.toggled.emit(isOpen);
    }

    emitInitialValue() {
        const initialValueArray = this.initialValue
            ? Array.isArray(this.initialValue)
                ? this.initialValue
                : [this.initialValue]
            : this.menu[0].subMenu
            ? [this.menu[0].subMenu[0].value]
            : [this.menu[0].value];

        let initialSelectedMenuItems = [];
        this.menu.forEach((menuItem) => {
            if (initialValueArray.includes(menuItem.value)) {
                initialSelectedMenuItems.push(menuItem);
            }
            initialSelectedMenuItems = [
                ...initialSelectedMenuItems,
                ...(menuItem.subMenu?.length
                    ? menuItem.subMenu.filter((subItem) =>
                          initialValueArray.includes(subItem.value)
                      )
                    : []),
            ];
        });

        this.selectedMenuItems = initialSelectedMenuItems;

        if (this.isMulti) {
            this.multipleItemsSelected.emit(this.selectedMenuItems);
        } else {
            this.singleItemSelected.emit({
                ...this.selectedMenuItems[0],
                isInitialValue: true,
            });
        }

        if (this.defaultSelectFirst) {
            this.filterSelectedValues();
        }
    }

    onSingleItemSelected(menuItem: MenuItem): void {
        this.selectedMenuItems = [menuItem];
        this.singleItemSelected.emit({
            ...menuItem,
            isInitialValue: false,
        });

        if (menuItem.action) {
            this.store$.dispatch(menuItem.action);
        }

        if (this.defaultSelectFirst) {
            this.filterSelectedValues();
        }
    }

    onMultipleItemsSelected(menuItems: MenuItem[]): void {
        this.selectedMenuItems = menuItems;
        this.multipleItemsSelected.emit(menuItems);
    }

    filterSelectedValues() {
        const selectedValues = this.selectedMenuItems.map(
            (selectedMenu) => selectedMenu.value
        );
        this.menu = this.allMenuItems.filter(
            (menuItem) => !selectedValues.includes(menuItem.value)
        );
    }
}
