<molecule-search-bar
    [isLoading]="isLoading"
    [searchType]="searchType"
    [searchTypePlural]="searchTypePlural"
    [totalRecordCount]="totalRecordCount"
    [inputSearchText]="inputSearchText"
    [placeholder]="placeholder"
    [sortMenu]="sortMenu"
    [alwaysShowFilters]="alwaysShowFilters"
    [showSearchBar]="showSearchBar"
    [filterHandler]="filterHandler"
    [filterFormId]="filterFormId"
    [filterModalId]="instanceId"
    [hasMobileFilter]="hasMobileFilter"
    (search)="onSearch($event)"
    (sort)="onSort($event)"
>
    <ng-content prefixActionButtons select="[prefixActionButtons]"></ng-content>

    <ng-container actionButtons>
        <ng-container *ngIf="showImportExport">
            <clmi-import-export
                class="d-inline-block ms-2"
                [disableExport]="totalRecordCount === 0"
                [hasExportPrivilege]="routeDataService?.exportPrivilege"
            ></clmi-import-export>
        </ng-container>

        <ng-container *ngIf="showImport">
            <clmi-import-entities
                class="d-inline-block ms-2"
            ></clmi-import-entities>
        </ng-container>

        <ng-content select="[actionButtons]"></ng-content>
    </ng-container>
</molecule-search-bar>

<atom-modal-wrapper
    *ngIf="filterHandler"
    [modalId]="instanceId"
    [modalTemplate]="filterModal"
>
    <ng-template #filterModal>
        <organism-modal modalTitle="Filter">
            <clmi-form-filters
                *ngIf="hasFilterSearch"
                class="h-100 w-100"
            ></clmi-form-filters>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
