import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { KeyValueObject } from '@wdx/shared/utils';
import {
    ICON_DOWNLOAD,
    ICON_EXPORTS,
    ICON_FILE_IMPORT,
} from '../../../constants/icons.constants';

export const EXPORT_VAL = 'export';
export const IMPORT_VAL = 'import';
export const DOWNLOAD_VAL = 'download';

export const IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP: KeyValueObject<FeatureFlag> =
    {
        [SystemEntity.Adviser]: FeatureFlag.ImportAdviser,
        [SystemEntity.Intermediary]: FeatureFlag.ImportIntermediary,
        [SystemEntity.Lead]: FeatureFlag.ImportLead,
        [SystemEntity.ProductProvider]: FeatureFlag.ImportProductProvider,
        [SystemEntity.Opportunity]: FeatureFlag.ImportOpportunity,
    };

export const EXPORT = {
    icon: ICON_EXPORTS.icon,
    label: 'Export',
    value: EXPORT_VAL,
};

export const IMPORT = [
    {
        icon: ICON_FILE_IMPORT.icon,
        label: 'Import',
        value: IMPORT_VAL,
    },
    {
        icon: ICON_DOWNLOAD.icon,
        label: 'Download Template',
        value: DOWNLOAD_VAL,
    },
];
