<molecule-action-button
    *ngIf="actionButton?.dropdownMenu?.length"
    [actionButton]="actionButton"
    [size]="size"
    (selectMenuItem)="onDropdownOptionClicked($event)"
>
</molecule-action-button>

<clmi-import-entities-modal></clmi-import-entities-modal>
<clmi-import-entities-download-modal></clmi-import-entities-download-modal>
