import { Action, createReducer, on } from '@ngrx/store';
import { FileUploadState } from '../../models/file-upload-state.model';
import { FileUploadStatus } from '../../models/file-upload-status.model';
import * as fileUploadActions from './avatar-file-upload.actions';

export interface State {
    avatarUploads: { [key: string]: FileUploadState };
}

export const initialState: State = {
    avatarUploads: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        fileUploadActions.uploadFile,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileCancel,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileReset,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                    fileIndex: undefined,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileStarted,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Started,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileProgress,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    progress: props.progress,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileComplete,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Completed,
                    progress: 100,
                    fileIndex: props.fileIndex,
                },
            },
        })
    ),

    on(
        fileUploadActions.uploadFileFailure,
        (state, props): State => ({
            ...state,
            avatarUploads: {
                ...state.avatarUploads,
                [props.id]: {
                    ...(state.avatarUploads[props.id] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Failed,
                    error: props.error,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
