import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { EntityOperations, SystemEntity } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OperationsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getOperationsForEntity(
        entityType: SystemEntity
    ): Observable<EntityOperations> {
        return this.http.get<EntityOperations>(
            `${
                this.config.getConfiguration().API_BASE
            }/system/operation/${entityType.toLowerCase()}`
        );
    }

    getOperationsForId(
        entityType: SystemEntity,
        entityId: string
    ): Observable<EntityOperations> {
        return this.http.get<EntityOperations>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType?.toLowerCase()}/${entityId}/operation`
        );
    }

    patchActionWithURL(
        entityType: SystemEntity,
        entityId: string,
        url: string
    ): Observable<any> {
        return this.http.patch(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType?.toLowerCase()}/${entityId}/action/${url}`,
            null
        );
    }
}
