import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityAction, List, Query, SystemEntity } from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import { QueryType } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { TranslationsService } from '@wdx/system';
import { Observable, Subject, pipe } from 'rxjs';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { FilterHandler } from '../../../../../../classes/filter-handler.class';
import * as rootReducer from '../../../../../../state/_setup/reducers';
import * as listsSelectors from '../../../../../../state/lists/lists.selectors';
import { EntityOperationsService } from '../../../../../services/entity-operations';
import { RouteDataService } from '../../../../../services/route-data/route-data.service';
import * as filterSelectors from '../../../shared/state/selector';
import { FilterQueryFacadeService } from '../../services/filter-query-facade/filter-query-facade.service';
import { FilterQueryService } from '../../services/filter-query/filter-query.service';
import { SEARCH_NAME_TRUNCATE_LIMIT } from './filter-core.static';

@Injectable()
export abstract class FilterCoreAbstractClass extends WdxDestroyClass {
    loading$: Observable<boolean>;
    hasError$: Observable<boolean>;

    lists$ = new Subject<List[]>();
    listsIsLoading$: Observable<boolean>;
    listsHasError$: Observable<boolean>;

    entityActions$ = new Observable<EntityAction[]>();
    entityActionsIsLoading$: Observable<boolean>;
    entityActionsHasError$: Observable<boolean>;

    selectFirstResultsCore$: Observable<any>;
    selectFilterResultsSuccessCore$: Observable<any>;
    filterHandlerValuesChange$: Observable<any>;
    getFilterPaging$: Observable<Paging>;

    readonly NAME_TRUNCATE_LIMIT = SEARCH_NAME_TRUNCATE_LIMIT;

    get paging(): Paging {
        return this.filterQueryService.paging;
    }

    get viewType(): SystemEntity {
        return this.routeDataService.viewType;
    }

    get queryType(): QueryType {
        return this.routeDataService.queryType;
    }

    get searchPlaceholder(): string {
        return `Search ${this.searchTypePlural}`;
    }

    get searchTypePlural(): string {
        return this.translationsService.getTranslatedEntityType(
            this.routeDataService.viewType,
            true
        );
    }

    get searchType(): string {
        return this.translationsService.getTranslatedEntityType(
            this.routeDataService.viewType
        );
    }

    get query(): Query {
        return this.filterQueryService.query;
    }

    filterHandler = new FilterHandler();

    constructor(
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected routeDataService: RouteDataService,
        protected store$: Store<rootReducer.State>,
        protected translationsService: TranslationsService
    ) {
        super();

        this.filterQueryService.viewType = this.routeDataService.viewType;
        this.filterQueryService.queryType = this.routeDataService.queryType;
        this.filterQueryService.contextual = this.routeDataService.contextual;
        this.filterQueryService.filterHandler = this.filterHandler;

        this.getRouteView();

        this.loading$ = this.store$
            .select(
                filterSelectors.selectFilterLoading,
                this.filterQueryService.queryType
            )
            .pipe(this.takeUntil());

        this.hasError$ = this.store$
            .select(
                filterSelectors.selectFilterError,
                this.filterQueryService.queryType
            )
            .pipe(this.takeUntil());

        this.listsIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingLists
        );
        this.listsHasError$ = this.store$.select(
            listsSelectors.getHasLoadingListsError
        );

        this.entityActionsIsLoading$ =
            this.entityOperationsService.operationsIsLoading$;
        this.entityActionsHasError$ =
            this.entityOperationsService.operationsHasError$;
    }

    getRouteView(): void {
        this.routeDataService.view$
            .pipe(
                filter((res) => Boolean(res)),
                take(1)
            )
            .subscribe((view) => {
                this.filterQueryService.setView(view);
                this.filterQueryService.filterHandler.setSortDirection(
                    view.filter.sortDirection
                );
            });
    }

    coreOnInit(): void {
        this.filterQueryService.getLocationStorageFilterVisibilityToggle();

        this.filterQueryService.buildQuery$
            .pipe(takeUntil(this.destroyed$), skip(1))
            .subscribe((query) =>
                this.filterQueryFacadeService.resetResultsGetRecords(
                    this.filterQueryService.queryType,
                    query,
                    this.filterQueryService.paging
                )
            );

        this.filterQueryService.view$
            .pipe(takeUntil(this.destroyed$), skip(1))
            .subscribe((view) =>
                this.filterQueryFacadeService.resetResultsGetRecords(
                    this.filterQueryService.queryType,
                    view.filter,
                    this.filterQueryService.paging
                )
            );

        this.filterHandlerValuesChange$ = this.filterHandler.valuesChanged.pipe(
            takeUntil(this.destroyed$)
        );

        this.filterHandlerValuesChange$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((val: any) => {
                this.filterQueryService.sortQueryParams(val);
            });

        // The below is commented out because it is making use of an outdated api call
        // It's purpose was to fetch all operations available to an entity in order to
        // populate the multiselect banner, but this appears to have been depricated on
        // the backend.
        // The purpose of this deprication is tbd, so there is tech debt around either
        // updating or removing this and the entityOperationsService.

        // this.entityActions$ = this.entityOperationsService.getOperations(
        //     this.viewType
        // ).actions$;
    }

    coreAfterViewInit(): void {
        this.store$
            .select(
                filterSelectors.selectFirstPaging,
                this.filterQueryService.queryType
            )
            .pipe(take(1))
            .subscribe((paging) => (this.filterQueryService.paging = paging));

        this.getFilterPaging();

        this.selectFirstResultsCore$ = this.store$
            .select(
                filterSelectors.selectFirstResults,
                this.filterQueryService.queryType
            )
            .pipe(take(1));

        this.selectFilterResultsSuccessCore$ = this.store$
            .select(
                filterSelectors.selectFilterResultsSuccess,
                this.filterQueryService.queryType
            )
            .pipe(skip(1));
    }

    getFilterPaging(): void {
        this.store$
            .select(
                filterSelectors.getFilterPaging,
                this.filterQueryService.queryType
            )
            .pipe(skip(1), this.takeUntil())
            .subscribe(
                (paging: Paging) => (this.filterQueryService.paging = paging)
            );
    }

    takeUntil(): any {
        return pipe(takeUntil(this.destroyed$));
    }

    toggle(): void {
        this.filterQueryService.setLocationStorageFilterVisibilityToggle();
    }

    onPageChanged(page: number): void {
        this.filterQueryService.updateResults({
            ...this.paging,
            page: page,
        });
    }

    onInfinityScrollFired(): void {
        this.filterQueryService.updateResults({
            ...this.paging,
            page: this.paging?.page + 1,
        });
    }
}
