import { Locale, SelectApiSource, Currency } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import {
    ContextualDataContext,
    ExtendedFieldDefinitionOption,
} from '../models';

export abstract class IFormDynamicData {
    public abstract getContextSelectApiSourceOptions(
        source: SelectApiSource,
        lookups: any[],
        context?: ContextualDataContext[]
    ): Observable<ExtendedFieldDefinitionOption[]>;

    public abstract getSelectApiSourceOptions(
        selectSource: SelectApiSource,
        selectQueryParams?: Record<string, string>
    ): Observable<ExtendedFieldDefinitionOption[]>;

    public abstract getMeLocale(): Observable<Locale>;

    public abstract getCurrencies(): Observable<Currency[]>;
}
