<wdx-info-card class="w-100" [noBorder]="noContainer">
    <div wdxInfoCardLeading>
        <wdx-icon-container
            *ngIf="fileIndex.contentType | fileTypeAsIcon as fileType"
            class="bg-icon file-type-{{ fileType.fileType }}"
            size="md"
        >
            <wdx-icon [icon]="fileType.icon"></wdx-icon>
        </wdx-icon-container>
    </div>
    <div wdxInfoCardLabel>{{ label ?? fileIndex?.name }}</div>
    <div wdxInfoCardSub>
        {{ subtitle ?? (fileIndex?.sizeInBytes | fileSize) }}
    </div>
    <div wdxInfoCardTrailing>
        <atom-icon-button
            size="sm"
            [icon]="ICON_DOWNLOAD"
            (click)="downloadFile()"
        >
        </atom-icon-button>
        <ng-content></ng-content>
    </div>
</wdx-info-card>
