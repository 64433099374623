import { Component, Input } from '@angular/core';
import { Avatar } from '@wdx/clmi/utils/models';
import { WdxSize } from '@wdx/shared/utils';
import { BaseComponentClass } from '../../../../classes/base-component.class';
import { MenuItem } from '../../../../models/menu-item.model';
import { RandomStringPipe } from '../../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar-group',
    templateUrl: './molecule-avatar-group.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex align-items-center' },
})
export class MoleculeAvatarGroupComponent extends BaseComponentClass {
    @Input() avatars: Avatar[];
    @Input() size: WdxSize = 'md';
    @Input() limit = 3;
    @Input() fixedWidth: boolean; // fixes the min width of the component to show 2 avatars + count
    @Input() forceGroup: boolean;
    @Input() onMutedBg: boolean;
    @Input() onActiveBg: boolean;
    @Input() editModalId: string;

    getAvatarGroup(avatars: Avatar[]): Avatar[] {
        if (avatars?.length) {
            const uniquePartyIds = Array.from(
                new Set(avatars.map((avatar) => avatar?.partyId))
            );
            return uniquePartyIds.map((partyId) =>
                avatars.find((avatar) => avatar?.partyId === partyId)
            );
        }
    }

    getOverflowAvatarsMenu(avatars: Avatar[], limit: number): MenuItem[] {
        if (avatars?.length > limit) {
            return avatars
                .slice(limit - 1)
                .map((avatar) => {
                    return {
                        label: avatar.text,
                        avatar: avatar,
                        modalId: new RandomStringPipe().transform(),
                    };
                })
                .filter((party) => party);
        }
    }
}
