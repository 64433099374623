<div class="widget h-100" [ngClass]="widgetSize ? 'widget-' + widgetSize : ''" [attr.data-cy] = "'widget-' + widgetTitle">
    <div *ngIf="widgetTitle || viewsMenu?.length" class="widget-header">
        <h5 class="mb-0 flex-grow-1">
            <div class="widget-header__title d-flex">
                <ng-container *ngIf="!viewsMenu?.length; else viewSelector">
                    <span>{{ widgetTitle }}</span>
                    <span *ngIf="(count || count === 0) && !hideCount"
                        >&nbsp;({{ count }})</span
                    >
                </ng-container>
                <ng-template #viewSelector>
                    <molecule-context-menu-dropdown
                        *ngIf="viewsMenu?.length"
                        parentContainer="body"
                        btnClass="h5 btn-link"
                        [placement]="[
                            'bottom-left',
                            'top-left',
                            'bottom-right',
                            'top-right'
                        ]"
                        [menu]="viewsMenu"
                        [defaultSelectFirst]="true"
                        (singleItemSelected)="onViewSelected($event)"
                        [attr.data-cy]="'widget-context-menu-' + widgetTitle"
                    ></molecule-context-menu-dropdown>
                </ng-template>
            </div>
        </h5>
        <button
            *ngIf="
                spokeButton &&
                spokeButton.position === SPOKE_BUTTON_POSITION.Header
            "
            class="btn btn-link"
            data-cy="btn-see-all"
            [modalId]="spokeButton.modalId"
            (click)="onSpokeButtonClicked()"
        >
            {{ spokeButton.label }}
        </button>
        <ng-content select=".widget-header-action-buttons"></ng-content>
    </div>

    <div *ngIf="filterHandler && sortMenu" class="widget-header">
        <molecule-filter-bar
            [showFilters]="false"
            [filterHandler]="filterHandler"
            [sortMenu]="sortMenu"
        ></molecule-filter-bar>
    </div>

    <div
        class="widget-body position-relative"
        [class.overflow-hidden]="!disableOverflowClasses"
        [ngClass]="containerClass"
    >
        <atom-async-data
            *ngIf="!showNoDataMessage || isLoading || hasError"
            class="d-block h-100"
            [class.overflow-auto]="!disableOverflowClasses"
            [isLoading]="isLoading"
            [hasError]="hasError"
        >
            <ng-content select="div.widget-header"></ng-content>
            <ng-content></ng-content>
        </atom-async-data>

        <div
            *ngIf="showNoDataMessage && !isLoading && !hasError"
            class="d-flex h-100 justify-content-center align-items-center"
        >
            <atom-feature-svg size="xs" [svg]="NO_DATA_SVG">
                {{ noDataMessage }}
            </atom-feature-svg>
        </div>
    </div>

    <atom-widget-full-width-button
        *ngIf="
            spokeButton &&
            !(spokeButton | fn: hideSpokeButton:numberToCompareToLimit:limit) &&
            (!spokeButton.position ||
                spokeButton.position === SPOKE_BUTTON_POSITION.Bottom)
        "
        [attr.data-cy]="'btn-see-all-' + spokeButton.label"
        [modalId]="spokeButton.modalId"
        (click)="onSpokeButtonClicked()"
    >
        {{ spokeButton.label }}
    </atom-widget-full-width-button>
</div>
