import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as operationsActions from '../../state/operations/operations.actions';
import * as outcomesActions from './outcomes.actions';
import { OutcomesService } from './outcomes.service';

@Injectable()
export class OutcomesEffects {
    private actions$ = inject(Actions);
    private outcomesService = inject(OutcomesService);

    setOutcomeForWorkItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(outcomesActions.setOutcomeForWorkItem),
            switchMap((action) =>
                this.outcomesService
                    .setOutcomeForWorkItem(action.outcomeId, action.workItemId)
                    .pipe(
                        map((response) =>
                            outcomesActions.setOutcomeForWorkItemSuccess({
                                outcomeId: action.outcomeId,
                                workItemId: action.workItemId,
                                message: response.message,
                            })
                        ),
                        catchError((error) =>
                            of(
                                outcomesActions.setOutcomeForWorkItemFailure({
                                    outcomeId: action.outcomeId,
                                    workItemId: action.workItemId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    setOutcomeForWorkItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(outcomesActions.setOutcomeForWorkItemSuccess),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Activity,
                    entityId: action.workItemId,
                })
            )
        )
    );
}
