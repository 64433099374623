import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { userActions } from '@wdx/clmi/api-services/services';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    createSignalRHub,
    mergeMapHubToAction,
    signalrConnected,
    signalrHubUnstarted,
    startSignalRHub,
} from 'ngrx-signalr-core';
import { merge } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { SignalrEvent } from '../../constants/signalr.constants';
import { SignalRService } from '../../services/signalr.service';
import * as countriesActions from '../../state/countries/countries.actions';
import * as currenciesActions from '../../state/currencies/currencies.actions';
import * as rolesActions from '../../state/roles/roles.actions';
import * as systemActions from '../../state/system/system.actions';
import { WINDOW } from '../../window.provider';
import * as globalActions from './global.actions';
@Injectable()
export class GlobalEffects {
    private actions$ = inject(Actions);
    private signalRService = inject(SignalRService);
    private toastService = inject(WdxToastService);
    private window = inject(WINDOW);

    allPotentialRequiredInitialisationActions = [
        countriesActions.getCountriesSuccess,
        currenciesActions.getCurrenciesSuccess,
        rolesActions.getAllSuccess,
        systemActions.getBuildSuccess,
        userActions.getMeLocaleSuccessAction,
    ];
    requiredInitialisationActions = [];
    requiredInitialisationSuccessActions = [];

    receivedInitialisationActions = [];

    initialiseApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(globalActions.initialiseApp),
            mergeMap((action) => {
                this.requiredInitialisationActions = [
                    systemActions.getBuild(),
                    ...(action.isAuthenticated
                        ? [
                              countriesActions.getCountries(),
                              currenciesActions.getCurrencies(),
                              rolesActions.getAll(),
                              userActions.getMeLocaleAction(),
                          ]
                        : []),
                ];

                this.requiredInitialisationSuccessActions = [
                    systemActions.getBuildSuccess,
                    ...(action.isAuthenticated
                        ? [
                              countriesActions.getCountriesSuccess,
                              currenciesActions.getCurrenciesSuccess,
                              rolesActions.getAllSuccess,
                              userActions.getMeLocaleSuccessAction,
                          ]
                        : []),
                ];

                return [
                    ...this.requiredInitialisationActions,
                    createSignalRHub(this.signalRService.getConfig()),
                ];
            })
        )
    );

    appInitialised$ = createEffect(() =>
        this.actions$.pipe(
            ofType(...this.allPotentialRequiredInitialisationActions),
            mergeMap((action) => {
                this.receivedInitialisationActions = [
                    ...this.receivedInitialisationActions,
                    action.type,
                ];

                return this.requiredInitialisationSuccessActions.every(
                    (requiredAction) =>
                        this.receivedInitialisationActions.includes(
                            requiredAction.type
                        )
                )
                    ? [globalActions.appInitialised()]
                    : [];
            })
        )
    );

    initRealtime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrHubUnstarted),
            map((hub) => startSignalRHub(hub))
        )
    );

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.UIReload).pipe(
                        tap(() => this.window.location.reload()),
                        map(() => globalActions.listenToUiReloadSuccess())
                    )
                )
            )
        )
    );
}
