import {
    CubeQuery,
    CurrencyValue,
    FilterFieldType,
    LookupFieldResult,
    Query,
    QueryExpression,
    QueryRollupType,
    SystemEntity,
} from '@wdx/clmi/api-models';

/**
 * =====================
 * Cube Dashboard Config
 * =====================
 */
export interface CubeDashboardConfig {
    name?: string;
    icon?: string;
    description?: string;
    rollupsTitle?: string;
    rollups?: RollupConfig[];
    grid?: string;
    sharedWith?: LookupFieldResult[];
    cubes?: CubeConfig[];
}

/**
 * ==================
 * Rollup tile Config
 * ==================
 */

export interface RollupConfig {
    /**
     * The entity type that is sent to the cube
     */
    entityType: SystemEntity;
    /**
     * The assocaited view id (this will internally resolve the filter query)
     */
    viewId?: string;
    /**
     * The associated view query (this is a static query to be used instead of a viewId and will not resolve the filter query)
     */
    viewQuery?: Query;
    /**
     * The name of the tile
     */
    name: string;
    /**
     * What rollup type to use in the cube query
     */
    rollupType?: QueryRollupType;
    /**
     * Which rollup column to use (if applicable)
     */
    rollupColumn?: CubeGroup;
    /**
     * The rollup thresholds levels
     */
    thresholds?: {
        target: string;
        level1: string;
        level2: string;
    };
}

/**
 * ============
 * Cube Config
 * =============
 */

/**
 * The Cube interface defines and object
 */
export interface CubeConfig {
    /**
     * Internal identifier
     */
    id?: string;
    /**
     * Disable the highcharts menu
     */
    disableMenu?: boolean;
    /**
     * The main title of the chart
     */
    title: string;
    /**
     * The sub title of the chart
     */
    subtitle?: string;
    /**
     * The entity type that is sent to the cube
     */
    entityType: SystemEntity;
    /**
     * The assocaited view id (this will internally resolve the filter query)
     */
    viewId?: string;
    /**
     * The associated view query (this is a static query to be used instead of a viewId and will not resolve the filter query)
     */
    viewQuery?: CubeQuery;
    /**
     * The overall cube chart type including mixed type charts
     */
    chartType: string;
    /**
     * A series definition is required for each chart type
     */
    series: CubeConfigSeries[];
    /**
     * Config for highcharts xAxis (category axis)
     */
    xAxis: CubeXAxis;
    // TODO: this was added as part of nx install work, cube-dashboard.component.html expects this to exist
    // and build filas unless rollups is included in config - need to check if this actually part of the data
    rollups?: RollupConfig[];
}

/**
 * Series Options are dependent on the type of chart consumed. .
 */
export interface CubeConfigSeries {
    /**
     * The name of the series
     */
    name: string;
    /**
     * The property that data be grouped by
     */
    group: CubeGroup;
    /**
     * The series chartType (this will be the same as overall charttype unless mixed)
     */
    chartType: CubeChartType;
    /**
     * What rollup type to use in the cube query
     */
    rollupType: QueryRollupType;
    /**
     * Which rollup column to use (if applicable)
     */
    rollupColumn?: CubeGroup;
    /**
     * The interval (for date related grouping)
     */
    interval?: CubeInterval;
    /**
     * Stacking options (if applicable)
     */
    stacking?: Highcharts.OptionsStackingValue;
    /**
     * Binds a value to a css color class (accepts a bootstrap colour class) e.g.
     */
    bindColor?: { name: string; color: string }[];
    /**
     * Enable or disable the data labels
     */
    dataLabels?: boolean;
}

/**
 * The xAxis of the cube
 */
export interface CubeXAxis {
    /**
     * The label to be displayed on the axis
     */
    title?: string;
    /**
     * The property key to use for the axis grouping
     */
    group: CubeGroup;
    /**
     * The interval (for date related grouping)
     */
    interval?: CubeInterval;
}

export enum CubeInterval {
    Decade = 'Decade',
    Year = 'Year',
    Quarter = 'Quarter',
    Month = 'Month',
    Week = 'Week',
    Tens = '10',
    Twenties = '20',
    Fifties = '50',
    Hundreds = '100',
    Thousands = '1000',
    Millions = '1000000',
}

export interface CubeGroup {
    name: string;
    type: FilterFieldType;
}

export type CubeChartType =
    | 'area'
    | 'column'
    | 'line'
    | 'bar'
    | 'pie'
    | 'bullet'
    | 'mixed';

/**
 * ==========
 * Cube Data
 * =========
 */
export interface CubeCategory {
    name: string;
    code: string;
    rollupValue?: CubeRollupValue;
    value?: any;
}

export type CubeRollupValue = number | CurrencyValue;

export interface CubeSeries extends CubeConfigSeries {
    code: string;
    categories: CubeCategory[];
}

/**
 * ==========
 * Cube Events
 * ==========
 */
export interface CubeSegmentEvent {
    viewExpressions: QueryExpression[];
    cubeExpressions: QueryExpression[];
    groups: CubeSegmentGroup[];
    tooltip: any;
}

export interface CubeSegmentData {
    cubeConfig: CubeConfig;
    viewExpressions: QueryExpression[];
    cubeExpressions: QueryExpression[];
    groups: CubeSegmentGroup[];
    tooltip: any;
}

export interface CubeSegmentGroup {
    name: string;
    value: string;
}

export const CHARTS_ENTITY_ICON_MAP: Record<string, string> = {
    [SystemEntity.Activity]: 'tasks',
    [SystemEntity.Case]: 'suitcase',
    [SystemEntity.Client]: 'user',
    [SystemEntity.ClientProduct]: 'box-open',
    [SystemEntity.Lead]: 'funnel-dollar',
    [SystemEntity.Opportunity]: 'funnel-dollar',
    [SystemEntity.Organisation]: 'building',
    [SystemEntity.Person]: 'user',
    [SystemEntity.Task]: 'tasks',
    [SystemEntity.WorkItem]: 'tasks',
};
