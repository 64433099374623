import { Injectable, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    List,
    ListMemberDetailed,
    LookupFieldResult,
    Party,
    PartyRole,
    Person,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { ENTITY_ROUTE_MAP } from '@wdx/shared/utils';
import { Observable, map, tap, withLatestFrom } from 'rxjs';
import { EntityRoutePipe } from '../../../../pipes/entity-route.pipe';
import { ListTypePipe } from '../../../../pipes/list-type.pipe';
import * as listsActions from '../../../../state/lists/lists.actions';
import * as listsSelectors from '../../../../state/lists/lists.selectors';
import { LIST_TYPE_ICON_MAP } from '../constants/lists.static';

@Injectable({
    providedIn: 'root',
})
export class ListFacade {
    listId = '';

    marketingLists$: Observable<List[]>;
    marketingListsIsLoading$: Observable<boolean>;
    marketingListsHasError$: Observable<boolean>;

    list$: Observable<List>;
    listIsLoading$: Observable<boolean>;
    listHasError$: Observable<boolean>;

    members$: Observable<ListMemberDetailed[]>;
    membersIsLoading$: Observable<boolean>;
    membersHasError$: Observable<boolean>;

    parties$: Observable<Party[]>;
    partiesIsLoading$: Observable<boolean>;
    partiesHasError$: Observable<boolean>;

    partyRoles$: Observable<PartyRole[]>;
    partyRolesIsLoading$: Observable<boolean>;
    partyRolesHasError$: Observable<boolean>;

    people$: Observable<Person[]>;
    peopleIsLoading$: Observable<boolean>;
    peopleHasError$: Observable<boolean>;

    constructor(private store$: Store) {}

    loadMarketingLists(): void {
        this.store$.dispatch(listsActions.getLists());
    }

    setMarketingLists(): void {
        this.marketingLists$ = this.store$.select(listsSelectors.getLists);
        this.marketingListsIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingLists
        );
        this.marketingListsHasError$ = this.store$.select(
            listsSelectors.getHasLoadingListsError
        );
    }

    loadList(listId: string): void {
        this.store$.dispatch(listsActions.getListForId({ listId }));
    }

    setList(listId: string): void {
        this.list$ = this.store$.select(listsSelectors.getListForId(listId));
        this.listIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingListForId,
            { listId }
        );
        this.listHasError$ = this.store$.select(
            listsSelectors.getHasLoadingListForIdError,
            { listId }
        );
    }

    getList(listId): Observable<List> {
        this.listId = listId;
        this.setList(listId);
        return this.list$.pipe(
            withLatestFrom(this.listIsLoading$, this.listHasError$),
            tap(([list, listIsLoading, listHasError]) => {
                if (!list && !listIsLoading && !listHasError) {
                    this.loadList(listId);
                }
            }),
            map(([list]) => list)
        );
    }

    loadParties(listId: string): void {
        this.store$.dispatch(listsActions.getPartiesForId({ listId }));
    }

    loadPartyRoles(listId: string): void {
        this.store$.dispatch(listsActions.getPartyRolesForId({ listId }));
    }

    loadMembers(listId: string): void {
        this.store$.dispatch(listsActions.getMembersForId({ listId }));
    }

    setParties(listId: string): void {
        this.parties$ = this.store$.select(
            listsSelectors.getPartiesForId(listId)
        );
        this.partiesIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingPartiesForId(listId)
        );
        this.partiesHasError$ = this.store$.select(
            listsSelectors.getHasLoadingPartiesForIdError(listId)
        );
    }

    setPartyRoles(listId: string): void {
        this.partyRoles$ = this.store$.select(
            listsSelectors.getPartyRolesForId(listId)
        );
        this.partyRolesIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingPartyRolesForId(listId)
        );
        this.partyRolesHasError$ = this.store$.select(
            listsSelectors.getHasLoadingPartyRolesForIdError(listId)
        );
    }

    setMembers(listId: string): void {
        this.members$ = this.store$.select(
            listsSelectors.getMembersForId(listId)
        );
        this.membersIsLoading$ = this.store$.select(
            listsSelectors.getIsLoadingMembersForId(listId)
        );
        this.membersHasError$ = this.store$.select(
            listsSelectors.getHasLoadingMembersForIdError(listId)
        );
    }

    onAddPartiesToList(args: { parties: Party[]; list: List }): void {
        if (!args.parties && !args.list) {
            return;
        }

        this.store$.dispatch(
            listsActions.addPartiesToList({
                list: args.list,
                parties: args.parties,
            })
        );
    }

    onAddLookupResultsToList(args: {
        lookupResults: LookupFieldResult[];
        list: List;
    }): void {
        if (!args.lookupResults && !args.list) {
            return;
        }

        this.store$.dispatch(
            listsActions.addLookupResultsToList({
                list: args.list,
                lookupResults: args.lookupResults,
            })
        );
    }

    onRemoveMemberFromList(
        entityId: string,
        entityType: SystemEntity,
        listId?: string
    ): void {
        if (!entityId || !entityType) {
            return;
        }

        this.store$.dispatch(
            listsActions.deleteEntityFromList({
                listId: listId || this.listId,
                entityId,
                entityType,
            })
        );
    }

    getMarketingListAsTableRow(list: List, infoCardTemplate: TemplateRef<any>) {
        return {
            routerLink: new EntityRoutePipe().transform(
                SystemEntity.List,
                list.id
            ),
            values: [
                {
                    templateRef: infoCardTemplate,
                    data: {
                        label: list.name,
                        icon: LIST_TYPE_ICON_MAP[list.type],
                        size: 'md',
                        separator: true,
                        routerLink: [
                            ...ENTITY_ROUTE_MAP[SystemEntity.List],
                            list.id,
                        ],
                    },
                },
                {
                    templateRef: infoCardTemplate,
                    data: {
                        label: list.owner.name,
                        avatar: new PartyToAvatarPipe().transform(list.owner),
                        size: 'sm',
                    },
                },
                {
                    value: new ListTypePipe().transform(list.type),
                },
                {
                    value: `${list.numberOfMembers || 0}`,
                },
                {
                    value: list.description,
                },
                {
                    value: list.status,
                },
            ],
        };
    }

    deleteEntityFromList(
        listId: string,
        entityType: SystemEntity,
        entityId: string
    ): void {
        this.store$.dispatch(
            listsActions.deleteEntityFromList({
                listId,
                entityType,
                entityId,
            })
        );
    }
}
