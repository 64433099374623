import { Pipe, PipeTransform } from '@angular/core';
import { FormSummaryService } from '../../../../../shared/features/form-summary/services/form-summary/form-summary.service';
import { FieldDefinition } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { FormSummaryValueOptions } from '../../models/form-summary-value.model';

@Pipe({ name: 'formSummaryValue' })
export class FormSummaryValue implements PipeTransform {
    constructor(private formSummaryService: FormSummaryService) {}

    transform(
        value: string,
        fieldDefinition: FieldDefinition,
        formSchema?: FieldDefinition[],
        options?: FormSummaryValueOptions,
        parentData?: any
    ): Observable<string> {
        return this.formSummaryService.getStringValue(
            value,
            fieldDefinition,
            options,
            parentData,
            formSchema
        );
    }
}
