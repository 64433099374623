import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormSummaryMode } from '../models/form-summary-mode.model';
import { DecodeHtmlEntitiesPipe } from '../pipes/decode-html-entities.pipe';
import { LookupFieldResult } from '@wdx/clmi/api-models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEFAULT_INPUT_DEBOUNCE_DELAY } from '../constants/dynamic-form.constants';
import { DynamicFieldDefinitionOptionType } from '../models/dynamic-field-definition-option-type.model';
import { DynamicFieldDefinitionOption } from '../models/dynamic-field-definition-option.model';
import { ExtendedFieldDefinitionOption } from '../models/extended-field-definition-option.model';
import { FormDisplayMode } from '../models/form-display-mode.model';
import { MenuItem } from '../models/menu-item.model';
import { RandomStringPipe } from '../pipes/random-string.pipe';
import { BaseComponentClass } from './base-component.class';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';

@Directive()
export abstract class BaseInputClass
    extends BaseComponentClass
    implements OnChanges, AfterViewInit
{
    @Input() trackingValue: any;
    @Input() formInputData: FormInputData;
    @Input() validationErrorMessage: string;
    @Input() allowEmit = false;
    @Input() set initialValue(value) {
        const valueWithDefaultFallback = value || this.defaultValue;

        let valueToUse = valueWithDefaultFallback;

        if (this.useConverter) {
            valueToUse = this.convertIn(valueToUse);
        }

        if (this.formInputData?.hasDynamicOptions) {
            valueToUse = this.convertDynamicOptionIn(valueWithDefaultFallback);
        }

        this.middlemanValue = valueToUse;

        this.updateValue(valueToUse, this.allowEmit);
    }
    @Input() inputOnly: boolean;
    @Input() hideGuidanceText: boolean;
    @Input() appearEditable: boolean;
    @Input() formDisplayMode: FormDisplayMode;
    @Input() formSummaryMode: FormSummaryMode;
    @Input() dynamicOptions: DynamicFieldDefinitionOption[];

    @Output() inputRegistered = new EventEmitter<ElementRef>();
    @Output() valueChanged = new EventEmitter<any>();

    readonly FORM_DISPLAY_MODE = FormDisplayMode;

    dropdownMenu: MenuItem[];

    value: any;
    defaultValue: any;
    middlemanValue: any;
    arrayCache: any[] = [];
    valueSubject = new Subject<any>();

    useDebouncer = false;
    useConverter = false;
    requireConfirmation = false;
    debouncer = new Subject<any>();

    instanceId: string;

    options: ExtendedFieldDefinitionOption[] | DynamicFieldDefinitionOption[];

    constructor() {
        super();

        this.debouncer
            .pipe(debounceTime(DEFAULT_INPUT_DEBOUNCE_DELAY))
            .subscribe(() => this.valueChanged.emit(this.value));

        this.instanceId = new RandomStringPipe().transform();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (
            (_changes.formInputData &&
                _changes.formInputData.currentValue !==
                    _changes.formInputData.previousValue) ||
            (_changes.dynamicOptions &&
                _changes.dynamicOptions.currentValue !==
                    _changes.dynamicOptions.previousValue)
        ) {
            this.options = (
                this.formInputData?.options || this.dynamicOptions
            )?.map((option: ExtendedFieldDefinitionOption) => ({
                ...option,
                label: new DecodeHtmlEntitiesPipe().transform(option.label),
                ...(option?.subOptions && {
                    subOptions: option.subOptions.map((subOption) => ({
                        ...subOption,
                        label: new DecodeHtmlEntitiesPipe().transform(
                            subOption.label
                        ),
                    })),
                }),
            }));
        }
    }

    ngAfterViewInit(): void {
        this.inputRegistered.emit(this.elementRef);
    }

    createDropdownMenuFromOptions() {
        this.dropdownMenu = (this.formInputData.options || []).map(
            (option): MenuItem => ({
                value: option.value,
                label: option.label,
                icon: option.icon,
            })
        );
    }

    displaySelectedValues(
        value: any,
        options: ExtendedFieldDefinitionOption[],
        multiSelect?: boolean
    ): string {
        if (!value?.length || !options?.length) {
            return '';
        }
        const values = Array.isArray(value) ? value : [value];
        if (multiSelect) {
            return `${values.length} Selected`;
        } else {
            return options
                .map((subMenu) =>
                    subMenu?.subOptions
                        ? subMenu.subOptions.find(
                              (option) => option.value === values[0]
                          )?.label
                        : options.find((option) => option.value === values[0])
                              ?.label || ''
                )
                .filter((option) => option !== undefined)[0];
        }
    }

    valueAsConcatenatedString(
        value: any,
        options: ExtendedFieldDefinitionOption[],
        delimiter: string
    ): string {
        const labelArray =
            value && options
                ? (Array.isArray(value) ? value : [value]).map(
                      (item) =>
                          options.find((option) => option.value === item)
                              ?.label || ''
                  )
                : [];

        return labelArray.join(delimiter);
    }

    convertOut(middlemanValue: any) {
        return middlemanValue;
    }

    convertIn(middlemanValue: any) {
        return middlemanValue;
    }

    convertDynamicOptionOut(value: string): any {
        if (!value || !this.dynamicOptions) {
            return;
        }

        const valueIsArray = Array.isArray(value);
        const valueArray = valueIsArray ? value : [value];

        switch (this.dynamicOptionsType()) {
            case DynamicFieldDefinitionOptionType.Select:
                // eslint-disable-next-line no-case-declarations
                const selectFieldOptions = this.dynamicOptions.filter(
                    (dynamicOption) =>
                        valueArray.includes(dynamicOption.value) ||
                        valueArray.includes(dynamicOption.selectFieldResult?.id)
                );

                return valueIsArray
                    ? selectFieldOptions.map(
                          (dynamicOption) => dynamicOption.selectFieldResult.id
                      )
                    : selectFieldOptions[0]?.selectFieldResult?.id;

            case DynamicFieldDefinitionOptionType.Lookup:
                // eslint-disable-next-line no-case-declarations
                const lookupFieldOptions = this.dynamicOptions.filter(
                    (dynamicOption) =>
                        valueArray.includes(dynamicOption.value) ||
                        valueArray.includes(dynamicOption.lookupFieldResult?.id)
                );

                return valueIsArray
                    ? lookupFieldOptions.map(
                          (dynamicOption) => dynamicOption.lookupFieldResult.id
                      )
                    : lookupFieldOptions[0]?.lookupFieldResult?.id;

            default:
                break;
        }
    }

    convertDynamicOptionIn(value: any): any {
        switch (this.dynamicOptionsType()) {
            case DynamicFieldDefinitionOptionType.Select:
                // eslint-disable-next-line no-case-declarations
                const selectValue = value as string;
                return selectValue;
            case DynamicFieldDefinitionOptionType.Lookup:
                // eslint-disable-next-line no-case-declarations
                const lookupValue = value as LookupFieldResult;
                return lookupValue?.id;

            default:
                break;
        }
    }

    dynamicOptionsType(): DynamicFieldDefinitionOptionType {
        switch (true) {
            case Boolean(this.formInputData.selectSource):
                return DynamicFieldDefinitionOptionType.Select;
            case Boolean(this.formInputData.lookupSources):
                return DynamicFieldDefinitionOptionType.Lookup;
            default:
                break;
        }
    }

    onChange(value: any): void {
        this.updateValue(value);
    }

    onPatch(key: string, value: any): void {
        const newValue = {
            ...(this.value || {}),
            [key]: value,
        };

        this.updateValue(newValue);
    }

    onClear(): void {
        this.updateValue(undefined);
    }

    onToggle(value: any): void {
        this.arrayCache = this.arrayCache || [];

        this.arrayCache = this.arrayCache.includes(value)
            ? this.arrayCache.filter((cacheValue) => cacheValue !== value)
            : [...this.arrayCache, value];

        this.arrayCache = this.arrayCache.length ? this.arrayCache : undefined;

        this.updateValue(this.arrayCache);
    }

    onConfirm(): void {
        this.emitValue();
    }

    updateValue(value: any, allowEmit = true): void {
        let convertedValue = value;

        if (
            typeof value === 'string' ||
            (Array.isArray(this.value) && typeof value?.[0] === 'string')
        ) {
            convertedValue = new DecodeHtmlEntitiesPipe().transform(value);
        }

        if (this.useConverter) {
            convertedValue = this.convertOut(value);
        }

        this.value = convertedValue;

        this.arrayCache = Array.isArray(this.value) ? this.value : undefined;

        if (!this.requireConfirmation) {
            if (allowEmit) {
                this.emitValue();
            } else {
                this.valueSubject.next(this.compileEmitableValue());
            }
        }
    }

    compileEmitableValue(): any {
        return this.formInputData?.hasDynamicOptions
            ? this.convertDynamicOptionOut(this.value)
            : this.value;
    }

    emitValue(): void {
        const valueToEmit = this.compileEmitableValue();

        if (this.useDebouncer) {
            this.debouncer.next(valueToEmit);
        } else {
            this.valueChanged.emit(valueToEmit);
        }

        this.valueSubject.next(valueToEmit);
    }
}
