import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponentClass } from '../../classes/base-component.class';
import { AuthenticationService } from '../../services/authentication.service';
import { ErrorManagementService } from '../../shared/services/error-management/error-management.service';
import * as authenticationActions from '../../state/authentication/authentication.actions';
import * as rootReducer from '../../state/_setup/reducers';
import { Store } from '@ngrx/store';
@Component({
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    animations: [
        trigger('fadein', [
            transition(':enter', [
                style({
                    transform: 'scale(1.2)',
                    opacity: 0,
                }),
                animate(
                    '1500ms cubic-bezier(.04,.57,.32,1)',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    })
                ),
            ]),
        ]),
        trigger('fadeinlogo', [
            transition('1 => 0', [
                style({
                    transform: 'scale(1.2)',
                    opacity: 0,
                }),
                animate(
                    '1000ms 300ms ease-out',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    })
                ),
            ]),
        ]),
        trigger('fadeinbutton', [
            transition(':enter', [
                style({
                    transform: 'scale(1.2)',
                    boxShadow: '0 1.5rem 3rem rgba(0, 0, 0, .3)',
                    opacity: 0,
                }),
                animate(
                    '1000ms 500ms ease-out',
                    style({
                        transform: 'scale(1)',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
                        opacity: 1,
                    })
                ),
            ]),
        ]),
    ],
})
export class WelcomeComponent extends BaseComponentClass implements OnInit {
    NUMBER_IMAGES_TO_LOAD = 3;
    imageNum: number;
    numberOfAvailableImages = 3;
    showButton: boolean;
    loading = true;
    loadedImagesCount = 0;
    error: string[];

    constructor(
        store$: Store<rootReducer.State>,
        private errorManagementService: ErrorManagementService,
        private authenticationService: AuthenticationService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super();

        this.patchInjectedItems({
            store$,
        });
    }

    ngOnInit(): void {
        this.generateImageNum();
        if (this.errorManagementService.hasError()) {
            this.error = this.errorManagementService.getErrorMessage();
        }
    }

    generateImageNum(): void {
        this.imageNum = Math.ceil(Math.random() * this.numberOfAvailableImages);
    }

    login(): void {
        const returnToUrl =
            this.route.snapshot.queryParamMap.get('return_to') || null;
        this.authenticationService.login({ returnToUrl: returnToUrl });
    }

    logout(): void {
        this.store$.dispatch(authenticationActions.logout());
    }

    onImageLoaded(): void {
        this.loadedImagesCount++;

        if (this.loadedImagesCount === this.NUMBER_IMAGES_TO_LOAD) {
            this.loading = false;
        }
    }
}
