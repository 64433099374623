import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ActivityPartyType } from '@wdx/clmi/api-models';
import {
    ACTIVITY_FORM_ID_MAP,
    ACTIVITY_ICON_MAP,
} from '../../constants/activity.constants';
import { ACTIVITY_NOTES_FORM_ID } from '../../constants/dynamic-form.constants';
import { ListItem } from '../../models/list-item.model';
import { ToDoItem } from '../../models/to-do-item.model';
import { ReferenceToAvatarPipe } from '../reference-to-avatar.pipe';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Pipe({ name: 'activityAsToDoItem' })
export class ActivityAsToDoItemPipe implements PipeTransform {
    transform(value: Activity | Activity[]): ToDoItem | ToDoItem[] {
        if (!value) {
            return;
        }

        const valueArray = Array.isArray(value) ? value : [value];

        const results = valueArray?.map((activity) => ({
            id: activity.id,
            primaryInfo: activity.subject,
            content: activity.content,
            avatars: activity.activityParties
                ? activity.activityParties
                      .filter(
                          (m) =>
                              m.activityPartyType !== ActivityPartyType.Creator
                      )
                      .map((activityParty) =>
                          activityParty.party
                              ? new PartyToAvatarPipe().transform(
                                    activityParty.party
                                )
                              : new ReferenceToAvatarPipe().transform(
                                    activityParty.contactPoint
                                )
                      )
                : [],
            attachments: activity.countFiles,
            tags: activity.regarding?.length,
            icon: ACTIVITY_ICON_MAP[activity.activityType],
            dueDate: new Date(activity.due || activity.dateCreated),
            data: {
                activity,
                activityFormSetup: {
                    formId: ACTIVITY_FORM_ID_MAP[activity.activityType],
                    entityId: activity.id,
                },
                notesFormSetup: {
                    formId: ACTIVITY_NOTES_FORM_ID,
                    entityId: activity.id,
                },
            },
            unknownContacts: activity.activityParties?.filter(
                (party) => party.contactPoint && !party.party
            ).length,
            filedUnder: activity.regarding
                ? activity.regarding.map((regarding) => {
                      return {
                          label: regarding.name ?? regarding.type,
                          data: regarding,
                      } as ListItem;
                  })
                : [],
            status: activity.status,
            hasIssue: activity.hasIssue,
            commentCount: activity.commentCount,
            externalUrl: activity.conversationLink,
            conversationStatus: activity.conversationStatus,
        }));

        return Array.isArray(value) ? results : results[0];
    }
}
