import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { Privilege } from '../../../models/privilege.model';
import { SortMenuSortBy } from '../../../models/sort-menu.model';
import { SystemEntity } from '@wdx/clmi/api-models';
import { TranslationKey } from '@wdx/clmi/api-services/services';

export const CLIENT_RELATIONSHIP_TABLE_HEADER = [
    {
        value: 'Name',
        sortByFieldName: SortMenuSortBy.Name,
    },
    {
        value: 'Lifecycle Stage',
    },
    {
        value: 'Type',
    },
    {
        value: 'Sub-Type',
    },
    {
        value: 'Last Contacted',
        sortByFieldName: SortMenuSortBy.LastContacted,
    },
    {
        value: 'Next Activity',
        sortByFieldName: SortMenuSortBy.NextMeeting,
    },
    {
        value: 'Open Cases',
    },
    {
        value: 'Pipeline (#)',
        sortByFieldName: SortMenuSortBy.Pipeline,
    },
    {
        value: 'AUM',
        sortByFieldName: SortMenuSortBy.AUM,
    },
    {
        value: 'Last Review',
        sortByFieldName: SortMenuSortBy.DateLastReview,
    },
    {
        value: 'Next Review',
        sortByFieldName: SortMenuSortBy.DateNextReview,
    },
    {
        value: null,
    },
];

export const CLIENTS_ACTION_BTN = [
    {
        mode: ActionButtonMode.FavouriteButton,
        cySelector: 'btn-favorite-client',
    },
];

export const CLIENTS_ROUTE_DATA = {
    viewType: SystemEntity.Client,
    exportPrivilege: Privilege.ExportClient,
    label: 'Client Relationships',
    translationKey: TranslationKey.AreaContactsClients,
};
