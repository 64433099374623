<molecule-form-version-info *ngIf="showFormSwitcherPanel">
    <ng-container *ngTemplateOutlet="formVersionInfo"></ng-container>
</molecule-form-version-info>

<ngb-accordion
    *ngIf="data && layoutDefinition"
    class="accordion accordion-widget"
    [ngClass]="{
        'sub-form': isSubForm
    }"
    [destroyOnHide]="false"
    [closeOthers]="false"
    [activeIds]="activeIds"
    [animation]="false"
>
    <ng-container
        *ngFor="
            let formSectionLayoutDefinition of layoutDefinition?.sectionLayoutDefinitions;
            let i = index;
            trackBy: trackBySection
        "
    >
        <ngb-panel
            *ngIf="!formSectionLayoutDefinition.isHidden"
            id="panel-{{ i }}"
            [cardClass]="
                layoutDefinition?.sectionLayoutDefinitions?.length === 1
                    ? 'form-section no-panel-toggle'
                    : 'form-section'
            "
        >
            <ng-template ngbPanelHeader let-opened="opened">
                <button
                    *ngIf="isSubForm"
                    ngbPanelToggle
                    type="button"
                    class="accordion-button d-flex p-2"
                >
                    <div class="flex-grow-1 d-flex align-items-center">
                        <molecule-subform-info
                            class="flex-grow-1"
                            [entityId]="entityId"
                            [fieldDefinition]="layoutDefinition"
                            [sectionLayoutDefinitionName]="
                                formSectionLayoutDefinition.name
                            "
                            [data]="data"
                            [parentData]="parentData"
                            [schema]="definition?.schema"
                        >
                        </molecule-subform-info>

                        <div
                            *ngIf="data?.addedOn"
                            class="flex-shrink-0 text-strong"
                        >
                            Added on:
                            {{
                                data?.addedOn
                                    | formSummaryValue
                                        : {
                                              fieldType: FORM_FIELD_TYPE.Date
                                          }
                                        : definition?.schema
                                    | ngrxPush
                            }}
                        </div>
                    </div>
                </button>

                <button
                    *ngIf="!isSubForm && formSectionLayoutDefinition.label"
                    ngbPanelToggle
                    class="form-section__header accordion-button d-flex align-items-center justify-content-between"
                    [attr.data-cy]="
                        'panel-toggle-' + formSectionLayoutDefinition.name
                    "
                >
                    <h5>
                        {{ formSectionLayoutDefinition.label }}
                    </h5>
                </button>
            </ng-template>

            <ng-template ngbPanelContent>
                <div
                    class="form overflow-hidden"
                    [attr.data-cy]="
                        'panel-' + formSectionLayoutDefinition.label
                    "
                >
                    <div
                        class="grid"
                        [ngClass]="{
                            'mt-2':
                                isSubForm ||
                                layoutDefinition?.sectionLayoutDefinitions[0]
                                    ?.label,
                            'mx-2': isSubForm
                        }"
                    >
                        <ng-container
                            *ngFor="
                                let element of formSectionLayoutDefinition.elementLayoutDefinitions;
                                let ii = index;
                                trackBy: trackByElement
                            "
                        >
                            <ng-container
                                *ngIf="
                                    isSubForm &&
                                    subFormData[layoutDefinition.name]
                                "
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        elementTemplate;
                                        context: {
                                            element:
                                                subFormData[
                                                    layoutDefinition.name
                                                ][subFormDataIndex][ii],  
                                            pendingChanges: formSummaryPendingChangesService?.pendingChangesSubForm?.[
                                                layoutDefinition?.name  
                                            ]?.[subFormDataIndex]    
                                        }
                                    "
                                ></ng-container>
                            </ng-container>

                            <ng-container *ngIf="!isSubForm">
                                <ng-container
                                    *ngTemplateOutlet="
                                        elementTemplate;
                                        context: { element: element }
                                    "
                                ></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ng-container>
</ngb-accordion>

<ng-template
    #elementTemplate
    let-element="element"
    let-pendingChanges="pendingChanges"
>
    <ng-container *ngIf="element && !element.isHidden">
        <div *ngIf="element.forcesNewRow" class="g-col-12"></div>
        <div
            class="g-col-12 mb-2"
            [class.mb-5]="
                formDetails?.pendingChanges?.length ||
                PENDING_CHANGE_LOCK_ALL?.includes(pendingChanges?.status) || pendingChanges?.[element.name]
            "
            [ngClass]="{
                'g-col-lg-6':
                    !element.forcesFullWidth &&
                    !ARRAY_SUMMARY_TYPES.includes(element.fieldType)
            }"
        >
            <div
                *ngIf="
                    ARRAY_SUMMARY_TYPES.includes(element.fieldType);
                    else singleElement
                "
                [attr.data-cy]="'content-' + element?.label"
                [attr.data-cy-value]="data[element.name]?.length || 0"
            >
                <h5 class="mb-3 no-wrap">
                    <span class="d-lg-inline fw-normal">
                        {{ element?.label }}
                    </span>
                    <span
                        [attr.data-cy]="'quantity-amount-' + element?.label"
                        [attr.data-cy-value]="data[element.name]?.length || '0'"
                        >({{ data[element.name]?.length || '0' }})
                    </span>
                </h5>

                <organism-form-summary-content
                    *ngFor="let arrayData of data[element.name]; let i = index"
                    [entityId]="entityId"
                    [isSubForm]="true"
                    [data]="arrayData"
                    [parentData]="data"
                    [layoutDefinition]="element"
                    [fieldIsRequiredInLayout]="fieldIsRequiredInLayoutMap"
                    [subFormData]="subFormData"
                    [subFormDataIndex]="i"
                >
                </organism-form-summary-content>
            </div>

            <ng-template #singleElement>
                <molecule-summary-field
                    class="h-100"
                    [label]="element.label"
                    [isRequired]="
                        element.isRequired ||
                        fieldIsRequiredInLayoutMap[element.name]
                    "
                    [isSensitive]="element.isSensitive"
                    [isPending]="
                        element.pendingChange ||
                        PENDING_CHANGE_LOCK_ALL?.includes(
                            pendingChanges?.status
                        ) || pendingChanges?.[element.name]
                    "
                    [cySelector]="'card-' + element.label"
                >
                    <ng-container
                        *ngIf="data[element.name] !== null; else noValue"
                    >
                        <div
                            *ngIf="
                                !CUSTOM_SUMMARY_TYPES.includes(
                                    element.fieldType
                                )
                            "
                        >
                            <ng-container
                                [ngTemplateOutlet]="
                                    element.fieldType ===
                                    FORM_FIELD_TYPE.Multiselect
                                        ? standardArrayTemplate
                                        : standardSingleTemplate
                                "
                            >
                            </ng-container>

                            <ng-template #standardArrayTemplate>
                                <span
                                    *ngFor="
                                        let item of data[element.name];
                                        let last = last
                                    "
                                    [ngClass]="{
                                        'divider--short divider-end': !last
                                    }"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            standardTemplate;
                                            context: {
                                                item: item
                                            }
                                        "
                                    >
                                    </ng-container></span
                            ></ng-template>

                            <ng-template #standardSingleTemplate>
                                <ng-container
                                    *ngTemplateOutlet="
                                        standardTemplate;
                                        context: {
                                            item: data[element.name]
                                        }
                                    "
                                >
                                </ng-container>
                            </ng-template>

                            <ng-template #standardTemplate let-item="item">
                                <div
                                    class="text-pre-line"
                                    [innerHtml]="
                                        item
                                            | formSummaryValue
                                                : element
                                                : definition?.schema
                                            | ngrxPush
                                    "
                                >
                                    <ng-container *ngIf="element?.prefix">
                                        {{ element.prefix }}
                                    </ng-container>

                                    <ng-container *ngIf="element?.suffix">
                                        {{ element.suffix }}
                                    </ng-container>
                                </div>
                            </ng-template>
                        </div>

                        <div
                            *ngIf="
                                OPTIONS_SUMMARY_TYPES.includes(
                                    element.fieldType
                                )
                            "
                            class="d-flex align-items-center w-100 form-label"
                        >
                            <span
                                *ngIf="
                                    element.selectSource ===
                                    SELECT_API_SOURCE.Country
                                "
                                class="flag flag-{{
                                    data[element?.name] | lowercase
                                }} me-1"
                            ></span>
                            <div>
                                {{
                                    data[element.name]
                                        | formSummaryValue
                                            : element
                                            : definition?.schema
                                            : {
                                                  entityId: entityId,
                                                  formData: data
                                              }
                                            : parentData
                                        | ngrxPush
                                }}
                            </div>
                        </div>

                        <div
                            *ngIf="
                                PHONE_SUMMARY_TYPES.includes(element.fieldType)
                            "
                            class="d-flex align-items-center w-100"
                        >
                            <span
                                class="flag flag-{{
                                    data[element.name].country | lowercase
                                }} me-1"
                            ></span>
                            <span>
                                {{
                                    data[element.name]
                                        | formSummaryValue
                                            : element
                                            : definition?.schema
                                        | ngrxPush
                                        | mask
                                            : (data[element.name]
                                                  | telephonePattern
                                                  | ngrxPush)
                                }}
                            </span>
                            <wdx-badge class="ms-auto me-2" themeColor="info">
                                {{ data[element.name].type | titleCase }}
                            </wdx-badge>
                        </div>

                        <ng-container
                            *ngIf="
                                ADDRESS_SUMMARY_TYPES.includes(
                                    element.fieldType
                                )
                            "
                        >
                            <ng-container
                                *ngIf="
                                    getDisplayAddress(data[element.name])
                                        | ngrxPush;
                                    let displayAddress
                                "
                            >
                                <a
                                    [attr.href]="
                                        'https://www.google.com/maps/place/' +
                                        (displayAddress | encodeURIComponent)
                                    "
                                    target="_blank"
                                    [innerHtml]="displayAddress"
                                >
                                </a>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                TAGS_SUMMARY_TYPES.includes(element.fieldType)
                            "
                        >
                            <wdx-info-card
                                *ngFor="let tag of data[element.name]"
                                class="d-inline-block me-1 mb-1"
                                [noBorder]="true"
                                [attr.data-cy]="'info-card-title-' + tag"
                            >
                                <div wdxInfoCardLabel>{{ tag }}</div>
                            </wdx-info-card>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                LOOKUP_SUMMARY_TYPES.includes(element.fieldType)
                            "
                        >
                            <div
                                *ngIf="
                                    data[element.name] | lookupAvatars;
                                    let avatars
                                "
                            >
                                <ng-container
                                    *ngIf="avatars?.length > 0; else noValue"
                                >
                                    <wdx-info-card
                                        *ngFor="let avatar of avatars"
                                        class="d-inline-flex me-1 mb-1"
                                        [attr.data-cy]="
                                            'info-card-title-' + avatar.text
                                        "
                                    >
                                        <div wdxInfoCardLeading>
                                            <molecule-avatar-group-container
                                                size="sm"
                                                [avatars]="[avatar]"
                                            ></molecule-avatar-group-container>
                                        </div>
                                        <div wdxInfoCardLabel>
                                            {{ avatar.text }}
                                        </div>
                                    </wdx-info-card>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                FILE_SUMMARY_TYPES.includes(element.fieldType)
                            "
                        >
                            <molecule-document-info
                                *ngFor="let file of data[element.name]"
                                class="d-block mb-1"
                                [fileIndex]="
                                    file?.fileIndex?.fileIndex
                                        ? file.fileIndex
                                        : file
                                "
                            >
                            </molecule-document-info>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                element.fieldType ===
                                FORM_FIELD_TYPE.DocumentLookup
                            "
                        >
                            <molecule-document-info
                                *ngFor="let document of data[element.name]"
                                class="d-block mb-1"
                                [label]="document.name"
                                [subtitle]="document.fileIndex | fileName"
                                [fileIndex]="document?.fileIndex"
                            >
                            </molecule-document-info>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                RICH_TEXT_SUMMARY_TYPES.includes(
                                    element.fieldType
                                )
                            "
                        >
                            <div
                                [innerHtml]="
                                    data[element.name]
                                        | formSummaryValue
                                            : element
                                            : definition?.schema
                                        | ngrxPush
                                        | markdownToHtml
                                "
                            ></div>
                        </ng-container>
                    </ng-container>
                </molecule-summary-field>
            </ng-template>

            <ng-template #noValue> &nbsp; </ng-template>
        </div>
    </ng-container>
</ng-template>

<ng-template #formVersionInfo>
    <div>
        You are viewing a <strong>{{ formDetails.status }}</strong> version of
        this form (by
        <button
            class="btn btn-link"
            (click)="
                openLinkInNewTab(
                    formDetails?.context?.lastUpdatedBy?.partyType,
                    formDetails?.context?.lastUpdatedBy?.id
                )
            "
        >
            {{ formDetails?.lastUpdatedBy || 'unknown user' }}</button
        >,

        {{ formDetails?.lastUpdatedOn | displayDate : { relative: true } }}) -

        <button class="btn btn-link" (click)="switchFormVersion()">
            Click here to view the
            {{
                formDetails.status === FORM_DATA_STATUS.Draft
                    ? 'previously saved'
                    : 'draft'
            }}
            version
        </button>
    </div>
</ng-template>
