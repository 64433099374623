import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { AtomAddButtonModule } from '../../../components/atoms/atom-add-button/atom-add-button.module';
import { AtomCloseModule } from '../../../components/atoms/atom-close/atom-close.module';
import { AtomInputNoneSelectedModule } from '../../../components/atoms/atom-input-none-selected/atom-input-none-selected.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { MoleculeInputWrapperModule } from '../../../components/molecules/molecule-input-wrapper/molecule-input-wrapper.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeInputTagsComponent } from './molecule-input-tags.component';

@NgModule({
    imports: [
        AtomAddButtonModule,
        AtomCloseModule,
        AtomInputNoneSelectedModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeInfoCardSearchModule,
        MoleculeInputWrapperModule,
        OrganismModalModule,
        PipesModule,
        WdxInfoCardModule,
    ],
    declarations: [MoleculeInputTagsComponent],
    exports: [MoleculeInputTagsComponent],
})
export class MoleculeInputTagsModule {}
