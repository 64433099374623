import { createAction, props } from '@ngrx/store';
import {
    List,
    ListMemberDetailed,
    LookupFieldResult,
    LookupSourceType,
    Party,
    PartyRole,
    SystemEntity,
} from '@wdx/clmi/api-models';

export const addList = createAction(
    '[Lists] Add List',
    props<{
        list: List;
    }>()
);
export const addListSuccess = createAction(
    '[Lists] Add List Success',
    props<{
        list: List;
    }>()
);
export const addListFailure = createAction(
    '[Lists] Add List Failure',
    props<{
        error: Error;
    }>()
);

export const addPartiesToList = createAction(
    '[Lists] Add Party To List',
    props<{
        list: List;
        parties: Party[];
        lookupSourceType?: LookupSourceType;
    }>()
);
export const addPartiesToListSuccess = createAction(
    '[Lists] Add Parties To List Success',
    props<{
        list: List;
        parties: Party[];
    }>()
);
export const addPartiesToListFailure = createAction(
    '[Lists] Add Parties To List Failure',
    props<{
        list: List;
        parties: Party[];
        error: Error;
    }>()
);

export const addLookupResultsToList = createAction(
    '[Lists] Add Lookup Results To List',
    props<{
        list: List;
        lookupResults: LookupFieldResult[];
    }>()
);
export const addLookupResultsToListSuccess = createAction(
    '[Lists] Add Lookup Results To List Success',
    props<{
        list: List;
        lookupResults: LookupFieldResult[];
    }>()
);
export const addLookupResultsToListFailure = createAction(
    '[Lists] Add Lookup Results To List Failure',
    props<{
        list: List;
        lookupResults: LookupFieldResult[];
        error: Error;
    }>()
);

export const deleteListForId = createAction(
    '[Lists] Delete List For Id',
    props<{
        listId: string;
    }>()
);
export const deleteListForIdSuccess = createAction(
    '[Lists] Delete List For Id Success',
    props<{
        listId: string;
    }>()
);
export const deleteListForIdFailure = createAction(
    '[Lists] Delete List For Id Failure',
    props<{
        error: Error;
    }>()
);

export const getLists = createAction('[Lists] Get Lists');
export const getListsSuccess = createAction(
    '[Lists] Get Lists Success',
    props<{
        lists: List[];
    }>()
);
export const getListsFailure = createAction(
    '[Lists] Get Lists Failure',
    props<{
        error: Error;
    }>()
);

export const getListForId = createAction(
    '[Lists] Get List For Id',
    props<{
        listId: string;
    }>()
);
export const getListForIdSuccess = createAction(
    '[Lists] Get List For Id Success',
    props<{
        list: List;
    }>()
);
export const getListForIdFailure = createAction(
    '[Lists] Get List For Id Failure',
    props<{
        error: Error;
    }>()
);

export const getMembersForId = createAction(
    '[Lists] Get Members For List Id',
    props<{
        listId: string;
    }>()
);
export const getMembersForIdSuccess = createAction(
    '[Lists] Get Members For Id Success',
    props<{
        listId: string;
        members: ListMemberDetailed[];
    }>()
);
export const getMembersForIdFailure = createAction(
    '[Lists] Get Members For Id Failure',
    props<{
        listId: string;
        error: Error;
    }>()
);

export const getPartiesForId = createAction(
    '[Lists] Get Parties For List Id',
    props<{
        listId: string;
    }>()
);
export const getPartiesForIdSuccess = createAction(
    '[Lists] Get Parties For Id Success',
    props<{
        listId: string;
        parties: Party[];
    }>()
);
export const getPartiesForIdFailure = createAction(
    '[Lists] Get Parties For Id Failure',
    props<{
        listId: string;
        error: Error;
    }>()
);

export const getPartyRolesForId = createAction(
    '[Lists] Get PartyRoles For List Id',
    props<{
        listId: string;
    }>()
);
export const getPartyRolesForIdSuccess = createAction(
    '[Lists] Get PartyRoles For Id Success',
    props<{
        listId: string;
        partyRoles: PartyRole[];
    }>()
);
export const getPartyRolesForIdFailure = createAction(
    '[Lists] Get PartyRoles For Id Failure',
    props<{
        listId: string;
        error: Error;
    }>()
);

export const deleteEntityFromList = createAction(
    '[Lists] Delete Entity from List Id',
    props<{
        listId: string;
        entityType: SystemEntity;
        entityId: string;
    }>()
);
export const deleteEntityFromListSuccess = createAction(
    '[Lists] Delete Entity from  Id Success',
    props<{
        listId: string;
        entityType: SystemEntity;
        entityId: string;
    }>()
);
export const deleteEntityFromListFailure = createAction(
    '[Lists] Delete Entity from Id Failure',
    props<{
        listId: string;
        entityType: SystemEntity;
        entityId: string;
        error: Error;
    }>()
);
