<div
    class="d-flex align-items-center"
    [attr.data-cy]="'subform-' + sectionLayoutDefinitionName"
>
    <ng-container *ngIf="!CUSTOM_SUMMARY_TYPES.includes(titleField?.fieldType)">
        <div
            *ngIf="
                titleValue &&
                    titleField?.selectSource === SELECT_API_SOURCE.Country;
                else defaultIcon
            "
            class="icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-md"
        >
            <span class="flag flag-{{ titleValue | lowercase }}"></span>
        </div>
    </ng-container>

    <ng-container *ngIf="LOOKUP_SUMMARY_TYPES.includes(titleField?.fieldType)">
        <ng-container *ngIf="titleValue | lookupAvatars; let avatars">
            <molecule-avatar-group-container
                *ngIf="avatars?.length > 0; else defaultIcon"
                [avatars]="avatars"
            >
            </molecule-avatar-group-container>
        </ng-container>
    </ng-container>

    <div class="d-block position-relative flex-grow-1 px-2">
        <h6 data-cy="subform-info-title" [innerHtml]="title$ | ngrxPush"></h6>
        <div class="d-flex align-items-center">
            <ng-container *ngIf="subtitles$ | ngrxPush; let subtitles">
                <div
                    *ngFor="let subtitle of subtitles; let last = last"
                    class="small divider--short"
                    [class.divider-end]="!last"
                >
                    {{ subtitle }}
                </div>
            </ng-container>
            <div *ngIf="isPrimaryField" class="ms-1">
                <wdx-badge class="small text-truncate" themeColor="primary">
                    Primary
                </wdx-badge>
            </div>
        </div>
    </div>

    <wdx-badge
        data-cy="subform-status-badge"
        *ngIf="status"
        themeColor="warning"
    >
        <wdx-icon [icon]="ICON_DANGER.icon"></wdx-icon>
        {{ status }}
    </wdx-badge>
</div>

<ng-template #defaultIcon>
    <wdx-icon
        class="bg-icon icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-md"
        icon="arrow-alt-right"
    >
    </wdx-icon>
</ng-template>
