import {
    ListType,
    LookupContextMode,
    LookupResultsMode,
    LookupSourceType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { LIST_FORM_ID } from '../../../../constants/dynamic-form.constants';
import { ICON_ADD } from '../../../../constants/icons.constants';
import {
    ASSIGN_TO_MENU_ITEM,
    ASSIGN_TO_ME_MENU_ITEM,
    SELECT_MENU_ITEM,
} from '../../../../constants/menu.constants';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { TableHeader } from '../../../../models/table-header.model';

export const LISTS_ROUTE_DATA = {
    viewType: SystemEntity.List,
};

export const LIST_DETAILS_ROUTE = 'details';
export const LIST_MEMBERS_ROUTE = 'members';
export const LIST_DASHBOARD_ROUTE = 'dashboard';
export const LIST_NETWORK_CHART_ROUTE = 'network';

export const LISTS_HEADERS: TableHeader[] = [
    {
        value: 'Name',
    },
    {
        value: 'Owner',
    },
    {
        value: 'Type',
    },
    {
        value: 'Member Count',
    },
    {
        value: 'Purpose',
    },
    {
        value: 'Status',
    },
    {
        value: null,
    },
];

export const CONTEXT_MENU_BUTTON: ActionButton = {
    mode: ActionButtonMode.DropdownButtonMenu,
    dropdownMenu: [
        SELECT_MENU_ITEM,
        ASSIGN_TO_ME_MENU_ITEM,
        ASSIGN_TO_MENU_ITEM,
    ],
};

export const SEARCH_BAR_ACTION_BUTTONS: ActionButton[] = [
    {
        mode: ActionButtonMode.IconButton,
        icon: ICON_ADD.icon,
        cySelector: 'btn-add',
        formSetup: {
            formId: LIST_FORM_ID,
        },
    },
];

export const LIST_TYPE_ICON_MAP: KeyValueObject<string> = {
    [ListType.Business]: 'building',
    [ListType.Family]: 'user-group',
    [ListType.Standard]: 'list',
};

export const LIST_TYPE_LOOKUP_SOURCE_MAP: KeyValueObject<LookupSourceType[]> = {
    [ListType.Business]: [LookupSourceType.EligibleClient],
    [ListType.Family]: [LookupSourceType.EligibleClient],
    [ListType.Standard]: [
        LookupSourceType.EligibleClient,
        LookupSourceType.Organisation,
        LookupSourceType.Person,
    ],
};

export const LIST_TYPE_LOOKUP_CONTEXT_MODE_MAP: KeyValueObject<LookupContextMode> =
    {
        [ListType.Business]: LookupContextMode.SearchIgnoresContext,
        [ListType.Family]: LookupContextMode.SearchPreservesContext,
        [ListType.Standard]: LookupContextMode.SearchIgnoresContext,
    };

export const LIST_ADD_MEMBER_FORM_INPUT_DATA = (
    id: string,
    label: string,
    listType: ListType
) => ({
    label,
    id,
    lookupSources: LIST_TYPE_LOOKUP_SOURCE_MAP[listType].map(
        (lookupSource) => ({
            type: lookupSource,
        })
    ),
    lookupContextMode: LIST_TYPE_LOOKUP_CONTEXT_MODE_MAP[listType],
    lookupResultsMode: LookupResultsMode.Immediate,
});

export const LIST_ADD_MEMBER_CONTEXTUAL_RESULTS = (listId: string) => [
    {
        id: listId,
        lookupType: SystemEntity.List,
    },
];

export const LISTS_FOR_PARTY_FORM_INPUT_DATA = (label: string, id: string) => ({
    label,
    id,
    lookupSources: [{ type: LookupSourceType.List }],
    lookupContextMode: LookupContextMode.SearchPreservesContext,
    lookupResultsMode: LookupResultsMode.Immediate,
});

export const LISTS_FOR_PARTY_CONTEXTUAL_RESULTS = (
    partyId: string,
    lookupType: LookupSourceType = LookupSourceType.Party
) => [
    {
        id: partyId,
        lookupType,
    },
];
