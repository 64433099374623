import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
    FormDefinition,
    FormLayout,
    FormMessageDefinition,
} from '@wdx/clmi/api-models';
import { Subject } from 'rxjs';
import { FormInitialisationMode } from '../../../../models/form-setup.model';
import {
    IFormDataResult,
    IFormPendingChange,
} from '../../constants/control-types';

@Injectable()
export class FormStaticService implements OnDestroy {
    formDefinition: FormDefinition;
    childFormDefinitions: Record<string, FormDefinition>;
    formLayout: FormLayout;
    initialisationMode: FormInitialisationMode;
    form: UntypedFormGroup;
    formId: string;
    formData: IFormDataResult;
    destroyed$ = new Subject<boolean>();
    submitAttempted$ = new Subject<boolean>();
    fieldsToNullCheck: string[] = [];
    messageDefinitionToShow: { [key: string]: FormMessageDefinition } = {};
    messageDefinitionToObject: { [key: string]: FormMessageDefinition } = {};
    pendingChangesSubForm: {
        [key: string]: {
            [key: string]: {
                [key: string]: IFormPendingChange;
            };
        };
    } = {};

    /**
     * Parses form data and flattens formgroups with empty data to null (if required)
     * - eg. if a FormGroup has a value of {a: null, b:null} but backend expects a null value returned
     * - if no parsed fields are defined, then simply return the raw form value
     */
    get formValue() {
        if (this.fieldsToNullCheck.length) {
            return Object.entries(this.form.getRawValue()).reduce(
                (previous, [key, value]) => {
                    const parsedValue =
                        value &&
                        this.fieldsToNullCheck.includes(key) &&
                        typeof value === 'object' &&
                        Object.values(value).every((val) => {
                            // check for null, undefined or empty string
                            return (
                                val === undefined ||
                                val === null ||
                                val?.length === 0
                            );
                        })
                            ? null
                            : value;
                    return { ...previous, [key]: parsedValue };
                },
                {}
            );
        }
        return this.form.getRawValue();
    }

    /**
     * Add a field name to list of fields to null check
     */
    addFieldsToNullCheck(fieldName: string) {
        if (!this.fieldsToNullCheck.includes(fieldName)) {
            this.fieldsToNullCheck.push(fieldName);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
