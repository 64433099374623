import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateEntityPipe } from './translate-entity/translate-entity.pipe';
import { TranslatePipe } from './translate/translate.pipe';

@NgModule({
    declarations: [TranslatePipe, TranslateEntityPipe],
    imports: [CommonModule],
    exports: [TranslatePipe, TranslateEntityPipe],
})
export class TranslationPipesModule {}
