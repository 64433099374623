<ng-container *ngIf="moleculeActionButtonService.actions$ | ngrxPush as info">
    <organism-modal
        [modalTitle]="info.action.displayName.value"
        [hideClose]="false"
    >
        <section
            class="form-action-buttons px-4 py-2 active-component d-flex align-items-center justify-content-end"
        >
            <button
                wdxButton
                class="me-2"
                type="button"
                (click)="modalManagementService.closeActiveModal()"
            >
                Cancel
            </button>

            <button
                type="button"
                [wdxButton]="WDX_BUTTON_STYLE.Primary"
                [wdxIsLoading]="moleculeActionButtonService.isLoading"
                [disabled]="!selectedItem || null"
                (click)="optionSelected()"
            >
                Submit
            </button>
        </section>

        <section class="list-group list-group-flush">
            <label
                class="list-group-item list-group-item-action"
                [class.active]="option.code === selectedItem?.code"
                [attr.for]="option.code"
                *ngFor="let option of info.action.options"
            >
                <input
                    class="form-check-input"
                    type="radio"
                    name="action"
                    value="option.code"
                    [attr.id]="option.code"
                    (click)="updateSelectedItem(option)"
                />

                {{ option.displayName.value }}
            </label>
        </section>
    </organism-modal>
</ng-container>
