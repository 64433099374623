<div
    *ngIf="caseCardItem"
    class="card h-100"
    [attr.data-cy]="'carditem-' + caseCardItem?.id"
    [ngClass]="{
        'flex-row border-0': headerMode,
        'card-clickable': caseCardItem?.routerLink
    }"
    [routerLink]="caseCardItem?.routerLink"
>
    <div
        class="card-body d-flex min-w-0"
        [attr.data-cy]="'carditem-action-case-' + caseCardItem?.regarding"
        [ngClass]="{
            'flex-row me-2 p-0': headerMode,
            'flex-column': !headerMode
        }"
    >
        <div class="d-flex flex-column flex-grow-1 text-truncate">
            <div class="d-flex flex-grow-1">
                <div class="flex-grow-1 text-truncate">
                    <div
                        *ngIf="headerMode; else cardHeader"
                        class="d-flex align-items-center fw-bold flex-row"
                    >
                        <molecule-avatar-group
                            *ngIf="caseCardItem?.avatars"
                            class="me-1"
                            size="sm"
                            [avatars]="caseCardItem?.avatars"
                        ></molecule-avatar-group>
                        <div
                            class="d-flex flex-column align-items-xl-center flex-xl-row text-truncate"
                        >
                            <div class="divider-xl-end divider--short">
                                <a
                                    [routerLink]="caseCardItem?.routerLink"
                                    [attr.data-cy]="
                                        'carditem-action-' + caseCardItem?.id
                                    "
                                >
                                    {{ caseCardItem?.subject }}
                                </a>
                            </div>
                            <div
                                class="d-flex align-items-center text-truncate"
                            >
                                <div
                                    *ngIf="caseCardItem?.regarding"
                                    class="text-truncate"
                                    [class.divider-end]="
                                        caseCardItem?.internalIdentifier
                                    "
                                >
                                    <a
                                        [routerLink]="
                                            caseCardItem?.regardingRouterLink
                                        "
                                        [attr.data-cy]="
                                            'carditem-action-' +
                                            caseCardItem?.regarding
                                        "
                                        [title]="caseCardItem?.regarding"
                                    >
                                        {{ caseCardItem?.regarding }}
                                    </a>
                                </div>
                                <div
                                    class="divider--short"
                                    [class.divider-start]="
                                        caseCardItem?.internalIdentifier
                                    "
                                >
                                    {{ caseCardItem?.internalIdentifier }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #cardHeader>
                        <div class="d-flex align-items-center">
                            <div
                                class="small divider--short"
                                [class.divider-end]="caseCardItem?.dateStarted"
                            >
                                {{ caseCardItem?.internalIdentifier }}
                            </div>
                            <div class="small text-muted">
                                Created
                                {{
                                    caseCardItem?.dateStarted
                                        | displayDate
                                            : { format: 'dd MMM yyyy' }
                                }}
                            </div>
                        </div>
                        <div class="fw-bold text-truncate">
                            <a
                                [routerLink]="caseCardItem?.routerLink"
                                [attr.data-cy]="
                                    'carditem-action-' + caseCardItem?.id
                                "
                            >
                                {{ caseCardItem?.subject }}
                            </a>
                        </div>
                        <div class="small text-truncate">
                            <a
                                *ngIf="caseCardItem?.regarding"
                                [title]="caseCardItem?.regarding"
                                [routerLink]="caseCardItem?.regardingRouterLink"
                                [attr.data-cy]="
                                    'carditem-action-' + caseCardItem?.regarding
                                "
                                [innerHtml]="caseCardItem?.regarding"
                            >
                            </a>
                        </div>
                    </ng-template>
                </div>
                <ng-container *ngIf="!headerMode">
                    <ng-container
                        *ngIf="
                            caseCardItem?.isSuggestedCardList &&
                                caseCardItem?.linkToEntityId;
                            else contextMenu
                        "
                    >
                        <molecule-action-button
                            class="ms-1"
                            [actionButton]="linkToActionButton"
                            (onClick)="onLinkToActionButtonClicked()"
                        ></molecule-action-button>
                    </ng-container>
                    <ng-template #contextMenu>
                        <molecule-action-button
                            class="ms-1"
                            [actionButton]="contextMenuActionButton"
                            (selectMenuItem)="onContextMenuSelected($event)"
                        ></molecule-action-button>
                    </ng-template>
                </ng-container>
            </div>

            <div *ngIf="caseCardItem?.totalActivities > 0" class="mt-2">
                <ngb-progressbar
                    [type]="caseCardItem?.severity"
                    [textType]="caseCardItem?.severity"
                    [value]="
                        (caseCardItem?.completion.completed /
                            caseCardItem?.completion.total) *
                        100
                    "
                ></ngb-progressbar>
                <div *ngIf="!headerMode && caseCardItem?.nextAction">
                    <div class="d-flex align-items-center flex-shrink-0 mt-2">
                        <wdx-icon
                            class="me-1"
                            [scale]="1"
                            [ngClass]="{
                                'text-danger':
                                    caseCardItem?.nextAction?.issueCount,
                                'text-success':
                                    !caseCardItem?.nextAction?.issueCount
                            }"
                            icon="flag"
                            iconStyle="fas"
                        ></wdx-icon>
                        <div class="small">Next Action</div>
                        <div class="small divider-start divider--short">
                            {{ caseCardItem?.nextAction?.subject }}
                        </div>
                        <div
                            *ngIf="caseCardItem?.nextAction?.issueCount"
                            class="d-flex align-items-center"
                        >
                            <wdx-icon
                                class="text-danger ms-1"
                                [scale]="1"
                                icon="comment"
                                iconStyle="fas"
                            ></wdx-icon>
                            <div class="small ms-1">
                                {{ caseCardItem?.nextAction?.issueCount }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-1">
                    <div
                        class="d-flex align-items-center"
                        [ngClass]="
                            caseCardItem | fn : getStatusClass : headerMode
                        "
                    >
                        <wdx-icon
                            class="justify-content-center align-items-center rounded-circle icon-container-size-xs"
                            [icon]="
                                CASE_COMPLETED_STATUS_ICON_MAP[
                                    caseCardItem?.completedStatus
                                ]
                            "
                        >
                        </wdx-icon>
                        <span class="small d-flex align-items-center">
                            {{ caseCardItem | fn : getStatusText }}
                            <span
                                *ngIf="caseCardItem | fn : hasStatusDate"
                                class="divider-start divider--short"
                            >
                                {{ caseCardItem | fn : getStatusDate }}
                            </span>
                        </span>
                    </div>
                    <div class="flex-shrink-0 small">
                        {{ caseCardItem?.completion.completed }}/{{
                            caseCardItem?.completion.total
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!headerMode">
        <div class="card-footer d-flex">
            <div class="flex-grow-1">
                <div class="d-flex align-items-center">
                    <molecule-avatar-group
                        *ngIf="caseCardItem?.avatars"
                        size="sm"
                        [avatars]="caseCardItem?.avatars"
                    ></molecule-avatar-group>
                </div>
            </div>
            <div class="d-flex flex-row-reverse">
                <wdx-toggle-button
                    size="sm"
                    [toggle]="caseCardItem?.isFavourite"
                    (wdxOnChange)="onToggleFavourite($event)"
                    (click)="$event.stopPropagation()"
                ></wdx-toggle-button>
            </div>
        </div>
    </ng-container>
    <div
        *ngIf="
            !headerMode &&
            (caseCardItem?.status === CASE_STATUS_TYPE.Complete ||
                caseCardItem?.status === CASE_STATUS_TYPE.Cancelled)
        "
        class="d-flex align-items-center justify-content-between custom-card__footer"
    >
        <div class="d-flex align-items-center flex-grow-1 p-1 small">
            <ng-container
                *ngIf="
                    caseCardItem?.completedStatus ===
                        COMPLETED_STATUS.Cancelled;
                    else statusCompleted
                "
            >
                All actions are cancelled
            </ng-container>
            <ng-template #statusCompleted>
                All actions are completed
            </ng-template>
        </div>
    </div>
</div>

<ng-container #assignLookupContainer></ng-container>
