import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AppStatus,
    EntityPermissionType,
    HeatMapEntry,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OperationsSetup } from '../../../models/operations.model';
import * as operationsActions from '../../../state/operations/operations.actions';
import * as operationsSelectors from '../../../state/operations/operations.selectors';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    entityIdsCache: string[] = [];

    constructor(private store$: Store) {}

    /**
     * Determines 'Edit' permissions of a user for a given entity record
     * @param entityType
     * @param entityId
     */
    canEdit$(entityType: SystemEntity, entityId: string) {
        if (!entityType || !entityId) {
            return of(false);
        }
        return this.hasPermission$(
            entityType,
            entityId,
            EntityPermissionType.Edit
        );
    }

    /**
     * Determines if user has 'Edit' permission to a given app.
     * @param app
     * @returns
     */
    canEditApp$(app: AppStatus | HeatMapEntry) {
        if (app.case) {
            return this.canEdit$(SystemEntity.Case, app.case?.id);
        }
        if (app.party) {
            return this.canEdit$(SystemEntity.Party, app.party?.id);
        }
        if (app.partyRole) {
            return this.canEdit$(SystemEntity.PartyRole, app.partyRole?.id);
        }
        if (app.client) {
            return this.canEdit$(SystemEntity.PartyRole, app.client?.id);
        }
        return of(false);
    }

    getPermissions$({ entityType, entityId }: OperationsSetup) {
        return this.store$
            .select(operationsSelectors.getPermissionsForId, {
                entityType,
                entityId,
            })
            .pipe(
                tap(() => {
                    const cacheKey = entityType + '-' + entityId;
                    if (!this.entityIdsCache.includes(cacheKey)) {
                        this.entityIdsCache.push(cacheKey);
                        this.store$.dispatch(
                            operationsActions.getOperationsForId({
                                entityType,
                                entityId,
                            })
                        );
                    }
                })
            );
    }

    private hasPermission$(
        entityType: SystemEntity,
        entityId: string,
        permission: EntityPermissionType
    ) {
        return this.getPermissions$({ entityType, entityId }).pipe(
            filter((permissions) => Boolean(permissions)),
            map((permissions) => permissions.includes(permission))
        );
    }
}
