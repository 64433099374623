import { Action, createReducer, on } from '@ngrx/store';
import { MenuItem } from '../../models/menu-item.model';
import { TagsAdminCategory } from '../../models/tags.model';

import { SimpleTag, Tag, TagCat } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as tagsActions from './tags.actions';

export interface State {
    tags: CrudStateObject<Tag | SimpleTag>;
    categories: CrudStateObject<TagCat>;
    categoriesMenu: CrudState<MenuItem>;
    all: CrudState<TagCat>;
    admin: CrudState<TagsAdminCategory>;
}

export const initialState: State = {
    tags: {},
    categories: {},
    categoriesMenu: {},
    all: {},
    admin: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        tagsActions.getAllTags,
        (state): State => ({
            ...state,
            all: {
                isLoadingList: true,
            },
        })
    ),

    on(tagsActions.getAllTagsSuccess, (state, props): State => {
        return {
            ...state,
            all: {
                isLoadingList: false,
                list: props.tagCategories,
            },
            categoriesMenu: {
                list: props.tagCategories.map((tagCat) => ({
                    label: tagCat.name,
                    value: tagCat.id,
                })),
            },
            admin: {
                list: getAdmin(getFilteredTags(props.tagCategories, null)),
            },
        };
    }),

    on(tagsActions.filterAdmin, (state, props): State => {
        return {
            ...state,
            admin: {
                list: getAdmin(getFilteredTags(state.all.list, props)),
            },
        };
    }),

    on(tagsActions.addTag, (state, props): State => {
        return {
            ...state,
            all: {
                list: state.all.list.map((tagCat) => {
                    if (tagCat.id === props.categoryId) {
                        return {
                            ...tagCat,
                            tags: [...(tagCat.tags || []), props.tag].sort(
                                (a, b) => a.name.localeCompare(b.name)
                            ),
                        };
                    }
                    return tagCat;
                }),
            },
        };
    }),

    on(tagsActions.updateTag, (state, props): State => {
        return {
            ...state,
            all: {
                list: state.all.list.map((tagCat) => {
                    if (tagCat.id === props.categoryId) {
                        return {
                            ...tagCat,
                            tags: tagCat.tags
                                .map((tag) => {
                                    if (tag.id === props.tagId) {
                                        return {
                                            ...tag,
                                            name: props.name,
                                        };
                                    }
                                    return tag;
                                })
                                .sort((a, b) => a.name.localeCompare(b.name)),
                        };
                    }
                    return tagCat;
                }),
            },
        };
    }),

    on(tagsActions.disableTagSuccess, (state, props): State => {
        return {
            ...state,
            all: {
                list: state.all.list.map((tagCat) => {
                    return {
                        ...tagCat,
                        tags: tagCat.tags?.map((tag) => {
                            if (tag.id === props.tagId) {
                                return {
                                    ...tag,
                                    isDisabled: props.isDisabled,
                                };
                            }
                            return tag;
                        }),
                    };
                }),
            },
        };
    }),

    on(
        tagsActions.getForRecord,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.recordId]: {
                    ...(state.tags[props.recordId] || ({} as CrudState<Tag>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        tagsActions.getForRecordSuccess,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.recordId]: {
                    ...state.tags[props.recordId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.tags,
                },
            },
        })
    ),

    on(
        tagsActions.getForRecordFailure,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.recordId]: {
                    ...state.tags[props.recordId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        tagsActions.getCategoriesForSystemEntity,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.systemEntity]: {
                    ...(state.categories[props.systemEntity] ||
                        ({} as CrudState<TagCat>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        tagsActions.getCategoriesForSystemEntitySuccess,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.systemEntity]: {
                    ...state.categories[props.systemEntity],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.categories,
                },
            },
        })
    ),

    on(
        tagsActions.getCategoriesForSystemEntityFailure,
        (state, props): State => ({
            ...state,
            categories: {
                ...state.categories,
                [props.systemEntity]: {
                    ...state.categories[props.systemEntity],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategory,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.category.id]: {
                    ...(state.tags[props.category.id] ||
                        ({} as CrudState<Tag>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategorySuccess,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.category.id]: {
                    ...state.tags[props.category.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.tags,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategoryFailure,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [props.category.id]: {
                    ...state.tags[props.category.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        tagsActions.createForRecord,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...(state.tags[`${props.entity}-${props.recordId}`] ||
                        ({} as CrudState<Tag>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(
        tagsActions.createForRecordSuccess,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...state.tags[`${props.entity}-${props.recordId}`],
                    isCreating: false,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(
        tagsActions.createForRecordFailure,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...state.tags[`${props.entity}-${props.recordId}`],
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        })
    ),

    on(
        tagsActions.deleteForRecord,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...(state.tags[`${props.entity}-${props.recordId}`] ||
                        ({} as CrudState<Tag>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        tagsActions.deleteForRecordSuccess,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...state.tags[`${props.entity}-${props.recordId}`],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        tagsActions.deleteForRecordFailure,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.recordId}`]: {
                    ...state.tags[`${props.entity}-${props.recordId}`],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategoryAndRecord,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.entityId}-${props.categoryId}`]: {
                    ...(state.tags[
                        `${props.entity}-${props.entityId}-${props.categoryId}`
                    ] || ({} as CrudState<SimpleTag>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategoryAndRecordSuccess,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.entityId}-${props.categoryId}`]: {
                    ...state.tags[
                        `${props.entity}-${props.entityId}-${props.categoryId}`
                    ],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.tags,
                },
            },
        })
    ),

    on(
        tagsActions.getForCategoryAndRecordFailure,
        (state, props): State => ({
            ...state,
            tags: {
                ...state.tags,
                [`${props.entity}-${props.entityId}-${props.categoryId}`]: {
                    ...state.tags[
                        `${props.entity}-${props.entityId}-${props.categoryId}`
                    ],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

function getFilteredTags(
    categories: TagCat[],
    props: {
        categoryId: string;
        searchText?: string;
    }
): TagCat[] {
    const filteredCategories = props?.categoryId
        ? categories.filter((tagCat) => tagCat.id === props?.categoryId)
        : categories;
    const filteredTags =
        props?.searchText?.length > 1
            ? filteredCategories
                  .map((tagCat) => ({
                      ...tagCat,
                      tags: tagCat.tags
                          ?.filter(
                              (tag) =>
                                  tag.name
                                      ?.toLowerCase()
                                      .indexOf(
                                          props?.searchText?.toLowerCase()
                                      ) > -1
                          )
                          .slice(0, 10),
                  }))
                  .filter((tagCat) => tagCat.tags?.length > 0)
            : filteredCategories;
    return filteredTags;
}

function getAdmin(categories: TagCat[]): TagsAdminCategory[] {
    return categories
        ?.map((tagCat) => {
            return {
                name: tagCat.name,
                id: tagCat.id,
                icon: tagCat.icon,
                list: tagCat.tags
                    ? Object.values(
                          tagCat.tags?.reduce((r, tagName) => {
                              const alpha = tagName.name[0].toUpperCase();
                              if (!r[alpha]) {
                                  r[alpha] = {
                                      alpha,
                                      tags: [tagName],
                                  };
                              } else {
                                  r[alpha].tags.push(tagName);
                              }
                              return r;
                          }, {})
                      ).map((item) => ({
                          ...item,
                          count: item.tags.length,
                      }))
                    : [],
            };
        })
        .map((tagsIndexCategory) => ({
            ...tagsIndexCategory,
            count: tagsIndexCategory.list.reduce(
                (previousValue, currentValue) =>
                    previousValue + currentValue.tags.length,
                0
            ),
        }));
}

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
