import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { BaseReactiveFormAdapterClass } from '../../../../classes/base-reactive-form-adapter.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-reactive-input-lookup',
    templateUrl: './molecule-reactive-input-lookup.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MoleculeReactiveInputLookupComponent),
            multi: true,
        },
    ],
})
export class MoleculeReactiveInputLookupComponent extends BaseReactiveFormAdapterClass {
    @Input() isMulti: boolean;
    @Input() formData: UntypedFormGroup;
    @Input() formContext: any;
}
