<div class="px-4 z-3" data-cy="wdx-dialog-container">
    <div class="d-flex align-items-center">
        <h5
            class="flex-grow-1 text-center mt-5 mb-4"
            data-cy="wdx-dialog-title"
            [textContent]="title"
        ></h5>

        <wdx-icon-button
            class="flex-shrink-0 align-self-start align-self-center"
            (wdxOnClick)="wdxDialogService.dismiss()"
        >
            <wdx-icon icon="times"></wdx-icon>
        </wdx-icon-button>
    </div>

    <div
        class="d-flex align-items-center justify-content-center pb-3 mb-3 border-bottom"
        [class.pt-5]="!defaultButtons"
    >
        <div class="dialog-body m-3">
            <ng-content></ng-content>
        </div>
    </div>

    <div
        *ngIf="defaultButtons"
        class="dialog-footer d-flex align-items-center justify-content-center mt-4 mb-5"
    >
        <button class="me-2" wdxButton (click)="wdxDialogService.dismiss()">
            No
        </button>

        <button [wdxButton]="actionButtonStyle" (click)="actionClick()">
            Yes
        </button>
    </div>
</div>
