import { IFormPendingChangeSubForm } from '@wdx/shared/infrastructure/form-framework';
import {
    ESubFormPendingChange,
    PENDING_CHANGE_TEXT,
} from './form-pending-changes.interface';

export const GET_STATUS_PENDING_CHANGES = {
    [ESubFormPendingChange.Adding]: `Add ${PENDING_CHANGE_TEXT}`,
    [ESubFormPendingChange.Deleting]: `Delete ${PENDING_CHANGE_TEXT}`,
    [ESubFormPendingChange.Updating]: `Update ${PENDING_CHANGE_TEXT}`,
};

export const PENDING_CHANGE_REG = new RegExp(/\[[0-9]+\]/);

export const PENDING_CHANGE_SPLIT = (field: string): string[] => {
    return field.replace('[', '.').replace(']', '').split('.');
};

export const UPDATE_PENDING_CHANGE_COUNTER = (
    pendingChangesSubFormCounter: IFormPendingChangeSubForm,
    name: string,
    type: ESubFormPendingChange,
    label?: string
): IFormPendingChangeSubForm => {
    const PENDING_CHANGE_SUB_FORM = { ...pendingChangesSubFormCounter };

    if (!PENDING_CHANGE_SUB_FORM[name]) {
        PENDING_CHANGE_SUB_FORM[name] = {
            updating: 0,
            adding: 0,
            deleting: 0,
            label: '',
        };
    }

    const DATA = PENDING_CHANGE_SUB_FORM[name];

    if (PENDING_CHANGE_SUB_FORM[name] && label) {
        PENDING_CHANGE_SUB_FORM[name]['label'] = label;
    }

    if (ESubFormPendingChange.Updating === type) {
        const VAL = PENDING_CHANGE_SUB_FORM[name]['updating'];
        PENDING_CHANGE_SUB_FORM[name]['updating'] = VAL + 1;
    }

    if (ESubFormPendingChange.Adding === type) {
        const VAL = DATA['adding'];
        PENDING_CHANGE_SUB_FORM[name]['adding'] = VAL + 1;
    }

    if (ESubFormPendingChange.Deleting === type) {
        const VAL = PENDING_CHANGE_SUB_FORM[name]['deleting'];
        PENDING_CHANGE_SUB_FORM[name]['deleting'] = VAL + 1;
    }

    return PENDING_CHANGE_SUB_FORM;
};
