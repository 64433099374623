export const SIGNAL_R_HUB_NAME = 'datachange';

export enum SignalrEvent {
    CaseReady = 'CaseReady',
    CaseUpdated = 'CaseUpdated',
    DocumentGenerated = 'DocumentGenerated',
    ExportGenerated = 'ExportGenerated',
    NotificationsUpdate = 'NotificationsUpdate',
    PartyUpdated = 'PartyUpdated',
    PartyCasesUpdated = 'PartyCasesUpdated',
    PartyRoleUpdated = 'PartyRoleUpdated',
    PartyRoleCasesUpdated = 'PartyRoleCasesUpdated',
    UIReload = 'UIReload',
    VisitorEngaged = 'VisitorEngaged',
    ImportCompleted = 'ImportCompleted',
    ActivityCommentAdded = 'ActivityCommentAdded',
}
