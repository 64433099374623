<organism-reactive-form-dumb
    *ngIf="!isLoadingData"
    [formId]="formId"
    [attr.data-cy]="formId"
    [entityId]="entityId"
    [appId]="appId"
    [formDefinition]="formDefinition"
    [formLayout]="formLayout"
    [formData]="formData"
    [isQuickCreate]="isQuickCreate"
    [modalInstanceId]="modalInstanceId"
    [isLoading]="isLoadingData"
    [hasError]="
        (reactiveFormPresentationService.formDefinitionHasError$ | ngrxPush) ||
        (formDataHasError$ | ngrxPush)
    "
    [isSaving]="formDataIsSaving$ | ngrxPush"
    (formTitleReceived)="onFormTitleReceived($event)"
    (cancelClicked)="onCancelClick()"
    (saveClicked)="onSaveClick($event)"
    (saveDraftClicked)="onSaveDraftClicked($event)"
    (saveDraftClicked)="onSaveDraftClicked($event)"
    (completionSummaryChanged)="onCompletionSummaryChanged($event)"
></organism-reactive-form-dumb>
