import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-create-case-modal',
    templateUrl: './organism-create-case-modal.component.html',
})
export class OrganismCreateCaseModalComponent {
    @Input() opportunityId: string;
    @Input() partyId: string;
}
