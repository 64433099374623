import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputTagsModule } from '../../../../components/molecules/molecule-input-tags/molecule-input-tags.module';
import { MoleculeReactiveInputTagsComponent } from './molecule-reactive-input-tags.component';

@NgModule({
    imports: [CommonModule, MoleculeInputTagsModule],
    declarations: [MoleculeReactiveInputTagsComponent],
    exports: [MoleculeReactiveInputTagsComponent],
})
export class MoleculeReactiveInputTagsModule {}
