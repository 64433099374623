import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSearchResult, SystemEntity } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { NOTE_FORM_ID } from '../../../constants/dynamic-form.constants';
import { EntityRoutePipe } from '../../../pipes/entity-route.pipe';
import { DynamicFormsManagementService } from '../../../services/dynamic-forms-management.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchProcessService {
    constructor(
        private dynamicFormsManService: DynamicFormsManagementService,
        private router: Router
    ) {}

    routeUseToEntity(globalSearchResult: GlobalSearchResult): void {
        const ROUTE = new EntityRoutePipe().transform(
            globalSearchResult.redirectEntityType,
            globalSearchResult.redirectEntityId
        );

        if (globalSearchResult.entityType !== SystemEntity.Note) {
            this.router.navigate(ROUTE, {
                queryParams: this.getGlobalSearchQueryParams(
                    globalSearchResult.entityType,
                    globalSearchResult.title
                ),
            });
        }

        if (globalSearchResult.entityType === SystemEntity.Note) {
            this.onOpenDynamicForm({
                formId: NOTE_FORM_ID,
                entityId: globalSearchResult.entityId,
            });
        }
    }

    private getGlobalSearchQueryParams(
        entityType: SystemEntity,
        value: string
    ): KeyValueObject {
        const activityTypes = ['Activity', 'Email', 'Appointment', 'PhoneCall'];

        return (
            activityTypes.includes(entityType) && {
                globalSearch: value,
            }
        );
    }

    private onOpenDynamicForm(formSettings: {
        formId: string;
        entityId: string;
    }): void {
        this.dynamicFormsManService.openDynamicFormModal({
            formId: formSettings.formId,
            entityId: formSettings.entityId,
        });
    }
}
