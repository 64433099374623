import { Component, Input, OnInit } from '@angular/core';
import { FormDataResult } from '@wdx/clmi/api-models';
import { FormLockService } from '../../../../../features/reactive-forms/services/form-lock/form-lock.service';
import { IconStyle } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-form-lock-summary',
    templateUrl: './molecule-form-lock-summary.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block w-100',
    },
})
export class MoleculeFormLockSummaryComponent implements OnInit {
    @Input() formData: FormDataResult;

    shouldOverride: boolean;

    readonly ICON_STYLE = IconStyle;

    constructor(private formLockService: FormLockService) {}

    ngOnInit(): void {
        this.shouldOverride = this.formLockService.shouldOverrideFormLock(
            this.formData.lock
        );
    }
}
