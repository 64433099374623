import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { TranslationPipesModule } from '@wdx/system';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomHighlightTextModule } from '../../atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeLifecycleStageTagComponent } from './molecule-lifecycle-stage-tag.component';

@NgModule({
    imports: [
        AtomHighlightTextModule,
        CommonModule,
        PipesModule,
        TranslationPipesModule,
        WdxIconModule,
    ],
    declarations: [MoleculeLifecycleStageTagComponent],
    exports: [MoleculeLifecycleStageTagComponent],
})
export class MoleculeLifecycleStageTagModule {}
