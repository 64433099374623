import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SortDirection } from '@wdx/clmi/api-models';
import { FilterHandler } from '../../../classes/filter-handler.class';
import {
    ICON_SORT_ASC,
    ICON_SORT_DESC,
} from '../../../constants/icons.constants';
import { MenuItem } from '../../../models/menu-item.model';
import { SortMenuValue } from '../../../models/sort-menu.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-sort-menu',
    templateUrl: './molecule-sort-menu.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex align-items-center' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoleculeSortMenuComponent {
    @Input() sortMenu: MenuItem<SortMenuValue>[];
    @Input() filterHandler: FilterHandler;

    readonly ICON_SORT_ASC = ICON_SORT_ASC.icon;
    readonly ICON_SORT_DESC = ICON_SORT_DESC.icon;

    get isAscending() {
        return (
            this.filterHandler.getSortDirection() === SortDirection.Ascending
        );
    }

    toggle(): void {
        this.filterHandler.toggleSortDirection();
    }

    select(menuItem: MenuItem<SortMenuValue>): void {
        if (!menuItem.isInitialValue) {
            this.filterHandler.setSortDirection(
                menuItem.value.sortDirection || SortDirection.Ascending,
                false
            );
            this.filterHandler.setSortBy(menuItem.value.sortBy);
        }
    }
}
