import { Injectable } from '@angular/core';
import { FieldDefinition } from '@wdx/clmi/api-models';
import { ContextualData } from '@wdx/clmi/api-services/models';

@Injectable({
    providedIn: 'root',
})
export class FormContextualDataService {
    /**
     * Returns a contextual model data to be consumed in form component
     * @param contextualValues {string[]} - an array of 'contextual' values provided by the form schema
     * @param containerFormData - the form data provided from the local formGroup
     * @param rootFormData - the data provided from the root form (required for subform items)
     * @param formSchema {FieldDefinition[]} - the schema for the current form
     */
    getContextualData(
        contextualValues: string[],
        containerFormData?: any,
        rootFormData?: any,
        formSchema?: FieldDefinition[]
    ): ContextualData {
        const DATA: ContextualData = {
            controls: [],
            strings: [],
            lookups: [],
            context: [],
        };

        if (!contextualValues || !contextualValues.length) {
            return DATA;
        }

        contextualValues?.forEach((contextualValue) => {
            const USE_FORM_DATA = this.useFormData(contextualValue);

            if (USE_FORM_DATA) {
                const formData = this.useRootFormData(contextualValue)
                    ? rootFormData
                    : containerFormData;

                if (formData) {
                    const PROP = this.getPropName(contextualValue);
                    // eslint-disable-next-line no-prototype-builtins
                    const hasProp = formData?.hasOwnProperty(PROP);

                    const PROP_DATA = formData[PROP];

                    if (hasProp && PROP_DATA && Array.isArray(PROP_DATA)) {
                        PROP_DATA.forEach((data) => {
                            DATA.controls.push(data);
                        });
                    }

                    if (hasProp && PROP_DATA && !Array.isArray(PROP_DATA)) {
                        if (typeof PROP_DATA === 'string') {
                            const type = formSchema?.find(
                                (schema) => schema.name === PROP
                            )?.selectType;
                            if (type) {
                                DATA.context.push({ type, value: PROP_DATA });
                            } else {
                                DATA.controls.push(PROP_DATA);
                            }
                        } else {
                            const updatedData = { ...PROP_DATA };
                            // eslint-disable-next-line no-prototype-builtins
                            if (updatedData?.hasOwnProperty('name')) {
                                delete updatedData.name;
                            }
                            DATA.controls.push(updatedData);
                        }
                    }

                    if (formData && !hasProp) {
                        console.error(`Form missing the property ${PROP}`);
                    }
                }
            }

            if (!USE_FORM_DATA) {
                DATA.strings.push(contextualValue);
            }
        });

        DATA.lookups = DATA.controls.filter(
            (control) => typeof control === 'object'
        );

        return DATA;
    }

    /**
     * Flattens all the combined values from strings and control values into a single array
     */
    getContextualResults(contextual: ContextualData) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        return [...contextual?.controls, ...contextual?.strings].filter((res) =>
            Boolean(res)
        );
    }

    /**
     * Return true if contextual value refers to form data field
     * @param contextualValue
     */
    useFormData(contextualValue: string) {
        return contextualValue.startsWith('@');
    }

    /**
     * Return true if contextual value should use data from the root form
     */
    useRootFormData(contextualValue: string) {
        return contextualValue.startsWith('@root.');
    }

    /**
     * Return the associated property name of the form data object
     */
    getPropName(contextualValue: string) {
        return this.useRootFormData(contextualValue)
            ? contextualValue.replace(/(^@root.)/gi, '')
            : contextualValue.slice(1);
    }
}
