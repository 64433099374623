import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldDefinition } from '@wdx/clmi/api-models';
import {
    ICON_DANGER,
    ICON_REMOVE,
} from '../../../../../constants/icons.constants';
import { GET_STATUS_PENDING_CHANGES } from '../../../services/form-pending-changes/form-pending-changes.helper';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-reactive-accordion-header',
    templateUrl: './accordion-header.component.html',
})
export class AccordionHeaderComponent {
    @Input() entityId: string;
    @Input() place;
    @Input() elementDefinition: FieldDefinition;
    @Input() parentData: any;
    @Input() formControlObj: UntypedFormGroup;
    @Input() set panelInfo(val: string[]) {
        let found = false;
        val.forEach((item) => {
            const STR_ARR = item.split('-');
            if (`${STR_ARR[0]}-${this.place}` === item) {
                this.open = true;
                found = true;
            }
        });

        if (!found) {
            this.open = false;
        }
    }
    @Input() hasError = false;
    @Input() disabled = false;
    @Input() status: string;

    @Output() removeItem = new EventEmitter();

    get statusInfo(): string {
        return GET_STATUS_PENDING_CHANGES[this.status];
    }

    open: boolean;
    readonly ICON_DANGER = ICON_DANGER;
    readonly ICON_REMOVE = ICON_REMOVE.icon;

    removeItemClicked(): void {
        this.removeItem.emit();
    }
}
