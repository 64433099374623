<wdx-alert themeColor="primary">
    <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
            <div>
                <h6 *ngIf="title" class="fw-bold">{{ title }}</h6>
                <span>{{ selectedCount }}</span>
                <span *ngIf="totalItems">&nbsp;of {{ totalItems }}</span>
                <span>&nbsp;Selected</span>
            </div>
        </div>
        <div class="d-flex align-items-stretch">
            <ng-container
                *ngIf="contentProjectActions; else actionButtonsTemplate"
            >
                <ng-content></ng-content>
            </ng-container>
            <ng-template #actionButtonsTemplate>
                <div
                    *ngIf="actionButtons?.length; else noActions"
                    class="d-flex align-items-center"
                >
                    <molecule-action-button
                        *ngFor="
                            let actionButton of actionButtons;
                            let first = first
                        "
                        class="me-1"
                        btnClass="btn-primary"
                        size="md"
                        [actionButton]="actionButton"
                        (onClick)="onActionButtonClicked(actionButton)"
                    ></molecule-action-button>
                </div>
            </ng-template>
            <ng-template #noActions>
                <div class="d-flex align-items-center m-2">
                    <div>No bulk actions available</div>
                </div>
            </ng-template>

            <atom-close
                btnClass="btn btn-primary"
                (click)="onClose()"
            ></atom-close>
        </div>
    </div>
</wdx-alert>
