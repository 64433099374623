import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { DocumentAssociation, LookupSourceType } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DOCUMENT_FORM_ID } from '../../../../../constants/dynamic-form.constants';
import { ICON_ADD } from '../../../../../constants/icons.constants';
import { BaseReactiveFormControlClass } from '../../../../../features/reactive-forms/abstract-classes/base-reactive-form-control.class';
import { FormContextualDataService } from '../../../../../features/reactive-forms/services/form-contextual-data/form-contextual-data.service';
import { FormStaticService } from '../../../../../features/reactive-forms/services/form-static/form-static.class';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { FiledDocumentsService } from '../../../../../shared/features/filed-documents/shared/services/filed-documents.service';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services';
import * as documentsActions from '../../../../../state/documents/documents.actions';
import * as dynamicFormsActions from '../../../../../state/dynamic-forms/dynamic-forms.actions';

@Component({
    selector: 'clmi-reactive-document-lookup-control',
    templateUrl: './reactive-document-lookup-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveDocumentLookupControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    addDocumentActionButton$ = new BehaviorSubject<ActionButton>(null);

    readonly DOCUMENT_FORM_ID = DOCUMENT_FORM_ID;

    get documents() {
        return this.formControl?.value || [];
    }

    get label() {
        return `${this.formElement?.label} (${
            this.formControl?.value?.length || 0
        })`;
    }

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService,
        private cdf: ChangeDetectorRef,
        private actionsSubject$: ActionsSubject,
        private formStaticService: FormStaticService,
        private formContextualDataService: FormContextualDataService,
        private filedDocumentsService: FiledDocumentsService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit() {
        this.addDocumentActionButton$.next(this.getAddDocumentActionButton());

        this.controlContainer.control?.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() =>
                this.addDocumentActionButton$.next(
                    this.getAddDocumentActionButton()
                )
            );

        this.actionsSubject$
            .pipe(
                takeUntil(this.destroyed$),
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
                ),
                filter((action: any) => action.formId === DOCUMENT_FORM_ID)
            )
            .subscribe((action: any) => this.updateForm(action));

        this.actionsSubject$
            .pipe(
                takeUntil(this.destroyed$),
                ofType(documentsActions.deleteDocumentSuccess)
            )
            .subscribe((action: any) => this.removeDocumentFromForm(action));
    }

    getAddDocumentActionButton(): ActionButton {
        return {
            mode: ActionButtonMode.IconButton,
            icon: ICON_ADD.icon,
            formSetup: {
                isQuickCreate: true,
                preserveActiveModal: true,
                formId: DOCUMENT_FORM_ID,
                initialisationParams: {
                    ...this.getContextualInitialisationParams(),
                    ...this.getSchemaInitialisationParams(),
                },
            },
        };
    }

    getSchemaInitialisationParams(): KeyValueObject {
        const documentType = this.formElement?.documentType?.code;
        const documentCategory = this.formElement?.documentCategory;
        return {
            ...(documentType && { documentType }),
            ...(documentCategory && { documentCategory }),
        };
    }

    getContextualInitialisationParams(): KeyValueObject {
        return this.formElement.contextualValues
            ?.filter((contextualValue) =>
                this.formContextualDataService.useFormData(contextualValue)
            )
            .reduce((current, contextualValue) => {
                const propName =
                    this.formContextualDataService.getPropName(contextualValue);
                const propValue =
                    this.formStaticService.form.getRawValue()[propName];

                if (Array.isArray(propValue) && propValue?.length) {
                    return {
                        ...current,
                        [`${propName}Id`]: propValue
                            .map((prop) => prop.id)
                            .join(','),
                    };
                }

                if (propValue?.id) {
                    return {
                        ...current,
                        [`${propName}Id`]: propValue?.id,
                    };
                }
                return current;
            }, {});
    }

    updateForm(action: any): void {
        const id = action.response?.entityId;
        const updateIndex = this.documents.findIndex((val) => val.id === id);
        const updateValue = {
            id: action.response?.entityId,
            type: LookupSourceType.Document,
            name: action.response?.data?.name,
            fileIndex: action.response?.data?.document[0],
        };
        if (updateIndex > -1) {
            this.formControl.setValue(
                this.documents.map((val) => {
                    if (val.id === id) {
                        return updateValue;
                    }
                    return val;
                })
            );
        } else {
            this.formControl.setValue([...this.documents, ...[updateValue]]);
        }
    }

    removeDocumentFromForm(action: any): void {
        this.formControl.setValue(
            this.documents.filter(
                (document) => document.id !== action.documentId
            )
        );
        this.cdf.detectChanges();
    }

    onDeleteDocument(document: DocumentAssociation): void {
        this.filedDocumentsService.deleteDocument(document);
    }
}
