import { Injectable } from '@angular/core';
import { FileIndex } from '@wdx/clmi/api-models';
import { FilePreview } from '../../../../../models/document-data.model';
import { FileUploadPackage } from '../../../../../models/file-upload-package.model';
import * as fileDownloadActions from '../../../../../state/file-download/file-download.actions';
import * as fileDownloadSelectors from '../../../../../state/file-download/file-download.selectors';
import * as filesActions from '../../../../../state/files/files.actions';
import * as filesSelectors from '../../../../../state/files/files.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FilesFacadeService {
    previewFileIndexes: string[] = [];

    constructor(private store$: Store) {}

    getFilePreview$(fileIndex: FileIndex): Observable<FilePreview> {
        return this.store$
            .select(fileDownloadSelectors.getFilePreview, {
                id: fileIndex?.fileIndex,
            })
            .pipe(
                tap(() => {
                    if (
                        !this.previewFileIndexes.includes(fileIndex?.fileIndex)
                    ) {
                        this.loadFilePreview(fileIndex);
                        this.previewFileIndexes.push(fileIndex?.fileIndex);
                    }
                })
            );
    }

    getFilePreviewIsLoading$(fileIndex: FileIndex): Observable<boolean> {
        return this.store$.select(
            fileDownloadSelectors.getFilePreviewIsLoading,
            {
                id: fileIndex.fileIndex,
            }
        );
    }

    getFilePreviewHasError$(fileIndex: FileIndex): Observable<boolean> {
        return this.store$.select(
            fileDownloadSelectors.getFilePreviewHasLoadingError,
            {
                id: fileIndex.fileIndex,
            }
        );
    }

    getFileUploadFileIndex$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadFileIndex, {
            id: fileUploadPackage.id,
        });
    }

    getFileUploadStatus$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadStatus, {
            id: fileUploadPackage.id,
        });
    }

    getFileUploadProgress$(fileUploadPackage: FileUploadPackage) {
        return this.store$.select(filesSelectors.getFileUploadProgress, {
            id: fileUploadPackage.id,
        });
    }

    loadFileIndex(fileUploadPackage: FileUploadPackage) {
        this.store$.dispatch(
            filesActions.uploadFile({
                id: fileUploadPackage.id,
                file: fileUploadPackage.file,
            })
        );
    }

    loadFilePreview(fileIndex: FileIndex): void {
        this.store$.dispatch(
            fileDownloadActions.getFilePreview({
                fileIndex: fileIndex,
            })
        );
    }

    destroyFilePreviews() {
        this.previewFileIndexes.forEach((fileIndex) => {
            this.store$.dispatch(
                fileDownloadActions.destroyFilePreview({
                    fileIndex,
                })
            );
        });
        this.previewFileIndexes = [];
    }

    downloadFile(fileIndex: FileIndex): void {
        this.store$.dispatch(
            fileDownloadActions.getFileDownload({
                fileIndex: fileIndex.fileIndex,
                fileName: fileIndex.name,
            })
        );
    }

    deleteFile(fileIndex: string): void {
        this.store$.dispatch(
            fileDownloadActions.deleteFile({
                fileIndex: fileIndex,
            })
        );
    }
}
