import { SystemEntity } from '@wdx/clmi/api-models';
// eslint-disable-next-line max-len
import {
    ICON_ARTICLE,
    ICON_CASE,
    ICON_DOCUMENT,
    ICON_DOCUMENT_ASSOCIATION,
    ICON_EXPORTS,
    ICON_FEED,
    ICON_INBOX,
    ICON_LEAD,
    ICON_LETTER,
    ICON_LIST,
    ICON_MISSING,
    ICON_OPPORTUNITY,
    ICON_ORGANISATION,
    ICON_PERSON_BASE,
    ICON_PRODUCT,
    ICON_QUEUES,
    ICON_TASK,
    ICON_TEAM,
    ICON_USER,
} from './icons.constants';

export const ENTITY_ICON_MAP = {
    [SystemEntity.Activity]: ICON_INBOX.icon,
    [SystemEntity.ActivityParty]: ICON_MISSING.icon,
    [SystemEntity.Adviser]: ICON_PERSON_BASE.icon,
    [SystemEntity.AmlReport]: ICON_MISSING.icon,
    [SystemEntity.Appointment]: ICON_MISSING.icon,
    [SystemEntity.AppStatus]: ICON_MISSING.icon,
    [SystemEntity.Article]: ICON_ARTICLE.icon,
    [SystemEntity.ArticleDistribution]: ICON_MISSING.icon,
    [SystemEntity.BankAccount]: ICON_MISSING.icon,
    [SystemEntity.Benchmark]: ICON_MISSING.icon,
    [SystemEntity.Case]: ICON_CASE.icon,
    [SystemEntity.Client]: ICON_PERSON_BASE.icon,
    [SystemEntity.ClientProduct]: ICON_PRODUCT.icon,
    [SystemEntity.ClientProductRelationship]: ICON_MISSING.icon,
    [SystemEntity.ContactPoint]: ICON_MISSING.icon,
    [SystemEntity.DataHistory]: ICON_MISSING.icon,
    [SystemEntity.DataLock]: ICON_MISSING.icon,
    [SystemEntity.Document]: ICON_DOCUMENT.icon,
    [SystemEntity.DocumentAssociation]: ICON_DOCUMENT_ASSOCIATION.icon,
    [SystemEntity.EdsMessage]: ICON_MISSING.icon,
    [SystemEntity.EdsMessageBatch]: ICON_MISSING.icon,
    [SystemEntity.ElectronicAddress]: ICON_LETTER.icon,
    [SystemEntity.Email]: ICON_MISSING.icon,
    [SystemEntity.Engagement]: ICON_MISSING.icon,
    [SystemEntity.Export]: ICON_EXPORTS.icon,
    [SystemEntity.Feed]: ICON_FEED.icon,
    [SystemEntity.FeedNudge]: ICON_FEED.icon,
    [SystemEntity.FeeStructure]: ICON_MISSING.icon,
    [SystemEntity.GoalPoint]: ICON_MISSING.icon,
    [SystemEntity.InsuranceAccount]: ICON_PRODUCT.icon,
    [SystemEntity.Intermediary]: ICON_PERSON_BASE.icon,
    [SystemEntity.InvestmentAccount]: ICON_PRODUCT.icon,
    [SystemEntity.Lead]: ICON_LEAD,
    [SystemEntity.LendingAccount]: ICON_MISSING.icon,
    [SystemEntity.List]: ICON_LIST.icon,
    [SystemEntity.Mandate]: ICON_MISSING.icon,
    [SystemEntity.Message]: ICON_MISSING.icon,
    [SystemEntity.AmlRisk]: ICON_MISSING.icon,
    [SystemEntity.Note]: ICON_MISSING.icon,
    [SystemEntity.Notification]: ICON_MISSING.icon,
    [SystemEntity.Nudge]: ICON_MISSING.icon,
    [SystemEntity.NudgeLog]: ICON_MISSING.icon,
    [SystemEntity.Objective]: ICON_MISSING.icon,
    [SystemEntity.Opportunity]: ICON_OPPORTUNITY.icon,
    [SystemEntity.Organisation]: ICON_ORGANISATION.icon,
    [SystemEntity.Party]: ICON_MISSING.icon,
    [SystemEntity.PartyRole]: ICON_MISSING.icon,
    [SystemEntity.PartyRoleAccess]: ICON_MISSING.icon,
    [SystemEntity.Person]: ICON_PERSON_BASE.icon,
    [SystemEntity.PhoneAddress]: ICON_MISSING.icon,
    [SystemEntity.PhoneCall]: ICON_MISSING.icon,
    [SystemEntity.PostalAddress]: ICON_MISSING.icon,
    [SystemEntity.PotentialDuplicate]: ICON_MISSING.icon,
    [SystemEntity.Process]: ICON_MISSING.icon,
    [SystemEntity.Product]: ICON_MISSING.icon,
    [SystemEntity.ProductGroup]: ICON_MISSING.icon,
    [SystemEntity.ProductGroupMember]: ICON_MISSING.icon,
    [SystemEntity.QueueItem]: ICON_QUEUES.icon,
    [SystemEntity.Relationship]: ICON_MISSING.icon,
    [SystemEntity.Reminder]: ICON_MISSING.icon,
    [SystemEntity.RiskLevel]: ICON_MISSING.icon,
    [SystemEntity.SocialAddress]: ICON_MISSING.icon,
    [SystemEntity.Tag]: ICON_MISSING.icon,
    [SystemEntity.Task]: ICON_TASK.icon,
    [SystemEntity.Team]: ICON_TEAM.icon,
    [SystemEntity.TimeBooking]: ICON_MISSING.icon,
    [SystemEntity.User]: ICON_USER.icon,
    [SystemEntity.WebHook]: ICON_MISSING.icon,
    [SystemEntity.WorkItem]: ICON_TASK.icon,
};
