import { createSelector } from '@ngrx/store';
import * as investmentDataReducer from './investment-data.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    investmentData: investmentDataReducer.State;
}): investmentDataReducer.State => state.investmentData;

/**
 * Reducer property selectors
 */
export const getIsLoadingValuationHistory = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.valuationHistory[props.id]?.isLoadingSingle
);
export const getHasLoadingValuationHistoryError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.valuationHistory[props.id]?.hasLoadingSingleError
);
export const getValuationHistory = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.valuationHistory[props.id]?.single
);

export const getIsLoadingPerformance = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.performance[props.id]?.isLoadingSingle
);
export const getHasLoadingPerformanceError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.performance[props.id]?.hasLoadingSingleError
);
export const getPerformance = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.performance[props.id]?.single
);

export const getIsLoadingAssetAllocationByCountry = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByCountry[props.id]?.isLoadingList
);
export const getHasLoadingAssetAllocationByCountryError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByCountry[props.id]?.hasLoadingListError
);
export const getAssetAllocationByCountry = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByCountry[props.id]?.list
);

export const getIsLoadingAssetAllocationByGeographic = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByGeographic[props.id]?.isLoadingList
);
export const getHasLoadingAssetAllocationByGeographicError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByGeographic[props.id]?.hasLoadingListError
);
export const getAssetAllocationByGeographic = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByGeographic[props.id]?.list
);

export const getIsLoadingAssetAllocationBySector = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationBySector[props.id]?.isLoadingList
);
export const getHasLoadingAssetAllocationBySectorError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationBySector[props.id]?.hasLoadingListError
);
export const getAssetAllocationBySector = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationBySector[props.id]?.list
);

export const getIsLoadingAssetAllocationByAssetClass = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByAssetClass[props.id]?.isLoadingList
);
export const getHasLoadingAssetAllocationByAssetClassError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByAssetClass[props.id]?.hasLoadingListError
);
export const getAssetAllocationByAssetClass = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetAllocationByAssetClass[props.id]?.list
);
export const getMovementsIsLoadingPage = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.movements[props.id]?.isLoadingPage
);
export const getMovementsHasLoadingPageError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.movements[props.id]?.hasLoadingPageError
);
export const getMovementsInfinityPaging = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.movements[props.id]?.infinity?.paging
);
export const getMovementsInfinityCombinedList = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.movements[props.id]?.infinity?.combinedList
);
export const getSecurityPositionsIsLoadingPage = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.securityPositions[props.id]?.isLoadingPage
);
export const getSecurityPositionsHasLoadingPageError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.securityPositions[props.id]?.hasLoadingPageError
);
export const getSecurityPositionsInfinityPaging = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.securityPositions[props.id]?.infinity?.paging
);
export const getSecurityPositionsInfinityCombinedList = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.securityPositions[props.id]?.infinity?.combinedList
);
export const getCashSummariesIsLoadingSingle = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.cashSummaries[props.id]?.isLoadingSingle
);
export const getCashSummariesHasLoadingSingleError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.cashSummaries[props.id]?.hasLoadingSingleError
);
export const getCashSummaries = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.cashSummaries[props.id]?.single
);
export const getAssetClassIsLoadingSingle = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetClass[props.id]?.isLoadingSingle
);
export const getAssetClassHasLoadingSingleError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetClass[props.id]?.hasLoadingSingleError
);
export const getAssetClass = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.assetClass[props.id]?.single
);

export const getTopMoversIsLoadingList = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.topMovers[props.id]?.isLoadingList
);
export const getTopMoversHasLoadingListError = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.topMovers[props.id]?.hasLoadingListError
);
export const getTopMovers = createSelector(
    getState,
    (state: investmentDataReducer.State, props: any) =>
        state.topMovers[props.id]?.list
);
