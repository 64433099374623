import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormFieldType } from '@wdx/clmi/api-models';
import {
    CHECKBOX_CONTROL_TYPES,
    DATE_CONTROL_TYPES,
    FILE_UPLOAD_CONTROL_TYPES,
    INPUT_CONTROL_TYPES,
    RADIO_BUTTONS_CONTROL_TYPES,
    SELECT_CONTROL_TYPES,
    WRAPPED_CONTROL_TYPES,
} from '../../constants/control-types';
import { ReactiveFormLayoutAndDefinition } from '../../../../models/reactive-dynamic.model';

@Component({
    selector: 'clmi-reactive-form-controls',
    templateUrl: './reactive-form-controls.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block position-relative mb-3',
    },
})
export class ReactiveFormControlsComponent {
    @Input() entityId: string;
    @Input() formElement: ReactiveFormLayoutAndDefinition;
    @Input() formContext: any;

    readonly CHECKBOX_CONTROL_TYPES = CHECKBOX_CONTROL_TYPES;
    readonly RADIO_BUTTONS_CONTROL_TYPES = RADIO_BUTTONS_CONTROL_TYPES;
    readonly DATE_CONTROL_TYPES = DATE_CONTROL_TYPES;
    readonly WRAPPED_CONTROL_TYPES = WRAPPED_CONTROL_TYPES;
    readonly INPUT_CONTROL_TYPES = INPUT_CONTROL_TYPES;
    readonly SELECT_CONTROL_TYPES = SELECT_CONTROL_TYPES;
    readonly FILE_UPLOAD_CONTROL_TYPES = FILE_UPLOAD_CONTROL_TYPES;
    readonly FORM_FIELD_TYPE = FormFieldType;

    constructor(public controlContainer: ControlContainer) {}
}
