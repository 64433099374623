import { SystemEntity } from '@wdx/clmi/api-models';
import { TranslationKey } from '@wdx/clmi/api-services/services';
import { ADVISER_FORM_ID } from '../../../constants/dynamic-form.constants';
import { ICON_ADD } from '../../../constants/icons.constants';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { Privilege } from '../../../models/privilege.model';
import { SortMenuSortBy } from '../../../models/sort-menu.model';

export const ADVISERS_TABLE_HEADER = [
    {
        value: 'Name',
        sortByFieldName: 'party.name',
    },
    {
        value: 'Intermediary',
    },
    {
        value: 'Last Contacted',
        sortByFieldName: SortMenuSortBy.LastContacted,
    },
    {
        value: 'Next Activity',
        sortByFieldName: SortMenuSortBy.NextMeeting,
    },
    {
        value: 'Completed Activities',
    },
    {
        value: 'Total Value Introduced',
    },
    {
        value: 'Total Value Managed',
    },
    {
        value: 'Pipeline (#)',
        sortByFieldName: SortMenuSortBy.Pipeline,
    },
    {
        value: null,
    },
];

export const ADVISERS_ACTION_BTN = [
    {
        mode: ActionButtonMode.FavouriteButton,
        cySelector: 'btn-advisers-favourite',
    },
    {
        mode: ActionButtonMode.IconButton,
        cySelector: 'btn-advisers-add',
        icon: ICON_ADD.icon,
        privilege: Privilege.CreateAdviser,
        formSetup: {
            formId: ADVISER_FORM_ID,
        },
    },
];

export const ADVISERS_ROUTE_DATA = {
    viewType: SystemEntity.Adviser,
    exportPrivilege: Privilege.ExportAdviser,
    label: 'Advisers',
    translationKey: TranslationKey.AreaContactsAdvisers,
};
