import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxListModule } from '@wdx/shared/components/wdx-list';
import { AtomAvatarModule } from '../../../components/atoms/atom-avatar/atom-avatar.module';
import { AtomCheckBoxModule } from '../../../components/atoms/atom-check-box/atom-check-box.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeArticlePreviewModule } from '../../../components/molecules/molecule-article-preview/molecule-article-preview.module';
import { MoleculeAvatarGroupModule } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeIdentifierListModule } from '../../../components/molecules/molecule-identifier-list/molecule-identifier-list.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeInfoCardDumbComponent } from './molecule-info-card-dumb/molecule-info-card-dumb.component';
import { MoleculeInfoCardComponent } from './molecule-info-card/molecule-info-card.component';
import { TextToHslPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomAvatarModule,
        AtomCheckBoxModule,
        AtomIconButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeArticlePreviewModule,
        MoleculeAvatarGroupModule,
        MoleculeIdentifierListModule,
        PipesModule,
        RouterModule,
        WdxBadgeModule,
        WdxIconModule,
        WdxListModule,
        WdxIconContainerModule,
        TextToHslPipe,
    ],
    declarations: [MoleculeInfoCardComponent, MoleculeInfoCardDumbComponent],
    exports: [MoleculeInfoCardComponent],
})
export class MoleculeInfoCardModule {}
