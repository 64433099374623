import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
    transform(originalValue: any, textToHighlight: string): string {
        if (!originalValue || !textToHighlight) {
            return originalValue;
        }

        const matches = new RegExp(textToHighlight + '(?!([^<]+)?<)', 'gi');
        return String(originalValue).replace(
            matches,
            '<strong><mark>$&</mark></strong>'
        );
    }
}
