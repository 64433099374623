import { FormElementType, FormFieldType } from '@wdx/clmi/api-models';

export const STRING_FIELD_TYPES = [
    FormFieldType.Text,
    FormFieldType.MultilineText,
    FormFieldType.UrlLookup,
    FormFieldType.TextDropdown,
    FormFieldType.PhoneNumber,
    FormFieldType.RichText,
];

export const NUMERIC_FIELD_TYPES = [
    FormFieldType.Integer,
    FormFieldType.Decimal,
];

export const CURRENCY_FIELD_TYPES = [FormFieldType.Currency];

export const ARRAY_FIELD_TYPES = [
    FormFieldType.Select,
    FormFieldType.Array,
    FormFieldType.Multiselect,
    FormFieldType.Checkboxes,
    FormFieldType.TagListMulti,
    FormFieldType.CheckTiles,
    FormFieldType.LookupMulti,
];

/**
 * I've removed FormFieldType.Array from this list as
 * it's not needed for setting up the validations. Going to treat
 * array as an number input type. Check ARRAY_VALIDATOR
 */
export const ARRAY_FIELD_TYPES_FOR_VALIDATORS = [
    FormFieldType.Select,
    FormFieldType.Multiselect,
    FormFieldType.Checkboxes,
    FormFieldType.TagListMulti,
    FormFieldType.CheckTiles,
    FormFieldType.LookupMulti,
];

export const ARRAY_VALIDATOR = [FormElementType.Array];

export const OPTIONS_FIELD_TYPES = [
    FormFieldType.Checkboxes,
    FormFieldType.TextDropdown,
    FormFieldType.Select,
    FormFieldType.Multiselect,
    FormFieldType.Radios,
    FormFieldType.RadioTiles,
    FormFieldType.ButtonGroup,
];

export const TAGS_FIELD_TYPES = [
    FormFieldType.TagListSingle,
    FormFieldType.TagListMulti,
];
