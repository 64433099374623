import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    takeUntil,
} from 'rxjs/operators';

import {
    FieldDefinitionOption,
    FormCompletionSummary,
    FormDataResult,
    FormDataStatus,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormLayout,
    FormLock,
    FormSaveMode,
    FormSectionLayoutDefinition,
    SummaryLevel,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { ARRAY_CONTROL_TYPES } from '../../../../features/reactive-forms/constants/control-types';
import {
    FormInitialisationMode,
    FormStateData,
} from '../../../../models/form-setup.model';
import {
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../../models/form-validation.model';
import { MenuItem } from '../../../../models/menu-item.model';
import { Privilege } from '../../../../models/privilege.model';
import { StringifyComparePipe } from '../../../../pipes/stringify-compare.pipe';
import { FormSummaryLayoutService } from '../../../../shared/features/form-summary/services/form-summary-layout/form-summary-layout.service';
import { OpenInNewTabService } from '../../../../shared/services';

import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { FormBuilderService } from '../../services/form-builder/form-builder.service';
import { FormConditionsService } from '../../services/form-conditions/form-conditions.service';
import { FormControlService } from '../../services/form-conditions/form-control.service';
import { FormProcessConditionDataService } from '../../services/form-conditions/process-condition-data.service';
import { FormLockService } from '../../services/form-lock/form-lock.service';
import { FormPendingChangesService } from '../../services/form-pending-changes/form-pending-changes.service';
import { FormStaticService } from '../../services/form-static/form-static.class';
import { FormValidationService } from '../../services/form-validation/form-validation.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-reactive-form-dumb',
    templateUrl: './organism-reactive-form-dumb.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex flex-column h-100 overflow-hidden' },
    providers: [
        FormBuilderService,
        FormConditionsService,
        FormValidationService,
        FormStaticService,
        FormProcessConditionDataService,
        FormControlService,
        FormPendingChangesService,
    ],
})
export class OrganismReactiveFormDumbComponent
    extends WdxDestroyClass
    implements OnInit, AfterContentInit
{
    @Input() formId: string;
    @Input() entityId: string;
    @Input() appId: string;
    @Input() formDefinition: FormDefinition;
    @Input() formLayout: FormLayout;
    @Input() formData: FormDataResult;
    @Input() isQuickCreate = false;
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() isSaving: boolean;
    @Input() modalInstanceId?: string;

    @Output() formTitleReceived = new EventEmitter<string>();
    @Output() formValueChanged = new EventEmitter<KeyValueObject>();
    @Output() cancelClicked = new EventEmitter();
    @Output() saveClicked = new EventEmitter<FormStateData>();
    @Output() saveDraftClicked = new EventEmitter<FormStateData>();
    @Output() completionSummaryChanged =
        new EventEmitter<FormCompletionSummary>();

    @ViewChild(NgbAccordion) accordion: NgbAccordion;

    validationSummary: ValidationSummarySection[];
    completionSummary: FormCompletionSummary;

    form: UntypedFormGroup;
    sectionsInError: string[];

    fullFormLock: boolean;
    saveMode: boolean;
    saveCompleteMode: boolean;
    saveDraftMode: boolean;
    isDraftForm: boolean;
    isPublishedForm: boolean;
    showFormSwitcherPanel: boolean;

    draftForm: FormDataResult;
    publishedForm: FormDataResult;
    validationMessage: string;
    activePanelIds: string[];
    submitAttempted = false;

    get summaryInfoCards$(): Observable<FieldDefinitionOption[]> {
        return this.formSummaryLayoutService.summaryInfoCards$;
    }

    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly ARRAY_CONTROL_TYPES = ARRAY_CONTROL_TYPES;
    readonly PRIVILEGE = Privilege;
    readonly FORM_DATA_STATUS = FormDataStatus;
    readonly SAVE_AS_DRAFT = 'Save as draft';

    splitButtonMenuItems: MenuItem[] = [{ label: this.SAVE_AS_DRAFT }];

    get messageDefinition() {
        return this.formStaticService?.messageDefinitionToShow;
    }

    get messageDefinitionLength() {
        return Object.keys(this.formStaticService?.messageDefinitionToShow)
            ?.length;
    }

    public fbService = inject(FormBuilderService);
    public condService = inject(FormConditionsService);
    private formStaticService = inject(FormStaticService);
    private formValidationService = inject(FormValidationService);
    private elementRef = inject(ElementRef);
    private ointService = inject(OpenInNewTabService);
    private formSummaryLayoutService = inject(FormSummaryLayoutService);
    private formLockService = inject(FormLockService);
    private modalManagementService = inject(ModalManagementService);
    public formPendingChangesService = inject(FormPendingChangesService);

    ngOnInit() {
        this.setupForm();
        this.setModes();
        this.setUpSummary();
        this.setDraftModeProps();
        this.formPendingChangesService.setUpPendingChanges(
            this.condService.layoutAndDefinition,
            this.fbService.firstLevel
        );

        this.validationMessage = this.saveDraftMode
            ? 'Complete missing fields below, or save as a draft until you can complete them'
            : 'Can not save form because there are missing values or validation errors';

        this.formStaticService.form.valueChanges
            .pipe(
                distinctUntilChanged((x, y) =>
                    new StringifyComparePipe().transform(x, y)
                ),
                takeUntil(this.formStaticService.destroyed$),
                debounceTime(300)
            )
            .subscribe((value) => {
                this.formValueChanged.emit(value);
            });

        if (this.isQuickCreate) {
            this.modalManagementService.activeModalClosed$
                .pipe(
                    takeUntil(this.destroyed$),
                    filter((modalId) => this.modalInstanceId === modalId)
                )
                .subscribe(() => this.formStaticService.form.reset());
        }
    }

    ngAfterContentInit() {
        this.showFormVersionSwitcher();
        this.storeFormSwitchData();
    }

    setupForm(forceFormLock = false): void {
        this.formTitleReceived.emit(this?.formLayout?.label);
        this.formStaticService.formDefinition = this.formDefinition;
        this.formStaticService.formLayout = this.formLayout;
        this.formStaticService.formData = this.formData;
        this.formStaticService.formId = this.formId;

        this.formStaticService.initialisationMode = this.formData?.entityId
            ? FormInitialisationMode.Edit
            : FormInitialisationMode.Add;
        this.fbService.buildForm();
        this.form = this.formStaticService.form;

        this.condService.combinedLayoutAndDefinition();
        this.condService.applyTriggerCondition();

        this.activePanelIds = Array.from(
            { length: this.condService.layoutAndDefinition?.length },
            (_, i) => `reactive-form-${i}`
        );

        this.fullFormLock = this.getFullFormLock(
            this.formData.lock,
            forceFormLock
        );

        if (this.formData.lock || forceFormLock) {
            this.applyFormLock(this.formData.lock, forceFormLock);
        }

        this.calculateValidationSummary();

        this.formStaticService.form.statusChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(200))
            .subscribe(() => this.calculateValidationSummary());
    }

    getFullFormLock(lock: FormLock, forceFormLock = false) {
        return forceFormLock || this.formLockService.hasFullFormLock(lock);
    }

    /**
     * Apply form lock by disabling the entire or individual form fields
     */
    applyFormLock(lock: FormLock, forceFormLock = false): void {
        // Update the models in condition service (so lock status can be referenced from definition)
        this.condService.updateLockStatuses(forceFormLock);

        // Check for form lock status and disable entire form if true
        if (this.formLockService.hasFullFormLock(lock) || forceFormLock) {
            this.formStaticService.form.disable();
            return;
        }

        // For partial locks, disable the associated form controls
        if (this.formLockService.hasPartialFormLock(lock)) {
            lock.fields?.forEach((field) => {
                const controlToLock = this.formStaticService?.form?.get(field);
                if (controlToLock) {
                    controlToLock.disable();
                }
            });
        }
    }

    setUpSummary() {
        this.formSummaryLayoutService.getSummaryInfoCards(
            this.formDefinition,
            this.formStaticService.form.getRawValue()
        );

        this.formStaticService.formDefinition.schema
            .filter((item) => item.summaryLevel === SummaryLevel.InfoCard)
            .forEach((summaryField) => {
                this.formStaticService.form?.controls[
                    summaryField.name
                ]?.valueChanges.subscribe(() => {
                    this.formSummaryLayoutService.getSummaryInfoCards(
                        this.formDefinition,
                        this.formStaticService.form.getRawValue()
                    );
                });
            });
    }

    setModes(): void {
        this.saveMode = this.formDefinition?.saveModes.includes(
            FormSaveMode.Save
        );

        this.saveCompleteMode = this.formDefinition?.saveModes.includes(
            FormSaveMode.Complete
        );
    }

    setDraftModeProps(): void {
        this.saveDraftMode = this.formDefinition?.saveModes.includes(
            FormSaveMode.Draft
        );
        this.isDraftForm =
            this.formData?.status === this.FORM_DATA_STATUS.Draft;
        this.isPublishedForm =
            this.formData?.status === this.FORM_DATA_STATUS.Published;
    }

    showFormVersionSwitcher(): void {
        this.showFormSwitcherPanel =
            this.saveDraftMode &&
            this.isDraftForm &&
            this.formData?.lastPublishedData;
    }

    storeFormSwitchData(): void {
        if (this.formData?.status === FormDataStatus.Draft) {
            this.draftForm = this.formData;
        }

        if (this.formData?.lastPublishedData) {
            this.publishedForm = this.formData.lastPublishedData;
        }
    }

    trackSection(_, section: FormSectionLayoutDefinition) {
        return section.name;
    }

    trackElement(_, layout: FormElementLayoutDefinition) {
        return layout.name;
    }

    trackByMessage(_, messageDefinition): string {
        return messageDefinition?.key;
    }

    /**
     * When form is cancelled, reset form to initial state
     */
    onCancelClick(): void {
        if (this.isQuickCreate) {
            this.formStaticService.form.reset();
        }
        this.cancelClicked.emit();
    }

    onSplitMenuItemClicked(menuItem: MenuItem): void {
        menuItem.label === this.SAVE_AS_DRAFT && this.onDraftSaveClick();
    }

    switchFormVersion() {
        const formStatus: FormDataStatus = this.formData?.status;

        if (formStatus === FormDataStatus.Draft) {
            this.formData = this.publishedForm;
            this.condService.reset();
            this.setupForm(true);
            this.setDraftModeProps();
        } else if (formStatus === FormDataStatus.Published) {
            this.formData = this.draftForm;
            this.condService.reset();
            this.setupForm(false);
            this.setDraftModeProps();
        }
    }
    onSaveClick(queryParams?: string): void {
        if (this.formStaticService.form.invalid) {
            if (!this.formStaticService.submitAttempted$.isStopped) {
                this.formStaticService.form.markAllAsTouched();
                this.formStaticService.form.updateValueAndValidity();
                this.formStaticService.submitAttempted$.next(true);
                this.formStaticService.submitAttempted$.complete();
                this.submitAttempted = true;
            }

            if (this.validationSummary?.length === 0) {
                this.saveClickEmit(queryParams);
            }

            return;
        }

        this.saveClickEmit(queryParams);
    }

    saveClickEmit(queryParams: string): void {
        this.completionSummaryChanged.emit(this.completionSummary);
        this.saveClicked.emit({
            value: this.formStaticService.formValue,
            queryParams: queryParams,
        });
    }

    onDraftSaveClick(): void {
        this.completionSummaryChanged.emit(this.completionSummary);
        this.saveDraftClicked.emit({
            value: this.formStaticService.formValue,
        });
    }

    calculateValidationSummary() {
        const validationSummary =
            this.formValidationService.getValidationSummary(
                this.condService.layoutAndDefinition,
                this.formStaticService.form.controls
            );

        this.validationSummary = validationSummary.sections.filter(
            (section) => section?.errors?.length > 0
        );

        this.completionSummary = validationSummary.completionSummary;

        this.sectionsInError = this.validationSummary.map(
            (section) => section.name
        );
    }

    onErrorClicked(error: ValidationSummaryError): void {
        this.accordion.expand(`reactive-form-${error.sectionIndex}`);
        const fieldDefinitionElementRef =
            this.elementRef.nativeElement.querySelector(
                `[data-form-control='${error.name}']`
            );
        this.formValidationService.scrollToError(fieldDefinitionElementRef);
    }

    openLinkInNewTab(entity: string, id?: string): void {
        this.ointService.openInNewTab(
            SystemEntity[entity] || UserActionEntity[entity],
            id
        );
    }
}
