import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SelectApiService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TypedKeyValueObject } from '../../models/typed-key-value-object.model';
import * as selectsActions from './selects.actions';

@Injectable()
export class SelectsEffects {
    private actions$ = inject(Actions);
    private selectApiService = inject(SelectApiService);

    cache: TypedKeyValueObject<Action> = {};

    getSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectsActions.getSelect),
            mergeMap(
                (action) =>
                    (this.cache[action.selectSource] &&
                        of(this.cache[action.selectSource])) ||
                    this.selectApiService.getSelect(action.selectSource).pipe(
                        map((results) => {
                            const successAction =
                                selectsActions.getSelectSuccess({
                                    selectSource: action.selectSource,
                                    results,
                                });

                            this.cache = {
                                ...this.cache,
                                [action.selectSource]: successAction,
                            };

                            return successAction;
                        }),
                        catchError((error) =>
                            of(
                                selectsActions.getSelectFailure({
                                    selectSource: action.selectSource,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getContextualSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectsActions.getContextualSelect),
            mergeMap(
                (action) =>
                    (this.cache[action.selectSource] &&
                        of(this.cache[action.selectSource])) ||
                    this.selectApiService
                        .getContextualSelect(
                            action.source,
                            action.lookups,
                            action.context
                        )
                        .pipe(
                            map((results) => {
                                const successAction =
                                    selectsActions.getContextualSelectSuccess({
                                        selectSource: action.selectSource,
                                        results,
                                    });

                                this.cache = {
                                    ...this.cache,
                                    [action.selectSource]: successAction,
                                };

                                return successAction;
                            }),
                            catchError((error) =>
                                of(
                                    selectsActions.getContextualSelectFailure({
                                        selectSource: action.selectSource,
                                        error,
                                    })
                                )
                            )
                        )
            )
        )
    );
}
