<ng-container
    [formGroup]="controlContainer.control"
    *ngIf="fbService?.firstLevel[elementDefinition.name] as FIRST_LEVEL_ELEMENT"
>
    <reactive-sub-form-header
        [amount]="FIRST_LEVEL_ELEMENT.formArray.controls.length"
        [elementDefinition]="elementDefinition"
        [disabled]="FIRST_LEVEL_ELEMENT.disabled"
        [showValidation]="validationStatus"
        (addToArray)="addToArray(elementDefinition)"
    ></reactive-sub-form-header>
    <hr />

    <ngb-accordion
        #accordionSubFrom
        class="accordion sub-form-accordion d-block"
        *ngIf="fbService?.firstLevel"
        [closeOthers]="false"
        [destroyOnHide]="false"
        (panelChange)="panelChange($event)"
    >
        <ng-container
            *ngFor="
                let childForm of FIRST_LEVEL_ELEMENT.formArray.controls;
                let childFormIndex = index;
                let first = first
            "
        >
            <ngb-panel
                tabindex="0"
                *ngIf="FIRST_LEVEL_ELEMENT?.pendingChanges as PENDING_CHANGES"
                id="ngb-panel-{{ elementDefinition.name }}-child-{{
                    childFormIndex
                }}"
                [class.danger]="PENDING_CHANGES[childFormIndex]"
                cardClass="card-muted sub-form"
            >
                <ng-template ngbPanelHeader let-opened="opened">
                    <button
                        #panelToggle
                        type="button"
                        class="accordion-header accordion-button w-100 bg-muted"
                        ngbPanelToggle
                    >
                        <organism-reactive-accordion-header
                            class="flex-grow-1"
                            [entityId]="entityId"
                            [place]="childFormIndex"
                            [elementDefinition]="elementDefinition"
                            [parentData]="parentData"
                            [formControlObj]="childForm"
                            [panelInfo]="openPanels"
                            [hasError]="validationSummary[childFormIndex]"
                            [disabled]="childForm.disabled"
                            [status]="PENDING_CHANGES[childFormIndex]?.status"
                            (removeItem)="
                                fbService.removeFromArray(
                                    elementDefinition,
                                    childFormIndex
                                )
                            "
                        ></organism-reactive-accordion-header>
                    </button>
                </ng-template>

                <ng-template ngbPanelContent>
                    <reactive-sub-form-content
                        #subForm
                        class="d-block"
                        [formGroupName]="childFormIndex"
                    >
                        <div
                            *ngIf="
                                validationSummary[childFormIndex];
                                let validationSummary
                            "
                            class="g-col-12"
                        >
                            <molecule-validation-summary
                                *ngIf="validationSummary?.errors?.length > 0"
                                [message]="VALIDATION_SUMMARY_MESSAGE"
                                [validationSummary]="validationSummary"
                                (errorClicked)="
                                    onErrorClicked($event, childFormIndex)
                                "
                            ></molecule-validation-summary>
                        </div>

                        <ng-container
                            *ngFor="
                                let childElement of elementDefinition
                                    .children[0].layoutAndDefinition[
                                    childFormIndex
                                ];
                                let childElementIndex = index;
                                trackBy: trackSubFormElement
                            "
                        >
                            <ng-container
                                *ngIf="
                                    !childElement.isHidden &&
                                    (childElement.elementType ||
                                        childElement.fieldType)
                                "
                            >
                                <div
                                    class="g-col-12"
                                    *ngIf="
                                        childElement.forcesNewRow &&
                                        !childElement.forcesFullWidth
                                    "
                                ></div>

                                <div
                                    class="g-col-12 input form-input"
                                    [attr.data-form-control]="childElement.name"
                                    [class.g-col-lg-6]="
                                        !childElement.forcesFullWidth &&
                                        childElement.fieldType !==
                                            FORM_FIELD_TYPE.Address &&
                                        (!childElement.forcesNewRow ||
                                            !childElement.forcesFullWidth)
                                    "
                                >
                                    <clmi-reactive-form-controls
                                        *ngIf="
                                            childElement.fieldType !==
                                            FORM_FIELD_TYPE.Array
                                        "
                                        [entityId]="entityId"
                                        [formElement]="
                                            childElement
                                                | spread
                                                    : {
                                                          parentName:
                                                              elementDefinition.name,
                                                          index: childFormIndex
                                                      }
                                        "
                                        [formContext]="formContext"
                                    ></clmi-reactive-form-controls>
                                </div>

                                <div
                                    class="g-col-12 input form-input"
                                    *ngIf="childElement.fieldType === 'Array'"
                                    [formArrayName]="childElement.name"
                                >
                                    <div
                                        class="form-section__body__blurb sub-3"
                                        *ngIf="
                                            childElement?.children[0]?.section
                                                ?.guidanceText
                                        "
                                    >
                                        {{
                                            childElement?.children[0]?.section
                                                ?.guidanceText
                                        }}
                                    </div>
                                    <reactive-sub-form-header
                                        [amount]="
                                            fbService.firstLevel[
                                                elementDefinition.name
                                            ].formArray.controls[childFormIndex]
                                                .controls[childElement.name]
                                                .controls.length
                                        "
                                        [elementDefinition]="childElement"
                                        (addToArray)="
                                            fbService?.addToArray(
                                                childElement,
                                                elementDefinition,
                                                childFormIndex
                                            )
                                        "
                                    ></reactive-sub-form-header>

                                    <ngb-accordion
                                        class="accordion sub-form-accordion d-block"
                                        [closeOthers]="false"
                                        [destroyOnHide]="false"
                                        [animation]="false"
                                        (panelChange)="panelChildChange($event)"
                                    >
                                        <ngb-panel
                                            cardClass="sub-form"
                                            id="ngb-panel-child-{{
                                                childElement.name
                                            }}-grandchild-{{
                                                grandChildFormIndex
                                            }}"
                                            *ngFor="
                                                let grandChildForm of fbService
                                                    .firstLevel[
                                                    elementDefinition.name
                                                ].formArray.controls[
                                                    childFormIndex
                                                ].controls[childElement.name]
                                                    .controls;
                                                let grandChildFormIndex = index
                                            "
                                        >
                                            <ng-template ngbPanelHeader>
                                                <button
                                                    type="button"
                                                    class="accordion-header d-block w-100"
                                                    ngbPanelToggle
                                                >
                                                    <organism-reactive-accordion-header
                                                        [entityId]="entityId"
                                                        [place]="
                                                            grandChildFormIndex
                                                        "
                                                        [elementDefinition]="
                                                            childElement
                                                        "
                                                        [formControlObj]="
                                                            grandChildForm
                                                        "
                                                        [panelInfo]="
                                                            openChildPanels
                                                        "
                                                        [hasError]="
                                                            validationSummary[
                                                                childFormIndex
                                                            ]?.subsections[
                                                                grandChildFormIndex
                                                            ]
                                                        "
                                                        (removeItem)="
                                                            fbService.removeFromArray(
                                                                childElement,
                                                                grandChildFormIndex,
                                                                elementDefinition,
                                                                childFormIndex
                                                            )
                                                        "
                                                    ></organism-reactive-accordion-header>
                                                </button>
                                            </ng-template>

                                            <ng-template ngbPanelContent>
                                                <reactive-sub-form-content
                                                    #grandChildForm
                                                    class="d-block accordion-body"
                                                    [formGroupName]="
                                                        grandChildFormIndex
                                                    "
                                                >
                                                    <molecule-validation-summary
                                                        *ngIf="
                                                            validationSummary[
                                                                childFormIndex
                                                            ]?.subsections[
                                                                grandChildFormIndex
                                                            ]
                                                        "
                                                        [message]="
                                                            VALIDATION_SUMMARY_MESSAGE
                                                        "
                                                        [validationSummary]="
                                                            validationSummary[
                                                                childFormIndex
                                                            ]?.subsections[
                                                                grandChildFormIndex
                                                            ]
                                                        "
                                                        (errorClicked)="
                                                            onErrorClicked(
                                                                $event,
                                                                childFormIndex,
                                                                grandChildFormIndex
                                                            )
                                                        "
                                                    ></molecule-validation-summary>

                                                    <ng-container
                                                        *ngFor="
                                                            let granChildElement of childElement
                                                                .children[0]
                                                                .layoutAndDefinition;
                                                            let grandChildElementIndex = index
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                !granChildElement.isHidden &&
                                                                (granChildElement.elementType ||
                                                                    granChildElement.fieldType)
                                                            "
                                                        >
                                                            <div
                                                                class="g-col-12"
                                                                *ngIf="
                                                                    granChildElement.forcesNewRow &&
                                                                    !granChildElement.forcesFullWidth
                                                                "
                                                            ></div>

                                                            <div
                                                                class="g-col-12 input form-input"
                                                                [class.g-col-lg-6]="
                                                                    !granChildElement.forcesFullWidth &&
                                                                    granChildElement.fieldType !==
                                                                        FORM_FIELD_TYPE.Address &&
                                                                    (!granChildElement.forcesNewRow ||
                                                                        !granChildElement.forcesFullWidth)
                                                                "
                                                                [attr.data-form-control]="
                                                                    granChildElement.name
                                                                "
                                                            >
                                                                <clmi-reactive-form-controls
                                                                    *ngIf="
                                                                        granChildElement.fieldType !==
                                                                        FORM_FIELD_TYPE.Array
                                                                    "
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formElement]="
                                                                        granChildElement
                                                                    "
                                                                    [formContext]="
                                                                        formContext
                                                                    "
                                                                ></clmi-reactive-form-controls>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </reactive-sub-form-content>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </ng-container>
                        </ng-container>
                    </reactive-sub-form-content>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
</ng-container>
