<molecule-widget-container
    class="d-block h-100 overflow-hidden"
    [widgetTitle]="widgetTitle"
    [widgetSize]="widgetSize"
    [spokeButton]="spokeButton"
    [views]="views"
    (viewSelected)="onViewSelected($event)"
    (spokeButtonClicked)="onSpokeButtonClicked()"
>
    <organism-table
        class="d-block h-100 overflow-hidden"
        [table]="table"
        [displayMode]="displayMode"
        [isLoading]="isLoading"
        [hasError]="hasError"
        [tablePaging]="tablePaging"
        [filterHandler]="filterHandler"
        [filterFormId]="filterFormId"
        [highlightFirstColumn]="highlightFirstColumn"
        [rowSelectorStyle]="rowSelectorStyle"
        [searchBarActionButtons]="searchBarActionButtons"
        [legend]="legend"
        [searchType]="searchType"
        [searchTypePlural]="searchTypePlural"
        [searchPlaceholder]="searchPlaceholder"
        [showSearch]="showSearch"
        [multiselectBannerButtons]="multiselectBannerButtons"
        [canSelectRows]="canSelectRows"
        [canDragRows]="canDragRows"
        [showSearchBar]="showSearchBar"
        [initialSelectedRows]="initialSelectedRows"
        [hasMobileFilter]="hasMobileFilter"
        [hasFilterSearch]="hasFilterSearch"
        (rowSelectChange)="onRowSelectChange($event)"
        (actionButtonClicked)="onActionButtonClicked($event)"
        (download)="onDownloadDocument($event)"
        (multiselectActionButtonClicked)="
            onMultiselectActionButtonClicked($event)
        "
        (pageChanged)="onPageChanged($event)"
        (infinityScrollFired)="onInfinityScrollFired()"
        (rowDragged)="onRowDragged($event)"
        (searchText)="onSearchText($event)"
    >
        <ng-content
            prefixActionButtons
            select="[prefixActionButtons]"
        ></ng-content>
    </organism-table>
</molecule-widget-container>
