import { Component, Input } from '@angular/core';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { MenuItem } from '../../../models/menu-item.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-filter-bar',
    templateUrl: './molecule-filter-bar.component.html',
    styleUrls: ['./molecule-filter-bar.component.scss'],
})
export class MoleculeFilterBarComponent {
    @Input() showFilters = true;
    @Input() showFavourite: boolean;
    @Input() sortMenu: MenuItem[];
    @Input() useSortToggleMenu: boolean;
    @Input() filtersModalId: string;
    @Input() filterHandler: FilterHandler;
    @Input() inlineStyle: boolean;
    @Input() noActionButtonsMargin: boolean;
    @Input() favouriteFilterToggle: boolean;

    onSortByAction(menuItem: MenuItem) {
        if (this.filterHandler) {
            this.filterHandler.setSort(menuItem.value, menuItem.isInitialValue);
        }
    }

    onSetIsFavourite(isFavourite: boolean) {
        this.filterHandler.setIsFavourite(isFavourite);
    }
}
