import { Directive, HostListener, Injectable } from '@angular/core';

@Directive({
    selector: '[wdxUtilsStopPropagation]',
    standalone: true,
})
@Injectable({
    providedIn: 'root',
})

// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class WdxStopPropagationDirective {
    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.stopPropagation();
    }
}
