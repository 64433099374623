import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EnvelopeService } from '@wdx/clmi/api-services/services';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as envelopeActions from './envelope.actions';

@Injectable()
export class EnvelopeEffects {
    private actions$ = inject(Actions);
    private envelopeService = inject(EnvelopeService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.getAll),
            switchMap(() =>
                this.envelopeService.getAll().pipe(
                    map((envelopes) =>
                        envelopeActions.getAllSuccess({ envelopes })
                    ),
                    catchError((error) =>
                        of(envelopeActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );

    getForEntityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.getForEntityId),
            switchMap((action) =>
                this.envelopeService
                    .getForEntityId(action.entityId, action.entity)
                    .pipe(
                        map((envelopes) =>
                            envelopeActions.getForEntityIdSuccess({
                                envelopes,
                                entityId: action.entityId,
                                entity: action.entity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                envelopeActions.getForEntityIdFailure({
                                    error,
                                    entityId: action.entityId,
                                    entity: action.entity,
                                })
                            )
                        )
                    )
            )
        )
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.getForId),
            mergeMap((action) =>
                this.envelopeService.getForId(action.id).pipe(
                    map((envelope) =>
                        envelopeActions.getForIdSuccess({
                            envelope,
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.getForIdFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    sendForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.sendForId),
            mergeMap((action) =>
                this.envelopeService.sendForId(action.id).pipe(
                    map((envelope) =>
                        envelopeActions.sendForIdSuccess({
                            id: action.id,
                            envelope,
                        })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.sendForIdFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    resendForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.resendForId),
            mergeMap((action) =>
                this.envelopeService.resendForId(action.id).pipe(
                    map((envelope) =>
                        envelopeActions.resendForIdSuccess({
                            id: action.id,
                            envelope,
                        })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.resendForIdFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.deleteForId),
            mergeMap((action) =>
                this.envelopeService.deleteForId(action.id).pipe(
                    map(() =>
                        envelopeActions.deleteForIdSuccess({ id: action.id })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.deleteForIdFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    getDocumentsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.getDocumentsForId),
            mergeMap((action) =>
                this.envelopeService.getDocumentsForId(action.id).pipe(
                    map((documents) =>
                        envelopeActions.getDocumentsForIdSuccess({
                            documents,
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.getDocumentsForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getSignatoriesForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.getSignatoriesForId),
            switchMap((action) =>
                this.envelopeService.getSignatoriesForId(action.id).pipe(
                    map((signatories) =>
                        envelopeActions.getSignatoriesForIdSuccess({
                            signatories,
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            envelopeActions.getSignatoriesForIdFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    addDocumentForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.addDocumentForId),
            mergeMap((action) =>
                this.envelopeService
                    .addDocumentForId(action.envelopeId, action.documentId)
                    .pipe(
                        map((document) =>
                            envelopeActions.addDocumentForIdSuccess({
                                envelopeId: action.envelopeId,
                                documentId: action.documentId,
                                document,
                            })
                        ),
                        catchError((error) =>
                            of(
                                envelopeActions.addDocumentForIdFailure({
                                    envelopeId: action.envelopeId,
                                    documentId: action.documentId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    addSignatoryForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.addSignatoryForId),
            mergeMap((action) =>
                this.envelopeService
                    .addSignatoryForId(action.envelopeId, action.partyId)
                    .pipe(
                        map((signatory) =>
                            envelopeActions.addSignatoryForIdSuccess({
                                envelopeId: action.envelopeId,
                                partyId: action.partyId,
                                signatory,
                            })
                        ),
                        catchError((error) =>
                            of(
                                envelopeActions.addSignatoryForIdFailure({
                                    envelopeId: action.envelopeId,
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteDocumentForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(envelopeActions.deleteDocumentForId),
            mergeMap((action) =>
                this.envelopeService
                    .deleteDocumentForId(action.envelopeId, action.documentId)
                    .pipe(
                        map(() =>
                            envelopeActions.deleteDocumentForIdSuccess({
                                envelopeId: action.envelopeId,
                                documentId: action.documentId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                envelopeActions.deleteDocumentForIdFailure({
                                    envelopeId: action.envelopeId,
                                    documentId: action.documentId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
