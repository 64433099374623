<ng-container *ngIf="isSmartView; else manualInputTemplate">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors | validationErrorMessage
        "
        [warningMessage]="warningText"
    >
        <button
            *ngIf="
                !formControl.disabled ||
                !PENDING_CHANGES_LOCK_FIELD.includes(
                    formElement?.pendingChange?.status
                )
            "
            formFieldActionButton
            class="btn btn-link ms-2"
            data-cy="action-smart-input"
            (click)="onToggleView()"
        >
            Manual Input
        </button>
        <div class="input-group">
            <div *ngIf="!addressSelect.hasValue" class="input-group-text">
                <wdx-icon icon="search"></wdx-icon>
            </div>
            <ng-select
                #addressSelect
                class="flex-grow-1"
                bindLabel="displayText"
                bindValue="identifier"
                typeToSearchText="Search for an address"
                notFoundText="No Addresses found"
                data-cy="dropdown-address"
                [typeahead]="addressInput$"
                [trackByFn]="trackByFn"
                [loading]="addressSearchResultsIsLoading$ | ngrxPush"
                [items]="addressSearchResults$ | ngrxPush"
                [readonly]="
                    formControl?.disabled ||
                    formElement?.pendingChange?.status ===
                        PENDING_CHANGE_STATUS_TYPE.Submitted
                "
                (change)="onLookupSelected($event)"
            ></ng-select>
        </div>

        <ng-container *ngIf="hasValue$ | ngrxPush">
            <div
                class="card card-body d-flex flex-row align-items-center justify-content-between mt-2"
            >
                <address class="mb-0" data-cy="address-result">
                    <span
                        class="d-block"
                        *ngFor="
                            let addressField of smartAddressFields$ | ngrxPush
                        "
                        [innerHtml]="addressField"
                    >
                    </span>
                </address>

                <atom-icon-button
                    *ngIf="!formControl.disabled"
                    size="sm"
                    data-cy="btn-clear"
                    icon="trash-alt"
                    (onClick)="onLookupClear()"
                ></atom-icon-button>
            </div>
        </ng-container>
    </wdx-form-field>
</ng-container>

<ng-template #manualInputTemplate>
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
    >
        <div class="d-flex" formFieldActionButton>
            <button
                *ngIf="!formControl.disabled && addressLookupEnabled"
                class="btn btn-link ms-2"
                data-cy="action-manual-input"
                (click)="onToggleView()"
            >
                Smart Input
            </button>
        </div>

        <molecule-validation-summary
            *ngIf="
                formControl.invalid && validationSummary$ | ngrxPush;
                let validationSummary
            "
            class="d-block mb-3"
            message="Address contains missing values or validation errors"
            [validationSummary]="validationSummary"
            (errorClicked)="onErrorClicked($event)"
        ></molecule-validation-summary>

        <ng-container [formGroup]="formControl">
            <div class="card card-muted pt-3">
                <div *ngIf="formElement.children" class="card-body grid">
                    <ng-container
                        *ngFor="
                            let formElement of formElement.children;
                            trackBy: trackByFn
                        "
                    >
                        <div
                            *ngIf="formElement.forcesNewRow"
                            class="g-col-12"
                        ></div>
                        <div
                            class="g-col-12 input form-input"
                            [ngClass]="{
                                'g-col-lg-6': !formElement.forcesFullWidth
                            }"
                            [attr.data-form-control]="formElement.name"
                        >
                            <clmi-reactive-form-controls
                                [formElement]="formElement"
                            ></clmi-reactive-form-controls>
                        </div>
                    </ng-container>
                </div></div></ng-container
    ></wdx-form-field>
</ng-template>
