<div class="radio-tile">
    <ng-content></ng-content>
    <label [for]="idFor">
        <div class="bullet"></div>
        <div class="tile-icon">
            <ng-content select="[icon]"></ng-content>
        </div>
        <div class="tile-label">{{ label }}</div>
    </label>
</div>
