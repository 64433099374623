import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon, WdxSize } from '@wdx/shared/utils';
import { ICON_CONTEXT_MENU } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { InfoCardExtendedData } from '../../../models/info-card-extended-data.model';
import { MenuItem } from '../../../models/menu-item.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-info-card-extended',
    templateUrl: './molecule-info-card-extended.component.html',
})
export class MoleculeInfoCardExtendedComponent {
    @Input() extendedInfoCard: InfoCardExtendedData;
    @Input() showButtons = true;
    @Input() size: WdxSize = 'lg';
    @Input() fixedWidthAvatarsAndIcons: boolean;
    @Input() buttons: ActionButton[];
    @Input() icon: Icon;

    @Output() toggleFavourite = new EventEmitter<boolean>();
    @Output() linkClicked = new EventEmitter();
    @Output() menuItemSelected = new EventEmitter<MenuItem>();

    readonly ICON_CONTEXT_MENU = ICON_CONTEXT_MENU;
    readonly ACTION_BUTTONS_MODE = ActionButtonMode;

    onToggleFavourite(isFavourite: boolean) {
        this.toggleFavourite.emit(isFavourite);
    }

    onLinkClicked() {
        this.linkClicked.emit();
    }

    onMenuItemSelected(menuItem: MenuItem) {
        this.menuItemSelected.emit(menuItem);
    }
}
