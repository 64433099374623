import { Pipe, PipeTransform } from '@angular/core';
import { ICON_BROWSER, ICON_USER_COG } from '../constants/icons.constants';
import { View, ViewType } from '@wdx/clmi/api-models';
import { MenuItem } from '../models/menu-item.model';

@Pipe({ name: 'viewsAsMenuItems' })
export class ViewsAsMenuItemsPipe implements PipeTransform {
    transform(views: View[]): MenuItem[] {
        if (!views?.length) {
            return;
        }

        const systemViews: MenuItem[] = views
            ?.filter((view) => view.type === ViewType.System)
            .map((view) => ({
                label: view.name,
                value: view.id,
                data: view,
            }));

        const personalViews: MenuItem[] = views
            ?.filter((view) => view.type === ViewType.Personal)
            .map((view) => ({
                label: view.name,
                value: view.id,
                data: view,
            }));

        const systemPersonalGroupedViews: MenuItem[] = [
            ...(systemViews?.length
                ? [
                      {
                          label: 'System Views',
                          icon: ICON_BROWSER.icon,
                          subMenu: systemViews,
                      },
                  ]
                : []),
            ...(personalViews?.length
                ? [
                      {
                          label: 'Personal Views',
                          icon: ICON_USER_COG.icon,
                          subMenu: personalViews,
                      },
                  ]
                : []),
        ];

        return systemPersonalGroupedViews;
    }
}
