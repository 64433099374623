import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringifyCompare' })
export class StringifyComparePipe implements PipeTransform {
    transform(valueA: any, valueB: any): boolean {
        return (
            valueA === valueB ||
            JSON.stringify(valueA) === JSON.stringify(valueB)
        );
    }
}
