import { Component, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
import { PartyAvatarInfo } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { Avatar } from '@wdx/clmi/utils/models';
import * as rootReducer from '../../../../../state/_setup/reducers';
import {
    avatarInfoActions,
    avatarInfoSelectors,
} from '@wdx/clmi/api-services/state';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar-info',
    templateUrl: './molecule-avatar-info.component.html',
})
export class MoleculeAvatarInfoComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() avatar: Avatar;
    @Input() editModalId: string;

    partyAvatarInfo$: Observable<PartyAvatarInfo>;
    partyAvatarInfoIsLoading$: Observable<boolean>;
    partyAvatarInfoHasLoadingError$: Observable<boolean>;

    constructor(actions$: Actions, store$: Store<rootReducer.State>) {
        super();

        this.patchInjectedItems({
            actions$,
            store$,
        });
    }

    ngOnInit() {
        if (this.avatar.partyType) {
            this.store$.dispatch(
                avatarInfoActions.getForId({ partyId: this.avatar.partyId })
            );

            this.partyAvatarInfo$ = this.store$.select(
                avatarInfoSelectors.getSingle,
                { id: this.avatar.partyId }
            );
            this.partyAvatarInfoIsLoading$ = this.store$.select(
                avatarInfoSelectors.getIsLoadingSingle,
                { id: this.avatar.partyId }
            );
            this.partyAvatarInfoHasLoadingError$ = this.store$.select(
                avatarInfoSelectors.getHasLoadingSingleError,
                { id: this.avatar.partyId }
            );
        }
    }
}
