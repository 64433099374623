import { Injectable } from '@angular/core';
import { DYNAMIC_FORM } from '../constants/dynamic-form.constants';
import { QuickCreateModalComponent } from '../features/reactive-forms/components/quick-create-modal/quick-create-modal.component';
import { FormSetup } from '../models/form-setup.model';
import { ModalManagementService } from './modal-management.service';

@Injectable()
export class DynamicFormsManagementService {
    constructor(private modalManagementService: ModalManagementService) {}

    openDynamicFormModal(formSetup: FormSetup): void {
        if (!formSetup.preserveActiveModal) {
            this.modalManagementService.closeActiveModal();
        }

        if (formSetup.isQuickCreate) {
            this.openQuickCreateModal(formSetup);
            return;
        }

        this.modalManagementService.openModalWithId(DYNAMIC_FORM, formSetup);
    }

    openQuickCreateModal(formSetup: FormSetup) {
        const quickCreateModal =
            this.modalManagementService.openModalWithComponent(
                QuickCreateModalComponent
            );
        quickCreateModal.componentInstance.formId = formSetup.formId;
        quickCreateModal.componentInstance.entityId = formSetup.entityId;
        quickCreateModal.componentInstance.initialisationParams =
            formSetup.initialisationParams;
        quickCreateModal.componentInstance.createSuccess.subscribe(() =>
            quickCreateModal.close()
        );
    }
}
