import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputLookupModule } from '../../../../components/molecules/molecule-input-lookup/molecule-input-lookup.module';
import { MoleculeReactiveInputLookupComponent } from './molecule-reactive-input-lookup.component';

@NgModule({
    imports: [CommonModule, MoleculeInputLookupModule],
    declarations: [MoleculeReactiveInputLookupComponent],
    exports: [MoleculeReactiveInputLookupComponent],
})
export class MoleculeReactiveInputLookupModule {}
