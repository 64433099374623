import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxRadioButtonGroupComponent } from './wdx-radio-button-group.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxRadioButtonGroupComponent],
    exports: [WdxRadioButtonGroupComponent],
})
export class WdxRadioButtonGroupModule {}
