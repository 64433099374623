import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    IFormPendingChange,
    IFormPendingChangeSubForm,
} from './pending-changes.component.interface';

@Component({
    selector: 'wdx-ff-pending-changes',
    templateUrl: './pending-changes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingChangesComponent {
    @Input() pendingChanges: IFormPendingChange[] = [];
    get pendingChangesSubForm(): IFormPendingChangeSubForm {
        return this._pendingChangesSubForm;
    }
    @Input() set pendingChangesSubForm(subFormData: IFormPendingChangeSubForm) {
        this.subFormCount = Object.keys(subFormData).length;
        this._pendingChangesSubForm = subFormData;
    }
    @Input() showButton: boolean;

    @Output() wdxOnClick = new EventEmitter();

    readonly ICON_TRIANGLE_EXCLAMATION = 'triangle-exclamation';
    readonly PENDING_CHANGES_MAPPING: { [k: string]: string } = {
        '=0': 'is no pending change',
        '=1': '1 change below that is pending validation',
        other: 'are # changes below that are pending validation',
    };
    readonly PENDING_CHANGES_SUB_FORM: { [k: string]: string } = {
        '=0': 'no',
        '=1': 'record being',
        other: 'records being',
    };
    subFormCount = 0;
    _pendingChangesSubForm: IFormPendingChangeSubForm;

    onClick(): void {
        this.wdxOnClick.emit();
    }
}
