import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON_ADD } from '../../../../../constants/icons.constants';
import { LayoutAndDefinitionEntry } from '../../../../../models/reactive-dynamic.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'reactive-sub-form-header',
    templateUrl: './sub-form-header.component.html',
})
export class ReactiveSubFormHeaderComponent {
    @Input() amount: number;
    @Input() set elementDefinition(value: LayoutAndDefinitionEntry) {
        this.definition = value;
        if (value?.min) {
            this.validationErrorMessage = value.max
                ? `Requires ${value.min} - ${value.max} entries`
                : `Requires atleast ${value.min} entries`;
        }
    }
    @Input() disabled: boolean;
    @Input() showValidation = false;

    @Output() addToArray = new EventEmitter();

    public validationErrorMessage = '';
    public definition: LayoutAndDefinitionEntry;

    readonly addIcon = ICON_ADD.icon;

    onAddToArrayClicked(): void {
        this.addToArray.emit();
    }
}
