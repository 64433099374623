import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as partiesActions from '../../../../../state/parties/parties.actions';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { FileIndex } from '@wdx/clmi/api-models';
import { FileUploadPackage } from '../../../../../models/file-upload-package.model';
import { FileUploadStatus } from '../../../../../models/file-upload-status.model';
import { UploaderType } from '../../../../../models/uploader-type.model';
import * as fileUploadActions from '../../../../../state/avatar-file-upload/avatar-file-upload.actions';
import * as fileUploadSelectors from '../../../../../state/avatar-file-upload/avatar-file-upload.selectors';
import * as rootReducer from '../../../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-contact-avatar-upload',
    templateUrl: './molecule-input-contact-avatar-upload.component.html',
})
export class MoleculeInputContactAvatarUploadComponent
    extends BaseInputClass
    implements OnInit
{
    @Input() uploadType: UploaderType;
    @Input() partyId: string;

    fileUploadStatus$: Observable<FileUploadStatus>;
    fileUploadProgress$: Observable<number>;
    fileIndex$: Observable<FileIndex>;
    fileUrl$: Observable<string>;

    valueFileIndex: FileIndex;

    constructor(
        actions$: Actions,
        elementRef: ElementRef,
        store$: Store<rootReducer.State>
    ) {
        super();

        this.patchInjectedItems({
            actions$,
            elementRef,
            store$,
        });
    }

    ngOnInit() {
        this.listenTo(fileUploadActions.uploadFileComplete).subscribe(() => {
            // reload avatar image
            this.store$.dispatch(
                partiesActions.getForId({ partyId: this.partyId })
            );
        });
    }

    onFileAdded(fileUploadPackage: FileUploadPackage): void {
        if (!this.partyId) {
            return;
        }

        this.store$.dispatch(
            fileUploadActions.uploadFile({
                id: fileUploadPackage.id,
                partyId: this.partyId,
                file: fileUploadPackage.file,
            })
        );

        this.fileUploadStatus$ = this.store$.select(
            fileUploadSelectors.getFileUploadStatus,
            { id: fileUploadPackage.id }
        );
        this.fileUploadProgress$ = this.store$.select(
            fileUploadSelectors.getFileUploadProgress,
            { id: fileUploadPackage.id }
        );
        this.fileIndex$ = this.store$.select(
            fileUploadSelectors.getFileUploadFileIndex,
            { id: fileUploadPackage.id }
        );
    }

    onRemoveFile(fileUploadPackage: FileUploadPackage) {
        this.onClear();

        if (fileUploadPackage) {
            this.store$.dispatch(
                fileUploadActions.uploadFileReset({ id: fileUploadPackage.id })
            );
        }
    }
}
