import { Directive, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    FormElementType,
    FormFieldType,
    PendingChangeStatusType,
    SelectApiSource,
    RandomStringPipe,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { PENDING_CHANGES_TEXT, PENDING_CHANGES_LOCK_FIELD } from '../constants';
import {
    ExtendedFieldDefinitionOption,
    ReactiveFormLayoutAndDefinition,
    ContextualDataContext,
} from '../models';
import { IFormDynamicData } from '../interfaces';

@Directive()
export class BaseReactiveFormControlClass extends WdxDestroyClass {
    _formElement?: ReactiveFormLayoutAndDefinition;

    get formElement(): ReactiveFormLayoutAndDefinition {
        return this._formElement as ReactiveFormLayoutAndDefinition;
    }

    @Input() set formElement(formElement: ReactiveFormLayoutAndDefinition) {
        this._formElement = formElement;

        this.formControl = this.controlContainer?.control?.get(
            formElement.name as string
        ) as AbstractControl;

        if (this.formElement.options) {
            this.options$.next(
                this.filterInActiveOptions(this.formElement.options)
            );
        }

        if (this.formElement.selectSource) {
            this.setOptionsForSelectSource();
        }

        if (formElement.pendingChange) {
            this.warningText = PENDING_CHANGES_TEXT;
        }
    }

    inputId = new RandomStringPipe().transform();

    options$ = new BehaviorSubject<ExtendedFieldDefinitionOption[]>([]);

    readonly FORM_ELEMENT_TYPE = FormElementType;
    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly PENDING_CHANGE_STATUS_TYPE = PendingChangeStatusType;
    readonly PENDING_CHANGES_LOCK_FIELD = PENDING_CHANGES_LOCK_FIELD;
    warningText: string | null = null;

    formControl?: AbstractControl;

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: IFormDynamicData
    ) {
        super();
    }

    setOptionsForSelectSource() {
        this.getOptionsForSelectSource$()
            .pipe(take(1))
            .subscribe((options) => this.options$.next(options));
    }

    getOptionsForContextSelectSource$(
        lookups: any[],
        context?: ContextualDataContext[]
    ) {
        return this.dynamicDataService
            .getContextSelectApiSourceOptions(
                this.formElement.selectSource as SelectApiSource,
                lookups,
                context
            )
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    getOptionsForSelectSource$(params = {}) {
        return this.dynamicDataService
            .getSelectApiSourceOptions(
                this.formElement.selectSource as SelectApiSource,
                params
            )
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    filterInActiveOptions(options: ExtendedFieldDefinitionOption[]) {
        return options.filter(
            (option) =>
                option['value'] === this.formControl?.value ||
                option.active !== false
        );
    }
}
