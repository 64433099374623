import { TemplateRef } from '@angular/core';
import { ListItem } from './list-item.model';

export interface DynamicTab {
    id: string;
    label: string;
    secondaryInfo?: ListItem[];
    template: TemplateRef<any>;
}

export enum FilterFieldName {
    Regarding = 'Regarding',
    InvolvedParties = 'InvolvedParties',
    Members = 'Members',
}
