<ng-container [formGroup]="controlContainer.control">
    <wdx-ff-input-control
        *ngIf="INPUT_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
    ></wdx-ff-input-control>

    <clmi-reactive-select
        *ngIf="SELECT_CONTROL_TYPES.includes(formElement.fieldType)"
        [formElement]="formElement"
    ></clmi-reactive-select>

    <wdx-ff-checkboxes-control
        *ngIf="CHECKBOX_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
    ></wdx-ff-checkboxes-control>

    <wdx-ff-radio-buttons-control
        *ngIf="RADIO_BUTTONS_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
    ></wdx-ff-radio-buttons-control>

    <wdx-ff-date-control
        *ngIf="DATE_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
    ></wdx-ff-date-control>

    <clmi-reactive-file-upload-control
        *ngIf="FILE_UPLOAD_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
    ></clmi-reactive-file-upload-control>

    <wdx-ff-currency-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Currency"
        [formElement]="formElement"
    ></wdx-ff-currency-control>

    <clmi-reactive-telephone-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.PhoneNumber"
        [formElement]="formElement"
    ></clmi-reactive-telephone-control>

    <clmi-reactive-rich-text-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.RichText"
        [formElement]="formElement"
    ></clmi-reactive-rich-text-control>

    <clmi-reactive-address-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Address"
        [formElement]="formElement"
    ></clmi-reactive-address-control>

    <clmi-reactive-hierarchical-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Hierarchical"
        [formElement]="formElement"
    ></clmi-reactive-hierarchical-control>

    <clmi-reactive-document-lookup-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.DocumentLookup"
        [formElement]="formElement"
    ></clmi-reactive-document-lookup-control>

    <wdx-ff-boolean-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Boolean"
        [formElement]="formElement"
    ></wdx-ff-boolean-control>

    <clmi-reactive-wrapped-control
        *ngIf="WRAPPED_CONTROL_TYPES.includes(formElement?.fieldType)"
        [formElement]="formElement"
        [formContext]="formContext"
    ></clmi-reactive-wrapped-control>
</ng-container>
