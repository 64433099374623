import { Injectable } from '@angular/core';
import { TranslationKey, Translations } from '@wdx/clmi/api-services/services';
import { LookupSourceType, SystemEntity } from '@wdx/clmi/api-models';
import {
    ENTITY_TRANSLATION_KEY_MAP,
    ENTITY_TRANSLATION_KEY_PLURAL_MAP,
} from '../translations.static';

@Injectable({
    providedIn: 'root',
})
export class TranslationsService {
    private translations!: Translations;

    /**
     * Sets the translation data from api (primarily from app guard)
     */
    set(translations: Translations) {
        this.translations = translations;
    }

    /**
     * Returns the entire translations object
     */
    get() {
        return this.translations;
    }

    /**
     * Returns a specific translation by key
     * @param key The translation key
     * @param replacerValue Replacer value if the key contains a pattern
     */
    getTranslationByKey(
        key: string | TranslationKey,
        replacerValue?: string
    ): string {
        const label = this.translations ? this.translations[key] : key;
        return replacerValue ? label.replace(/{value}/g, replacerValue) : label;
    }

    /**
     * Returns the tranlsation of a given entity type
     * @param entityType
     * @param plural
     */
    getTranslatedEntityType(
        entityType: SystemEntity | LookupSourceType,
        plural = false
    ) {
        const translationKey = plural
            ? ENTITY_TRANSLATION_KEY_PLURAL_MAP[entityType]
            : ENTITY_TRANSLATION_KEY_MAP[entityType];

        return translationKey
            ? this.getTranslationByKey(translationKey)
            : entityType;
    }
}
