import { Action, createReducer, on } from '@ngrx/store';
import { Dashboard } from '@wdx/clmi/api-models';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as dashboardsActions from './dashboards.actions';

export interface State {
    dashboards?: CrudState<Dashboard>;
}

export const initialState: State = {
    dashboards: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        dashboardsActions.getDashboards,
        (state): State => ({
            ...state,
            dashboards: {
                isLoadingList: true,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: props.dashboards,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsFailure,
        (state): State => ({
            ...state,
            dashboards: {
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        dashboardsActions.createDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.createDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: [props.dashboard, ...state.dashboards.list],
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.createDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
                hasUpdatingError: true,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list.map((dashboard) => {
                    if (dashboard.id === props.dashboard.id) {
                        return props.dashboard;
                    }
                    return dashboard;
                }),
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: false,
                hasUpdatingError: true,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list.filter(
                    (dashboard) => dashboard.id !== props.dashboardId
                ),
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards.list,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
