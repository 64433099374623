import { Location } from '@angular/common';
import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    CaseStatusType,
    CompletedStatusType,
    EntityPermissionType,
    NextAction,
    PartyStub,
    SlaStatusType,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';

import { Severity, URL_CASES } from '@wdx/shared/utils';
import { DEADLINE_ALERT_THRESHOLD_DAYS } from '../../../constants/case.constants';
import {
    CASEWORK_ACTIVE_SLA_STATUS_MAP,
    CASEWORK_COMPLETED_SLA_STATUS_MAP,
    CASEWORK_STATUS_CANCELLED,
} from '../../../constants/casework-status.constants';
import {
    CASE_COMPLETED_STATUS_ICON_MAP,
    COMPLETED_STATUS_SEVERITY_MAP,
} from '../../../constants/completed-status.constants';
import {
    CANCEL_MENU_ITEM,
    EXPORT_MENU_ITEM,
    OPEN_IN_NEW_TAB_MENU_ITEM,
    UNLINK_MENU_ITEM,
    VISIBILITY_MENU_ITEM,
} from '../../../constants/menu.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { Avatar } from '@wdx/clmi/utils/models';
import { CaseCardItem } from '../../../models/case-list-item.model';
import { DialogMode } from '../../../models/dialog.model';
import { MenuItem, MenuItemAction } from '../../../models/menu-item.model';
import { Privilege } from '../../../models/privilege.model';
import { DisplayDatePipe } from '../../../pipes/display-date.pipe';
import { EntityRouteOpenNewTabPipe } from '../../../pipes/entity-route-open-new-tab.pipe';
import { DialogService } from '../../../services/dialog.service';
import { ModalManagementService } from '../../../services/modal-management.service';
import { AccessVisibilityService } from '../../../shared/features/access-visibility/shared/services/access-visibility.service';
import { AssignToService } from '../../../shared/features/assign-to/services/assign-to.service';
import { ExportsFacadeService } from '../../../shared/features/exports/services/exports-facade.service';
import * as rootReducer from '../../../state/_setup/reducers';
import * as casesActions from '../../../state/cases/cases.actions';
import * as favouritesActions from '../../../state/favourites/favourites.actions';
import * as relatedRecordsActions from '../../../state/related-records/related-records.actions';
import { IconStyle } from '@wdx/shared/utils';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-case-card',
    templateUrl: './molecule-case-card.component.html',
})
export class MoleculeCaseCardComponent implements OnInit {
    @Input() caseCardItem: CaseCardItem;
    @Input() headerMode: boolean;

    @ViewChild('assignLookupContainer', { read: ViewContainerRef })
    assignLookupContainer: ViewContainerRef;

    readonly DEADLINE_ALERT_THRESHOLD_DAYS = DEADLINE_ALERT_THRESHOLD_DAYS;
    readonly SEVERITY = Severity;
    readonly CASE_STATUS_TYPE = CaseStatusType;
    readonly CASE_COMPLETED_STATUS_ICON_MAP = CASE_COMPLETED_STATUS_ICON_MAP;
    readonly ICON_STYLE = IconStyle;
    readonly COMPLETED_STATUS = CompletedStatusType;

    now = new Date();

    linkToActionButton: ActionButton;
    contextMenuActionButton: ActionButton;

    constructor(
        private store$: Store<rootReducer.State>,
        private dialogService: DialogService,
        private resolver: ComponentFactoryResolver,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private modalManagementService: ModalManagementService,
        private exportsFacadeService: ExportsFacadeService,
        private accessVisibilityService: AccessVisibilityService,
        private assignToService: AssignToService
    ) {}

    ngOnInit(): void {
        if (!this.headerMode) {
            this.linkToActionButton = {
                mode: ActionButtonMode.LinkedButton,
                isToggledOn: false,
                permission: EntityPermissionType.Edit,
            };

            this.contextMenuActionButton = {
                mode: ActionButtonMode.DropdownButtonMenu,
                operationsSetup: {
                    entityType: SystemEntity.Case,
                    entityId: this.caseCardItem?.id,
                },
                dropdownMenu: [
                    VISIBILITY_MENU_ITEM,
                    OPEN_IN_NEW_TAB_MENU_ITEM,
                    {
                        ...this.assignToService.getAssignToMenuItem(
                            SystemEntity.Case,
                            this.caseCardItem?.id
                        ),
                        permission: EntityPermissionType.Edit,
                    },
                    ...(this.caseCardItem?.linkToEntityType &&
                    this.caseCardItem.linkToEntityId
                        ? [UNLINK_MENU_ITEM]
                        : []),
                    {
                        ...CANCEL_MENU_ITEM,
                        permission: EntityPermissionType.Edit,
                    },
                    {
                        ...EXPORT_MENU_ITEM,
                        privilege: Privilege.ExportCase,
                        feature: FeatureFlag.Exports,
                    },
                ],
            };
        }
    }

    getStatusText(caseCardItem: CaseCardItem): string {
        if (caseCardItem.status === CaseStatusType.Cancelled) {
            return CASEWORK_STATUS_CANCELLED;
        }

        if (caseCardItem.status === CaseStatusType.Complete) {
            return CASEWORK_COMPLETED_SLA_STATUS_MAP[caseCardItem.slaStatus];
        }

        return CASEWORK_ACTIVE_SLA_STATUS_MAP[caseCardItem.slaStatus];
    }

    getStatusClass(caseCardItem: CaseCardItem, headerMode: boolean) {
        return {
            'divider-end divider--short': headerMode,
            'flex-grow-1': !headerMode,
            'text-body': caseCardItem.status !== CaseStatusType.Complete,
            'text-success':
                caseCardItem.status === CaseStatusType.Complete &&
                caseCardItem.slaStatus !== SlaStatusType.Breached,
            'text-danger':
                caseCardItem.status === CaseStatusType.Complete &&
                caseCardItem.slaStatus === SlaStatusType.Breached,
        };
    }

    getStatusDate(caseCardItem: CaseCardItem): string {
        if (
            caseCardItem.status === CaseStatusType.Complete ||
            caseCardItem.status === CaseStatusType.Cancelled
        ) {
            return new DisplayDatePipe().transform(caseCardItem.completedDate, {
                relative: true,
                todayAsString: true,
            });
        }
        return new DisplayDatePipe().transform(caseCardItem.dueDate, {
            relative: true,
            todayAsString: true,
        });
    }

    hasStatusDate(caseCardItem: CaseCardItem): boolean {
        return (
            caseCardItem.status === CaseStatusType.Complete ||
            caseCardItem.status === CaseStatusType.Cancelled ||
            caseCardItem.slaStatus !== SlaStatusType.None
        );
    }

    nextActionAsSeverity(nextAction: NextAction, now: Date): Severity {
        if (nextAction?.completedStatus) {
            return COMPLETED_STATUS_SEVERITY_MAP[nextAction?.completedStatus];
        }
        const nextActionDueDate = new Date(nextAction?.due);
        if (nextActionDueDate < now) {
            return Severity.Danger;
        }
        // calc 'soon' stutus - if next action due date is within 2 days
        const milliSecondsDiff = (nextActionDueDate as any) - (now as any);
        if (Math.floor(milliSecondsDiff / 1000 / 60 / 60 / 24) <= 2) {
            return Severity.Warning;
        }
        return Severity.Success;
    }

    assigneesAsAvatars(assignees: PartyStub[]): Avatar[] {
        return assignees?.map((assignee) => {
            return new PartyToAvatarPipe().transform(assignee);
        });
    }

    dueAsRelativeDate(dueDate: Date) {
        return new DisplayDatePipe().transform(dueDate, { relative: true });
    }

    caseCardItemAsRouterLink(caseCardItem: CaseCardItem) {
        return [...URL_CASES, caseCardItem.id, caseCardItem.nextAction?.id];
    }

    onContextMenuSelected(menuItem: MenuItem): void {
        switch (menuItem.value) {
            case MenuItemAction.OpenInNewTab:
                this.openInNewTabAction();
                break;

            case MenuItemAction.UnLink:
                this.unLinkAction();
                break;

            case MenuItemAction.Cancel:
                this.cancelCaseAction();
                break;

            case MenuItemAction.ExportDetail:
                this.exportsFacadeService.doExport({
                    queryType: SystemEntity.Case,
                    payload: {
                        expressions: [
                            {
                                field: 'Regarding',
                                operator: 'Equals',
                                contextual: true,
                                values: [
                                    {
                                        id: this.caseCardItem.id,
                                        lookupType: SystemEntity.Case,
                                    },
                                ],
                            },
                        ],
                        sortBy: null,
                        sortDirection: 'Ascending',
                    },
                });
                break;

            case MenuItemAction.Visibility:
                this.accessVisibilityService.open(
                    this.caseCardItem.id,
                    SystemEntity.Case
                );
                break;
        }
    }

    openInNewTabAction() {
        new EntityRouteOpenNewTabPipe().transform(
            SystemEntity.Case,
            this.router,
            this.route,
            this.location,
            this.caseCardItem.id
        );
    }

    linkToAction() {
        this.store$.dispatch(
            relatedRecordsActions.createLinkToCaseForEntity({
                entityType: this.caseCardItem.linkToEntityType,
                entityId: this.caseCardItem.linkToEntityId,
                caseId: this.caseCardItem.id,
            })
        );
    }

    unLinkAction() {
        this.store$.dispatch(
            relatedRecordsActions.deleteLinkToCaseForEntity({
                entityType: this.caseCardItem.linkToEntityType,
                entityId: this.caseCardItem.linkToEntityId,
                caseId: this.caseCardItem.id,
            })
        );
    }

    cancelCaseAction(): void {
        this.dialogService.open({
            dialogMode: DialogMode.CancelCase,
            confirmAction: casesActions.cancelCase({
                caseId: this.caseCardItem.id,
            }),
        });
    }

    onLinkToActionButtonClicked(): void {
        this.store$.dispatch(
            relatedRecordsActions.createLinkToCaseForEntity({
                entityType: this.caseCardItem.linkToEntityType,
                entityId: this.caseCardItem.linkToEntityId,
                caseId: this.caseCardItem.id,
            })
        );
    }

    onToggleFavourite(isFavourite: boolean) {
        this.store$.dispatch(
            favouritesActions.toggleFavourite({
                id: this.caseCardItem.id,
                entity: UserActionEntity.Case,
                newValue: isFavourite,
            })
        );
    }
}
