import { Component, Input } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { KeyValueObject } from '@wdx/shared/utils';
import { BaseComponentClass } from '../../../classes/base-component.class';
import { DYNAMIC_FORM } from '../../../constants/dynamic-form.constants';
import { FormInitialisationMode } from '../../../models/form-setup.model';
import { FeatureSvg } from '../../../models/svg.model';
import { ModalManagementService } from '../../../services/modal-management.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-dynamic-form-modal',
    templateUrl: './organism-dynamic-form-modal.component.html',
    styleUrls: ['./organism-dynamic-form-modal.component.scss'],
})
export class OrganismDynamicFormModalComponent extends BaseComponentClass {
    @Input() modalId: string;

    isLoading = true;
    formTitle: string;
    formTemplateId: string;
    formTemplateEntityId: string;
    formTemplateEntityType: SystemEntity;
    formId: string;
    entityId: string;
    appId: string;
    initialisationParams: KeyValueObject;
    initialisationMode: FormInitialisationMode;
    queryParams: KeyValueObject;
    emitInsteadOfPost: boolean;
    emitData: any;
    initialFormValue: KeyValueObject;

    readonly FEATURE_FLAG = FeatureFlag;
    readonly FEATURE_SVG = FeatureSvg;

    constructor(modalManagementService: ModalManagementService) {
        super();

        this.patchInjectedItems({ modalManagementService });

        this.modalManagementService.latestInstanceParams$.subscribe(
            (params) => {
                if (
                    params?.modalId === DYNAMIC_FORM &&
                    !params?.modalParams?.formId
                ) {
                    this.setError();
                    return;
                }
                if (
                    params?.modalParams?.formId &&
                    params?.modalId === this.modalId
                ) {
                    this.isLoading = true;
                    this.formTemplateId = params.modalParams.formTemplateId;
                    this.formTemplateEntityId =
                        params.modalParams.formTemplateEntityId;
                    this.formTemplateEntityType =
                        params.modalParams.formTemplateEntityType;
                    this.formId = params.modalParams.formId;
                    this.entityId = params.modalParams.entityId;
                    this.appId = params.modalParams.appId;
                    this.initialisationParams =
                        params.modalParams.initialisationParams;
                    this.queryParams = params.modalParams.queryParams;
                    this.emitInsteadOfPost =
                        params.modalParams.emitInsteadOfPost;
                    this.emitData = params.modalParams.emitData;
                    this.initialFormValue = params.modalParams.initialFormValue;
                    this.initialisationMode =
                        params.modalParams.initialisationMode;
                }
            }
        );
    }

    setError() {
        this.formTitle = 'Error';
        this.formId = null;
        this.entityId = null;
        this.isLoading = false;
    }

    onFormTitleReceived(formTitle: string): void {
        this.formTitle = formTitle;
        this.isLoading = false;
    }
}
