import { ActivityType } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { FormInitialisationMode } from '../models/form-setup.model';
import { DEFAULT_DEBOUNCE_DELAY } from './async.constants';

export const DEFAULT_INPUT_DEBOUNCE_DELAY = DEFAULT_DEBOUNCE_DELAY;

export const ACTIVITY_FILE_UNDER_FORM_ID = 'ActivityFileUnderForm';
export const ACTIVITY_NOTES_FORM_ID = 'ActivityNotesForm';
export const ADDRESS_CHANGE_FORM_ID = 'AddressChangeForm';
export const ADVISER_FORM_ID = 'AdviserForm';
export const ANSWER_FORM_ID = 'AnswerForm';
export const ARTICLE_EXTERNAL_FORM_ID = 'ArticleExternalForm';
export const ARTICLE_FILTER_FORM_ID = 'ArticleFilterForm';
export const ARTICLE_INTERNAL_FORM_ID = 'ArticleInternalForm';
export const ARTICLE_KNOWLEDGE_FORM_ID = 'ArticleKnowledgeForm';
export const BENCHMARK_FORM_ID = 'BenchmarkForm';
export const CASE_ACCOUNT_MAINTENANCE_FORM = 'CaseAccountMaintenanceForm';
export const CASE_ENQUIRY_FORM_ID = 'CaseEnquiryForm';
export const CASE_FILTER_FORM_ID = 'CaseFilterForm';
export const CASE_FORM_ID = 'CaseForm';
export const CASE_TEAM_MEMBER_FORM_ID = 'CaseTeamMemberForm';
export const CAMPAIGN_FORM_ID = 'CampaignForm';
export const CAMPAIGN_RESPONSE_FORM_ID = 'CampaignResponseForm';
export const CLIENT_ROLE_FORM_ID = 'ClientRoleForm';
export const COMPLAINT_FORM_ID = 'ComplaintForm';
export const DOCUMENT_TEMPLATE_FORM_ID = 'DocumentTemplateForm';
export const DOCUMENTS_FILTER_FORM_ID = 'FiledDocumentFilterForm';
export const DRIVERS_LICENCE_FORM_ID = 'DriversLicenseForm';
export const EMAIL_FORM_ID = 'EmailForm';
export const ENVELOPE_FORM_ID = 'EnvelopeForm';
export const ENVELOPE_RECIPIENT_FORM_ID = 'EnvelopeRecipientForm';
export const EXTERNAL_USER_FORM_ID = 'ExternalUserForm';
export const FEE_STRUCTURE_FORM_ID = 'FeeStructureForm';
export const FAMILY_FORM_ID = 'FamilyForm';
export const FORMPIPE_DOCUMENTS_FILTER_FORM_ID = 'FormPipeDocumentFilterForm';
export const GLIA_FORM_ID = 'GliaForm';
export const IDENTITY_CHECK_APPROVAL_FORM_ID = 'IdentityCheckApprovalForm';
export const INBOX_FILTER_FORM_ID = 'InboxFilterForm';
export const LEAD_FORM_ID = 'LeadForm';
export const LIST_FORM_ID = 'ListForm';
export const MAILING_INSTRUCTION_ID = 'MailingInstructionForm';
export const MANDATE_FORM_ID = 'MandateForm';
export const MANDATE_GROUP_FORM_ID = 'MandateGroupForm';
export const MANUAL_ADDRESS_FORM_ID = 'ManualAddressForm';
export const MEETING_FORM_ID = 'MeetingForm';
export const NOTE_FORM_ID = 'NoteForm';
export const OBJECTIVE_FORM_ID = 'ObjectiveForm';
export const ONBOARDING_FORM_ID = 'OnboardingForm';
export const OPPORTUNITY_FILTER_FORM_ID = 'OpportunityFilterForm';
export const OPPORTUNITY_FORM_ID = 'OpportunityForm';
export const OPPORTUNITY_TEAM_MEMBER_FORM_ID = 'OpportunityTeamMemberForm';
export const OPPORTUNITY_LOST_FORM_ID = 'OpportunityLostForm';
export const OPPORTUNITY_WON_FORM_ID = 'OpportunityWonForm';
export const ORGANISATION_FORM_ID = 'OrganisationForm';
export const ORGANISATION_LEAD_FORM_ID = 'OrganisationLeadForm';
export const ORGANISATION_CONNECTION_FORM_ID = 'OrganisationConnectionsForm';
export const INTERMEDIARY_FORM_ID = 'IntermediaryForm';
export const OTHER_DOCUMENT_FORM_ID = 'OtherDocumentForm';
export const DOCUMENT_FORM_ID = 'DocumentForm';
export const PARTY_TEAM_MEMBER_FORM_ID = 'PartyTeamMemberForm';
export const CLIENT_PRODUCT_TEAM_MEMBER_FORM_ID = 'ClientProductTeamMemberForm';
export const PASSPORT_FORM_ID = 'PassportForm';
export const PERSON_FORM_ID = 'PersonForm';
export const PHONECALL_FORM_ID = 'PhoneCallForm';
export const PRODUCT_FORM_ID = 'ProductForm';
export const PRODUCT_PROVIDER_FORM_ID = 'ProductProviderForm';
export const PROFESSIONAL_FORM_ID = 'ProfessionalConnectionsForm';
export const QUEUES_FILTER_FORM_ID = 'QueueItemFilterForm';
export const RISK_LEVELS_FORM_ID = 'RiskLevelForm';
export const TAG_FORM_ID = 'TagForm';
export const TASK_FILTER_FORM_ID = 'TaskFilterForm';
export const TASK_FORM_ID = 'TaskForm';
export const TEAM_FORM_ID = 'TeamForm';
export const TEAM_MEMBER_FORM_ID = 'TeamMemberForm';
export const SALUTATION_FORM_ID = 'SalutationForm';

export const USER_FORM_ID = 'UserForm';
export const VIEW_TILE_FORM_ID = 'ViewTileForm';
export const DOCUMENT_TYPE_FORM_ID = 'DocumentTypeForm';
export const DOCUMENT_REQUIREMENT_TYPE_FORM_ID = 'DocumentRequirementTypeForm';
export const UNBLU_FORM_ID = 'UnbluForm';
export const WORK_ITEM_FORM_ID = 'WorkItemForm';
export const SECURE_MESSAGE_FORM_ID = 'SecureMessageForm';
export const SMS_FORM_ID = 'SmsForm';
export const REGION_FORM_ID = 'RegionForm';

export const ACTIVITY_FORM_ID_MAP: KeyValueObject<string> = {
    [ActivityType.Appointment]: MEETING_FORM_ID,
    [ActivityType.Email]: EMAIL_FORM_ID,
    [ActivityType.Glia]: GLIA_FORM_ID,
    [ActivityType.IdentityCheckReview]: null,
    [ActivityType.Note]: NOTE_FORM_ID,
    [ActivityType.PhoneCall]: PHONECALL_FORM_ID,
    [ActivityType.Push]: null,
    [ActivityType.Qwil]: null,
    [ActivityType.SecureMessage]: SECURE_MESSAGE_FORM_ID,
    [ActivityType.Sms]: SMS_FORM_ID,
    [ActivityType.Task]: TASK_FORM_ID,
    [ActivityType.TimeBooking]: null,
    [ActivityType.Unblu]: UNBLU_FORM_ID,
    [ActivityType.Unknown]: null,
    [ActivityType.WorkItem]: null,
};

export const CASE_FORM_IDS = [
    CASE_ACCOUNT_MAINTENANCE_FORM,
    ADDRESS_CHANGE_FORM_ID,
    CASE_ENQUIRY_FORM_ID,
    ONBOARDING_FORM_ID,
    COMPLAINT_FORM_ID,
];

export const DOCUMENT_FORM_IDS = [
    DRIVERS_LICENCE_FORM_ID,
    OTHER_DOCUMENT_FORM_ID,
    PASSPORT_FORM_ID,
];

export const CONTACT_FORM_IDS = [
    ADVISER_FORM_ID,
    INTERMEDIARY_FORM_ID,
    LEAD_FORM_ID,
    ORGANISATION_FORM_ID,
    PERSON_FORM_ID,
    PRODUCT_PROVIDER_FORM_ID,
];

export const OPPOURTUNITY_FORM_IDS = [
    OPPORTUNITY_FORM_ID,
    OPPORTUNITY_WON_FORM_ID,
    OPPORTUNITY_LOST_FORM_ID,
];

export const INITIALISATION_MODE_MAP = {
    [FormInitialisationMode.Add]: 'inAddMode',
    [FormInitialisationMode.Delete]: 'inDeleteMode',
    [FormInitialisationMode.Edit]: 'inEditMode',
};

export const DYNAMIC_FORM = 'dynamic-form';
