import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Article, SystemEntity } from '@wdx/clmi/api-models';
import { WdxSize } from '@wdx/shared/utils';

import { Avatar } from '@wdx/clmi/utils/models';
import { Icon, KeyValueObject, Severity } from '@wdx/shared/utils';
import { IdList } from '../../../../models/id-list.model';
import { InfoCardData } from '../../../../models/info-card-data.model';
import { ListItem } from '../../../../models/list-item.model';
import { Modal } from '../../../../models/modal.model';
import { TagData } from '../../../../models/tag-data.model';
import { FormSetup } from './../../../../models/form-setup.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-info-card',
    templateUrl: './molecule-info-card.component.html',
})
export class MoleculeInfoCardComponent {
    @Input() primaryInfo: string;
    @Input() primaryInfoIcon: Icon | string;
    @Input() avatars: Avatar[];
    @Input() icon: Icon | string;
    @Input() iconClass: string;
    @Input() severity: Severity;
    @Input() size: WdxSize = 'md';
    @Input() avatarIconDivider: boolean;
    @Input() secondaryInfo: TagData[] | TagData;
    @Input() tertiaryInfo: ListItem[] | ListItem;
    @Input() idList: IdList;
    @Input() textColor: string;
    @Input() routerLinkData: string[];
    @Input() openInNewTab: boolean;
    @Input() entityType: SystemEntity;
    @Input() entityId: string;
    @Input() queryParamsData: KeyValueObject;
    @Input() subInfoCards: InfoCardData[];
    @Input() ignoreLinkClickedObservers = false;
    @Input() canDelete = false;
    @Input() canSelect = false;
    @Input() isSelected = false;
    @Input() modalData: Modal;
    @Input() article: Article;
    @Input() data: any;
    @Input() showEditForm: boolean;
    @Input() formSetup: FormSetup;
    @Input() compact = false;
    @Input() fillHorizontalSpace = true;
    @Input() linkWholeCard = false;
    @Input() cySelector: string;
    @Input() cyValue: string;

    @Output() linkClicked = new EventEmitter();
    @Output() subInfoCardLinkClicked = new EventEmitter<InfoCardData>();
    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() selected = new EventEmitter();
    @Output() deselected = new EventEmitter();

    onLinkClicked() {
        this.linkClicked.emit();
    }

    onSubInfoCardLinkClicked(subInfoCard: InfoCardData) {
        this.subInfoCardLinkClicked.emit(subInfoCard);
    }

    onEdit() {
        this.edit.emit();
    }

    onDelete() {
        this.delete.emit();
    }

    onSelected() {
        this.selected.emit();
    }

    onDeselected() {
        this.deselected.emit();
    }
}
