<span
    *ngIf="lifecycleStage"
    class="badge d-flex align-items-center"
    data-cy="lifecycle-stage-tag"
    [ngClass]="lifecycleStage | lifecycleStageToCSSClass"
>
    <wdx-icon *ngIf="icon" class="me-1" [scale]="1" [icon]="icon"></wdx-icon>
    <div data-cy="lifecycle-stage-status">
        {{ STAGES_TRANSLATION_KEY_MAP[lifecycleStage] | translate | titleCase }}
    </div>
</span>
