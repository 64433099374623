import { SystemEntity } from '@wdx/clmi/api-models';
import * as casesActions from '../../../../../../state/cases/cases.actions';
import * as casesSelectors from '../../../../../../state/cases/cases.selectors';
import * as clientProductsActions from '../../../../../../state/client-products/client-products.actions';
import * as clientProductsSelectors from '../../../../../../state/client-products/client-products.selectors';
import * as opportunitiesActions from '../../../../../../state/opportunities/opportunities.actions';
import * as opportunitiesSelectors from '../../../../../../state/opportunities/opportunities.selectors';
import * as partiesActions from '../../../../../../state/parties/parties.actions';
import * as partiesSelectors from '../../../../../../state/parties/parties.selectors';
import * as teamActions from '../../../../../../state/team/team.actions';
import * as teamSelectors from '../../../../../../state/team/team.selectors';

export const TEAM_MEMBERS_STATE = {
    [SystemEntity.ClientProduct]: {
        get: clientProductsSelectors.getMembersForId,
        loading: clientProductsSelectors.getMembersForIdIsLoadingList,
        error: clientProductsSelectors.getMembersForIdHasLoadingListError,
        getDispatch: clientProductsActions.getMembersForId,
        getDispatchKey: 'id',
        getInitialiseKey: 'clientProductId',
        deleteTeamMember: clientProductsActions.deleteTeamMember,
        deleteTeamMemberSuccess: clientProductsActions.deleteTeamMemberSuccess,
        deleteTypeKeyProp: 'memberId',
    },
    [SystemEntity.Party]: {
        get: partiesSelectors.getTeamMembersForParty,
        loading: partiesSelectors.getTeamMembersForPartyIsLoadingList,
        error: partiesSelectors.getTeamMembersForPartyHasLoadingListError,
        getDispatch: partiesActions.getTeamMembersForParty,
        getDispatchKey: 'partyId',
        deleteTeamMember: partiesActions.deleteTeamMemberForParty,
        deleteTeamMemberSuccess: partiesActions.deleteTeamMemberForPartySuccess,
        deleteTypeKeyProp: 'memberId',
    },
    [SystemEntity.PartyRole]: {
        get: partiesSelectors.getTeamMembersForPartyRole,
        loading: partiesSelectors.getTeamMembersForPartyRoleIsLoadingList,
        error: partiesSelectors.getTeamMembersForPartyRoleHasLoadingListError,
        getDispatch: partiesActions.getTeamMembersForPartyRole,
        getDispatchKey: 'partyRoleId',
        deleteTeamMember: partiesActions.deleteTeamMemberForPartyRole,
        deleteTeamMemberSuccess:
            partiesActions.deleteTeamMemberForPartyRoleSuccess,
        deleteTypeKeyProp: 'memberId',
    },
    [SystemEntity.Case]: {
        get: casesSelectors.getAssignedTeamMembersForCase,
        loading: partiesSelectors.getTeamMembersForPartyRoleIsLoadingList,
        error: partiesSelectors.getTeamMembersForPartyRoleHasLoadingListError,
        getDispatch: casesActions.getAllMembers,
        getDispatchKey: 'caseId',
        deleteTeamMember: casesActions.deleteTeamMember,
        deleteTeamMemberSuccess: casesActions.deleteTeamMemberSuccess,
        deleteTypeKeyProp: 'memberId',
    },
    [SystemEntity.Opportunity]: {
        get: opportunitiesSelectors.getAssignedTeamMembersForOpps,
        loading:
            opportunitiesSelectors.getAssignedTeamMembersForOppsIsLoadingPage,
        error: opportunitiesSelectors.getAssignedTeamMembersForOppsHasLoadingPageError,
        getDispatch: opportunitiesActions.getAllMembers,
        getDispatchKey: 'opportunityId',
        deleteTeamMember: opportunitiesActions.deleteTeamMember,
        deleteTeamMemberSuccess: opportunitiesActions.deleteTeamMemberSuccess,
        deleteTypeKeyProp: 'memberId',
    },
    [SystemEntity.Team]: {
        get: teamSelectors.getTeamMembersForId,
        loading: teamSelectors.getTeamMembersForIdIsLoading,
        error: teamSelectors.getTeamMembersForIdHasLoadingError,
        getDispatch: teamActions.getTeamMembersForId,
        getDispatchKey: 'teamId',
        deleteTeamMember: teamActions.deleteTeamMember,
        deleteTeamMemberSuccess: teamActions.deleteTeamMemberSuccess,
        deleteTeamMemberFailure: teamActions.deleteTeamMemberFailure,
        deleteTypeKeyProp: 'memberId',
    },
};
