<atom-async-data
    [ngClass]="{
        'px-5 d-block':
            partyAvatarInfoIsLoading || partyAvatarInfoHasLoadingError
    }"
    [isLoading]="partyAvatarInfoIsLoading"
    [hasError]="partyAvatarInfoHasLoadingError"
>
    <div *ngIf="avatar" class="card border-0">
        <div class="card-body d-flex align-items-center">
            <atom-avatar
                class="align-self-start"
                size="xl"
                [avatar]="avatar"
                [editModalId]="editModalId"
            ></atom-avatar>
            <div
                *ngIf="
                    avatar | fn : getAvatarInfo : partyAvatarInfo : location;
                    let avatarInfo
                "
                class="d-flex flex-column ms-4"
            >
                <h6>
                    <ng-container *ngIf="!avatarInfo?.routerLink">{{
                        avatarInfo.name
                    }}</ng-container>
                    <a
                        *ngIf="avatarInfo?.routerLink"
                        data-cy="a-avatar-name"
                        [routerLink]="avatarInfo?.routerLink"
                        [queryParams]="avatarInfo?.queryParams"
                        >{{ avatarInfo.name }}</a
                    >
                </h6>
                <div *ngIf="avatarInfo?.info" data-cy="lbl-avatar-job-title">
                    {{ avatarInfo.info | titleCase }}
                </div>
                <ng-container *ngFor="let phone of avatarInfo?.phones">
                    <a
                        [attr.data-cy]="'avartarPhone-' + phone"
                        [attr.href]="'tel:' + phone"
                    >
                        {{ phone }}
                    </a>
                </ng-container>
                <ng-container *ngFor="let email of avatarInfo?.emails">
                    <a
                        data-cy="lbl-avatar-email"
                        [attr.href]="'mailto:' + email"
                    >
                        {{ email }}
                    </a>
                </ng-container>

                <div *ngIf="avatar.isUnknown" class="mt-2">
                    <button class="btn" dynamicFormId="PersonForm">
                        Add to CLMi
                    </button>
                </div>
            </div>
        </div>
    </div>
</atom-async-data>
