import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PartyAvatarInfo } from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class AvatarInfoService extends BaseApiService {
    getForId(partyId: string): Observable<PartyAvatarInfo> {
        return this.get<PartyAvatarInfo>(`party/${partyId}/avatarinfo`);
    }
}
