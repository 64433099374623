<ng-content></ng-content>

<label
    class="btn btn-outline-primary"
    [class.m-1]="!group"
    [class.d-flex]="group"
    [class.align-items-center]="group"
    [for]="idFor"
>
    <wdx-icon
        *ngIf="icon"
        class="icon-container d-flex justify-content-center align-items-center rounded-circle mr-2 icon-container-size-md icon-no-container"
        [icon]="icon"
    ></wdx-icon>
    <span>{{ label }}</span>
</label>
