<molecule-context-menu-dropdown-dumb
    [menu]="menu"
    [menuTitle]="menuTitle"
    [buttonStyle]="buttonStyle"
    [placement]="placement"
    [btnClass]="btnClass"
    [altText]="altText"
    [isMulti]="isMulti"
    [autoClose]="autoClose"
    [hasSearch]="hasSearch"
    [highlightSelected]="highlightSelected"
    [size]="size"
    [parentContainer]="parentContainer"
    [hideDropdownChevron]="hideDropdownChevron"
    [selectedMenuItems]="selectedMenuItems"
    [disabled]="disabled"
    [isLoading]="isLoading"
    [cySelector]="cySelector"
    (dropdownToggled)="onDropdownToggled($event)"
    (singleItemSelected)="onSingleItemSelected($event)"
    (multipleItemsSelected)="onMultipleItemsSelected($event)"
>
    <ng-content></ng-content>
</molecule-context-menu-dropdown-dumb>
