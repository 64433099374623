import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as caseTypesActions from './case-types.actions';
import { CaseTypesService } from './case-types.service';

@Injectable()
export class CaseTypesEffects {
    private actions$ = inject(Actions);
    private caseTypesService = inject(CaseTypesService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(caseTypesActions.getAll),
            switchMap((action) =>
                this.caseTypesService.getAll(action.isActive).pipe(
                    map((caseTypes) =>
                        caseTypesActions.getAllSuccess({ caseTypes })
                    ),
                    catchError((error) =>
                        of(caseTypesActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );
}
