import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PostalAddress, PostalAddressLookup } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class AddressesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAddresses(searchText: string): Observable<PostalAddressLookup[]> {
        return this.http.get<PostalAddressLookup[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/postaladdress/lookup?searchText=${searchText}`
        );
    }

    getAddress(addressId: string): Observable<PostalAddress> {
        return this.http.get<PostalAddress>(
            `${
                this.config.getConfiguration().API_BASE
            }/postaladdress/lookup/${addressId}`
        );
    }
}
