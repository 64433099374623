import { Injectable } from '@angular/core';
import { Config } from '../models/config.model';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

@Injectable({
    providedIn: 'root',
})
export class SentryService {
    init(config: Config) {
        Sentry.init({
            dsn: config?.Sentry?.SentryAPI,
            integrations: [
                new BrowserTracing({
                    // Registers and configures the Tracing integration,
                    // which automatically instruments your application to monitor its
                    // performance, including custom Angular routing instrumentation
                    tracingOrigins: [
                        config.API_BASE,
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...config?.Sentry?.SentryTracingOrigin,
                    ],
                    routingInstrumentation: Sentry.routingInstrumentation,
                }),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: parseFloat(config?.Sentry?.SentryTraceSampleRace),
        });
    }
}
