import { Pipe, PipeTransform } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';
import { TranslationsService } from '../../services';

@Pipe({
    name: 'translateEntity',
})
export class TranslateEntityPipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: SystemEntity, plural = false): string {
        if (!value) {
            return '';
        }
        return this.translationsService.getTranslatedEntityType(value, plural);
    }
}
