import { Action, createReducer, on } from '@ngrx/store';
import { Notification } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as notificationsActions from './notifications.actions';

export interface State {
    notifications?: CrudStateObject<Notification>;
    unreadNotifications?: number;
}

export const initialState: State = {
    notifications: {},
    unreadNotifications: 0,
};

const reducerSetup = createReducer(
    initialState,

    on(
        notificationsActions.getPage,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        notificationsActions.getPageSuccess,
        (state, props): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.notifications.paging,
                        combinedList: [
                            ...(props.reset
                                ? []
                                : state.notifications[GLOBAL_STATE_INDEX_ID]
                                      .infinity?.combinedList || []),
                            ...props.notifications.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        notificationsActions.getPageFailure,
        (state): State => ({
            ...state,
            notifications: {
                ...state.notifications,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.notifications[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Notification>)),
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        notificationsActions.setUnreadNotifications,
        (state, props): State => ({
            ...state,
            unreadNotifications: props.unreadNotifications,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
