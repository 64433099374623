import { ProductStatus, SystemEntity } from '@wdx/clmi/api-models';
import { TranslationKey } from '@wdx/clmi/api-services/services';
import { KeyValueObject, WdxThemeColor } from '@wdx/shared/utils';
import { TableHeader } from '../../../models/table-header.model';

export const CLIENT_PRODUCTS_LISTING_ROUTE_DATA = {
    viewType: SystemEntity.ClientProduct,
    paginationOptions: { pageSize: 40 },
};

export const CLIENT_PRODUCTS_LISTING_HEADERS: TableHeader[] = [
    {
        value: 'Product Name',
    },
    {
        value: 'Client Name',
    },
    {
        value: 'Code',
    },
    {
        value: 'Product Type',
    },
    {
        value: 'Status',
    },
];

export const PRODUCT_STATUS_THEME_COLOR_MAP: KeyValueObject<WdxThemeColor> = {
    [ProductStatus.Active]: 'success',
    [ProductStatus.Blocked]: 'danger',
    [ProductStatus.Cancelled]: 'none',
    [ProductStatus.Closing]: 'warning',
    [ProductStatus.Draft]: 'info',
    [ProductStatus.Inactive]: 'none',
    [ProductStatus.Proposed]: 'info',
};

export const PRODUCT_STATUS_TRANSLATION_KEY_MAP: KeyValueObject<any> = {
    [ProductStatus.Active]: TranslationKey.EnumPrductsStatusActive,
    [ProductStatus.Blocked]: TranslationKey.EnumPrductsStatusBlocked,
    [ProductStatus.Cancelled]: TranslationKey.EnumPrductsStatusCancelled,
    [ProductStatus.Closing]: TranslationKey.EnumPrductsStatusClosing,
    [ProductStatus.Draft]: TranslationKey.EnumPrductsStatusDraft,
    [ProductStatus.Inactive]: TranslationKey.EnumPrductsStatusInactive,
    [ProductStatus.Proposed]: TranslationKey.EnumPrductsStatusProposed,
};
