import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';

import { FormPipesModule } from '../../../../../features/reactive-forms/pipes/form-pipes.module';

import { PushModule } from '@ngrx/component';
import { ReactiveSelectControlComponent } from './reactive-select-control.component';

@NgModule({
    declarations: [ReactiveSelectControlComponent],
    imports: [
        CommonModule,
        FormPipesModule,
        NgSelectModule,
        PushModule,
        ReactiveFormsModule,
        WdxFormFieldModule,
    ],
    exports: [ReactiveSelectControlComponent],
})
export class ReactiveSelectControlModule {}
