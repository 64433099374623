import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AssignToService } from '../../../shared/features/assign-to/services/assign-to.service';

import { SystemEntity } from '@wdx/clmi/api-models';
import { Subject } from 'rxjs';

import { InfoCardData } from '../../../models/info-card-data.model';
import { FeatureSvg } from '../../../models/svg.model';
import {
    TeamMembersService,
    TeamMembersTransformService,
} from './shared/services';

@Component({
    selector: 'clmi-team-members',
    templateUrl: './team-members.component.html',
    providers: [TeamMembersService, TeamMembersTransformService],
})
export class TeamMembersComponent implements OnInit, OnDestroy, AfterViewInit {
    data: any;
    teams: any[];
    requiredData$ = new Subject();
    hasCompletedOnInit: boolean;
    _id: string;

    get getTmService() {
        return this.tmService;
    }

    get id() {
        return this._id;
    }
    @Input() set id(val) {
        this._id = val;
        if (this.hasCompletedOnInit) {
            this.tmService.destroyed$.next(true);
            this.tmService.entityId = val;
            this.tmService.getTeamMembers();
        }
    }
    @Input() systemEntity: SystemEntity;
    @Input() addTeamMemberForm: string;
    @Input() readonlyView: boolean;
    @Input() isAccordion = false;

    @Output() assignSuccess = new EventEmitter();

    @ViewChild('dialogPrimaryMemberTeam')
    public dialogPrimaryMemberTeam: TemplateRef<Element>;

    readonly NO_DATA_SVG = FeatureSvg.NoData;

    constructor(
        private tmService: TeamMembersService,
        private assignToService: AssignToService
    ) {}

    ngOnInit(): void {
        this.hasCompletedOnInit = true;
        this.tmService.entityId = this.id;
        this.tmService.systemEntity = this.systemEntity;
        this.tmService.teamMemberForm = this.addTeamMemberForm;
        this.tmService.getTeamMembers();
        this.assignToService
            .onAssignToSuccess$()
            .pipe(takeUntil(this.tmService.destroyed$))
            .subscribe((data) => {
                this.assignSuccess.emit(data);
                this.tmService.dispatchTeamMembers();
            });
    }

    ngAfterViewInit(): void {
        this.tmService.dialogPrimaryMemberTeamTemplate =
            this.dialogPrimaryMemberTeam;
    }

    ngOnDestroy(): void {
        this.tmService.destroyed$.next(true);
        this.tmService.destroyed$.complete();
    }

    openLookupAssignTo(): void {
        this.assignToService.openAssignToModal(this.systemEntity, this.id);
    }

    trackByPartyTeamMemberId(
        _: number,
        partyTeamMember: { infoCard: InfoCardData }
    ): string {
        return partyTeamMember?.infoCard?.id;
    }
}
