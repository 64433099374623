export type ApiStringEnumValue = string;

export interface Action {
    recalculate?: string[];
    functions?: ApiFunction[];
    openUrls?: string[];
    updateFields?: UpdateField[];
    updateLabels?: UpdateLabel[];
    updateSections?: UpdateSection[];
    updateButtons?: UpdateButton[];
    showMessage?: ConditionalMessage[];
    hideMessage?: ConditionalMessage[];
}

export interface ApiFunction {
    name?: string;
}

export interface DocumentType {
    id?: string;
    status?: DocumentTypeStatusType;
    isActive?: boolean;
    code?: string;
    name?: string;
    categoryCode?: string;
    guidance?: string;
    readonly externalCode?: string;
    primaryIdentity?: string;
    isSignatureRequired?: boolean;
    formatsAccepted?: string[];
    entityTypes?: SystemEntity[];
}

export interface UpdateField {
    name?: string;
    isHidden?: boolean;
    label?: string;
    isRequired?: boolean;
    min?: number;
    max?: number;
    isDisabled?: boolean;
    options?: string[];
    guidanceText?: string;
    value?: any;
    lookupSource?: LookupSourceType;
}

export interface UpdateLabel {
    name?: string;
    isHidden?: boolean;
    label?: string;
}

export interface UpdateSection {
    name?: string;
    isHidden?: boolean;
    label?: string;
    guidanceText?: string;
}

export interface UpdateButton {
    name?: string;
    isHidden?: boolean;
    label?: string;
    isDisabled?: boolean;
}

export interface ConditionalMessage {
    name?: string;
}

export interface FormCompletionSummary {
    expected?: number;
    completed?: number;
    percentage?: number;
}

export interface FormDataResult {
    entityId?: string;
    entityType?: SystemEntity;
    formVersion?: number;
    status?: FormDataStatus;
    data?: any;
    lastPublishedData?: any;
    lock?: FormLock;
    context?: FormContext;
    lastUpdatedOn?: Date;
    lastUpdatedBy?: string;
    publishedOn?: Date;
    publishedBy?: string;
    completionSummary?: FormCompletionSummary;
    pendingChanges?: FormPendingChange[];
}

export interface FormLock {
    entityId?: string;
    entityType?: SystemEntity;
    source?: SourceSystem;
    identity?: string;
    dataLockLevel?: DataLockLevel;
    displayText?: LocalisedString;
    isOverridable?: boolean;
    fields?: string[];
}

export interface SourceSystem {
    name?: string;
    code?: string;
}

export interface LocalisedString {
    key?: string;
    value?: string;
    parameters?: { [key: string]: any };
}

export interface FormPendingChange {
    type?: DataChangeType;
    status?: PendingChangeStatusType;
    field?: string;
    originalValue?: string;
    originalDisplayValue?: string;
    targetValue?: string;
    targetDisplayValue?: string;
}

export interface FormContext {
    appType?: AppType;
    appStatus?: AppStatusType;
    party?: Party;
    partyRole?: PartyRole;
    client?: Client;
    clientProduct?: ClientProduct;
    productGroup?: ProductGroup;
    process?: Process;
    case?: Case;
    userRoles?: string[];
    userPrivileges?: string[];
    dateStarted?: Date;
    dateFinished?: Date;
    dateLastUpdated?: Date;
    dateNextReviewDue?: Date;
    lastUpdatedBy?: PartyStub;
}

export interface FormDefinition {
    name?: string;
    description?: string;
    version?: number;
    saveMode?: FormSaveMode;
    readonly saveModes?: string[];
    app?: AppType;
    entityType?: SystemEntity;
    canDelete?: boolean;
    duplicateDetection?: boolean;
    standardEndpoint?: boolean;
    endpointPath?: string;
    schema?: FieldDefinition[];
    layout?: FormLayout;
    layoutHash?: string;
    conditions?: FormCondition;
    conditionsHash?: string;
}

export interface FieldDefinition {
    name?: string;
    fieldType?: FormFieldType;
    isRequired?: boolean;
    options?: FieldDefinitionOption[];
    childSchema?: FieldDefinition[];
    selectType?: string;
    selectSource?: SelectApiSource;
    lookupSources?: LookupSourceDefinition[];
    placeholder?: string;
    inputMaskRegEx?: string;
    inputMaskDescription?: string;
    min?: number;
    max?: number;
    dateRestrictionType?: DateRestrictionType;
    isSensitive?: boolean;
    defaultValue?: any;
    tagCategory?: TagCategory;
    previousTags?: string[];
    lookupCreationMode?: LookupCreationMode;
    lookupResultsMode?: LookupResultsMode;
    lookupContextMode?: LookupContextMode;
    childFormType?: string;
    documentType?: DocumentType;
    documentCategory?: string;
    formatsAccepted?: string;
    isPrimaryName?: boolean;
    isSortable?: boolean;
    summaryLevel?: SummaryLevel;
    summaryIcon?: string;
    quickCreateFormName?: string;
    minimumSearchCharacters?: number;
    contextualValues?: string[];
    isObfuscated?: boolean;
}

export interface LookupSourceDefinition {
    type?: LookupSourceType;
    quickCreateFormName?: string;
}

export interface FormLayout {
    name?: string;
    label?: string;
    quickCreateEnabled?: boolean;
    sectionLayoutDefinitions?: FormSectionLayoutDefinition[];
    messageDefinitions?: FormMessageDefinition[];
}

export interface FormSectionLayoutDefinition {
    name?: string;
    label?: string;
    guidanceText?: string;
    isHidden?: boolean;
    elementLayoutDefinitions?: FormElementLayoutDefinition[];
}

export interface FormElementLayoutDefinition {
    name?: string;
    label?: string;
    elementType?: FormElementType;
    elementStyle?: FormElementStyle;
    sectionLayoutDefinitions?: FormSectionLayoutDefinition[];
    guidanceText?: string;
    isHidden?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    min?: number;
    max?: number;
    placeholder?: string;
    lines?: number;
    forcesNewRow?: boolean;
    forcesFullWidth?: boolean;
    isLockable?: boolean;
    prefix?: string;
    suffix?: string;
}

export interface FormTemplateInstance {
    entityType?: SystemEntity;
    formName?: string;
    formVersion?: number;
    data?: any;
}

export interface FormCondition {
    triggers?: Trigger[];
    calculations?: CalculatedField[];
    initialisation?: Action;
    initialisationByMode?: Initialisation;
}

export interface FormMessageDefinition {
    name?: string;
    label?: string;
}

export interface Initialisation {
    inAddMode?: Action;
    inEditMode?: Action;
    inDeleteMode?: Action;
}

export interface CalculatedField {
    field?: string;
    equalTo?: Calculation;
}

export interface Calculation {
    sum?: Sum;
    subtract?: Subtract;
    function?: string;
    decision?: string;
}

export interface Sum {
    of?: string[];
}

export interface Subtract {
    left?: string;
    right?: string;
}

export interface Trigger {
    field?: string;
    button?: string;
    link?: string;
    is?: Is[];
    changed?: Action;
    clicked?: Action;
}

export interface Is {
    equalTo?: any;
    anyOf?: string[];
    future?: boolean;
    past?: boolean;
    lesserThan?: number;
    greaterThan?: number;
    then?: Action;
    else?: Action;
    empty?: boolean;
    notEmpty?: boolean;
}

export interface FormFunctionResult {
    dataChanges?: { [key: string]: any };
    schemaChanges?: { [key: string]: FieldDefinition };
}

export interface FormTemplate {
    code?: string;
    name?: string;
    description?: string;
    formName?: string;
    formVersion?: number;
}

export interface Party {
    id?: string;
    name?: string;
}

export interface PartyRole {
    id?: string;
    name?: string;
}

export interface Client {
    id?: string;
    code?: string;
    name?: string;
}

export interface PartyStub {
    id?: string;
    name?: string;
    partyType?: PartyType;
}

export interface ClientProduct {
    id?: string;
    name?: string;
}

export interface ProductGroup {
    id?: string;
    name?: string;
    code?: string;
}

export interface Process {
    id?: string;
    subject: string;
    dateStarted: Date;
}

export interface Case {
    id?: string;
    subject: string;
    dateStarted: Date;
}

export interface MenuItem {
    icon?: string;
    label?: LocalisedString;
    url?: string;
}

export interface FieldDefinitionOption {
    value?: any;
    label?: string;
    active?: boolean;
    icon?: string;
    iconStack?: OptionComplexIcon[];
}

export interface OptionComplexIcon {
    icon?: string;
    style?: string;
    ratio?: number;
    tagColor?: number;
    textColor?: string;
    rotate?: number;
    offsetTop?: number;
    offsetLeft?: number;
    scale?: number;
}

export interface Currency {
    isoCode: string;
    name?: string;
    isActive?: boolean;
    symbol?: string;
    region?: CurrencyRegion;
}

export interface Locale {
    englishName?: string;
    nativeName?: string;
    name?: string;
    lcid?: number;
    dateTimeFormat?: DateTimeFormat;
    numberFormat?: NumberFormat;
    twoLetterIsoRegionName?: string;
    threeLetterIsoLanguageName?: string;
    defaultDiallingPrefix?: string;
    currency?: Currency;
}

export interface DateTimeFormat {
    fullDateTimePattern?: string;
    longDatePattern?: string;
    longTimePattern?: string;
    shortDatePattern?: string;
    shortTimePattern?: string;
}

export interface NumberFormat {
    numberDecimalSeparator?: string;
    numberGroupSeparator?: string;
    currencySymbol?: string;
}

export interface Currency {
    isoCode: string;
    name?: string;
    isActive?: boolean;
    symbol?: string;
    region?: CurrencyRegion;
}

export interface Country {
    name?: string;
    localisedName?: LocalisedString;
    isoCode: string;
    isActive?: boolean;
    currency?: Currency;
    dialCode?: string;
    isEea?: boolean;
    isUsa?: boolean;
    phoneRegex?: string;
    mobileRegex?: string;
}

// *** Enums ***

export enum CurrencyRegion {
    TheAmericas = 'TheAmericas',
    AsiaAndThePacificRegion = 'AsiaAndThePacificRegion',
    Crptocurrency = 'Crptocurrency',
    Europe = 'Europe',
    MiddleEastAndAfrica = 'MiddleEastAndAfrica',
}

export enum PartyType {
    Unknown = 'Unknown',
    Person = 'Person',
    Organisation = 'Organisation',
    User = 'User',
    Team = 'Team',
    Construct = 'Construct',
}

export enum FormSaveMode {
    ReadOnly = 'ReadOnly',
    Save = 'Save',
    Draft = 'Draft',
    Complete = 'Complete',
}

export enum FormDataStatus {
    Draft = 'Draft',
    Published = 'Published',
    HistoricDraft = 'HistoricDraft',
    HistoricPublished = 'HistoricPublished',
    Confirmed = 'Confirmed',
}

export enum FormElementType {
    Field = 'Field',
    Label = 'Label',
    Button = 'Button',
    Link = 'Link',
    Array = 'Array',
}

export enum FormFieldType {
    Text = 'Text',
    MultilineText = 'MultilineText',
    RichText = 'RichText',
    DateTime = 'DateTime',
    Date = 'Date',
    Time = 'Time',
    Duration = 'Duration',
    DateRange = 'DateRange',
    DateTimeRange = 'DateTimeRange',
    Select = 'Select',
    Multiselect = 'Multiselect',
    Checkboxes = 'Checkboxes',
    Radios = 'Radios',
    RadioTiles = 'RadioTiles',
    CheckTiles = 'CheckTiles',
    ButtonGroup = 'ButtonGroup',
    TextDropdown = 'TextDropdown',
    Boolean = 'Boolean',
    Integer = 'Integer',
    Decimal = 'Decimal',
    Currency = 'Currency',
    LookupSingle = 'LookupSingle',
    LookupMulti = 'LookupMulti',
    DocumentLookup = 'DocumentLookup',
    ContactPointLookup = 'ContactPointLookup',
    UrlLookup = 'UrlLookup',
    Hierarchical = 'Hierarchical',
    ImageUpload = 'ImageUpload',
    DocumentUpload = 'DocumentUpload',
    Array = 'Array',
    Address = 'Address',
    PhoneNumber = 'PhoneNumber',
    EmailAddress = 'EmailAddress',
    TagListSingle = 'TagListSingle',
    TagListMulti = 'TagListMulti',
    Password = 'Password',
    Identifier = 'Identifier',
}

export enum PendingChangeStatusType {
    Pending = 'Pending',
    Submitted = 'Submitted',
    Accepted = 'Accepted',
    Applied = 'Applied',
    Rejected = 'Rejected',
    Superceded = 'Superceded',
}

export enum ActivityType {
    Unknown = 'Unknown',
    PhoneCall = 'PhoneCall',
    Email = 'Email',
    Appointment = 'Appointment',
    Task = 'Task',
    WorkItem = 'WorkItem',
    Note = 'Note',
    TimeBooking = 'TimeBooking',
    Glia = 'Glia',
    Qwil = 'Qwil',
    Sms = 'Sms',
    Push = 'Push',
    SecureMessage = 'SecureMessage',
    Unblu = 'Unblu',
    IdentityCheckReview = 'IdentityCheckReview',
}

export enum DataLockLevel {
    None = 'None',
    Full = 'Full',
    Partial = 'Partial',
}

export enum SystemEntity {
    Organisation = 'Organisation',
    Person = 'Person',
    User = 'User',
    Team = 'Team',
    Appointment = 'Appointment',
    Task = 'Task',
    ActivityParty = 'ActivityParty',
    ElectronicAddress = 'ElectronicAddress',
    PhoneAddress = 'PhoneAddress',
    PostalAddress = 'PostalAddress',
    PartyRole = 'PartyRole',
    PartyRoleAccess = 'PartyRoleAccess',
    Relationship = 'Relationship',
    Email = 'Email',
    PhoneCall = 'PhoneCall',
    WebHook = 'WebHook',
    Construct = 'Construct',
    Process = 'Process',
    WorkItem = 'WorkItem',
    SocialAddress = 'SocialAddress',
    Product = 'Product',
    ClientProductRelationship = 'ClientProductRelationship',
    ClientProduct = 'ClientProduct',
    ProductGroup = 'ProductGroup',
    Mandate = 'Mandate',
    Objective = 'Objective',
    Tag = 'Tag',
    Note = 'Note',
    Channel = 'Channel',
    DocumentAssociation = 'DocumentAssociation',
    Client = 'Client',
    Intermediary = 'Intermediary',
    Salutation = 'Salutation',
    AmlReport = 'AmlReport',
    FeeStructure = 'FeeStructure',
    TimeBooking = 'TimeBooking',
    Notification = 'Notification',
    AmlRisk = 'AmlRisk',
    DataLock = 'DataLock',
    RiskLevel = 'RiskLevel',
    Comment = 'Comment',
    Nudge = 'Nudge',
    NudgeLog = 'NudgeLog',
    FeedNudge = 'FeedNudge',
    Reminder = 'Reminder',
    Adviser = 'Adviser',
    GoalPoint = 'GoalPoint',
    Article = 'Article',
    ProductGroupMember = 'ProductGroupMember',
    InvestmentAccount = 'InvestmentAccount',
    BankAccount = 'BankAccount',
    UserView = 'UserView',
    Opportunity = 'Opportunity',
    MailingInstruction = 'MailingInstruction',
    Benchmark = 'Benchmark',
    Case = 'Case',
    AppStatus = 'AppStatus',
    LendingAccount = 'LendingAccount',
    InsuranceAccount = 'InsuranceAccount',
    PotentialDuplicate = 'PotentialDuplicate',
    Engagement = 'Engagement',
    Message = 'Message',
    ArticleDistribution = 'ArticleDistribution',
    DocumentTemplate = 'DocumentTemplate',
    DataHistory = 'DataHistory',
    QueueItem = 'QueueItem',
    CaseTeamMember = 'CaseTeamMember',
    List = 'List',
    Answer = 'Answer',
    Document = 'Document',
    GeneratedDocument = 'GeneratedDocument',
    Feed = 'Feed',
    DocumentRequirementType = 'DocumentRequirementType',
    DocumentRequirement = 'DocumentRequirement',
    Import = 'Import',
    ExternalBankAccount = 'ExternalBankAccount',
    ExternalBankAccountAssociation = 'ExternalBankAccountAssociation',
    CountryAssociation = 'CountryAssociation',
    NationalIdentifier = 'NationalIdentifier',
    OpportunityTeamMember = 'OpportunityTeamMember',
    Region = 'Region',
    Portfolio = 'Portfolio',
    Lead = 'Lead',
    ClientProductAccess = 'ClientProductAccess',
    Export = 'Export',
    EdsMessageBatch = 'EdsMessageBatch',
    EdsMessage = 'EdsMessage',
    MandateGroup = 'MandateGroup',
    DocumentType = 'DocumentType',
    History = 'History',
    Campaign = 'Campaign',
    CampaignResponse = 'CampaignResponse',
    UserLogin = 'UserLogin',
    ViewTile = 'ViewTile',
    ExternalUser = 'ExternalUser',
    Session = 'Session',
    IdentityCheck = 'IdentityCheck',
    IdentityCheckReview = 'IdentityCheckReview',
    UserDashboard = 'UserDashboard',
    ImportLog = 'ImportLog',
    Envelope = 'Envelope',
    Signatory = 'Signatory',
    IdentityMap = 'IdentityMap',
    ProductProvider = 'ProductProvider',
    WealthManagementAccount = 'WealthManagementAccount',
    ProtectionAccount = 'ProtectionAccount',
    FinancialRecord = 'FinancialRecord',
    FinancialRecordSubType = 'FinancialRecordSubType',
    PendingChange = 'PendingChange',
    PensionAccount = 'PensionAccount',
    AppendixEntry = 'AppendixEntry',
    AppendixSchema = 'AppendixSchema',
    AppendixLayout = 'AppendixLayout',
    SystemLog = 'SystemLog',
    ContactPoint = 'ContactPoint',
    Activity = 'Activity',
    Party = 'Party',
}

export enum AppType {
    Consent = 'Consent',
    ContactInformation = 'ContactInformation',
    Goals = 'Goals',
    SourceOfWealth = 'SourceOfWealth',
    AttitudeToRisk = 'AttitudeToRisk',
    Knowledge = 'Knowledge',
    Financials = 'Financials',
    Family = 'Family',
    Employment = 'Employment',
    Tax = 'Tax',
    InterestsAndHobbies = 'InterestsAndHobbies',
    InvestorRiskProfile = 'InvestorRiskProfile',
    PersonBusinessRisk = 'PersonBusinessRisk',
    Employees = 'Employees',
    MailingInstructions = 'MailingInstructions',
    EstatePlanning = 'EstatePlanning',
    Protection = 'Protection',
    ExternalArticle = 'ExternalArticle',
    InternalArticle = 'InternalArticle',
    ProfessionalConnections = 'ProfessionalConnections',
    OrganisationBusinessRisk = 'OrganisationBusinessRisk',
    OrganisationEmirFmia = 'OrganisationEmirFmia',
    OrganisationSourceOfWealth = 'OrganisationSourceOfWealth',
    AdverseMediaCheck = 'AdverseMediaCheck',
    IndepthSourceOfWealth = 'IndepthSourceOfWealth',
    LicenseRegistrationConfirmation = 'LicenseRegistrationConfirmation',
    CorporateStructure = 'CorporateStructure',
    GovernmentLists = 'GovernmentLists',
    PepAndSanctions = 'PepAndSanctions',
    CoreScreening = 'CoreScreening',
    IdVerification = 'IdVerification',
    ClientRiskAssessment = 'ClientRiskAssessment',
    NationalityAndResidence = 'NationalityAndResidence',
    FinancialsForm = 'FinancialsForm',
    CaseDocumentChecklist = 'CaseDocumentChecklist',
    DocumentChecklist = 'DocumentChecklist',
    CommercialContext = 'CommercialContext',
    ClientSummary = 'ClientSummary',
    Esg = 'Esg',
    InvestmentThemes = 'InvestmentThemes',
    ClientRoles = 'ClientRoles',
    ProductSelection = 'ProductSelection',
    Review = 'Review',
    DueDiligence = 'DueDiligence',
    ClientCommunication = 'ClientCommunication',
    Portfolio = 'Portfolio',
    Investment = 'Investment',
    Insurance = 'Insurance',
    Lending = 'Lending',
    Banking = 'Banking',
    PortfolioInstructions = 'PortfolioInstructions',
    PartyBankAccount = 'PartyBankAccount',
    ClientBankAccount = 'ClientBankAccount',
    PersonDetails = 'PersonDetails',
    BusinessRiskClient = 'BusinessRiskClient',
    TaxClient = 'TaxClient',
    OrganisationDetails = 'OrganisationDetails',
    OrganisationTax = 'OrganisationTax',
    OrganisationFinancials = 'OrganisationFinancials',
    AboutYou = 'AboutYou',
    IdentityVerification = 'IdentityVerification',
    ExternalUser = 'ExternalUser',
    OrganisationConnections = 'OrganisationConnections',
    Powers = 'Powers',
    PendingChanges = 'PendingChanges',
    ProcessData = 'ProcessData',
    Appendix = 'Appendix',
    Testing = 'Testing',
    Group = 'Group',
    NotApplicable = 'NotApplicable',
}

export enum AppStatusType {
    Incomplete = 'Incomplete',
    PendingConfirmation = 'PendingConfirmation',
    NearingExpiration = 'NearingExpiration',
    Expired = 'Expired',
    Confirmed = 'Confirmed',
    Closed = 'Closed',
    Dormant = 'Dormant',
    Inactive = 'Inactive',
}

export enum DataChangeType {
    Manual = 'Manual',
    Calculated = 'Calculated',
}

export enum DateRestrictionType {
    None = 'None',
    HistoricOnly = 'HistoricOnly',
    FutureOnly = 'FutureOnly',
}

export enum LookupSourceType {
    None = 'None',
    Any = 'Any',
    Party = 'Party',
    Person = 'Person',
    Organisation = 'Organisation',
    User = 'User',
    Team = 'Team',
    RootTeam = 'RootTeam',
    BookingCentre = 'BookingCentre',
    ServicingCentre = 'ServicingCentre',
    Construct = 'Construct',
    ClientProduct = 'ClientProduct',
    ClientProductLive = 'ClientProductLive',
    ClientProductLiveNotClosing = 'ClientProductLiveNotClosing',
    ClientPortfolio = 'ClientPortfolio',
    ProductGroup = 'ProductGroup',
    Adviser = 'Adviser',
    Intermediary = 'Intermediary',
    ProductProvider = 'ProductProvider',
    Company = 'Company',
    Charity = 'Charity',
    Trust = 'Trust',
    Collective = 'Collective',
    Corporate = 'Corporate',
    Foundation = 'Foundation',
    Client = 'Client',
    Case = 'Case',
    Process = 'Process',
    PartyRole = 'PartyRole',
    PartyRoleParty = 'PartyRoleParty',
    PersonPartyRole = 'PersonPartyRole',
    OrganisationPartyRole = 'OrganisationPartyRole',
    Opportunity = 'Opportunity',
    Activity = 'Activity',
    List = 'List',
    Campaign = 'Campaign',
    ContactPoint = 'ContactPoint',
    ElectronicAddress = 'ElectronicAddress',
    PhoneAddress = 'PhoneAddress',
    PostalAddress = 'PostalAddress',
    SocialAddress = 'SocialAddress',
    UserOrTeam = 'UserOrTeam',
    PersonOrOrganisation = 'PersonOrOrganisation',
    PersonOrUser = 'PersonOrUser',
    Assignee = 'Assignee',
    Document = 'Document',
    DocumentTemplate = 'DocumentTemplate',
    DocumentRequirement = 'DocumentRequirement',
    EdsMessageBatch = 'EdsMessageBatch',
    Import = 'Import',
    Envelope = 'Envelope',
    Signatory = 'Signatory',
    AccountHolders = 'AccountHolders',
    EligibleClient = 'EligibleClient',
    PersonalRelatedPeople = 'PersonalRelatedPeople',
}

export type SelectApiSource = ApiStringEnumValue;
export type TagCategory = ApiStringEnumValue;
export type LookupCreationMode = ApiStringEnumValue;
export type LookupResultsMode = ApiStringEnumValue;
export type LookupContextMode = ApiStringEnumValue;
export type SummaryLevel = ApiStringEnumValue;
export type FormElementStyle = ApiStringEnumValue;
export type EntityPermissionType = ApiStringEnumValue;
export type DocumentTypeStatusType = ApiStringEnumValue;

export type FeatureFlagValue = string;
export type FeatureFlagKey = string;
export type FeatureFlagEnum = Record<FeatureFlagKey, FeatureFlagValue>;
