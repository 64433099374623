import { Injectable, TemplateRef, inject } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class WdxDialogService {
    modalRef!: NgbModalRef;

    private ngbModal = inject(NgbModal);

    open(content: TemplateRef<Element>, config = {}): NgbModalRef {
        const DEFAULT = {
            backdropClass: 'modal-backdrop--ngb',
            windowClass: 'modal-window--ngb',
            centered: true,
            modalDialogClass: 'modal-dialog-centred-xs',
        };

        this.modalRef = this.ngbModal.open(content, { ...DEFAULT, ...config });

        return this.modalRef;
    }

    close(): void {
        this.modalRef.close();
    }

    dismiss(): void {
        this.modalRef.dismiss();
    }
}
