import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '@wdx/clmi/api-models';
import { userActions } from '@wdx/clmi/api-services/services';
import { userSelectors } from '@wdx/clmi/api-services/services';
import {
    SystemApiService,
    UserApiService,
} from '@wdx/clmi/api-services/services';
import { TenantsService } from '@wdx/shared/utils';
import { TranslationsService } from '@wdx/system';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';
import { SystemSettingsService, ThemeService } from '../../../shared/services';
import { FeaturesService } from '../../../shared/services/features/features.service';
import * as rootReducer from '../../../state/_setup/reducers';
import * as globalActions from '../../../state/global/global.actions';

@Injectable({
    providedIn: 'root',
})

/**
 * Guard that is run once on the top level App component
 */
export class AppGuard implements CanActivate {
    constructor(
        private router: Router,
        private store$: Store<rootReducer.State>,
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private systemApiService: SystemApiService,
        private userApiService: UserApiService,
        private tenantsService: TenantsService,
        private themeService: ThemeService,
        private systemSettingsService: SystemSettingsService,
        private featuresService: FeaturesService,
        private translationsService: TranslationsService
    ) {}

    canActivate(): Observable<boolean> {
        return this.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                /**
                 * Set isLoggedIn status on AuthenticationService
                 */
                this.authenticationService.loggedIn = isAuthenticated;

                /**
                 * If user not authenticated redirect to welcome page and restrict access to app
                 */
                if (!isAuthenticated) {
                    this.router.navigate(['/']);
                    this.tenantsService.clearChangeTenantCode();
                    return of(false);
                }

                /**
                 * Handles redirectTo url in the background (if applicable)
                 * Get appstate will only be subscribed to on a successfull login callback
                 */
                this.authenticationService
                    .getAppState$()
                    .pipe(
                        take(1),
                        filter((appState) => Boolean(appState.returnToUrl))
                    )
                    .subscribe((appState) =>
                        this.authenticationService.returnTo(
                            appState.returnToUrl
                        )
                    );

                /**
                 * If user is authenticated dispatch getMe(), perform route setup and return to app
                 */
                this.store$.dispatch(userActions.getMeAction());

                return this.store$.select(userSelectors.getMeSelector).pipe(
                    filter((res) => Boolean(res)),
                    first(),

                    // set tenant code
                    tap((user: User) => {
                        this.tenantsService.tenantCode = user.tenant;
                    }),

                    //  loads eager apis (those required to start the application)
                    switchMap(() =>
                        forkJoin({
                            features: this.systemApiService.getFeatures(),
                            settings: this.systemApiService.getSystemSettings(),
                            tenants: this.userApiService.getTenants(),
                            theme: this.systemApiService.getTheme(),
                            translations:
                                this.systemApiService.getTranslations(),
                        })
                    ),
                    tap((res) => {
                        // set the eager responses on relevant core services
                        this.systemSettingsService.set(res.settings);
                        this.featuresService.set(res.features);
                        this.themeService.set(res.theme);
                        this.tenantsService.set(res.tenants);
                        this.translationsService.set(res.translations);

                        // initialise lazy global actions
                        this.store$.dispatch(
                            globalActions.initialiseApp({ isAuthenticated })
                        );
                    }),

                    // Return to app
                    map(() => true)
                );
            })
        );
    }

    get isAuthenticated$() {
        return this.configService?.config?.TestMode
            ? of(true)
            : this.authenticationService.auth0Service.isAuthenticated$;
    }
}
