import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityOperations, EntityPermissionType } from '@wdx/clmi/api-models';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { WdxSize } from '@wdx/shared/utils';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponentClass } from '../../../../classes/base-component.class';
import { FilterHandler } from '../../../../classes/filter-handler.class';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { DropdownButtonStyle } from '../../../../models/dropdown-button-style.model';
import { FileUploadPackage } from '../../../../models/file-upload-package.model';
import { MenuItem } from '../../../../models/menu-item.model';
import { Privilege } from '../../../../models/privilege.model';
import { RandomStringPipe } from '../../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { FeaturesService } from '../../../../shared/services/features/features.service';
import * as rootReducer from '../../../../state/_setup/reducers';
import * as operationsActions from '../../../../state/operations/operations.actions';
import * as operationsSelectors from '../../../../state/operations/operations.selectors';
import { MoleculeActionButtonService } from '../shared/services/molecule-action-button.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-action-button',
    templateUrl: './molecule-action-button.component.html',
})
export class MoleculeActionButtonComponent
    extends BaseComponentClass
    implements OnInit, OnChanges
{
    @Input() actionButton: ActionButton;
    @Input() btnClass = 'btn-outline-primary';
    @Input() size: WdxSize = 'sm';
    @Input() parentContainer = 'body';
    @Input() hideDropdownChevron: boolean;
    @Input() dropdownButtonStyle: DropdownButtonStyle =
        DropdownButtonStyle.Label;
    @Input() dropdownPlacement = ['bottom-right', 'top-right'];
    @Input() dropdownMenuSize = 'xs';
    @Input() dropdownAutoClose: boolean | 'outside' | 'inside';
    @Input() isDisabled = false;
    @Input() filterHandler: FilterHandler;
    @Input() highlightSelectedMenuItem: boolean;
    @Input() cySelector?: string;

    @Output() selectMenuItem = new EventEmitter<MenuItem>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter<boolean>();
    @Output() fileSelected = new EventEmitter<FileUploadPackage>();
    @Output() dropdownMenuToggled = new EventEmitter<boolean>();

    dropdownTemplateId: string;
    dropdownMenu$ = new BehaviorSubject<MenuItem[]>(null);

    operations$: Observable<EntityOperations>;
    isLoading$ = of(false);
    isVisible = true;
    resetButtonsFlag = false;

    private moleculeActionButtonService = inject(MoleculeActionButtonService);

    constructor(
        private changeDetector: ChangeDetectorRef,
        private featuresService: FeaturesService,
        store$: Store<rootReducer.State>,
        modalManagementService: ModalManagementService
    ) {
        super();
        this.patchInjectedItems({
            store$,
            modalManagementService,
        });
    }

    ngOnChanges() {
        if (this.actionButton?.dropdownMenu) {
            this.setDropdownMenu();
        }

        if (this.actionButton?.permission) {
            this.setPermissionVisibility();
        }

        if (this.actionButton?.privilege) {
            this.setPrivilegeVisibility();
        }

        if (this.actionButton?.feature) {
            this.isVisible = this.featuresService.hasFeature(
                this.actionButton?.feature
            );
        }
    }

    ngOnInit(): void {
        this?.filterHandler?.resetValues
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetButtonsFlag = false;

                if (
                    this.actionButton.mode === ActionButtonMode.FavouriteButton
                ) {
                    this.changeDetector.detectChanges();
                    this.resetButtonsFlag = true;
                }
            });
    }

    setDropdownMenu() {
        this.dropdownTemplateId = new RandomStringPipe().transform();
        this.dropdownMenu$.next(this.actionButton.dropdownMenu);
    }

    setPermissionVisibility() {
        if (this.actionButton?.operationsSetup) {
            this.isVisible = false;
            this.getOperations();
            this.operations$
                .pipe(
                    takeUntil(this.destroyed$),
                    filter((operations) => Boolean(operations?.permissions))
                )
                .subscribe(
                    (operations) =>
                        (this.isVisible = operations.permissions.includes(
                            this.actionButton?.permission
                        ))
                );
        }
    }

    setPrivilegeVisibility() {
        this.isVisible = false;
        this.store$
            .select(userSelectors.getPrivilegesSelector)
            .pipe(take(1))
            .subscribe(
                (privileges) =>
                    (this.isVisible =
                        privileges?.includes(Privilege.Administrator) ||
                        privileges?.includes(this.actionButton?.privilege))
            );
    }

    getOperations() {
        if (this.operations$) return;
        const { operationsSetup } = this.actionButton;
        if (
            operationsSetup &&
            operationsSetup?.entityType &&
            operationsSetup?.entityId
        ) {
            this.isLoading$ = this.store$.select(
                operationsSelectors.operationsForIdIsLoadingSingle,
                operationsSetup
            );
            this.operations$ = this.store$
                .select(operationsSelectors.getOperationsForId, operationsSetup)
                .pipe(
                    takeUntil(this.destroyed$),
                    tap((operations) => {
                        if (!operations) {
                            this.store$.dispatch(
                                operationsActions.getOperationsForId(
                                    operationsSetup
                                )
                            );
                        }
                    })
                );
        } else {
            this.isLoading$ = of(false);
            this.operations$ = of({
                permissions: [EntityPermissionType.Edit],
                actions: [],
            });
        }
    }

    onFileSelected(uploadEvent: any): void {
        const fileUploadPackage = {
            id: new RandomStringPipe().transform(),
            file: uploadEvent.target.files[0],
        };
        this.fileSelected.emit(fileUploadPackage);
    }

    onActionButtonClicked(toggle?: boolean) {
        if (this.actionButton.modalId) {
            this.modalManagementService.openModalWithId(
                this.actionButton.modalId
            );
        }
        if (this.actionButton.callback) {
            this.actionButton.callback();
        }
        this.onClick.emit(toggle);
    }

    onMenuItemSelected(menuItem: MenuItem) {
        this.selectMenuItem.emit(menuItem);
    }

    onDropdownToggled(isOpen: boolean) {
        this.dropdownMenuToggled.emit(isOpen);
        if (isOpen) {
            this.getOperations();
            combineLatest([
                this.store$.select(userSelectors.getPrivilegesSelector),
                this.operations$,
            ])
                .pipe(takeUntil(this.destroyed$))
                .subscribe(([privileges, operations]) => {
                    const updatedMenu = this.actionButton?.dropdownMenu?.map(
                        (menu) => ({
                            ...menu,
                            hidden: (() => {
                                if (menu.hidden) {
                                    return true;
                                }

                                if (menu.feature) {
                                    return !this.featuresService.hasFeature(
                                        menu.feature
                                    );
                                }

                                if (
                                    menu.privilege &&
                                    !privileges.includes(
                                        Privilege.Administrator
                                    )
                                ) {
                                    return !privileges?.includes(
                                        menu.privilege
                                    );
                                }
                                if (menu.permission) {
                                    return !operations?.permissions?.includes(
                                        menu.permission
                                    );
                                }
                                return false;
                            })(),
                        })
                    );
                    let actionMenu;
                    if (
                        !this.actionButton?.ignoreActions &&
                        operations?.actions?.length
                    ) {
                        actionMenu = operations.actions.map((action) => ({
                            id: action.displayName?.key,
                            label: action.displayName?.value,
                            icon: action.icon,
                            callback:
                                action.url &&
                                (() => {
                                    this.moleculeActionButtonService.operationActionCallback(
                                        this.actionButton.operationsSetup,
                                        action
                                    );
                                }),
                            data: action,
                        }));
                        // ---------------------------------------------------------
                        // Adding ability to override action item
                        // To be removed once the actions endpoint has been updated
                        // to do this automatically
                        // ---------------------------------------------------------
                        actionMenu.forEach((actionItem) => {
                            const matchIndex = updatedMenu.findIndex(
                                (updatedItem) =>
                                    updatedItem.id === actionItem.id
                            );
                            if (matchIndex > -1) {
                                actionItem.hidden = true;
                                updatedMenu[matchIndex].hidden = false;
                            }
                        });
                        // ---------------------------------------------------------
                    }
                    this.dropdownMenu$.next([
                        ...(updatedMenu || []),
                        ...(actionMenu || []),
                    ]);
                });
        }
    }
}
