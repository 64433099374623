import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { ICON_ADD_CIRCLE } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { MenuItem } from '../../../models/menu-item.model';
import { ModalManagementService } from '../../../services/modal-management.service';
import { ImportEntitiesDownloadModalComponent } from './import-entities-download-modal/import-entities-download-modal.component';
import { ImportEntitiesModalComponent } from './import-entities-modal/import-entities-modal.component';
import {
    DOWNLOAD_VAL,
    IMPORT,
    IMPORT_ENTITIES_DOWNLOAD_MODAL_ID,
    IMPORT_ENTITIES_MODAL_ID,
    IMPORT_VAL,
} from './import-entities.constant';

@Component({
    selector: 'clmi-import-entities',
    templateUrl: './import-entities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportEntitiesComponent extends WdxDestroyClass implements OnInit {
    private modalManagementService = inject(ModalManagementService);

    @ViewChild(ImportEntitiesModalComponent)
    private importEntitiesModalComponent: ImportEntitiesModalComponent;

    @ViewChild(ImportEntitiesDownloadModalComponent)
    private importEntitiesDownloadModalComponent: ImportEntitiesDownloadModalComponent;

    size = 'md';

    actionButton: ActionButton = {
        mode: ActionButtonMode.DropdownButtonMenu,
        dropdownMenu: [],
        icon: ICON_ADD_CIRCLE.icon,
    };

    ngOnInit(): void {
        this.actionButton.dropdownMenu.push(...IMPORT);
    }

    onDropdownOptionClicked(menu: MenuItem): void {
        if (menu?.value === IMPORT_VAL) {
            this.onModalOpen(IMPORT_ENTITIES_MODAL_ID);
            this.importEntitiesModalComponent.fetchData();
        }

        if (menu?.value === DOWNLOAD_VAL) {
            this.onModalOpen(IMPORT_ENTITIES_DOWNLOAD_MODAL_ID);
            this.importEntitiesDownloadModalComponent.fetchData();
        }
    }

    onModalOpen(modalId): void {
        this.modalManagementService.openModalWithId(modalId);
    }
}
