export enum ESubFormPendingChange {
    Deleting = 'Deleting',
    Adding = 'Adding',
    Updating = 'Updating',
}

export const PENDING_CHANGE_LOCK_ALL = [
    ESubFormPendingChange.Adding,
    ESubFormPendingChange.Deleting,
];

export const PENDING_CHANGE_TEXT = 'pending validation';
