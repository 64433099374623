import { Component, Input, OnInit, Optional } from '@angular/core';
import { ImportApiService } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { saveAs } from 'file-saver';
import { ICON_CONTEXT_MENU } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { MenuItem } from '../../../models/menu-item.model';
import { ExportsFacadeService } from '../../../shared/features/exports/services/exports-facade.service';
import { ImportFileUploaderService } from '../../../shared/features/import-file-uploader/shared/services';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { FilterQueryService } from '../../features/form-filters/shared/services/filter-query';
import {
    DOWNLOAD_VAL,
    EXPORT,
    EXPORT_VAL,
    IMPORT,
    IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP,
    IMPORT_VAL,
} from './import-export.constant';

@Component({
    selector: 'clmi-import-export',
    templateUrl: './import-export.component.html',
})
export class ImportExportComponent extends WdxDestroyClass implements OnInit {
    @Input() hasExportPrivilege: boolean;
    @Input() disableExport: boolean;
    @Input() size = 'md';
    @Input() btnClass = 'btn-outline-primary';

    actionButton: ActionButton = {
        mode: ActionButtonMode.DropdownButtonMenu,
        dropdownMenu: [],
        icon: ICON_CONTEXT_MENU.icon,
    };

    constructor(
        @Optional() private filterQueryService: FilterQueryService,
        private exportsFacadeService: ExportsFacadeService,
        private importFileUploaderService: ImportFileUploaderService,
        private featuresService: FeaturesService,
        private importApiService: ImportApiService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.hasExportPrivilege) {
            this.actionButton.dropdownMenu.push(EXPORT);
        }

        if (
            Object.keys(IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP).some(
                (key) =>
                    this.filterQueryService?.viewType === key &&
                    this.featuresService.hasFeature(
                        IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP[key]
                    )
            )
        ) {
            this.actionButton.dropdownMenu.push(...IMPORT);
        }
    }

    onImportIconClicked(menu: MenuItem): void {
        if (menu?.value === EXPORT_VAL && !this.disableExport) {
            this.onExport();
        }

        if (menu?.value === IMPORT_VAL) {
            this.onImport();
        }

        if (menu?.value === DOWNLOAD_VAL) {
            this.onDownloadTemplate();
        }
    }

    onExport(): void {
        this.exportsFacadeService.doExport({
            queryType: this.filterQueryService?.viewType,
            payload: this.filterQueryService?.inBuilder
                ? this.filterQueryService?.builderQuery
                : this.filterQueryService?.query,
        });
    }

    onImport(): void {
        this.importFileUploaderService.openModal(
            this.filterQueryService?.viewType
        );
    }

    onDownloadTemplate(): void {
        this.importApiService
            .downloadTemplate$(this.filterQueryService?.viewType)
            .subscribe((data: string) => {
                saveAs(
                    new Blob([data], { type: 'application/octet-stream' }),
                    `${this.filterQueryService?.viewType}-template.csv`
                );
            });
    }
}
