<ul
    class="list-group list-group-flush h-100 overflow-hidden d-flex flex-column"
    [formGroup]="form"
>
    <li
        class="list-group-item d-flex flex-shrink-0 justify-content-between align-items-center"
    >
        <h5 class="">Filter</h5>

        <section class="d-flex">
            <button
                class="btn btn-outline-primary ms-2"
                (click)="clearAndReturnToViews()"
            >
                Cancel
            </button>
            <button
                class="btn btn-outline-primary ms-2"
                [disabled]="expressions.invalid"
                data-cy="btn-apply-filter"
                (click)="applyFilter()"
            >
                Apply
            </button>

            <button
                class="btn btn-primary ms-2"
                [disabled]="form.invalid"
                data-cy="btn-save-filter"
                (click)="saveView()"
            >
                Save
            </button>
        </section>
    </li>
    <li class="list-group-item bg-muted d-flex flex-column flex-shrink-0">
        <input
            type="text"
            placeholder="Name new filter"
            class="mb-2"
            wdxInputText
            formControlName="name"
            data-cy="input-name-new-filter"
        />
        <section
            class="d-flex justify-content-between align-items-center"
            [class.d-none]="viewBuilderResults$ | ngrxPush"
        >
            <wdx-dropdown ngbDropdown container="body">
                <atom-add-button
                    type="button"
                    data-cy="dropdown-add-condition"
                    ngbDropdownToggle
                >
                    Add Condition</atom-add-button
                >

                <wdx-dropdown-menu ngbDropdownMenu>
                    <a
                        class="pointer"
                        wdxDropdownItem
                        *ngFor="
                            let definition of definitions$ | ngrxPush;
                            trackBy: trackByDefinitionName
                        "
                        (click)="addNewQuery(definition)"
                    >
                        {{ definition?.displayName?.value }}
                    </a>
                </wdx-dropdown-menu>
            </wdx-dropdown>
        </section>
    </li>
    <li
        class="flex-grow-1 overflow-hidden"
        formArrayName="expressions"
        [class.d-none]="viewBuilderResults$ | ngrxPush"
    >
        <section class="h-100 overflow-auto">
            <ng-container
                *ngFor="
                    let expressionForm of expressions.controls;
                    let i = index
                "
            >
                <ng-container
                    *ngIf="!expressionViewData[i]?.contextual"
                    [formGroup]="expressionForm"
                >
                    <li class="list-group-item">
                        <section
                            class="d-flex justify-content-between align-items-center"
                        >
                            <section>
                                <span class="small text-muted mb-1">
                                    {{ i === 0 ? 'Where' : 'And' }}
                                </span>

                                <div class="mb-2 d-flex align-items-center">
                                    <span class="me-2">
                                        {{
                                            expressionViewData[i]?.displayName
                                                ?.value
                                        }}
                                    </span>

                                    <wdx-dropdown ngbDropdown container="body">
                                        <button
                                            ngbDropdownToggle
                                            background
                                            class="btn btn-outline-primary"
                                        >
                                            {{
                                                FILTER_OPERATOR_PREFIX +
                                                    expressionForm.controls
                                                        ?.operator?.value
                                                    | translate
                                            }}
                                        </button>
                                        <wdx-dropdown-menu ngbDropdownMenu>
                                            <label
                                                class="d-inline-flex justify-content-between align-items-center pointer"
                                                wdxDropdownItem
                                                *ngFor="
                                                    let opt of expressionViewData[
                                                        i
                                                    ]?.operators;
                                                    trackBy: trackByOperatorsName
                                                "
                                                [class.active]="
                                                    expressionForm.controls
                                                        ?.operator?.value ===
                                                    opt
                                                "
                                            >
                                                <span
                                                    class="flex-grow-1 text-truncate"
                                                >
                                                    {{
                                                        FILTER_OPERATOR_PREFIX +
                                                            opt | translate
                                                    }}
                                                </span>

                                                <input
                                                    hidden
                                                    type="radio"
                                                    [value]="opt"
                                                    formControlName="operator"
                                                />

                                                <wdx-icon
                                                    *ngIf="
                                                        expressionForm.controls
                                                            ?.operator
                                                            ?.value === opt
                                                    "
                                                    size="sm"
                                                    class="flex-shrink-0 ms-2"
                                                    icon="check"
                                                ></wdx-icon>
                                            </label>
                                        </wdx-dropdown-menu>
                                    </wdx-dropdown>
                                </div>
                            </section>

                            <section class="align-self-start">
                                <atom-icon-button
                                    btnClass="btn"
                                    size="sm"
                                    icon="trash-alt"
                                    (click)="deleteExpression(i)"
                                ></atom-icon-button>
                            </section>
                        </section>

                        <section class="d-flex flex-wrap">
                            <ng-container
                                formArrayName="values"
                                *ngIf="
                                    expressionViewData[i]?.type !==
                                        FilterFieldType.Lookup &&
                                    expressionForm.controls?.operator?.value !==
                                        FilterOperator.ContainsData &&
                                    expressionForm.controls?.operator?.value !==
                                        FilterOperator.DoesNotContainData
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let valuesForm of getValues(i).controls;
                                        let j = index
                                    "
                                >
                                    <clmi-filter-select
                                        class="w-100"
                                        [definition]="expressionViewData[i]"
                                        [formControlNameOrIndex]="j"
                                        [options]="
                                            expressionViewData[i].possibleValues
                                        "
                                        [multiple]="
                                            expressionViewData[i]
                                                .multiOperator !== false
                                        "
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Select ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Answer ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Enum
                                        "
                                    ></clmi-filter-select>

                                    <input
                                        wdxInputText
                                        type="text"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.String ||
                                            expressionViewData[i]?.type ===
                                                FilterFieldType.Search
                                        "
                                        [formControlName]="j"
                                    />

                                    <input
                                        wdxInputText
                                        type="number"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Integer
                                        "
                                        [formControlName]="j"
                                    />

                                    <input
                                        wdxInputText
                                        type="number"
                                        step="any"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Decimal
                                        "
                                        [formControlName]="j"
                                    />

                                    <clmi-filter-currency
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Currency
                                        "
                                        [formControlName]="j"
                                    ></clmi-filter-currency>

                                    <clmi-filter-date
                                        class="w-100"
                                        type="date"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Date
                                        "
                                        [operator]="
                                            expressionForm.controls?.operator
                                                ?.value
                                        "
                                        [formControlObj]="j"
                                    ></clmi-filter-date>

                                    <clmi-filter-date
                                        class="w-100"
                                        type="datetime-local"
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.DateTime
                                        "
                                        [operator]="
                                            expressionForm.controls?.operator
                                                ?.value
                                        "
                                        [formControlObj]="j"
                                    ></clmi-filter-date>

                                    <wdx-form-field
                                        *ngIf="
                                            expressionViewData[i]?.type ===
                                            FilterFieldType.Boolean
                                        "
                                        labelnomarginbottom
                                    >
                                        <wdx-radio
                                            label="True"
                                            value="true"
                                            [labelForId]="
                                                expressionViewData[i]?.name +
                                                '-true'
                                            "
                                            labelnomarginbottom
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                [value]="true"
                                                [formControlName]="j"
                                            />
                                        </wdx-radio>

                                        <wdx-radio
                                            label="False"
                                            value="false"
                                            [labelForId]="
                                                expressionViewData[i]?.name +
                                                '-false'
                                            "
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                [value]="false"
                                                [formControlName]="j"
                                            />
                                        </wdx-radio>
                                    </wdx-form-field>
                                </ng-container>
                            </ng-container>

                            <ng-container
                                formArrayName="values"
                                *ngIf="
                                    (expressionViewData[i]?.type ===
                                        FilterFieldType.Enum ||
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.LookupMultiple ||
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.Lookup) &&
                                    expressionForm.controls?.operator?.value !==
                                        FilterOperator.ContainsData &&
                                    expressionForm.controls?.operator?.value !==
                                        FilterOperator.DoesNotContainData
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.LookupMultiple ||
                                        expressionViewData[i]?.type ===
                                            FilterFieldType.Lookup
                                    "
                                >
                                    <ng-container
                                        *ngFor="
                                            let val of arrayMultiSelectData[i]
                                                ?.data;
                                            let childIndex = index
                                        "
                                    >
                                        <wdx-info-card
                                            background
                                            class="me-1 mb-1"
                                            [noBorder]="true"
                                        >
                                            <div wdxInfoCardLabel>
                                                {{ val?.name }}
                                            </div>
                                            <div wdxInfoCardTrailing>
                                                <atom-icon-button
                                                    btnClass="btn"
                                                    size="sm"
                                                    icon="times"
                                                    (click)="
                                                        deleteExpressionValue(
                                                            i,
                                                            childIndex,
                                                            val,
                                                            'id'
                                                        )
                                                    "
                                                ></atom-icon-button>
                                            </div>
                                        </wdx-info-card>
                                    </ng-container>

                                    <button
                                        data-cy="btn-ellipsis"
                                        background
                                        class="btn m-1"
                                        (click)="
                                            addDynamicComponent(
                                                i,
                                                expressionViewData[i]
                                            )
                                        "
                                    >
                                        <wdx-icon
                                            icon="ellipsis-h"
                                            iconStyle="fas"
                                        ></wdx-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </section>
                    </li>
                </ng-container>
            </ng-container>
        </section>
    </li>
</ul>

<ng-container #viewContainerRef></ng-container>
