import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { WdxAlertModule } from '@wdx/shared/components/wdx-alert';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { WdxFormFrameworkModule } from '@wdx/shared/infrastructure/form-framework';
import {
    WdxDateTimeService,
    WdxMarkdownToHtmlPipe,
    WdxNumberService,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';
import { AtomAsyncButtonModule } from '../../components/atoms/atom-async-button/atom-async-button.module';
import { AtomAsyncDataModule } from '../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomIconButtonModule } from '../../components/atoms/atom-icon-button/atom-icon-button.module';
import { OrganismModalModule } from '../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FormSummaryModule } from '../../shared/features/form-summary/form-summary.module';
import { OpenInNewTabService } from '../../shared/services';
import { MoleculeValidationSummaryModule } from './components/molecule-validation-summary/molecule-validation-summary.module';
import { AccordionHeaderComponent } from './components/organism-reactive-form-array/accordion-header/accordion-header.component';
import { OrganismReactiveFormArrayComponent } from './components/organism-reactive-form-array/organism-reactive-form-array/organism-reactive-form-array.component';
import { ReactiveSubFormContentComponent } from './components/organism-reactive-form-array/sub-form-content/sub-form-content.component';
import { ReactiveSubFormHeaderComponent } from './components/organism-reactive-form-array/sub-form-header/sub-form-header.component';
import { OrganismReactiveFormDumbComponent } from './components/organism-reactive-form-dumb/organism-reactive-form-dumb.component';
import { QuickCreateModalComponent } from './components/quick-create-modal/quick-create-modal.component';
import { ReactiveFormControlsModule } from './components/reactive-form-controls/reactive-form-controls.module';
import { OrganismReactiveFormComponent } from './containers/organism-reactive-form/organism-reactive-form.component';
import { FormPipesModule } from './pipes/form-pipes.module';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxSplitButtonComponent } from '@wdx/shared/components/wdx-split-button';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';

@NgModule({
    declarations: [
        AccordionHeaderComponent,
        OrganismReactiveFormArrayComponent,
        OrganismReactiveFormComponent,
        OrganismReactiveFormDumbComponent,
        QuickCreateModalComponent,
        ReactiveSubFormContentComponent,
        ReactiveSubFormHeaderComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        NgbAccordionModule,
        AtomAsyncButtonModule,
        AtomAsyncDataModule,
        AtomIconButtonModule,
        DirectivesModule,
        FormPipesModule,
        FormSummaryModule,
        MoleculeValidationSummaryModule,
        OrganismModalModule,
        PipesModule,
        PushModule,
        ReactiveFormControlsModule,
        WdxBadgeModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxIconStackModule,
        WdxInfoCardModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
        WdxAlertModule,
        WdxFormFrameworkModule,
        WdxIconContainerModule,
        WdxIsLoadingModule,
        WdxSpinnerModule,
        WdxSplitButtonComponent,
    ],
    exports: [
        OrganismReactiveFormArrayComponent,
        OrganismReactiveFormComponent,
        OrganismReactiveFormDumbComponent,
    ],
    providers: [OpenInNewTabService, WdxDateTimeService, WdxNumberService],
})
export class CLMiReactiveFormsModule {}
