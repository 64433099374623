import { createAction, props } from '@ngrx/store';
import { Dashboard, SystemEntity } from '@wdx/clmi/api-models';

export const getDashboards = createAction('[Dashboards] Get All Dashboards');

export const getDashboardsSuccess = createAction(
    '[Dashboards] Get All  Dashboards Success',
    props<{
        dashboards: Dashboard[];
    }>()
);

export const getDashboardsFailure = createAction(
    '[Dashboards] Get All  Dashboards Failure',
    props<{
        error: Error;
    }>()
);

export const createDashboard = createAction(
    '[Dashboards] Create Dashboard',
    props<{
        body: any;
    }>()
);

export const createDashboardSuccess = createAction(
    '[Dashboards] Create Dashboard Success',
    props<{
        dashboard: Dashboard;
    }>()
);

export const createDashboardFailure = createAction(
    '[Dashboards] Create Dashboard Failure',
    props<{
        error: Error;
    }>()
);

export const updateDashboard = createAction(
    '[Dashboards] Update Dashboard',
    props<{
        dashboardId: string;
        body: any;
    }>()
);

export const updateDashboardSuccess = createAction(
    '[Dashboards] Update Dashboard Success',
    props<{
        dashboard: Dashboard;
    }>()
);

export const updateDashboardFailure = createAction(
    '[Dashboards] Update Dashboard Failure',
    props<{
        error: Error;
    }>()
);

export const deleteDashboard = createAction(
    '[Dashboards] Delete Dashboard',
    props<{
        dashboardId: string;
        entityType: SystemEntity;
    }>()
);

export const deleteDashboardSuccess = createAction(
    '[Dashboards] Delete Dashboard Success',
    props<{
        dashboardId: string;
        entityType: SystemEntity;
    }>()
);

export const deleteDashboardFailure = createAction(
    '[Dashboards] Delete Dashboard Failure',
    props<{
        error: Error;
    }>()
);

export const shareDashboard = createAction(
    '[Dashboards] Share Dashboard',
    props<{
        dashboardId: string;
        contactId: string;
    }>()
);

export const shareDashboardSuccess = createAction(
    '[Dashboards] Share Dashboard Success',
    props<{
        dashboardId: string;
        contactId: string;
    }>()
);

export const shareDashboardFailure = createAction(
    '[Dashboards] Share Dashboard Failure',
    props<{
        error: Error;
    }>()
);

export const unshareDashboard = createAction(
    '[Dashboards] Unshare Dashboard',
    props<{
        dashboardId: string;
        contactId: string;
    }>()
);

export const unshareDashboardSuccess = createAction(
    '[Dashboards] Unshare Dashboard Success',
    props<{
        dashboardId: string;
        contactId: string;
    }>()
);

export const unshareDashboardFailure = createAction(
    '[Dashboards] Unshare Dashboard Failure',
    props<{
        error: Error;
    }>()
);
