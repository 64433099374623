import { Injectable, inject } from '@angular/core';
import { FieldDefinition, FormFieldType } from '@wdx/clmi/api-models';
import {
    WdxDateFormat,
    WdxDateTimeService,
    WdxNumberService,
} from '@wdx/shared/utils';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import showdown from 'showdown';
import { FormContextualDataService } from '../../../../../features/reactive-forms/services/form-contextual-data/form-contextual-data.service';
import { ExtendedFieldDefinitionOption } from '../../../../../models/extended-field-definition-option.model';
import { TitleCasePipe } from '../../../../../pipes/title-case.pipe';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services/dynamic-data';
import { TelephoneService } from '../../../../services';
import {
    ADDRESS_SUMMARY_TYPES,
    CURRENCY_SUMMARY_TYPES,
    DATE_RANGE_SUMMARY_TYPES,
    DATE_SUMMARY_TYPES,
    DECIMAL_SUMMARY_TYPES,
    INTEGER_SUMMARY_TYPES,
    LOOKUP_SUMMARY_TYPES,
    OPTIONS_SUMMARY_TYPES,
    PHONE_SUMMARY_TYPES,
    RICH_TEXT_SUMMARY_TYPES,
    TEXT_TYPES,
} from '../../constants/summary-types';
import { FormSummaryValueOptions } from '../../models/form-summary-value.model';

@Injectable({
    providedIn: 'root',
})
export class FormSummaryService {
    private wdxDateTimeService = inject(WdxDateTimeService);
    private wdxNumberService = inject(WdxNumberService);
    private dynamicDataService = inject(ReactiveFormDynamicDataService);
    private telephoneService = inject(TelephoneService);
    private formContextualDataService = inject(FormContextualDataService);

    getStringValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[]
    ): Observable<string> {
        if (!fieldDefinition) {
            return of(null);
        }

        if (TEXT_TYPES.includes(fieldDefinition.fieldType)) {
            return of(fieldValue);
        }

        if (DECIMAL_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatDecimal(
                            fieldValue,
                            locale.name
                        )
                    )
                );
        }

        if (INTEGER_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatNumber(
                            fieldValue,
                            locale.name
                        )
                    )
                );
        }

        if (CURRENCY_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxNumberService.formatCurrency(fieldValue?.amount, {
                        locale: locale.name,
                        currencyCode: fieldValue?.isoCode,
                        groupSeparator:
                            locale.numberFormat.numberGroupSeparator,
                        decimalSeparator:
                            locale.numberFormat.numberDecimalSeparator,
                    })
                )
            );
        }

        if (PHONE_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            if (!fieldValue) {
                return of(null);
            }
            return this.telephoneService.fieldValueToTelephonePattern$(
                fieldValue
            );
        }

        if (DATE_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return of(
                this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                    fieldValue,
                    {
                        format:
                            fieldDefinition.fieldType === FormFieldType.DateTime
                                ? WdxDateFormat.AbsoluteDateTime
                                : WdxDateFormat.AbsoluteDate,
                    }
                )
            );
        }

        if (DATE_RANGE_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            const start =
                this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                    fieldValue.start,
                    {
                        format:
                            fieldDefinition.fieldType ===
                            FormFieldType.DateTimeRange
                                ? WdxDateFormat.AbsoluteDateTime
                                : WdxDateFormat.AbsoluteDate,
                    }
                );
            const end = this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                fieldValue.end,
                {
                    format:
                        fieldDefinition.fieldType ===
                        FormFieldType.DateTimeRange
                            ? WdxDateFormat.AbsoluteDateTime
                            : WdxDateFormat.AbsoluteDate,
                }
            );
            return end ? of(`${start} - ${end}`) : of(start);
        }

        if (OPTIONS_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return this.getOptionsValue(
                fieldValue,
                fieldDefinition,
                options,
                parentData,
                formSchema
            );
        }

        if (LOOKUP_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            return of(
                Array.isArray(fieldValue)
                    ? fieldValue[0]?.name
                    : fieldValue?.name
            );
        }

        if (ADDRESS_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            const address = Object.keys(fieldValue).length
                ? fieldValue
                : parentData?.postalAddresses?.find(
                      (address) =>
                          address.addressType &&
                          address.addressType === options?.formData?.addressType
                  )?.address;

            return this.getAddress$(address).pipe(
                map((item) => item.join(', '))
            );
        }

        if (fieldDefinition.fieldType === FormFieldType.Boolean) {
            return of(fieldValue ? 'Checked' : '');
        }

        /**
         * Rich text fields will either be markdown (as saved from rich text ui) or html (for example email content)
         * If HTML is returned strip out images
         */
        if (RICH_TEXT_SUMMARY_TYPES.includes(fieldDefinition.fieldType)) {
            const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
            return isHTML(fieldValue)
                ? of(fieldValue.replace(/<img[^>]*>/g, ''))
                : of(new showdown.Converter().makeHtml(fieldValue));
        }

        return of(fieldValue);
    }

    /**
     * Returns string value from an Option defintition
     */
    getOptionsValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[]
    ) {
        if (fieldDefinition.options?.length) {
            const fieldValueArray = Array.isArray(fieldValue)
                ? fieldValue
                : [fieldValue];
            return of(
                fieldValueArray
                    .map(
                        (value) =>
                            fieldDefinition.options.find((option) =>
                                this.dynamicDataService.compareOptionValue(
                                    option.value,
                                    value
                                )
                            )?.label
                    )
                    .join(', ')
            );
        }

        if (fieldDefinition.selectSource) {
            const contextualData =
                this.formContextualDataService.getContextualData(
                    fieldDefinition.contextualValues,
                    options?.formData,
                    parentData,
                    formSchema
                );

            let optionsProvider: Observable<ExtendedFieldDefinitionOption[]>;

            if (
                contextualData.lookups?.length ||
                contextualData.context?.length
            ) {
                const { context, lookups } = contextualData;
                optionsProvider =
                    this.dynamicDataService.getContextSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        lookups,
                        context
                    );
            } else {
                const contextualValues =
                    this.formContextualDataService.getContextualResults(
                        contextualData
                    );
                optionsProvider =
                    this.dynamicDataService.getSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        {
                            ...(contextualValues?.length > 0 && {
                                parent: contextualValues.join(','),
                            }),
                        }
                    );
            }

            return optionsProvider.pipe(
                map((fieldOptions) => {
                    if (Array.isArray(fieldValue)) {
                        return fieldOptions
                            .filter((option) =>
                                fieldValue
                                    .map((fieldValue) =>
                                        fieldValue.toLowerCase()
                                    )
                                    .includes(option.value?.toLowerCase())
                            )
                            .map((options) => options.label)
                            .join(', ');
                    }
                    const matchedOption = fieldOptions.find((option) =>
                        this.dynamicDataService.compareOptionValue(
                            option.value,
                            fieldValue
                        )
                    );
                    if (
                        fieldDefinition.fieldType === FormFieldType.Hierarchical
                    ) {
                        return `${matchedOption?.value} - ${matchedOption?.label}`;
                    }
                    return matchedOption?.label;
                })
            );
        }

        return of(new TitleCasePipe().transform(fieldValue));
    }

    /**
     * Returns format friendly address
     * @param fieldValue
     */
    getAddress$(fieldValue: any, useAddressLine = false): Observable<string[]> {
        if (!fieldValue) {
            return of([]);
        }

        const addressLine =
            fieldValue['addressLine'] && useAddressLine
                ? ['addressLine']
                : ['buildingName', 'streetBuildingIdentification', 'street'];
        const addressFields = [
            'department',
            'subDepartment',
            'floor',
            'postOfficeBox',
            'room',
            'townLocationName',
            'districtName',
            'city',
            'region',
            'postCode',
            'countryCode',
        ];

        return this.dynamicDataService.getCountries().pipe(
            take(1),
            map((countries) => {
                const addressString = addressLine
                    .map((field) => fieldValue[field])
                    .filter((field) => Boolean(field))
                    .join(', ');

                const formattedAddressFields = addressFields
                    .map((field) => {
                        if (field === 'countryCode') {
                            return countries.find(
                                (country) =>
                                    country.isoCode === fieldValue[field]
                            )?.name;
                        } else {
                            return fieldValue[field];
                        }
                    })
                    .filter((field) => Boolean(field));

                return [addressString, ...formattedAddressFields];
            })
        );
    }
}
