import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { LookupMode } from '@wdx/shared/utils';
import {
    LookupFieldResult,
    LookupSourceDefinition,
} from '@wdx/clmi/api-models';
import { BaseInputClass } from '../../../classes/base-input-class';
import { ICON_ADD, ICON_INFO } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { InfoCardData } from '../../../models/info-card-data.model';
import { InfoCardSearchMode } from '../../../models/info-card-search-mode.model';
import { LookupSearchEvent } from '../../../models/lookup-options.model';

import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { afterLifecyleEvents } from '../../../shared/helpers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-info-card-search',
    templateUrl: './molecule-info-card-search.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex flex-column h-100 overflow-hidden' },
})
export class MoleculeInfoCardSearchComponent extends BaseInputClass {
    @Input() mode: InfoCardSearchMode = InfoCardSearchMode.Select;
    @Input() infoCards: InfoCardData[];
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() clientSideSearch = false;
    @Input() lookupMode = LookupMode.Standard;
    @Input() bannerInfo: string;
    @Input() placeholder: string;

    @Output() search = new EventEmitter<LookupSearchEvent>();
    @Output() confirm = new EventEmitter();
    @Output() generateDocument = new EventEmitter<string>();
    @Output() previewAvailableDocumentData =
        new EventEmitter<LookupFieldResult>();

    inputName: string;
    searchText: string;
    isQuickCreateEnabled = false;
    showQuickCreateButton = false;
    canConfirm: boolean;
    activeLookupSourceDefinition: LookupSourceDefinition;
    multipleLookupEnabled: boolean;
    createActionButton: ActionButton;

    readonly MODE = InfoCardSearchMode;
    readonly LOOKUP_MODE = LookupMode;
    readonly CREATE_NEW = 'Create New';
    readonly CONFIRM = 'Confirm';
    readonly ICON_INFO = ICON_INFO.icon;

    constructor(elementRef: ElementRef) {
        super();

        this.patchInjectedItems({
            elementRef,
        });

        this.requireConfirmation = true;

        this.inputName = new RandomStringPipe().transform();

        afterLifecyleEvents(() => {
            this.multipleLookupEnabled =
                this.formInputData?.lookupSources?.length > 1;

            if (this.formInputData?.lookupSources?.[0]) {
                this.setLookupSourceType(
                    this.formInputData?.lookupSources?.[0]
                );
            }
            this.onSearch('');
        });
    }

    setLookupSourceType(lookupSourceField: LookupSourceDefinition) {
        this.activeLookupSourceDefinition = lookupSourceField;

        this.isQuickCreateEnabled = Boolean(
            lookupSourceField.quickCreateFormName
        );

        this.createActionButton = this.isQuickCreateEnabled
            ? this.getCreateActionButton(lookupSourceField)
            : null;
    }

    getCreateActionButton(
        lookupSourceField: LookupSourceDefinition
    ): ActionButton {
        return {
            mode: ActionButtonMode.StandardButton,
            icon: ICON_ADD.icon,
            formSetup: {
                isQuickCreate: true,
                formId: lookupSourceField.quickCreateFormName,
            },
        } as ActionButton;
    }

    parsedInfoCards(
        clientSideSearch: boolean,
        infoCards: InfoCardData[],
        searchText: string
    ): InfoCardData[] {
        return clientSideSearch && searchText?.length > 0
            ? infoCards?.filter((infoCard) =>
                  [
                      infoCard.primaryInfo?.toString(),
                      infoCard.secondaryInfo?.toString(),
                      infoCard.tertiaryInfo?.toString(),
                  ]
                      .filter((value) => Boolean(value))
                      .some((value) =>
                          value
                              .toLowerCase()
                              .includes(searchText?.toLowerCase())
                      )
              )
            : infoCards;
    }

    onConfirmClicked() {
        this.onConfirm();
        this.confirm.emit();
    }

    onSearch(searchText: string, minimumSearchChars?: number): void {
        const invalidSearchCriteria: boolean =
            searchText &&
            minimumSearchChars &&
            searchText.length < minimumSearchChars;

        if (invalidSearchCriteria) {
            return;
        }

        this.searchText = searchText;
        this.search.emit({
            searchText,
            lookupSourceDefinition: this.activeLookupSourceDefinition,
        });
        if (searchText) {
            this.showQuickCreateButton = true;
        }
    }

    onGenerateAndDownloadDocument(): void {
        this.generateDocument.emit(this.value?.id);
    }

    onPreviewAvailableDocumentData(): void {
        this.previewAvailableDocumentData.emit(this.value);
    }

    onLookupSourceTypeChange(lookupSourceDefinition: LookupSourceDefinition) {
        this.updateValue(null);
        this.setLookupSourceType(lookupSourceDefinition);
        this.search.emit({
            searchText: this.searchText,
            lookupSourceDefinition,
        });
    }
}
