import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { ImportFileUploaderModule } from '../../../shared/features/import-file-uploader';
import { ImportExportComponent } from './import-export.component';

@NgModule({
    declarations: [ImportExportComponent],
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        ImportFileUploaderModule,
    ],
    exports: [ImportExportComponent],
})
export class ImportExportModule {}
