import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as avatarInfoActions from './avatar-info.actions';
import { AvatarInfoService } from '@wdx/clmi/api-services/services';

@Injectable()
export class AvatarInfoEffects {
    private actions$ = inject(Actions);
    private avatarInfoService = inject(AvatarInfoService);

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(avatarInfoActions.getForId),
            mergeMap((action) =>
                this.avatarInfoService.getForId(action.partyId).pipe(
                    map((avatarInfo) =>
                        avatarInfoActions.getForIdSuccess({
                            partyId: action.partyId,
                            avatarInfo,
                        })
                    ),
                    catchError((error) =>
                        of(
                            avatarInfoActions.getForIdFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
