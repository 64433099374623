import { Injectable } from '@angular/core';
import {
    Document,
    FilterOperator,
    QueryExpression,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT_FORM_ID } from '../../../../../constants/dynamic-form.constants';
import { ICON_ADD } from '../../../../../constants/icons.constants';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { DialogMode } from '../../../../../models/dialog.model';
import { DialogService } from '../../../../../services/dialog.service';
import { FilterQueryFacadeService } from '../../../../../shared/features/form-filters';
import { FeaturesService } from '../../../../../shared/services/features/features.service';
import * as documentsActions from '../../../../../state/documents/documents.actions';

@Injectable({
    providedIn: 'root',
})
export class FiledDocumentsService {
    constructor(
        private filterQueryFacadeService: FilterQueryFacadeService,
        private dialogService: DialogService,
        private featuresService: FeaturesService
    ) {}

    get isDMSEnabled() {
        return this.featuresService.hasFeature(FeatureFlag.DMSExternal);
    }

    get isClmiEnabled() {
        return this.featuresService.hasFeature(FeatureFlag.DMSCLMi);
    }

    get isFiledDocumentsEnabled() {
        return this.isDMSEnabled || this.isClmiEnabled;
    }

    getContextual(entityType: SystemEntity, entityId: string): QueryExpression {
        return {
            field: 'regarding',
            operator: FilterOperator.Equals,
            contextual: true,
            values: [this.getContextualValue(entityType, entityId)],
        } as QueryExpression;
    }

    getContextualValue(entityType: SystemEntity, entityId: string) {
        return {
            id: entityId,
            lookupType: entityType,
        };
    }

    getTotalDocumentCount$() {
        return combineLatest([
            this.filterQueryFacadeService.getFilterPaging$(
                SystemEntity.Document
            ),
        ]).pipe(
            map(([paging]) =>
                paging?.totalRecords > 0 ? paging?.totalRecords : 0
            )
        );
    }

    getAddDocumentActionButton(
        entityId: string,
        entityType: SystemEntity
    ): ActionButton {
        if (entityId && entityType) {
            return {
                mode: ActionButtonMode.IconButton,
                cySelector: 'btn-add-document',
                icon: ICON_ADD.icon,
                formSetup: {
                    formId: DOCUMENT_FORM_ID,
                    initialisationParams: {
                        [`${entityType.replace(/\b(\w)/g, (s) =>
                            s.toLowerCase()
                        )}Id`]: entityId,
                    },
                },
            };
        }
        return null;
    }

    deleteDocument(document: Document) {
        this.dialogService.open({
            dialogMode: DialogMode.DeleteDocument,
            confirmAction: documentsActions.deleteDocument({
                documentId: document.id,
            }),
            data: { name: document.name },
        });
    }
}
