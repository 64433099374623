import { Pipe, PipeTransform } from '@angular/core';
import { TranslationKey } from '@wdx/clmi/api-services/services';
import { TranslationsService } from '../../services';

@Pipe({
    name: 'translate',
})
export class TranslatePipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: string | TranslationKey): string {
        if (!value) {
            return '';
        }
        return this.translationsService.getTranslationByKey(
            value?.toUpperCase()
        );
    }
}
