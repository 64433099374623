<label
    *ngIf="label"
    class="form-label"
    [class.text-muted]="status === 'muted'"
    [attr.for]="labelForId || ''"
>
    <span [innerHtml]="label | markdownToHtml | safeHtml"></span>
    <sup *ngIf="required">
        <wdx-icon icon="asterisk" class="ms-1 text-danger"></wdx-icon>
    </sup>
</label>
