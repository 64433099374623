import { Directive, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    FormElementType,
    FormFieldType,
    PendingChangeStatusType,
} from '@wdx/clmi/api-models';
import { PENDING_CHANGES_TEXT } from '@wdx/shared/infrastructure/form-framework';
import { WdxDestroyClass } from '@wdx/shared/utils';

import { ContextualDataContext } from '@wdx/clmi/api-services/models';
import { ExtendedFieldDefinitionOption } from '../../../models/extended-field-definition-option.model';
import { ReactiveFormLayoutAndDefinition } from '../../../models/reactive-dynamic.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ReactiveFormDynamicDataService } from '../../../shared/services/dynamic-data';
import { PENDING_CHANGES_LOCK_FIELD } from '../constants/control-types';

@Directive()
export class BaseReactiveFormControlClass extends WdxDestroyClass {
    _formElement: ReactiveFormLayoutAndDefinition;

    get formElement(): ReactiveFormLayoutAndDefinition {
        return this._formElement;
    }

    @Input() set formElement(formElement: ReactiveFormLayoutAndDefinition) {
        this._formElement = formElement;

        this.formControl = this.controlContainer?.control.get(formElement.name);

        if (this.formElement.options) {
            this.options$.next(
                this.filterInActiveOptions(this.formElement.options)
            );
        }

        if (this.formElement.selectSource) {
            this.setOptionsForSelectSource();
        }

        if (formElement.pendingChange) {
            this.warningText = PENDING_CHANGES_TEXT;
        }
    }

    inputId = new RandomStringPipe().transform();

    options$ = new BehaviorSubject<ExtendedFieldDefinitionOption[]>([]);

    readonly FORM_ELEMENT_TYPE = FormElementType;
    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly PENDING_CHANGE_STATUS_TYPE = PendingChangeStatusType;
    readonly PENDING_CHANGES_LOCK_FIELD = PENDING_CHANGES_LOCK_FIELD;
    warningText = null;

    formControl: AbstractControl;

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService
    ) {
        super();
    }

    setOptionsForSelectSource() {
        this.getOptionsForSelectSource$()
            .pipe(take(1))
            .subscribe((options) => this.options$.next(options));
    }

    getOptionsForContextSelectSource$(
        lookups: any[],
        context?: ContextualDataContext[]
    ) {
        return this.dynamicDataService
            .getContextSelectApiSourceOptions(
                this.formElement.selectSource,
                lookups,
                context
            )
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    getOptionsForSelectSource$(params = {}) {
        return this.dynamicDataService
            .getSelectApiSourceOptions(this.formElement.selectSource, params)
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    filterInActiveOptions(options: ExtendedFieldDefinitionOption[]) {
        return options.filter(
            (option) =>
                option.value === this.formControl?.value ||
                option.active !== false
        );
    }
}
