import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PushModule } from '@ngrx/component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxCheckboxTileModule } from '@wdx/shared/components/wdx-checkbox-tile';
import { WdxCheckboxTileGroupModule } from '@wdx/shared/components/wdx-checkbox-tile-group';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxInputDateModule } from '@wdx/shared/components/wdx-input-date';
import { WdxInputDateRangeModule } from '@wdx/shared/components/wdx-input-date-range';
import { WdxInputGroupModule } from '@wdx/shared/components/wdx-input-group';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxInputTimeModule } from '@wdx/shared/components/wdx-input-time';
import { WdxLabelModule } from '@wdx/shared/components/wdx-label';
import { WdxRadioModule } from '@wdx/shared/components/wdx-radio';
import { WdxRadioButtonModule } from '@wdx/shared/components/wdx-radio-button';
import { WdxRadioButtonGroupModule } from '@wdx/shared/components/wdx-radio-button-group';
import {
    WdxRadioTileGroupModule,
    WdxRadioTileModule,
} from '@wdx/shared/components/wdx-radio-tile';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { AtomIconButtonModule } from '../../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeDocumentInfoModule } from '../../../../components/molecules/molecule-document-info/molecule-document-info.module';
import { MoleculeReactiveInputLookupModule } from '../../../../components/molecules/molecule-reactive-controls/_molecule-input-lookup/molecule-reactive-input-lookup.module';
import { MoleculeReactiveInputTagsModule } from '../../../../components/molecules/molecule-reactive-controls/_molecule-input-tags/molecule-reactive-input-tags.module';
import { customCurrencyMaskConfig } from '../../../../constants/currency-mask.constants';
import { FormPipesModule } from '../../../../features/reactive-forms/pipes/form-pipes.module';
import { PipesModule } from '../../../../pipes/pipes.module';
import { FileUploadModule } from '../../../../shared/features/files/components/file-upload/file-upload.module';
import { FormSummaryPipesModule } from '../../../../shared/features/form-summary/pipes/form-summary-pipes.module';
import { MoleculeValidationSummaryModule } from '../molecule-validation-summary/molecule-validation-summary.module';
import { ReactiveAddressControlComponent } from './reactive-address-control/reactive-address-control.component';
import { ReactiveDocumentLookupControlComponent } from './reactive-document-lookup-control/reactive-document-lookup-control.component';
import { ReactiveFileUploadControlComponent } from './reactive-file-upload-control/reactive-file-upload-control.component';
import { ReactiveFormControlsComponent } from './reactive-form-controls.component';
import { ReactiveHierarchicalControlComponent } from './reactive-hierarchical-control/reactive-hierarchical-control.component';
import { ReactiveRichTextControlComponent } from './reactive-rich-text-control/reactive-rich-text-control.component';
import { ReactiveSelectControlModule } from './reactive-select-control/reactive-select-control.module';
import { ReactiveTelephoneControlComponent } from './reactive-telephone-control/reactive-telephone-control.component';
import { ReactiveWrappedControlComponent } from './reactive-wrapped-control/reactive-wrapped-control.component';
import {
    FormControlsModule,
    IFormDynamicData,
} from '@wdx/shared/infrastructure/form-framework';
import { ReactiveFormDynamicDataService } from '../../../../shared/services';

@NgModule({
    declarations: [
        ReactiveFormControlsComponent,
        ReactiveAddressControlComponent,
        ReactiveHierarchicalControlComponent,
        ReactiveRichTextControlComponent,
        ReactiveTelephoneControlComponent,
        ReactiveWrappedControlComponent,
        ReactiveDocumentLookupControlComponent,
        ReactiveFileUploadControlComponent,
    ],
    imports: [
        AtomIconButtonModule,
        CommonModule,
        EditorModule,
        FileUploadModule,
        FormPipesModule,
        FormSummaryPipesModule,
        MoleculeActionButtonModule,
        MoleculeDocumentInfoModule,
        MoleculeReactiveInputLookupModule,
        MoleculeReactiveInputTagsModule,
        MoleculeValidationSummaryModule,
        NgSelectModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxMaskModule.forRoot(),
        PipesModule,
        PushModule,
        ReactiveFormsModule,
        ReactiveSelectControlModule,
        WdxCheckboxModule,
        WdxCheckboxModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileModule,
        WdxCheckboxTileModule,
        WdxFormFieldModule,
        WdxIconModule,
        WdxIconModule,
        WdxIconStackModule,
        WdxInputDateModule,
        WdxInputDateRangeModule,
        WdxInputTextModule,
        WdxInputTimeModule,
        WdxLabelModule,
        WdxRadioButtonGroupModule,
        WdxRadioButtonModule,
        WdxRadioModule,
        WdxRadioTileGroupModule,
        WdxRadioTileModule,
        WdxInputGroupModule,
        FormControlsModule,
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        { provide: IFormDynamicData, useClass: ReactiveFormDynamicDataService },
    ],
    exports: [ReactiveFormControlsComponent],
})
export class ReactiveFormControlsModule {}
