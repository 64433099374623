import { SystemEntity } from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const assignTo = createAction(
    '[Assign] Assign To',
    props<{
        entityType: SystemEntity;
        entityId: string;
        partyId: string;
    }>()
);

export const assignToSuccess = createAction(
    '[Assign] Assign To Success',
    props<{
        entityType: SystemEntity;
        entityId: string;
        partyId: string;
    }>()
);

export const assignToFailure = createAction(
    '[Assign] Assign To Failure',
    props<{
        entityId: string;
        partyId: string;
        error: Error;
    }>()
);
