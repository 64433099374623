import { createSelector } from '@ngrx/store';
import * as outcomesReducer from './outcomes.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    outcomes: outcomesReducer.State;
}): outcomesReducer.State => state.outcomes;

export const getWorkItemOutcomesStatus = (id: string) =>
    createSelector(
        getState,
        (state: outcomesReducer.State) => state.workItemOutcomes[id]?.status
    );
export const getWorkItemOutcomesIsUpdating = createSelector(
    getState,
    (state: outcomesReducer.State, props: any) =>
        state.workItemOutcomes[props.id]?.isUpdating
);
export const getWorkItemOutcomesHasUpdatingError = createSelector(
    getState,
    (state: outcomesReducer.State, props: any) =>
        state.workItemOutcomes[props.id]?.hasUpdatingError
);

export const getWorkItemOutcomesById = createSelector(
    getState,
    (state: outcomesReducer.State, props: { id: string }) =>
        state.workItemOutcomes[props.id]?.single
);

/**
 * Reducer property selectors
 */
