import { FilterOperator } from '@wdx/clmi/api-models';

export const DATE_TO_INT = [
    FilterOperator.LastXDays,
    FilterOperator.LastXHours,
    FilterOperator.NextXDays,
    FilterOperator.NextXHours,
];

export const INPUT_NUMBER = 'number';
