import { MenuItem } from '../models/menu-item.model';

export class BaseMenuItemClass {
    get menuItem(): MenuItem {
        return this.menuItem_;
    }
    private menuItem_: MenuItem;
    constructor(menuItem: MenuItem) {
        this.menuItem_ = menuItem;
    }
}
