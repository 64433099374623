import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AsyncSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FormDefinition } from '@wdx/clmi/api-models';
import * as rootReducer from '../../../../state/_setup/reducers';
import * as dynamicFormsActions from '../../../../state/dynamic-forms/dynamic-forms.actions';
import * as dynamicFormsSelectors from '../../../../state/dynamic-forms/dynamic-forms.selectors';

@Injectable()
export class ReactiveFormPresentationService {
    formDefinition$ = new AsyncSubject<FormDefinition>();
    formDefinitionIsLoading$: Observable<boolean>;
    formDefinitionHasError$: Observable<boolean>;

    constructor(private store$: Store<rootReducer.State>) {}

    queryFormDefinition(formId: string): void {
        this.store$.dispatch(dynamicFormsActions.getFormDefinition({ formId }));
        this.getFormDefinition(formId);
        this.getFormDefinitionIsLoading(formId);
        this.getFormDefinitionHasError(formId);
    }

    getFormDefinition(formId: string): void {
        this.store$
            .select(dynamicFormsSelectors.getFormDefinition, { id: formId })
            .pipe(filter((res) => Boolean(res)))
            .subscribe((res) => {
                this.formDefinition$.next(res);
                this.formDefinition$.complete();
            });
    }

    getFormDefinitionIsLoading(formId: string): void {
        this.formDefinitionIsLoading$ = this.store$.select(
            dynamicFormsSelectors.getIsLoadingFormDefinition,
            { id: formId }
        );
    }

    getFormDefinitionHasError(formId: string): void {
        this.formDefinitionHasError$ = this.store$.select(
            dynamicFormsSelectors.getHasLoadingFormDefinitionError,
            { id: formId }
        );
    }
}
