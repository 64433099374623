import { createSelector } from '@ngrx/store';
import * as selectsReducer from './selects.reducer';
/**
 * Reducer state selector
 */
export const getState = (state: {
    selects: selectsReducer.State;
}): selectsReducer.State => state.selects;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        state.selects[props.selectSource]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        state.selects[props.selectSource]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        state.selects[props.selectSource]?.list
);

/**
 * Derived selectors
 */
export const getIsLoadingListForIds = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        props.ids.some((id) => state.selects[id]?.isLoadingList)
);

export const getHasLoadingListErrorForIds = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        props.ids.some((id) => state.selects[id]?.hasLoadingListError)
);

export const getListForIds = createSelector(
    getState,
    (state: selectsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.selects[id]?.list || [],
            }),
            {}
        )
);
