import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldDefinitionOption } from '@wdx/clmi/api-models';

@Component({
    selector: 'clmi-form-summary-info-cards',
    templateUrl: './form-summary-info-cards.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSummaryInfoCardsComponent {
    @Input() infoCards: FieldDefinitionOption[];
}
