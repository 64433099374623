import { Action, createReducer, on } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/clmi/api-models';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import * as selectsActions from './selects.actions';

export interface State {
    selects: CrudStateObject<SelectFieldResult>;
}

export const initialState: State = {
    selects: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        selectsActions.getSelect,
        selectsActions.getContextualSelect,
        (state, props): State => ({
            ...state,
            selects: {
                ...state.selects,
                [props.selectSource]: {
                    ...(state.selects[props.selectSource] ||
                        ({} as CrudStateObject<SelectFieldResult>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        selectsActions.getSelectSuccess,
        selectsActions.getContextualSelectSuccess,
        (state, props): State => ({
            ...state,
            selects: {
                ...state.selects,
                [props.selectSource]: {
                    ...state.selects[props.selectSource],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.results,
                },
            },
        })
    ),

    on(
        selectsActions.getSelectFailure,
        selectsActions.getContextualSelectFailure,
        (state, props): State => ({
            ...state,
            selects: {
                ...state.selects,
                [props.selectSource]: {
                    ...state.selects[props.selectSource],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
