import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { PowerBIEmbedReport, PowerBIReport } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';

const POWER_BI_ENDPOINT = 'system/powerbi/poc';

@Injectable({ providedIn: 'root' })
export class PowerBiRequestsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getPowerBiList(): Observable<PowerBIReport[]> {
        return this.http.get<PowerBIReport[]>(
            `${this.config.getConfiguration().API_BASE}/${POWER_BI_ENDPOINT}`
        );
    }

    getDashboardConfig(
        powerBiDashboardId: string
    ): Observable<PowerBIEmbedReport> {
        return this.http.get<PowerBIEmbedReport>(
            `${
                this.config.getConfiguration().API_BASE
            }/${POWER_BI_ENDPOINT}/${powerBiDashboardId}`
        );
    }
}
