<div class="form-validation alert alert-danger mb-0">
    <div class="d-flex align-items-center mb-2">
        <div class="form-validation__icon">
            <wdx-icon
                class="d-block mt-n1 me-2 text-danger-contrast"
                size="lg"
                scale="1.25"
            ></wdx-icon>
        </div>
        <div class="form-validation__message">{{ message }}:</div>
        <div class="ms-auto">
            <ng-content select="button"></ng-content>
        </div>
    </div>

    <div class="form-validation__list" data-cy="validation-list">
        <ng-container *ngIf="isArrayOfSections; else singleSection">
            <div
                *ngFor="let section of validationSummary; let last = last"
                class="d-md-flex align-items-center"
                [class.mb-2]="!last"
            >
                <span *ngIf="section.label" class="me-2">
                    {{ section.label }}:
                </span>
                <div>
                    <ng-container
                        *ngTemplateOutlet="
                            valdationSection;
                            context: { section: section }
                        "
                    ></ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #singleSection>
        <ng-container
            *ngTemplateOutlet="
                valdationSection;
                context: { section: validationSummary }
            "
        ></ng-container>
    </ng-template>
</div>

<ng-template #valdationSection let-section="section">
    <ng-container *ngFor="let error of section?.errors; let last = last">
        <button
            type="button"
            class="btn btn-link text-start text-danger-contrast"
            [attr.data-cy]="'btn-' + error.fieldLabel"
            (click)="onErrorClicked(error)"
        >
            <span
                [innerHTML]="error.fieldLabel | markdownToHtml | safeHtml"
            ></span>
        </button>
        <ng-container *ngIf="!last">,&nbsp;</ng-container>
    </ng-container>
</ng-template>
