<ng-container [formGroup]="controlContainer?.control">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            numberControl?.touched && numberControl?.errors
                | validationErrorMessage : formElement
        "
        [warningMessage]="warningText"
    >
        <div class="input-group" [formGroup]="telephoneFormGroup">
            <ng-select
                #countrySelect
                formControlName="country"
                bindLabel="name"
                bindValue="isoCode"
                [attr.data-cy]="'dropdown-telephone-' + formElement?.label"
                [clearable]="false"
                [readonly]="
                    formControl.disabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        formElement?.pendingChange?.status
                    )
                "
            >
                <ng-option
                    *ngFor="
                        let option of countries$ | ngrxPush;
                        let index = index
                    "
                    attr.data-cy="option-country"
                    [value]="option.isoCode"
                >
                    <span class="d-flex align-items-center">
                        <span
                            class="flag flag-{{
                                option?.isoCode | lowercase
                            }} me-1"
                        ></span>
                        <span class="hide-on-trigger ms-1">{{
                            option.name
                        }}</span>
                        <span class="text-muted mx-1">
                            {{ option.dialCode }}
                        </span>
                    </span>
                </ng-option>
            </ng-select>
            <input
                wdxInputText
                formControlName="number"
                type="tel"
                inputmode="tel"
                [mask]="mask"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.maxlength]="formElement.max || 35"
                [readonly]="
                    formControl?.disabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        formElement?.pendingChange?.status
                    )
                "
            />
            <ng-select
                *ngIf="(options$ | ngrxPush)?.length > 1"
                formControlName="type"
                bindLabel="label"
                bindValue="value"
                placeholder="Select"
                data-cy="select-type"
                [items]="options$ | ngrxPush"
                [searchable]="false"
                [clearable]="false"
                [readonly]="
                    formControl?.disabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        formElement?.pendingChange?.status
                    )
                "
            >
            </ng-select>
            <div
                *ngIf="(options$ | ngrxPush)?.length === 1"
                class="input-group-text"
            >
                {{ (options$ | ngrxPush)[0]?.label }}
            </div>
        </div>
    </wdx-form-field>
</ng-container>
