import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[splitbutton]',
})
export class AtomSplitButtonDirective implements AfterViewInit {
    constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

    ngAfterViewInit() {
        const hostElement = this.hostElement.nativeElement;
        const button = hostElement.querySelector('button');

        if (button) {
            this.renderer.addClass(button, 'split-button');
        }
    }
}
