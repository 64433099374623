import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Country,
    Currency,
    FormDefinition,
    FormSectionLayoutDefinition,
    Locale,
    SelectApiSource,
    Tag,
} from '@wdx/clmi/api-models';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../../constants/state.constants';
import { ExtendedFieldDefinitionOption } from '../../../models/extended-field-definition-option.model';
import { Privilege } from '../../../models/privilege.model';
import * as rootReducer from '../../../state/_setup/reducers';
import * as countriesSelectors from '../../../state/countries/countries.selectors';
import * as currenciesSelectors from '../../../state/currencies/currencies.selectors';
import * as dynamicFormsActions from '../../../state/dynamic-forms/dynamic-forms.actions';
import * as dynamicFormsSelectors from '../../../state/dynamic-forms/dynamic-forms.selectors';
import * as selectsActions from '../../../state/selects/selects.actions';
import * as selectsSelectors from '../../../state/selects/selects.selectors';
import * as tagsActions from '../../../state/tags/tags.actions';
import * as tagsSelectors from '../../../state/tags/tags.selectors';
import { ContextualDataContext } from '@wdx/clmi/api-services/models';
import { IFormDynamicData } from '@wdx/shared/infrastructure/form-framework';

@Injectable({
    providedIn: 'root',
})
export class ReactiveFormDynamicDataService implements IFormDynamicData {
    select = [];
    functions = [];
    forms = [];
    lookup = [];

    readonly PRIVILEGE = Privilege;

    constructor(private store$: Store<rootReducer.State>) {}

    getMeLocale(): Observable<Locale> {
        return this.store$.select(userSelectors.getMeLocaleSelector);
    }

    getCurrencies(): Observable<Currency[]> {
        return this.store$.select(currenciesSelectors.getList, {
            id: GLOBAL_STATE_INDEX_ID,
        });
    }

    getCountries(): Observable<Country[]> {
        return this.store$.select(countriesSelectors.getList, {
            id: GLOBAL_STATE_INDEX_ID,
        });
    }

    getTagsForCategory(tagCategory: string): Observable<Tag[]> {
        return this.store$
            .select(tagsSelectors.getList, { id: tagCategory })
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.store$.dispatch(
                            tagsActions.getForCategory({
                                category: { id: tagCategory },
                            })
                        );
                    }
                })
            );
    }

    getSelectApiSourceOptions(
        selectSource: SelectApiSource,
        selectQueryParams?: Record<string, string>
    ): Observable<ExtendedFieldDefinitionOption[]> {
        const queryParamsString =
            selectQueryParams &&
            new HttpParams({
                fromObject: selectQueryParams,
            }).toString();

        const selectSourceKey =
            selectSource + (queryParamsString ? `?${queryParamsString}` : '');

        return this.store$
            .select(selectsSelectors.getList, { selectSource: selectSourceKey })
            .pipe(
                tap((res) => {
                    if (!res && !this.select.includes(selectSourceKey)) {
                        this.select.push(selectSourceKey);
                        this.store$.dispatch(
                            selectsActions.getSelect({
                                selectSource: selectSourceKey,
                            })
                        );
                    }
                }),
                filter((res) => Boolean(res)),
                map((selectFieldResults) =>
                    selectFieldResults.map((selectFieldResult) => ({
                        label: selectFieldResult.name,
                        value: selectFieldResult.id,
                        active: selectFieldResult.active !== false,
                        ...(selectFieldResult.icon && {
                            icon: selectFieldResult.icon,
                        }),
                        ...(selectFieldResult.level !== undefined && {
                            level: selectFieldResult.level,
                        }),
                    }))
                )
            );
    }

    getContextSelectApiSourceOptions(
        source: SelectApiSource,
        lookups: any[],
        context?: ContextualDataContext[]
    ): Observable<ExtendedFieldDefinitionOption[]> {
        const selectSource =
            source +
            btoa(JSON.stringify(lookups)) +
            btoa(JSON.stringify(context));

        return this.store$
            .select(selectsSelectors.getList, { selectSource })
            .pipe(
                tap((res) => {
                    if (!res && !this.select.includes(selectSource)) {
                        this.select.push(selectSource);
                        this.store$.dispatch(
                            selectsActions.getContextualSelect({
                                selectSource,
                                source,
                                lookups,
                                context,
                            })
                        );
                    }
                }),
                filter((res) => Boolean(res)),
                map((selectFieldResults) =>
                    selectFieldResults.map((selectFieldResult) => ({
                        label: selectFieldResult.name,
                        value: selectFieldResult.id,
                        active: selectFieldResult.active !== false,
                        ...(selectFieldResult.icon && {
                            icon: selectFieldResult.icon,
                        }),
                        ...(selectFieldResult.level !== undefined && {
                            level: selectFieldResult.level,
                        }),
                    }))
                )
            );
    }

    getFormLayoutAndDefinition(formId: string): Observable<{
        definition: FormDefinition;
        layoutAndDefinitions: FormSectionLayoutDefinition[];
    }> {
        return combineLatest([
            this.store$.select(dynamicFormsSelectors.getFormDefinition, {
                id: formId,
            }),
        ]).pipe(
            tap(([definition]) => {
                if (!definition && !this.forms.includes(formId)) {
                    this.store$.dispatch(
                        dynamicFormsActions.getFormDefinition({
                            formId: formId,
                        })
                    );
                    this.forms.push(formId);
                }
            }),
            filter(([definition]) => Boolean(definition)),
            map(([definition]) => {
                return {
                    definition: definition,
                    layoutAndDefinitions:
                        definition?.layout.sectionLayoutDefinitions.map(
                            (sectionLayoutDefinition) => {
                                return {
                                    name: sectionLayoutDefinition.name,
                                    label: sectionLayoutDefinition.label,
                                    elementLayoutDefinitions:
                                        sectionLayoutDefinition.elementLayoutDefinitions.map(
                                            (elementLayoutDefinition) => {
                                                const schema =
                                                    definition.schema.find(
                                                        (definitionSchema) =>
                                                            definitionSchema.name ===
                                                            elementLayoutDefinition.name
                                                    );
                                                const elementLayoutWithSchema =
                                                    {
                                                        ...elementLayoutDefinition,
                                                        sectionLayoutDefinitions:
                                                            elementLayoutDefinition.sectionLayoutDefinitions.map(
                                                                (
                                                                    sectionLayoutDefinition
                                                                ) => {
                                                                    const defsWithChildSchema =
                                                                        sectionLayoutDefinition.elementLayoutDefinitions.map(
                                                                            (
                                                                                def
                                                                            ) => {
                                                                                const childSchema =
                                                                                    schema.childSchema.find(
                                                                                        (
                                                                                            definitionChildSchema
                                                                                        ) =>
                                                                                            definitionChildSchema.name ===
                                                                                            def.name
                                                                                    );
                                                                                return {
                                                                                    ...def,
                                                                                    ...childSchema,
                                                                                };
                                                                            }
                                                                        );
                                                                    return {
                                                                        ...sectionLayoutDefinition,
                                                                        elementLayoutDefinitions:
                                                                            defsWithChildSchema,
                                                                    };
                                                                }
                                                            ),
                                                    };
                                                return {
                                                    ...schema,
                                                    ...elementLayoutWithSchema,
                                                };
                                            }
                                        ),
                                };
                            }
                        ) as FormSectionLayoutDefinition[],
                };
            })
        );
    }

    compareOptionValue(optionValue: any, fieldValue: any) {
        if (
            optionValue != null &&
            typeof optionValue.valueOf() === 'string' &&
            typeof fieldValue === 'string'
        ) {
            return optionValue.toLowerCase() === fieldValue.toLowerCase();
        }
        return optionValue === fieldValue;
    }
}
