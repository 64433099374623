export const APP_CAMPAIGN_ENGAGEMENT = 'appCampaignEngagement';
export const APP_CLIENT_INSIGHTS = 'appClientInsights';
export const APP_CLIENT_PRODUCTS = 'appClientProducts';
export const APP_CLIENTS = 'appClients';
export const APP_EMPLOYEES = 'appEmployees';
export const APP_INBOX = 'appInbox';
export const APP_TASKS = 'appTasks';
export const APP_LISTS = 'appLists';

export const APP_TYPES = [
    APP_CAMPAIGN_ENGAGEMENT,
    APP_CLIENT_INSIGHTS,
    APP_CLIENT_PRODUCTS,
    APP_CLIENTS,
    APP_EMPLOYEES,
    APP_INBOX,
    APP_TASKS,
    APP_LISTS,
];
