import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxAlertModule } from '@wdx/shared/components/wdx-alert';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { PendingChangesComponent } from './components/pending-changes/pending-changes.component';

@NgModule({
    declarations: [PendingChangesComponent],
    imports: [CommonModule, WdxIconModule, WdxAlertModule, WdxButtonModule],
    exports: [PendingChangesComponent],
})
export class WdxFormFrameworkModule {}
