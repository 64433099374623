import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxRadioButtonComponent } from './wdx-radio-button.component';

@NgModule({
    imports: [CommonModule, WdxIconModule],
    declarations: [WdxRadioButtonComponent],
    exports: [WdxRadioButtonComponent],
})
export class WdxRadioButtonModule {}
