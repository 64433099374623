import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { KeyValueObject } from '@wdx/shared/utils';
import { filter, take } from 'rxjs/operators';
import * as dynamicFormsActions from '../../../../state/dynamic-forms/dynamic-forms.actions';

@Component({
    selector: 'clmi-quick-create-modal',
    templateUrl: './quick-create-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickCreateModalComponent implements OnInit {
    formTitle: string;
    formId: string;
    entityId: string;
    initialisationParams: KeyValueObject;
    modalInstanceId?: string;

    @Output() createSuccess = new EventEmitter();

    constructor(private actionsSubject$: ActionsSubject) {}

    ngOnInit(): void {
        this.actionsSubject$
            .pipe(
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
                ),
                filter((action: any) => action?.formId === this.formId),
                take(1)
            )
            .subscribe(() => this.createSuccess.emit());
    }

    onFormTitleReceived(formTitle: string) {
        this.formTitle = formTitle;
    }
}
