import { createSelector } from '@ngrx/store';
import * as dashboardsReducer from './dashboards.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    dashboard: dashboardsReducer.State;
}): dashboardsReducer.State => state.dashboard;

export const getDashboards = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards.list
);

export const getDashboardById = createSelector(
    getState,
    (state: dashboardsReducer.State, props: { id: string }) =>
        state.dashboards.list?.find((dashboard) => dashboard.id === props.id)
);

export const getDashboardsIsUpdating = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards.isUpdating
);

export const getDashboardsIsLoading = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards.isLoadingList
);
