import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as teamActions from './team.actions';
import { TeamService } from './team.service';

@Injectable()
export class TeamEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private teamService = inject(TeamService);

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(teamActions.getForId),
            mergeMap((action) =>
                this.teamService.getForId(action.teamId).pipe(
                    map((team) =>
                        teamActions.getForIdSuccess({
                            team,
                        })
                    ),
                    catchError((error) =>
                        of(
                            teamActions.getForIdFailure({
                                teamId: action.teamId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getTeamMembersForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(teamActions.getTeamMembersForId),
            mergeMap((action) =>
                this.teamService.getTeamMembersForId(action.teamId).pipe(
                    map((teamMembers) =>
                        teamActions.getTeamMembersForIdSuccess({
                            teamId: action.teamId,
                            teamMembers,
                        })
                    ),
                    catchError((error) =>
                        of(
                            teamActions.getTeamMembersForIdFailure({
                                teamId: action.teamId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(teamActions.deleteTeamMember),
            mergeMap((action) =>
                this.teamService
                    .deleteTeamMember(action.teamId, action.memberId)
                    .pipe(
                        map(() =>
                            teamActions.deleteTeamMemberSuccess({
                                teamId: action.teamId,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                teamActions.deleteTeamMemberFailure({
                                    teamId: action.teamId,
                                    memberId: action.memberId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getHistoryForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(teamActions.getHistoryForId),
            mergeMap((action) =>
                this.teamService.getHistoryForId(action.teamId).pipe(
                    map((history) =>
                        teamActions.getHistoryForIdSuccess({
                            history,
                            teamId: action.teamId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            teamActions.getHistoryForIdFailure({
                                teamId: action.teamId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
