import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { SignalrEvent } from '../../constants/signalr.constants';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as notificationsActions from './notifications.actions';
import * as notificationsSelectors from './notifications.selectors';
import { NotificationsService } from './notifications.service';

@Injectable()
export class NotificationsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private notificationsService = inject(NotificationsService);

    getPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationsActions.getPage),
            withLatestFrom(
                this.store$.select(notificationsSelectors.getInfinityPaging, {
                    id: GLOBAL_STATE_INDEX_ID,
                })
            ),
            switchMap(([action, paging]) =>
                this.notificationsService
                    .getPage({
                        pageNumber: action.reset ? 1 : (paging?.page || 0) + 1,
                        pageSize: action.pageSize,
                    })
                    .pipe(
                        map((notifications) =>
                            notificationsActions.getPageSuccess({
                                notifications,
                                reset: action.reset,
                            })
                        ),
                        catchError((error) =>
                            of(notificationsActions.getPageFailure({ error }))
                        )
                    )
            )
        )
    );

    view$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notificationsActions.view),
            mergeMap(() =>
                this.notificationsService.view().pipe(
                    map(() => notificationsActions.viewSuccess()),
                    catchError((error) =>
                        of(notificationsActions.viewFailure({ error }))
                    )
                )
            )
        )
    );

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.NotificationsUpdate).pipe(
                        map((unreadNotifications: number) =>
                            notificationsActions.setUnreadNotifications({
                                unreadNotifications,
                            })
                        )
                    )
                )
            )
        )
    );
}
