import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

import { Severity } from '@wdx/shared/utils';
import {
    WdxIconButtonSize,
    WdxIconButtonStyle,
} from './wdx-icon-button.component.interface';

@Component({
    selector: 'wdx-icon-button',
    templateUrl: './wdx-icon-button.component.html',
    styleUrls: ['./wdx-icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIconButtonComponent {
    @Input() size: WdxIconButtonSize = 'md';
    @Input() overflowVisible = false;
    @Input() positionRelative = false;
    @Input() wdxStyle: WdxIconButtonStyle = '';
    @Input() disabled?: boolean;
    @Input() severity?: Severity;

    @HostBinding('class') style = 'd-inline-block';

    @Output() wdxOnClick = new EventEmitter<MouseEvent>();

    onClick(event: MouseEvent): void {
        this.wdxOnClick.emit(event);
    }
}
