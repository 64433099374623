import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TelephoneService } from '../../../../../shared/services/telephone/telephone.service';

@Pipe({ name: 'telephonePattern' })
export class TelephonePatternPipe implements PipeTransform {
    constructor(private telephoneService: TelephoneService) {}

    transform(value: any): Observable<string> {
        return this.telephoneService.fieldValueToTelephonePattern$(value, true);
    }
}
