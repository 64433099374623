import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    inject,
} from '@angular/core';

import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import {
    FieldDefinition,
    SelectApiSource,
    SummaryLevel,
} from '@wdx/clmi/api-models';

import { ICON_DANGER } from '../../../../../constants/icons.constants';
import { GET_STATUS_PENDING_CHANGES } from '../../../../../features/reactive-forms/services/form-pending-changes/form-pending-changes.helper';
import { LOOKUP_SUMMARY_TYPES } from '../../constants/summary-types';
import { FormSummaryService } from '../../services/form-summary/form-summary.service';

@Component({
    selector: 'molecule-subform-info',
    templateUrl: './molecule-subform-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoleculeSubformInfoComponent implements OnDestroy {
    @Input() entityId: string;
    @Input() fieldDefinition: FieldDefinition;
    get data() {
        return this._data;
    }
    @Input() set data(val: any) {
        this._data = val;
        this.setSummaryLevelData();
    }
    @Input() sectionLayoutDefinitionName: string;
    @Input() parentData: any;
    @Input() schema: FieldDefinition[];

    titleField: FieldDefinition;
    subtitle1Field: FieldDefinition;
    subtitle2Field: FieldDefinition;
    subtitle3Field: FieldDefinition;
    primaryField: FieldDefinition;
    status: string;

    isPrimaryField = false;

    titleValue: any;
    title$: Observable<string>;
    subtitles$ = new BehaviorSubject<string[]>(null);

    destroyed$ = new Subject<boolean>();

    readonly LOOKUP_SUMMARY_TYPES = LOOKUP_SUMMARY_TYPES;
    readonly CUSTOM_SUMMARY_TYPES = [...LOOKUP_SUMMARY_TYPES];
    readonly SELECT_API_SOURCE = SelectApiSource;
    readonly ICON_DANGER = ICON_DANGER;

    private _data;
    private formSummaryService = inject(FormSummaryService);

    setSummaryLevelData(): void {
        this.titleField = this.findFieldBySummaryLevel(SummaryLevel.Title);
        this.subtitle1Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle1
        );
        this.subtitle2Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle2
        );
        this.subtitle3Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle3
        );

        this.primaryField = this.findFieldBySummaryLevel(
            SummaryLevel.IsPrimary
        );

        this.status = this.getStatus();

        this.titleValue = this.data?.[this.titleField?.name];

        this.title$ = this.formSummaryService.getStringValue(
            this.data?.[this.titleField?.name],
            this.titleField,
            {
                formData: this.data,
                entityId: '',
            },
            this.parentData,
            this.schema
        );

        this.isPrimaryField = this.data?.[this.primaryField?.name];

        combineLatest([
            this.formSummaryService.getStringValue(
                this.data?.[this.subtitle1Field?.name],
                this.subtitle1Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
            this.formSummaryService.getStringValue(
                this.data?.[this.subtitle2Field?.name],
                this.subtitle2Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
            this.formSummaryService.getStringValue(
                this.data?.[this.subtitle3Field?.name],
                this.subtitle3Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
        ])
            .pipe(take(1))
            .subscribe((subtitles) => {
                this.subtitles$.next(subtitles.filter((subtitle) => subtitle));
            });
    }

    getStatus(): string {
        return GET_STATUS_PENDING_CHANGES[this.data?.status];
    }

    findFieldBySummaryLevel(summaryLevel: SummaryLevel) {
        return this.fieldDefinition?.childSchema?.find(
            (definition) => definition?.summaryLevel === summaryLevel
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
