<ng-container [formGroup]="controlContainer?.control">
    <wdx-form-field
        [attr.data-cy]="'editor-' + formElement.name"
        [locked]="formElement?.isLocked"
        [label]="formElement?.label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage : formElement
        "
        [warningMessage]="warningText"
    >
        <editor
            *ngIf="ready$ | async"
            data-cy="textarea-editor"
            [apiKey]="editorApiKey"
            [init]="editorConfig"
            [disabled]="
                formControl?.disabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    formElement?.pendingChange?.status
                )
            "
            [initialValue]="initialValue$ | async"
            (onSelectionChange)="onEditorChange($event)"
        ></editor>
    </wdx-form-field>
</ng-container>
