import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    FormControl,
    FormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import {
    Country,
    FormDefinition,
    PostalAddress,
    PostalAddressLookup,
} from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    take,
    takeUntil,
} from 'rxjs/operators';
import { MANUAL_ADDRESS_FORM_ID } from '../../../../../constants/dynamic-form.constants';
import { FormProcessConditionDataService } from '../../../../../features/reactive-forms/services/form-conditions/process-condition-data.service';
import { FormStaticService } from '../../../../../features/reactive-forms/services/form-static/form-static.class';
import { FormValidationService } from '../../../../../features/reactive-forms/services/form-validation/form-validation.service';
import {
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../../../models/form-validation.model';
import { FormSummaryService } from '../../../../../shared/features/form-summary/services/form-summary/form-summary.service';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services/dynamic-data';
import { FeaturesService } from '../../../../../shared/services/features/features.service';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as addressesActions from '../../../../../state/addresses/addresses.actions';
import * as addressesSelectors from '../../../../../state/addresses/addresses.selectors';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';
@Component({
    selector: 'clmi-reactive-address-control',
    templateUrl: './reactive-address-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveAddressControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit, OnChanges
{
    @ViewChild(NgSelectComponent) addressSelect: NgSelectComponent;

    countries$: Observable<Country[]>;
    addressSearchResults$: Observable<PostalAddressLookup[]> = of([]);
    addressSearchResultsIsLoading$: Observable<boolean>;
    fullAddress$: Observable<PostalAddress>;
    fullAddressIsLoading$: Observable<boolean>;
    fullAddressHasError$: Observable<boolean>;
    identifier$ = new BehaviorSubject<string>(null);
    hasValue$ = new BehaviorSubject<boolean>(false);
    smartAddressFields$ = new BehaviorSubject<string[]>(null);
    validationSummary$ = new BehaviorSubject<ValidationSummarySection>(null);
    hasValidators = false;

    addressInput$ = new Subject<string>();
    isRequired$ = new Subject<boolean>();

    hasAddress = false;
    addressLookupEnabled: boolean;
    isSmartView = false;
    requiredFields: string[];
    controlId = Date.now().toString();

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService,
        private store$: Store<rootReducer.State>,
        private elementRef: ElementRef,
        private formSummaryService: FormSummaryService,
        private featuresService: FeaturesService,
        private formValidationService: FormValidationService,
        private formStaticService: FormStaticService,
        private formProcessService: FormProcessConditionDataService,
        private cd: ChangeDetectorRef
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.configureAddressLookup();

        this.countries$ = this.dynamicDataService.getCountries();

        this.dynamicDataService
            .getFormLayoutAndDefinition(MANUAL_ADDRESS_FORM_ID)
            .pipe(take(1))
            .subscribe(({ definition }) => this.setUpManualAddress(definition));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.formElement.currentValue) {
            this.isRequired$.next(changes.formElement.currentValue.isRequired);
        }
    }

    setUpManualAddress(definition: FormDefinition) {
        this.addFormControls(definition);
        this.applyLayout(definition);

        this.cd.detectChanges();

        this.requiredFields = this.formElement.children
            .filter((child) => child.isRequired)
            .map((child) => child.name);

        this.applyValidators(
            this.formControl?.hasValidator(Validators.required)
        );

        this.setHasValue(this.formControl.getRawValue());

        this.formControl.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.setHasValue(value);
            });

        this.isRequired$
            .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
            .subscribe((isRequired) => this.applyValidators(isRequired));

        this.formStaticService.submitAttempted$.pipe(take(1)).subscribe(() => {
            this.formControl.markAsTouched();
            this.formControl.updateValueAndValidity();
            Object.keys((this.formControl as FormGroup).controls).forEach(
                (controlId) => {
                    const control = this.formControl.get(controlId);
                    control.markAsTouched();
                    this.cd.detectChanges();
                }
            );
            this.calculateValidationErrors();
            this.controlContainer.control.statusChanges
                .pipe(takeUntil(this.formStaticService.destroyed$))
                .subscribe(() => {
                    this.calculateValidationErrors();
                });
        });
    }

    setHasValue(value: any) {
        const hasValue = Object.values(value).some((value) => Boolean(value));

        this.hasValue$.next(hasValue);

        if (this.addressLookupEnabled) {
            this.formSummaryService
                .getAddress$(this.formControl.getRawValue(), true)
                .subscribe((value) => {
                    this.smartAddressFields$.next(value);
                });
        }
    }

    calculateValidationErrors() {
        this.validationSummary$.next({
            errors: this.formValidationService.getDefinitionErrors(
                this.formElement.children,
                (this.formControl as any).controls
            ),
        });
    }

    applyValidators(isRequired: boolean) {
        if (isRequired) {
            this.setValidators();
        } else {
            this.hasValue$
                .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
                .subscribe((hasValue) => {
                    if (hasValue) {
                        this.setValidators();
                    } else {
                        this.resetValidators();
                    }
                });
        }
    }

    resetValidators() {
        this.formElement.children.forEach((definition) => {
            if (this.requiredFields.includes(definition.name)) {
                definition.isRequired = false;
            }
            const control = this.formControl.get(definition.name);
            if (control) {
                control.setValidators(null);
                control.updateValueAndValidity();
            }
        });
        this.cd.detectChanges();
    }

    setValidators() {
        this.formElement.children.forEach((definition) => {
            if (this.requiredFields.includes(definition.name)) {
                definition.isRequired = true;
            }
            const validators =
                this.formValidationService.getValidators(definition);
            if (validators) {
                const control = this.formControl.get(definition.name);
                if (control) {
                    control.addValidators(validators);
                    control.updateValueAndValidity();
                }
            }
        });
        this.cd.detectChanges();
    }

    convertValueIn(value: Record<string, any>) {
        Object.keys(value).forEach((key) => {
            if (this.formControl.get(key)) {
                this.formControl.get(key).setValue(value[key]);
            }
        });
        this.hasAddress = true;
    }

    configureAddressLookup() {
        this.addressLookupEnabled = this.featuresService.hasFeature(
            FeatureFlag.AddressLookupEnabled
        );

        if (this.addressLookupEnabled) {
            this.isSmartView = true;
            this.loadAddressLookup();
            this.loadAddressSelectors();
        }
    }

    loadAddressSelectors() {
        this.addressSearchResults$ = this.store$.select(
            addressesSelectors.getAddresses,
            {
                id: this.controlId,
            }
        );
        this.addressSearchResultsIsLoading$ = this.store$.select(
            addressesSelectors.getAddressesIsLoadingList,
            {
                id: this.controlId,
            }
        );

        this.fullAddressIsLoading$ = this.store$.select(
            addressesSelectors.getAddressIsLoadingSingle,
            {
                id: this.controlId,
            }
        );
        this.fullAddressHasError$ = this.store$.select(
            addressesSelectors.getAddressHasLoadingSingleError,
            {
                id: this.controlId,
            }
        );
        this.fullAddress$ = this.store$.select(addressesSelectors.getAddress, {
            id: this.controlId,
        });
    }

    loadAddressLookup() {
        this.addressInput$
            .pipe(
                takeUntil(this.destroyed$),
                debounceTime(300),
                filter((searchText) => Boolean(searchText))
            )
            .subscribe((searchText) => {
                this.store$.dispatch(
                    addressesActions.getAddresses({
                        searchText: searchText,
                        fieldId: this.controlId,
                    })
                );
            });
    }

    reset() {
        Object.keys(this.formControl.value).forEach((key) => {
            this.formControl.get(key).setValue(null);
        });
        this.hasAddress = false;
    }

    trackByFn(address: PostalAddressLookup): string {
        return address.identifier;
    }

    onLookupSearch(event: { term: string }): void {
        const { term } = event;

        if (!term) {
            return;
        }

        this.store$.dispatch(
            addressesActions.getAddresses({
                searchText: term,
                fieldId: this.controlId,
            })
        );
    }

    onLookupClear(): void {
        this.addressSelect.clearModel();
        this.reset();
    }

    onLookupSelected(address: PostalAddressLookup): void {
        if (!address) {
            this.reset();
            return;
        }

        const { identifier } = address;

        this.store$.dispatch(
            addressesActions.getAddress({
                addressId: identifier,
                fieldId: this.controlId,
            })
        );

        this.fullAddress$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((address) => {
                if (!address) {
                    return;
                }
                this.convertValueIn({
                    ...address,
                    countryCode: address.country.isoCode,
                });
            });
    }

    addFormControls(formDefinition: FormDefinition) {
        const formData = this.formStaticService.formData.data;

        const data = this.formElement.parentName
            ? formData[this.formElement.parentName][this.formElement.index]
            : formData;

        formDefinition.layout.sectionLayoutDefinitions[0].elementLayoutDefinitions.forEach(
            (definition) => {
                (this.formControl as FormGroup).addControl(
                    definition.name,
                    new FormControl(
                        data && data[this.formElement.name]
                            ? data[this.formElement.name][definition.name]
                            : null,
                        this.formValidationService.getValidators(definition)
                    )
                );
            }
        );
    }

    applyLayout(formDefinition: FormDefinition): void {
        this.formElement.children =
            formDefinition.layout.sectionLayoutDefinitions[0].elementLayoutDefinitions.map(
                (innerElement) => {
                    const definition = {
                        ...formDefinition.schema.find(
                            (schemaItem) =>
                                schemaItem.name === innerElement.name
                        ),
                        ...innerElement,
                    };

                    const controlToUpdate = this.formControl.get(
                        innerElement.name
                    ) as UntypedFormControl;

                    this.formProcessService.updateValidation(
                        controlToUpdate,
                        definition
                    );
                    return definition;
                }
            );
    }

    onErrorClicked(error: ValidationSummaryError): void {
        const fieldDefinitionElementRef =
            this.elementRef.nativeElement.querySelector(
                `[data-form-control='${error.name}']`
            );
        this.formValidationService.scrollToError(fieldDefinitionElementRef);
    }

    onToggleView() {
        this.isSmartView = !this.isSmartView;
    }
}
