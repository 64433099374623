import { Injectable } from '@angular/core';
import { FormContext, Is, Trigger } from '@wdx/clmi/api-models';
import { StringifyComparePipe } from '../../../../pipes/stringify-compare.pipe';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class FormTriggersService {
    is(is: Is, triggerFieldValue: any): boolean {
        const emptyChecks = [undefined, null, '', []];

        return (
            (is.equalTo !== 'undefined' &&
                !Array.isArray(triggerFieldValue) &&
                triggerFieldValue === is.equalTo) ||
            (is.equalTo !== 'undefined' &&
                Array.isArray(triggerFieldValue) &&
                triggerFieldValue.includes(is.equalTo)) ||
            (is.anyOf &&
                !Array.isArray(triggerFieldValue) &&
                is.anyOf.includes(triggerFieldValue)) ||
            (is.anyOf &&
                Array.isArray(triggerFieldValue) &&
                is.anyOf.some((r) => triggerFieldValue.includes(r))) ||
            (is.future &&
                DateTime.fromJSDate(new Date(triggerFieldValue))
                    .diffNow('milliseconds')
                    .as('milliseconds') > 0) ||
            (is.past &&
                DateTime.fromJSDate(new Date(triggerFieldValue))
                    .diffNow('milliseconds')
                    .as('milliseconds') < 0) ||
            (is.lesserThan && triggerFieldValue < is.lesserThan) ||
            (is.greaterThan && triggerFieldValue > is.greaterThan) ||
            (is.empty &&
                emptyChecks.some((emptyCheck) =>
                    new StringifyComparePipe().transform(
                        emptyCheck,
                        triggerFieldValue
                    )
                )) ||
            (is.notEmpty &&
                emptyChecks.every(
                    (emptyCheck) =>
                        !new StringifyComparePipe().transform(
                            emptyCheck,
                            triggerFieldValue
                        )
                )) ||
            null
        );
    }

    useContext(trigger: Trigger) {
        return trigger.field.startsWith('$');
    }

    /**
     * Looks up a value from context object using dot notation from a trigger field value
     *
     * Supports objects and array notation
     *
     * { party: { title: 'Party Title', roles: [ {type: 'Role A'}, {type: 'Role B'}] } ] } }
     *
     * $party.title = 'Party Title'
     *
     * $party.roles[].type = ['Role A', 'Role B']
     */

    getContextTriggerValue(trigger: Trigger, context: FormContext) {
        return this.getContextValue(trigger.field.substring(1), context);
    }

    private getContextValue(field: string, context: FormContext) {
        const parts = field?.split('.');
        parts?.forEach((fieldPart, i) => {
            const isArrayPart = fieldPart.endsWith('[]');
            if (isArrayPart) {
                const arrayKey = fieldPart.replace('[]', '');
                // eslint-disable-next-line no-prototype-builtins
                if (context.hasOwnProperty(arrayKey)) {
                    context = context[arrayKey].map((arrayField) => {
                        return this.getContextValue(
                            parts.slice(i + 1).join('.'),
                            arrayField
                        );
                    });
                }
            } else {
                // eslint-disable-next-line no-prototype-builtins
                if (context?.hasOwnProperty(fieldPart)) {
                    context = context[fieldPart];
                }
            }
        });
        return context;
    }
}
