import { Pipe, PipeTransform } from '@angular/core';
import { FileIndex } from '@wdx/clmi/api-models';
import { FileSizePipe } from '@wdx/shared/utils';

/**
 * Converts fileIndex into a full file name in the convention 'Name (size)'
 */
@Pipe({ name: 'fileName' })
export class FileNamePipe implements PipeTransform {
    transform(fileIndex: FileIndex): string {
        return `${fileIndex?.name || ''} (${new FileSizePipe().transform(
            fileIndex?.sizeInBytes
        )})`;
    }
}
