/**
 * Manual Translation types (not part of generated api-models as object is returned as an untyped key/value object)
 */

export type Translations = Record<string, string>;

export enum TranslationKey {
    AreaArticles = 'AREA_ARTICLES',
    AreaCalendar = 'AREA_CALENDAR',
    AreaCases = 'AREA_CASES',
    AreaContacts = 'AREA_CONTACTS',
    AreaContactsAdvisers = 'AREA_CONTACTS_ADVISERS',
    AreaContactsAdviser = 'AREA_CONTACTS_ADVISER',
    AreaContactsClients = 'AREA_CONTACTS_CLIENTS',
    AreaContactsClient = 'AREA_CONTACTS_CLIENT',
    AreaContactsIntermediaries = 'AREA_CONTACTS_INTERMEDIARIES',
    AreaContactsIntermediary = 'AREA_CONTACTS_INTERMEDIARY',
    AreaContactsLeads = 'AREA_CONTACTS_LEADS',
    AreaContactsLead = 'AREA_CONTACTS_LEAD',
    AreaContactsOrganisations = 'AREA_CONTACTS_ORGANISATIONS',
    AreaContactsOrganisation = 'AREA_CONTACTS_ORGANISATION',
    AreaContactsPeople = 'AREA_CONTACTS_PEOPLE',
    AreaContactsPerson = 'AREA_CONTACTS_PERSON',
    AreaContactsProductProviders = 'AREA_CONTACTS_PRODUCTPROVIDERS',
    AreaContactsProductProvider = 'AREA_CONTACTS_PRODUCTPROVIDER',
    AreaDashboards = 'AREA_DASHBOARDS',
    AreaDuplicates = 'AREA_DUPLICATES',
    AreaError = 'AREA_ERROR',
    AreaExports = 'AREA_EXPORTS',
    AreaExternalUsers = 'AREA_EXTERNALUSERS',
    AreaFeed = 'AREA_FEED',
    AreaHome = 'AREA_HOME',
    AreaImports = 'AREA_IMPORTS',
    AreaImportsLog = 'AREA_IMPORTLOGS',
    AreaInbox = 'AREA_INBOX',
    AreaLeads = 'AREA_LEADS',
    AreaLists = 'AREA_LISTS',
    AreaMarketing = 'AREA_MARKETING',
    AreaMarketingList = 'AREA_MARKETINGLIST',
    AreaMarketingCampaigns = 'AREA_MARKETING_CAMPAIGNS',
    AreaMarketingResponses = 'AREA_MARKETING_RESPONSES',
    AreaMyAccount = 'AREA_MYACCOUNT',
    AreaNotifications = 'AREA_NOTIFICATIONS',
    AreaOrganisations = 'AREA_ORGANISATIONS',
    AreaPipeline = 'AREA_PIPELINE',
    AreaProducts = 'AREA_PRODUCTS',
    AreaQueues = 'AREA_QUEUES',
    AreaRecentRecords = 'AREA_RECENTRECORDS',
    AreaTasks = 'AREA_TASKS',
    AreaTeams = 'AREA_TEAMS',
    AreaKyc = 'AREA_KYC',
    AreaAdmin = 'AREA_ADMIN',
    AreaAdminUsersAndTeams = 'AREA_ADMIN_USERSANDTEAMS',
    AreaAdminProducts = 'AREA_ADMIN_PRODUCTS',
    AreaAdminAnswerSets = 'AREA_ADMIN_ANSWERSETS',
    AreaAdminReferencedata = 'AREA_ADMIN_REFERENCEDATA',
    AreaAdminDocuments = 'AREA_ADMIN_DOCUMENTS',
    AreaAdminTags = 'AREA_ADMIN_TAGS',
    AreaAdminWebHooks = 'AREA_ADMIN_WEBHOOKS',
    AreaAdminInterface = 'AREA_ADMIN_INTERFACE',
    AreaAdminGeographic = 'AREA_ADMIN_GEOGRAPHIC',
    AreaAdminForms = 'AREA_ADMIN_FORMS',
    ClientLifecycleStageAdvocate = 'ENUM_CLIENTLIFECYCLESTAGE_ADVOCATE',
    ClientLifecycleStageClient = 'ENUM_CLIENTLIFECYCLESTAGE_CLIENT',
    ClientLifecycleStageDisqualified = 'ENUM_CLIENTLIFECYCLESTAGE_DISQUALIFIED',
    ClientLifecycleStageEngaged = 'ENUM_CLIENTLIFECYCLESTAGE_ENGAGED',
    ClientLifecycleStageFlightrisk = 'ENUM_CLIENTLIFECYCLESTAGE_FLIGHTRISK',
    ClientLifecycleStageLeft = 'ENUM_CLIENTLIFECYCLESTAGE_LEFT',
    ClientLifecycleStageOnboarding = 'ENUM_CLIENTLIFECYCLESTAGE_ONBOARDING',
    ClientLifecycleStagePitching = 'ENUM_CLIENTLIFECYCLESTAGE_PITCHING',
    ClientLifecycleStageSwitched = 'ENUM_CLIENTLIFECYCLESTAGE_SWITCHED',
    ClientLifecycleStageTarget = 'ENUM_CLIENTLIFECYCLESTAGE_TARGET',
    ClientLifecycleStageUnwinding = 'ENUM_CLIENTLIFECYCLESTAGE_UNWINDING',
    EntityAdviser = 'ENTITY_ADVISER_SINGULAR',
    EntityAdviserPlural = 'ENTITY_ADVISER_PLURAL',
    EntityActivity = 'ENTITY_ACTIVITY_SINGULAR',
    EntityActivityPlural = 'ENTITY_ACTIVITY_PLURAL',
    EntityArticle = 'ENTITY_ARTICLE_SINGULAR',
    EntityArticlePlural = 'ENTITY_ARTICLE_PLURAL',
    EntityCampaign = 'ENTITY_CAMPAIGN_SINGULAR',
    EntityCampaignPlural = 'ENTITY_CAMPAIGN_PLURAL',
    EntityCampaignResponse = 'ENTITY_CAMPAIGNRESPONSE_SINGULAR',
    EntityCampaignResponsePlural = 'ENTITY_CAMPAIGNRESPONSE_PLURAL',
    EntityCase = 'ENTITY_CASE_SINGULAR',
    EntityCasePlural = 'ENTITY_CASE_PLURAL',
    EntityClient = 'ENTITY_CLIENT_SINGULAR',
    EntityClientPlural = 'ENTITY_CLIENT_PLURAL',
    EntityClientProduct = 'ENTITY_CLIENTPRODUCT_SINGULAR',
    EntityClientProductPlural = 'ENTITY_CLIENTPRODUCT_PLURAL',
    EntityDocument = 'ENTITY_DOCUMENT_SINGULAR',
    EntityDocumentPlural = 'ENTITY_DOCUMENT_PLURAL',
    EntityDocumentTemplate = 'ENTITY_DOCUMENTTEMPLATE_SINGULAR',
    EntityDocumentTemplatePlural = 'ENTITY_DOCUMENTTEMPLATE_PLURAL',
    EntityEdsMessage = 'ENTITY_EDSMESSAGE_SINGULAR',
    EntityEdsMessageBatch = 'ENTITY_EDSMESSAGEBATCH_SINGULAR',
    EntityEdsMessageBatchPlural = 'ENTITY_EDSMESSAGEBATCH_PLURAL',
    EntityEdsMessagePlural = 'ENTITY_EDSMESSAGE_PLURAL',
    EntityExport = 'ENTITY_EXPORT_SINGULAR',
    EntityExportPlural = 'ENTITY_EXPORT_PLURAL',
    EntityExternalUser = 'ENTITY_EXTERNALUSER_SINGULAR',
    EntityExternalUserPlural = 'ENTITY_EXTERNALUSER_PLURAL',
    EntityIntermediary = 'ENTITY_INTERMEDIARY_SINGULAR',
    EntityIntermediaryPlural = 'ENTITY_INTERMEDIARY_PLURAL',
    EntityFeed = 'ENTITY_FEED_SINGULAR',
    EntityFeedPlural = 'ENTITY_FEED_PLURAL',
    EntityLead = 'ENTITY_LEAD_SINGULAR',
    EntityLeadPlural = 'ENTITY_LEAD_PLURAL',
    EntityList = 'ENTITY_LIST_SINGULAR',
    EntityListPlural = 'ENTITY_LIST_PLURAL',
    EntityOpportunity = 'ENTITY_OPPORTUNITY_SINGULAR',
    EntityOpportunityPlural = 'ENTITY_OPPORTUNITY_PLURAL',
    EntityOrganisation = 'ENTITY_ORGANISATION_SINGULAR',
    EntityOrganisationPlural = 'ENTITY_ORGANISATION_PLURAL',
    EntityPerson = 'ENTITY_PERSON_SINGULAR',
    EntityPersonPlural = 'ENTITY_PERSON_PLURAL',
    EntityProductProvider = 'ENTITY_PRODUCTPROVIDER_SINGULAR',
    EntityProductProviderPlural = 'ENTITY_PRODUCTPROVIDER_PLURAL',
    EntityQueueItem = 'ENTITY_QUEUEITEM_SINGULAR',
    EntityQueueItemPlural = 'ENTITY_QUEUEITEM_PLURAL',
    EntityTask = 'ENTITY_TASK_SINGULAR',
    EntityTaskPlural = 'ENTITY_TASK_PLURAL',
    EntityTeam = 'ENTITY_TEAM_SINGULAR',
    EntityTeamPlural = 'ENTITY_TEAM_PLURAL',
    EntityUser = 'ENTITY_USER_SINGULAR',
    EntityUserPlural = 'ENTITY_USER_PLURAL',
    EntityWorkItem = 'ENTITY_WORKITEM_SINGULAR',
    EntityWorkItemPlural = 'ENTITY_WORKITEM_PLURAL',
    EnumAppGroupTypeProducts = 'ENUM_APPGROUPTYPE_PRODUCTS',
    EnumAppTypeProfessionalConnections = 'ENUM_APPTYPE_PROFESSIONALCONNECTIONS',
    EnumAppTypeDocumentChecklist = 'ENUM_APPTYPE_DOCUMENTCHECKLIST',
    EnumProductProviderTypeInformed = 'ENUM_PRODUCTPROVIDERTYPE_INFORMED',
    EnumProductProviderTypeUnderAgency = 'ENUM_PRODUCTPROVIDERTYPE_UNDERANGENCY',
    EnumProductProviderTypeUnknown = 'ENUM_PRODUCTPROVIDERTYPE_UNKNOWN',
    EnumNudgeActionTypeCreatePhoneCall = 'ENUM_NUDGEACTIONTYPE_CREATEPHONECALL',
    EnumNudgeActionTypeCreateMeeting = 'ENUM_NUDGEACTIONTYPE_CREATEMEETING',
    EnumNudgeActionTypeCreateOpportunity = 'ENUM_NUDGEACTIONTYPE_CREATEOPPORTUNITY',
    EnumNudgeActionTypeCreateCreateEmail = 'ENUM_NUDGEACTIONTYPE_CREATEEMAIL',
    EnumPendingChangeStatusTypeAccepted = 'ENUM_PENDINGCHANGESTATUSTYPE_ACCEPTED',
    EnumPendingChangeStatusTypeApplied = 'ENUM_PENDINGCHANGESTATUSTYPE_APPLIED',
    EnumPendingChangeStatusTypePending = 'ENUM_PENDINGCHANGESTATUSTYPE_PENDING',
    EnumPendingChangeStatusTypeRejected = 'ENUM_PENDINGCHANGESTATUSTYPE_REJECTED',
    EnumPendingChangeStatusTypeSubmitted = 'ENUM_PENDINGCHANGESTATUSTYPE_SUBMITTED',
    EnumPendingChangeStatusTypeSuperceded = 'ENUM_PENDINGCHANGESTATUSTYPE_SUPERCEDED',
    EnumRoleTypeMember = 'ENUM_ROLETYPE_MEMBER',
    EnumPrductsStatusActive = 'ENUM_PRODUCTSTATUS_ACTIVE',
    EnumPrductsStatusInactive = 'ENUM_PRODUCTSTATUS_INACTIVE',
    EnumPrductsStatusDraft = 'ENUM_PRODUCTSTATUS_DRAFT',
    EnumPrductsStatusProposed = 'ENUM_PRODUCTSTATUS_PROPOSED',
    EnumPrductsStatusBlocked = 'ENUM_PRODUCTSTATUS_BLOCKED',
    EnumPrductsStatusCancelled = 'ENUM_PRODUCTSTATUS_CANCELLED',
    EnumPrductsStatusClosing = 'ENUM_PRODUCTSTATUS_CLOSING',
    IntermediaryDirect = 'INTERMEDIARY_DIRECT',
    LabelNoAssociatedClient = 'LABEL_NO_ASSOCIATED_CLIENT',
    ListLeadCreated = 'LIST_LEAD_CREATED',
    ListReferenceClient = 'LIST_REFERENCE_CLIENT',
}
