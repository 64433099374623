import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[wdxListGroupItem]',
})
export class ListGroupItemDirective {
    @Input() wdxListGroupAction = false;

    @HostBinding('class') hostClasses = 'list-group-item';
    @HostBinding('class.list-group-item-action') get isActionClass() {
        return this.wdxListGroupAction;
    }
}
