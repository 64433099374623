import { Component, Input } from '@angular/core';
import { ClientLifecycleStage } from '@wdx/clmi/api-models';
import { TranslationKey } from '@wdx/clmi/api-services/services';
import { Icon, KeyValueObject } from '@wdx/shared/utils';
import { LIFECYCLE_STAGE_ICON_MAP } from '../../../constants/lifecycle-stage.constants';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-lifecycle-stage-tag',
    templateUrl: './molecule-lifecycle-stage-tag.component.html',
})
export class MoleculeLifecycleStageTagComponent {
    @Input() set lifecycleStage(stage: ClientLifecycleStage) {
        this._lifecycleStage = stage;
        this.icon = LIFECYCLE_STAGE_ICON_MAP[stage];
    }
    get lifecycleStage(): ClientLifecycleStage {
        return this._lifecycleStage;
    }
    _lifecycleStage: ClientLifecycleStage;
    icon: Icon;

    STAGES_TRANSLATION_KEY_MAP: KeyValueObject<string> = {
        [ClientLifecycleStage.Target]:
            TranslationKey.ClientLifecycleStageTarget,
        [ClientLifecycleStage.Engaged]:
            TranslationKey.ClientLifecycleStageEngaged,
        [ClientLifecycleStage.Pitching]:
            TranslationKey.ClientLifecycleStagePitching,
        [ClientLifecycleStage.Onboarding]:
            TranslationKey.ClientLifecycleStageOnboarding,
        [ClientLifecycleStage.Client]:
            TranslationKey.ClientLifecycleStageClient,
        [ClientLifecycleStage.Left]: TranslationKey.ClientLifecycleStageLeft,
        [ClientLifecycleStage.Disqualified]:
            TranslationKey.ClientLifecycleStageDisqualified,
        [ClientLifecycleStage.Unwinding]:
            TranslationKey.ClientLifecycleStageUnwinding,
    };
}
