import { createSelector } from '@ngrx/store';
import * as addressesReducer from './addresses.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    addresses: addressesReducer.State;
}): addressesReducer.State => state.addresses;

export const getAddressesIsLoadingList = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addressLookups[props.id]?.isLoadingList
);
export const getAddressesHasLoadingListError = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addressLookups[props.id]?.hasLoadingListError
);
export const getAddresses = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addressLookups[props.id]?.list
);

export const getAddressIsLoadingSingle = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addresses[props.id]?.isLoadingSingle
);
export const getAddressHasLoadingSingleError = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addresses[props.id]?.hasLoadingSingleError
);
export const getAddress = createSelector(
    getState,
    (state: addressesReducer.State, props: any) =>
        state.addresses[props.id]?.single
);

/**
 * Reducer property selectors
 */
