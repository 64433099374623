import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WdxLoadingType } from '../../types/wdx-loading-type.type';

@Component({
    templateUrl: './wdx-is-loading.component.html',
    styleUrls: ['./wdx-is-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIsLoadingComponent {
    @Input() hasError?: boolean;
    @Input() type: WdxLoadingType = 'spinner';
    @Input() overlay?: boolean;
}
