import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleCase' })
export class TitleCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value || typeof value !== 'string') {
            return value;
        }

        const words = value.split(/(?=[0-Z])/);

        let result = '';
        let previousWord = '';

        for (const word of words) {
            if (
                result !== '' &&
                !(previousWord.length === 1 && word.length === 1)
            ) {
                result += ' ';
            }
            result += word.charAt(0).toUpperCase() + word.slice(1);
            previousWord = word;
        }

        return result;
    }
}
