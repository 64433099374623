export enum WdxButtonStyle {
    Primary = 'primary',
    OutlinePrimary = 'outline-primary',
    Link = 'link',
    Danger = 'danger',
}

export enum WdxButtonSize {
    Normal = 'normal',
    Lg = 'lg',
    Sm = 'sm',
}
