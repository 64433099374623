import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import * as rootReducer from './../../state/_setup/reducers';
import * as fileDownloadActions from './file-download.actions';
import * as fileDownloadSelectors from './file-download.selectors';
import { FileDownloadService } from './file-download.service';

@Injectable()
export class FileDownloadEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private fileDownloadService = inject(FileDownloadService);

    getFilePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.getFilePreview),
            mergeMap((action) =>
                this.fileDownloadService
                    .getFile(action.fileIndex.fileIndex)
                    .pipe(
                        map((response) => {
                            const blob = new Blob([response.body], {
                                type: action.fileIndex.contentType,
                            });
                            const fileUrl = window.URL.createObjectURL(blob);
                            return fileDownloadActions.getFilePreviewSuccess({
                                fileIndex: action.fileIndex,
                                fileType: action.fileIndex.contentType,
                                fileUrl,
                            });
                        }),
                        catchError((error) => {
                            return of(
                                fileDownloadActions.getFilePreviewFailure({
                                    fileIndex: action.fileIndex,
                                    error,
                                })
                            );
                        })
                    )
            )
        )
    );

    destroyFilePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.destroyFilePreview),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store$.select(
                            fileDownloadSelectors.getFilePreview,
                            {
                                id: action.fileIndex,
                            }
                        )
                    )
                )
            ),
            map(([action, filePreview]) => {
                if (filePreview?.fileUrl) {
                    window.URL.revokeObjectURL(filePreview.fileUrl);
                }
                return fileDownloadActions.destroyFilePreviewSuccess({
                    fileIndex: action.fileIndex,
                });
            })
        )
    );

    deleteFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fileDownloadActions.deleteFile),
            mergeMap((action) =>
                this.fileDownloadService.deleteFile(action.fileIndex).pipe(
                    map(() => {
                        return fileDownloadActions.deleteFileSuccess({
                            fileIndex: action.fileIndex,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            fileDownloadActions.deleteFileFailure({
                                fileIndex: action.fileIndex,
                                error,
                            })
                        );
                    })
                )
            )
        )
    );

    getFileDownload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fileDownloadActions.getFileDownload),
                mergeMap((action) =>
                    this.fileDownloadService
                        .getFile(action.fileIndex)
                        .pipe(
                            tap((response) =>
                                this.fileDownloadService.downloadFile(
                                    action.fileName,
                                    response
                                )
                            )
                        )
                )
            ),
        { dispatch: false }
    );
}
