import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiConfigService } from '../api-config.service';

import { BaseApiService } from '../base-api.service';
import { ImportUrlSegment } from './import-url-segment';

@Injectable({
    providedIn: 'root',
})
export class ImportApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    importFileData$(entityType: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append(file.name, file);

        return this.post(`${ImportUrlSegment.Import}/${entityType}`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    downloadTemplate$(entityType: string): Observable<any> {
        return this.get(
            `${ImportUrlSegment.Import}/${entityType}/${ImportUrlSegment.Template}`,
            { responseType: 'text' }
        );
    }
}
