import { ProductStatus, StatusType } from '@wdx/clmi/api-models';
import { TagData } from '../models/tag-data.model';
import { Highlight } from '@wdx/shared/utils';

export const INACTIVE_TAG: TagData = {
    label: StatusType.Inactive,
    highlight: Highlight.Info,
    shade: 100,
};

export const DRAFT_TAG: TagData = {
    label: ProductStatus.Draft,
    highlight: Highlight.Info,
    shade: 100,
};

export const ALL_TAG_CATEGORIES_LABEL = 'All Tag Categories';
export const PRIMARY_LABEL = 'Primary';

export const PRIMARY_TAG: TagData = {
    label: PRIMARY_LABEL,
    highlight: Highlight.Info,
    shade: 100,
};
