import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';
import { IFormDynamicData } from '../../../interfaces';
import { ExtendedFieldDefinitionOption } from '../../../models';

@Component({
    selector: 'wdx-ff-radio-buttons-control',
    templateUrl: './form-radio-buttons-control.component.html',
})
export class FormRadioButtonsControlComponent extends BaseReactiveFormControlClass {
    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    trackByFn(index: number, option: ExtendedFieldDefinitionOption) {
        return option.value;
    }
}
