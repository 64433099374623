<ng-container [ngSwitch]="formElement?.elementType">
    <ng-container *ngSwitchCase="FORM_ELEMENT_TYPE.Field">
        <ng-container [ngSwitch]="formElement?.fieldType">
            <ng-container [formGroup]="$any(controlContainer?.control)">
                <wdx-form-field
                    *ngSwitchCase="FORM_FIELD_TYPE.Radios"
                    [locked]="formElement?.isLocked"
                    [label]="formElement?.label"
                    [guidanceText]="formElement?.guidanceText"
                    [isSensitive]="formElement?.isSensitive"
                    [isRequired]="formElement?.isRequired"
                    [validationErrorMessage]="
                        formControl?.touched
                            ? (formControl?.errors
                              | validationErrorMessage : formElement)
                            : ''
                    "
                    [warningMessage]="warningText"
                >
                    <wdx-radio
                        *ngFor="
                            let option of options$ | ngrxPush;
                            let i = index
                        "
                        [label]="option.label"
                        [labelForId]="inputId"
                        [value]="option.value"
                    >
                        <input
                            type="radio"
                            class="form-check-input"
                            [formControlName]="$any(formElement.name)"
                            [value]="option.value"
                            [attr.data-cy]="
                                'option-' +
                                formElement.name +
                                '-' +
                                option.value
                            "
                            [attr.disabled]="
                                formControl?.disabled ||
                                formElement?.pendingChange?.status ===
                                    PENDING_CHANGE_STATUS_TYPE.Submitted ||
                                null
                            "
                        />
                    </wdx-radio>
                </wdx-form-field>

                <wdx-form-field
                    *ngSwitchCase="FORM_FIELD_TYPE.RadioTiles"
                    [locked]="formElement?.isLocked"
                    [label]="formElement?.label"
                    [guidanceText]="formElement?.guidanceText"
                    [isSensitive]="formElement?.isSensitive"
                    [isRequired]="formElement?.isRequired"
                    [validationErrorMessage]="
                        formControl?.touched
                            ? (formControl?.errors
                              | validationErrorMessage : formElement)
                            : ''
                    "
                >
                    <wdx-radio-tile-group>
                        <wdx-radio-tile
                            *ngFor="
                                let option of options$ | ngrxPush;
                                let i = index
                            "
                            [label]="option.label"
                            [labelForId]="inputId"
                            [value]="option.value"
                        >
                            <input
                                type="radio"
                                [formControlName]="$any(formElement.name)"
                                [value]="option.value"
                                [attr.data-cy]="'option-' + option.value"
                                [attr.disabled]="
                                    formControl?.disabled ||
                                    formElement?.pendingChange?.status ===
                                        PENDING_CHANGE_STATUS_TYPE.Submitted ||
                                    null
                                "
                            />

                            <wdx-icon-stack *ngIf="option.iconStack" icon>
                                <wdx-icon
                                    *ngFor="let icon of option.iconStack"
                                    [icon]="
                                        icon.icon +
                                        ' fa-2x fa-stack-1x ' +
                                        icon.style
                                    "
                                    [rotate]="icon?.rotate"
                                    [offsetTop]="icon?.offsetTop"
                                    [offsetLeft]="icon?.offsetLeft"
                                    [scale]="icon?.scale"
                                ></wdx-icon>
                            </wdx-icon-stack>

                            <wdx-icon
                                *ngIf="option.icon"
                                icon
                                [icon]="option.icon + ' fa-2x'"
                            ></wdx-icon>
                        </wdx-radio-tile>
                    </wdx-radio-tile-group>
                </wdx-form-field>

                <wdx-form-field
                    *ngSwitchCase="FORM_FIELD_TYPE.ButtonGroup"
                    [locked]="formElement?.isLocked"
                    [label]="formElement?.label"
                    [guidanceText]="formElement?.guidanceText"
                    [isSensitive]="formElement?.isSensitive"
                    [isRequired]="formElement?.isRequired"
                >
                    <wdx-radio-button-group>
                        <wdx-radio-button
                            [group]="true"
                            [label]="option.label"
                            [labelForId]="inputId"
                            [value]="option.value"
                            [icon]="option.icon"
                            *ngFor="
                                let option of options$ | ngrxPush;
                                let i = index
                            "
                        >
                            <input
                                type="radio"
                                [formControlName]="$any(formElement.name)"
                                [value]="option.value"
                                [attr.data-cy]="'option-' + formElement.name"
                                [attr.disabled]="
                                    formControl?.disabled ||
                                    formElement?.pendingChange?.status ===
                                        PENDING_CHANGE_STATUS_TYPE.Submitted ||
                                    null
                                "
                            />
                        </wdx-radio-button>
                    </wdx-radio-button-group>
                </wdx-form-field>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
