import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Client, SystemEntity } from '@wdx/clmi/api-models';
import { Severity } from '@wdx/shared/utils';
import { TranslationsService } from '@wdx/system';
import { ACTIVITY_ICON_MAP } from '../../../../constants/activity.constants';
import {
    ICON_COMMENT,
    ICON_FLAG_SOLID,
} from '../../../../constants/icons.constants';
import { INACTIVE_TAG } from '../../../../constants/tags.constants';
import { ContactsService } from '../../../../features/contacts/shared/services/contacts.service';
import { ContactType } from '../../../../models/contact-type.model';
import { AbbreviateCurrencyPipe } from '../../../../pipes/abbreviate-currency.pipe';
import { DisplayDatePipe } from '../../../../pipes/display-date.pipe';
import { EntityRoutePipe } from '../../../../pipes/entity-route.pipe';
import { IdentitiesToIdListPipe } from '../../../../pipes/identities-to-id-list.pipe';
import { AccessVisibilityService } from '../../../../shared/features/access-visibility/shared/services/access-visibility.service';
import {
    FilterQueryFacadeService,
    FilterQueryService,
} from '../../../../shared/features/form-filters';
import { RouteDataService } from '../../../../shared/services';
import { EntityOperationsService } from '../../../../shared/services/entity-operations';
import * as rootReducer from '../../../../state/_setup/reducers';
import { FilterTableComponentClass } from '../filter-table-component.class';
import { CLIENT_RELATIONSHIPS_TABLE_HEADERS } from './client-relationships.static';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Component({
    selector: 'clmi-filter-table-client-relationships',
    templateUrl: '../filter-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableClientRelationshipsComponent
    extends FilterTableComponentClass
    implements AfterViewInit, OnInit
{
    @ViewChild('infoCardCell', { read: TemplateRef })
    infoCardCell: TemplateRef<any>;

    @ViewChild('lifecycleStageCell', { read: TemplateRef })
    lifecycleStageCell: TemplateRef<any>;

    @ViewChild('actionButtonCell', { read: TemplateRef })
    actionButtonCell: TemplateRef<any>;

    @ViewChild('responsiveCardTemplate', { read: TemplateRef })
    responsiveCardTemplate: TemplateRef<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected accessVisibilityService: AccessVisibilityService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
        private contactsService: ContactsService
    ) {
        super(
            router,
            route,
            location,
            accessVisibilityService,
            store$,
            filterQueryService,
            filterQueryFacadeService,
            entityOperationsService,
            cd,
            routeDataService,
            translationsService
        );

        this.headers = CLIENT_RELATIONSHIPS_TABLE_HEADERS;
        this.table.headers = this.headers;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(
            this.filterQueryService.queryType,
            this.getClientAsTableRow,
            this.infoCardCell,
            this.lifecycleStageCell,
            this.actionButtonCell,
            this.responsiveCardTemplate,
            this.contactsService
        );

        this.cd.detectChanges();
    }

    resetTable(): void {
        super.resetTable(CLIENT_RELATIONSHIPS_TABLE_HEADERS);
    }

    getClientAsTableRow(
        client: Client,
        infoCardCell,
        lifecycleStageCell,
        actionButtonCell,
        responsiveCardTemplate,
        contactsService
    ) {
        const infoCardData = {
            avatars: [
                new PartyToAvatarPipe().transform(
                    client,
                    false,
                    true,
                    client?.party?.id,
                    client?.id
                ),
            ],
            primaryInfo: client.name,
            secondaryInfo: [
                {
                    label: contactsService.getStatusDescription(client),
                },
                ...(client.isActive ? [] : [INACTIVE_TAG]),
            ],
            idList: [
                ...(client.code
                    ? [
                          {
                              id: client.code,
                          },
                      ]
                    : []),
                ...new IdentitiesToIdListPipe().transform(client.identities),
            ],
            routerLinkData: new EntityRoutePipe().transform(
                SystemEntity.Client,
                client.id
            ),
        };
        const clientLifecycleData = client.clientStatus?.clientLifecycle;

        const actionButtonData = {
            isMine: client.isMine,
            isFavourite: client.isFavourite,
            id: client.id,
            partyId: client.party.id,
            contactType: ContactType.Client,
            entityType: SystemEntity.Client,
            party: client.party,
            contextMenu: contactsService.getContextMenuActionButton({
                entityId: client.party.id,
                entityType: SystemEntity.Party,
            }),
        };

        return {
            data: client,
            routerLink: new EntityRoutePipe().transform(
                SystemEntity.Client,
                client.id
            ),
            responsiveCard: {
                templateRef: responsiveCardTemplate,
                data: {
                    infoCard: infoCardData,
                    clientLifecycle: clientLifecycleData,
                    actionButton: actionButtonData,
                },
            },
            values: [
                {
                    templateRef: infoCardCell,
                    data: infoCardData,
                },
                {
                    templateRef: lifecycleStageCell,
                    data: clientLifecycleData,
                },
                {
                    cySelector: 'role',
                    value: client.role.name,
                },
                {
                    value: client.subRole?.name,
                },
                ...(client.lastContacted?.date
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  client.lastContacted?.date,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  }
                              ),
                              icon: ACTIVITY_ICON_MAP[
                                  client.lastContacted?.type
                              ],
                          },
                      ]
                    : [{ value: '' }]),
                ...(client.nextMeeting?.date
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  client.nextMeeting?.date,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  }
                              ),
                              icon: ACTIVITY_ICON_MAP[client.nextMeeting?.type],
                          },
                      ]
                    : [{ value: '' }]),
                {
                    value: client.openCases,
                    ...(client.hasCasesWithIssues
                        ? {
                              icon: ICON_COMMENT.icon,
                              severity: Severity.Danger,
                          }
                        : {}),
                    ...(client.hasOverdueCases
                        ? {
                              icon: ICON_FLAG_SOLID.icon,
                              severity: Severity.Danger,
                          }
                        : {}),
                },
                ...(client.pipeline?.value
                    ? [
                          {
                              value: `${new AbbreviateCurrencyPipe().transform(
                                  client.pipeline?.value?.value,
                                  client.pipeline?.value?.isoCode
                              )}
                                    (${client.pipeline?.count})`,
                          },
                      ]
                    : [{ value: '' }]),
                ...(client?.totalAum
                    ? [
                          {
                              value: `${new AbbreviateCurrencyPipe().transform(
                                  client?.totalAum?.value,
                                  client?.totalAum?.isoCode
                              )} AUM`,
                          },
                      ]
                    : [{ value: '' }]),
                ...(client.dateLastReview
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  client.dateLastReview,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  }
                              ),
                          },
                      ]
                    : [{ value: '' }]),
                ...(client.dateNextReview
                    ? [
                          {
                              value: new DisplayDatePipe().transform(
                                  client.dateNextReview,
                                  {
                                      relative: true,
                                      relativeStyle: 'short',
                                  }
                              ),
                          },
                      ]
                    : [{ value: '' }]),
                {
                    templateRef: actionButtonCell,
                    data: actionButtonData,
                },
            ],
        };
    }
}
