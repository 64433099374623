<ng-container [formGroup]="controlContainer?.control">
    <wdx-form-field
        class="h-100"
        [locked]="formElement?.isLocked"
        [label]="label"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched && formControl?.errors
                | validationErrorMessage : formElement
        "
        [warningMessage]="warningText"
    >
        <div *ngIf="documents">
            <div class="form-control p-0">
                <molecule-document-info
                    *ngFor="let document of documents; let index = index"
                    class="d-block w-100 border-bottom"
                    [subtitle]="document.fileIndex | fileName"
                    [label]="document?.name"
                    [fileIndex]="document.fileIndex"
                >
                    <ng-container *ngIf="!formControl?.disabled">
                        <atom-icon-button
                            class="ms-2"
                            size="sm"
                            icon="trash-alt"
                            (onClick)="onDeleteDocument(document)"
                        ></atom-icon-button>
                        <atom-icon-button
                            class="ms-2"
                            size="sm"
                            icon="pen"
                            [formSetup]="{
                                isQuickCreate: true,
                                preserveActiveModal: true,
                                formId: DOCUMENT_FORM_ID,
                                entityId: document.id
                            }"
                        ></atom-icon-button
                    ></ng-container>
                </molecule-document-info>

                <div *ngIf="!formControl?.disabled" class="p-4">
                    <ng-container
                        *ngIf="documents?.length === formElement?.max"
                        class="text-center body-text-medium"
                    >
                        A maximum of {{ formElement?.max }} documents can be
                        added.
                    </ng-container>
                    <ng-container *ngIf="documents?.length < formElement?.max">
                        <div class="text-center body-text-medium mb-2">
                            Click to add
                            {{ documents?.length ? 'another' : 'a' }}
                            document
                        </div>
                        <div class="d-flex justify-content-center">
                            <molecule-action-button
                                size="md"
                                data-cy="btn-add-document"
                                [actionButton]="
                                    addDocumentActionButton$ | ngrxPush
                                "
                            ></molecule-action-button></div
                    ></ng-container>
                </div>
            </div>
        </div>
    </wdx-form-field>
</ng-container>
