import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'wdx-radio-button-group',
    templateUrl: './wdx-radio-button-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: {
        class: 'input-button-group',
    },
})
export class WdxRadioButtonGroupComponent {}
