import { Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-form-version-info',
    templateUrl: './molecule-form-version-info.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block w-100',
    },
})
export class MoleculeFormVersionInfoComponent {}
