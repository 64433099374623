import { createAction, props } from '@ngrx/store';
import { FeatureStatus, Role } from '@wdx/clmi/api-models';

export const getAll = createAction('[Roles] Get All');

export const getAllSuccess = createAction(
    '[Roles] Get All Success',
    props<{
        roles: Role[];
    }>()
);

export const getAllFailure = createAction(
    '[Roles] Get All Failure',
    props<{
        error: Error;
    }>()
);

export const getByRoleType = createAction(
    '[Roles] Get By Role Type',
    props<{
        roleType: string;
    }>()
);

export const getByRoleTypeSuccess = createAction(
    '[Roles] Get By Role Type Success',
    props<{
        roleType: string;
        role: Role;
    }>()
);

export const getByRoleTypeFailure = createAction(
    '[Roles] Get By Role Type Failure',
    props<{
        roleType: string;
        error: Error;
    }>()
);

export const getForClientProduct = createAction(
    '[Roles] Get For Client Product',
    props<{
        clientProductId: string;
    }>()
);

export const getForClientProductSuccess = createAction(
    '[Roles] Get For Client Product Success',
    props<{
        roles: Role[];
        clientProductId: string;
    }>()
);

export const getForClientProductFailure = createAction(
    '[Roles] Get For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>()
);

export const toggleStatus = createAction(
    '[Roles] Toggle Status',
    props<{
        code: string;
        newValue?: boolean;
    }>()
);

export const enableRoleStatus = createAction(
    '[Roles] Enable Role Status',
    props<{
        code: string;
        status?: FeatureStatus.Enabled;
    }>()
);

export const enableRoleStatusSuccess = createAction(
    '[Roles] Enable Role Status Success',
    props<{
        code: string;
        status?: FeatureStatus.Enabled;
    }>()
);

export const enableRoleStatusFailure = createAction(
    '[Roles] Enable Role Status Failure',
    props<{
        error: Error;
    }>()
);

export const availableRoleStatus = createAction(
    '[Roles] Available Role Status',
    props<{
        code: string;
        status?: FeatureStatus.Available;
    }>()
);

export const availableRoleStatusSuccess = createAction(
    '[Roles] Available Role Status Success',
    props<{
        code: string;
        status?: FeatureStatus.Available;
    }>()
);

export const availableRoleStatusFailure = createAction(
    '[Roles] Available Role Status Failure',
    props<{
        error: Error;
    }>()
);
