export enum ActionButtonMode {
    AddButton,
    DropdownButtonMenu,
    DropdownButtonMenuWithLabel,
    DropdownButtonAdd,
    DropdownButtonAddSearch,
    DropdownButtonIcon,
    FavouriteButton,
    IconButton,
    LikeButton,
    LinkedButton,
    UploadButton,
    StandardButton,
}
