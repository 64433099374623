import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { SortDirection } from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import { PagingType, WdxDestroyClass, WdxSize } from '@wdx/shared/utils';
import { BehaviorSubject, takeUntil } from 'rxjs';
import {
    MEDIA_BREAKPOINT_LG,
    MEDIA_BREAKPOINT_MD,
} from '../../../constants/breakpoints.constants';
import {
    ICON_CHEVRON_DOWN,
    ICON_DRAG_HANDLE,
    ICON_SORT_ARROWS,
    ICON_SORT_ARROW_DOWN,
    ICON_SORT_ARROW_UP,
} from '../../../constants/icons.constants';
import { IFilterHandler } from '../../../interfaces/filter-handler.interface';
import { ActionButton } from '../../../models/action-button.model';
import { CheckboxStyle } from '../../../models/checkbox-style.model';
import { LegendItem } from '../../../models/legend-item.model';
import { MenuItem } from '../../../models/menu-item.model';
import { MultiselectPayload } from '../../../models/multiselect-payload.model';
import { FeatureSvg } from '../../../models/svg.model';
import { TableDisplayMode } from '../../../models/table-display-mode.model';
import { TableHeader } from '../../../models/table-header.model';
import { TableRow } from '../../../models/table-row.model';
import { Table, TableRowDragEvent } from '../../../models/table.model';
import { ToggleEvent } from '../../../models/toggle-event.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ResizeService } from '../../../shared/services/resize';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-table',
    templateUrl: './organism-table.component.html',
    styleUrls: ['./organism-table.component.scss'],
})
export class OrganismTableComponent
    extends WdxDestroyClass
    implements OnInit, OnChanges, OnDestroy
{
    @Input() set table(table: Table) {
        this._table = table;
        this.setHoverableTable();
    }
    get table(): Table {
        return this._table;
    }
    _table: Table;

    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() tablePaging: Paging;
    @Input() filterHandler: IFilterHandler;
    @Input() filterFormId: string;
    @Input() highlightFirstColumn: boolean;
    @Input() multiselectBannerButtons: ActionButton[];
    @Input() multiselectBannerPosition: 'fixed' | 'sticky' = 'sticky';
    @Input() disableMultiselectBanner = false;
    @Input() set resetSelections(config: { emitEvent: boolean }) {
        if (!config) {
            return;
        }
        this.selectedRows = [];
        if (config.emitEvent) {
            this.selectedRowsChange.emit(this.selectedRows);
        }
    }
    @Input() rowSelectorStyle: CheckboxStyle = CheckboxStyle.Check;
    @Input() isSmall: boolean;
    @Input() displayMode: TableDisplayMode;
    @Input() key: LegendItem[];
    @Input() rowLimit: number;
    @Input() hideColumnIndexesOnSmallScreen: number[] = [];
    @Input() legend: LegendItem[];
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() searchPlaceholder: string;
    @Input() searchBarActionButtons: ActionButton[];
    @Input() canSelectRows: boolean;
    @Input() canSelectOnlyOneRow: boolean;
    @Input() canDragRows = false;
    @Input() canToggleRows: boolean;
    @Input() showSearch: boolean;
    @Input() showSearchBar = true;
    @Input() initialSelectedRows: TableRow[];
    @Input() noDataSvgSize: WdxSize = 'sm';
    @Input() hasMobileFilter: boolean;
    @Input() hasFilterSearch: boolean;

    @Output() actionButtonClicked = new EventEmitter<ActionButton>();
    @Output() multiselectActionButtonClicked =
        new EventEmitter<MultiselectPayload>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() infinityScrollFired = new EventEmitter();
    @Output() rowSelectChange = new EventEmitter<ToggleEvent>();
    @Output() selectedRowsChange = new EventEmitter<TableRow[]>();
    @Output() download = new EventEmitter<MenuItem>();
    @Output() rowDragged = new EventEmitter<TableRowDragEvent>();
    @Output() searchText = new EventEmitter<string>();

    get tableRows(): TableRow[] {
        if (
            this.table.pagingType === PagingType.ClientPagination &&
            this.tablePaging
        ) {
            const start =
                (this.tablePaging.page - 1) * this.tablePaging.pageSize;
            const end = start + this.tablePaging.pageSize;
            return this.table.rows.slice(start, end);
        }
        return this.table.rows;
    }

    instanceId: string;

    pageLengthMenu: MenuItem[] = [5, 10, 20, 50, 100].map((value) => ({
        label: String(value),
        value,
    }));

    hoverableTable: boolean;

    pageLengthMenuDefaultValue = 20;

    selectedRows: TableRow[] = [];
    get hasSelectedRows(): boolean {
        return Boolean(this.selectedRows?.length);
    }
    showMultiSelectBanner: boolean;

    dragStartIndex: number;
    dragDropIndex: number;

    isMobileView$ = new BehaviorSubject<boolean>(false);
    responsiveIsSmall$ = new BehaviorSubject<boolean>(false);

    readonly CHECKBOX_STYLE = CheckboxStyle;
    readonly FEATURE_SVG = FeatureSvg;
    readonly SORT_DIRECTION = SortDirection;
    readonly TABLE_DISPLAY_MODE = TableDisplayMode;
    readonly PAGING_TYPE = PagingType;

    readonly DEFAULT_SORT_ICON = ICON_SORT_ARROWS.stack.map(
        (item) => item.icon
    );
    readonly SORT_UP_ICON = ICON_SORT_ARROW_UP.icon;
    readonly SORT_DOWN_ICON = ICON_SORT_ARROW_DOWN.icon;
    readonly ICON_DRAG_HANDLE = ICON_DRAG_HANDLE.icon;
    readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN.icon;

    constructor(
        public elementRef: ElementRef,
        private router: Router,
        private renderer: Renderer2,
        private resizeService: ResizeService
    ) {
        super();
    }

    ngOnInit() {
        this.displayMode = this.displayMode || TableDisplayMode.ResponsiveTable;
        this.instanceId = new RandomStringPipe().transform();

        this.resizeService.activeBreakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((breakpoint) => {
                this.responsiveIsSmall$.next(breakpoint <= MEDIA_BREAKPOINT_LG);
                if (this.displayMode !== TableDisplayMode.Table) {
                    this.isMobileView$.next(
                        window.innerWidth < MEDIA_BREAKPOINT_MD
                    );
                }
            });

        if (
            this.hideColumnIndexesOnSmallScreen &&
            this.hideColumnIndexesOnSmallScreen.length > 0
        ) {
            this.displayMode = TableDisplayMode.Table;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initialSelectedRows &&
            this.initialiseSelectedRows(this.initialSelectedRows);

        if (changes?.isLoading && changes.isLoading.currentValue) {
            this.closeMultiselectBanner();
        }
    }

    setHoverableTable(): void {
        const rows = this.table?.rows;
        if (rows?.length) {
            this.hoverableTable =
                rows.findIndex(
                    (row) => row.formSetup || row.routerLink || row.callback
                ) > -1;
        } else {
            this.hoverableTable = false;
        }
    }

    getResultsCount(table: Table, tablePaging: Paging): number {
        if (tablePaging?.totalRecords) {
            return tablePaging.totalRecords;
        } else {
            return table?.rows?.length || 0;
        }
    }

    getResultCountFrom(page: number, pageSize: number): number {
        return (page - 1) * pageSize + 1;
    }

    getResultCountTo(
        page: number,
        pageSize: number,
        totalRecords: number
    ): number {
        return page * pageSize < totalRecords ? page * pageSize : totalRecords;
    }

    updatePaging(tablePaging: Paging): void {
        this.tablePaging = tablePaging;
    }

    onHeaderClick(header: TableHeader): void {
        if (!header.sortByFieldName) {
            return;
        }

        this.onSort(header.sortByFieldName);
    }

    dragstart(index: number): void {
        this.dragStartIndex = index;
    }

    dragenter(rowEl: HTMLTableRowElement): void {
        rowEl.classList.add('gu-transit');
    }

    dragleave(rowEl: HTMLTableRowElement, index: number): void {
        rowEl.classList.remove('gu-transit');
        this.dragDropIndex = index;
    }

    dragend(): void {
        this.rowDragged.emit({
            startIndex: this.dragStartIndex,
            dropIndex: this.dragDropIndex,
        });
    }

    dragover(event: Event): void {
        event.preventDefault();
    }

    onSort(sortBy: string): void {
        if (!sortBy) {
            return;
        }

        if (this.filterHandler.getSortBy() === sortBy) {
            this.filterHandler.setSortDirection(
                this.filterHandler.getSortDirection() ===
                    SortDirection.Ascending
                    ? SortDirection.Descending
                    : SortDirection.Ascending
            );
        } else {
            this.filterHandler.setSortBy(sortBy);
            this.filterHandler.setSortDirection(SortDirection.Ascending);
        }
    }

    onRowClicked(row: TableRow): void {
        if (!row.routerLink) {
            if (this.canSelectRows) {
                this.onSelectRow(row);
                return;
            }

            if (this.canToggleRows) {
                this.onToggleRow(row);
                return;
            }
        }

        if (row.routerLink) {
            this.router.navigate(row.routerLink);
        }

        if (row.callback) {
            row.callback();
        }
    }

    onToggleRow(row: TableRow): void {
        if (!this.canToggleRows) {
            return;
        }
        row.toggleRowTemplate.visible = !row.toggleRowTemplate.visible;
    }

    onSelectRow(row: TableRow, isRowSelected?: boolean): void {
        if (!this.canSelectRows) {
            return;
        }

        if (this.canSelectOnlyOneRow) {
            this.selectedRows = [row];
        } else {
            if (this.selectedRows.includes(row)) {
                this.selectedRows = this.selectedRows.filter(
                    (existingRow) => existingRow !== row
                );
            } else {
                this.selectedRows = [...this.selectedRows, row];
            }
        }
        this.selectedRowsChange.emit(this.selectedRows);

        this.onRowSelectChange(row, isRowSelected);
    }

    onRowSelectChange(row: TableRow, isRowSelected: boolean): void {
        if (isRowSelected !== undefined) {
            row.values.find(
                (value) =>
                    value?.data?.id &&
                    this.rowSelectChange.emit({
                        isToggled: isRowSelected,
                        data: value.data.id,
                    })
            );
        }
        this.showMultiSelectBanner = this.canSelectRows && this.hasSelectedRows;
    }

    selectAllRows(): void {
        if (this.selectedRows?.length < this.table.rows.length) {
            this.selectedRows = this.table.rows;
        } else {
            this.selectedRows = [];
        }
        this.selectedRowsChange.emit(this.selectedRows);

        this.table.rows.map((row) =>
            this.onRowSelectChange(row, Boolean(this.selectedRows.length))
        );
    }

    initialiseSelectedRows(selectedRows: TableRow[]): void {
        this.selectedRows = selectedRows;
    }

    rowIsSelected(selectedRows: TableRow[], row: TableRow): boolean {
        return selectedRows.includes(row);
    }

    onActionButtonClicked(event: boolean, actionButton: ActionButton): void {
        actionButton.isToggledOn = event;
        this.actionButtonClicked.emit(actionButton);
    }

    onMultiselectActionButtonClicked(button: ActionButton): void {
        this.multiselectActionButtonClicked.emit({
            multiselectBannerButton: button,
            selectedRows: this.selectedRows,
        });
    }

    onPageChanged(page: number): void {
        if (this.table.pagingType === PagingType.ClientPagination) {
            this.tablePaging.page = page;
        } else {
            this.pageChanged.emit(page);
        }
    }

    onInfinityScrollFired(): void {
        if (
            this.table.pagingType === PagingType.InfiniteScroll &&
            this.tablePaging?.page < this.tablePaging?.totalPages
        ) {
            this.infinityScrollFired.emit();
        }
    }

    onSearch(searchText: string): void {
        if (this.filterHandler) {
            this.filterHandler.setSearchText(searchText);
        } else {
            this.searchText.emit(searchText);
        }
    }

    closeMultiselectBanner(): void {
        this.showMultiSelectBanner = false;
        this.selectedRows = [];
        this.selectedRowsChange.emit(this.selectedRows);
    }

    getSortMenu(table: Table): MenuItem[] {
        return (
            table?.headers &&
            table.headers
                .filter((header) => header.sortByFieldName)
                .map(
                    (header) =>
                        ({
                            label: header.value,
                            id: header.sortByFieldName,
                        } as MenuItem)
                )
        );
    }
}
