import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxAlertModule } from '@wdx/shared/components/wdx-alert';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { AtomCloseModule } from '../../../components/atoms/atom-close/atom-close.module';
import { MoleculeActionButtonModule } from '../molecule-action-button/molecule-action-button.module';
import { MoleculeMultiselectBannerComponent } from './molecule-multiselect-banner.component';

@NgModule({
    imports: [
        AtomCloseModule,
        CommonModule,
        MoleculeActionButtonModule,
        WdxAlertModule,
        WdxCheckboxModule,
    ],
    declarations: [MoleculeMultiselectBannerComponent],
    exports: [MoleculeMultiselectBannerComponent],
})
export class MoleculeMultiselectBannerModule {}
