import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';

import { Severity } from '@wdx/shared/utils';
import { MenuItem } from '../../../models/menu-item.model';
import { Icon } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-context-menu',
    templateUrl: './molecule-context-menu.component.html',
})
export class MoleculeContextMenuComponent implements AfterViewInit, OnChanges {
    @Input() menu: MenuItem[];
    @Input() highlightSelected = true;
    @Input() showCheckOnHighlight = true;
    @Input() isMulti: boolean;
    @Input() set initialValue(values: string[]) {
        const valuesArray = Array.isArray(values) ? values : [values];
        this.selectedMenuItemValue = valuesArray && valuesArray[0];
        this.selectedMenuItemValues = valuesArray;
    }
    @Input() hideIcons: boolean;
    @Input() ghostIcons: boolean;
    @Input() cySelector: string;

    @Output() singleItemSelected = new EventEmitter<MenuItem>();
    @Output() multipleItemsSelected = new EventEmitter<MenuItem[]>();

    activeMenus: MenuItem[][] = [];

    selectedMenuItemValue: string;
    selectedMenuItemValues: string[] = [];

    readonly SEVERITY = Severity;

    ngAfterViewInit() {
        this.activeMenus = [this.menu];
    }

    ngOnChanges() {
        this.activeMenus = [this.menu];
    }

    onClick(item: MenuItem) {
        if (item.callback) {
            item.callback();
        }

        if (this.highlightSelected) {
            this.selectItem(item);
        } else {
            this.singleItemSelected.emit(item);
        }
    }

    manageLinkedMenu(item: MenuItem) {
        if (item.backButton) {
            this.activeMenus.splice(-1, 1);
        } else {
            this.activeMenus.push([
                {
                    label: item.label,
                    icon: 'chevron-left',
                    subMenu: item.linkedMenu$
                        ? item.linkedMenu$.value
                        : item.linkedMenu,
                    backButton: true,
                },
            ]);
        }
    }

    selectItem(menuItem: MenuItem) {
        if (this.isMulti) {
            const currentSelectedMenuItemIds =
                this.selectedMenuItemValues || [];

            this.selectedMenuItemValues = currentSelectedMenuItemIds.includes(
                menuItem.value
            )
                ? currentSelectedMenuItemIds.filter(
                      (existingItem) => existingItem !== menuItem.value
                  )
                : [...currentSelectedMenuItemIds, menuItem.value];

            const menuItemsForSelectedMenuItemValues = this.menu.filter(
                (menuItem) =>
                    this.selectedMenuItemValues.includes(menuItem.value)
            );

            this.multipleItemsSelected.emit(menuItemsForSelectedMenuItemValues);
        } else {
            this.selectedMenuItemValue = menuItem.value;

            this.singleItemSelected.emit(menuItem);
        }
    }

    isSelected(
        menuItem: MenuItem,
        selectedMenuItemValues: string[],
        selectedMenuItemValue: string,
        isMulti: boolean
    ): boolean {
        if (isMulti) {
            return selectedMenuItemValues?.includes(menuItem.value);
        } else {
            return (
                (menuItem.value && menuItem.value === selectedMenuItemValue) ||
                menuItem.selected
            );
        }
    }

    clearSelection() {
        this.selectedMenuItemValue = null;
        this.selectedMenuItemValues = [];
    }

    trackByFn(icon: Icon) {
        return icon.stack;
    }
}
