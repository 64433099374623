import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushModule } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeCardFooterModule } from '../../../components/molecules/molecule-card-footer/molecule-card-footer.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { MoleculeOpportunityContextMenuModule } from '../../../components/molecules/molecule-opportunity-context-menu/molecule-opportunity-context-menu.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeOpportunityCardComponent } from './molecule-opportunity-card.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeCardFooterModule,
        MoleculeInfoCardModule,
        MoleculeOpportunityContextMenuModule,
        PipesModule,
        PushModule,
        RouterModule,
        WdxIconModule,
    ],
    declarations: [MoleculeOpportunityCardComponent],
    exports: [MoleculeOpportunityCardComponent],
})
export class MoleculeOpportunityCardModule {}
