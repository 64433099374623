import { createAction, props } from '@ngrx/store';
import {
    AccessVisibility,
    Activity,
    ActivityFile,
    ActivityHistory,
    ActivityStatusType,
    FileIndex,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getAttachmentsForId = createAction(
    '[Activities] Get Attachments For Id',
    props<{
        activityId: string;
    }>()
);

export const getAttachmentsForIdSuccess = createAction(
    '[Activities] Get Attachments For Id Success',
    props<{
        activityId: string;
        attachments: ActivityFile[];
    }>()
);

export const getAttachmentsForIdFailure = createAction(
    '[Activities] Get Attachments For Id Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const getDocumentsForId = createAction(
    '[Activities] Get Documents For Id',
    props<{
        activityId: string;
        reset: boolean;
    }>()
);

export const getDocumentsForIdSuccess = createAction(
    '[Activities] Get Documents For Id Success',
    props<{
        documents: PaginatedApiResponse<Document>;
        activityId: string;
    }>()
);

export const getDocumentsForIdFailure = createAction(
    '[Activities] Get Documents For Id Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const getLastActivityForParty = createAction(
    '[Activities] Get Last Activity For Party',
    props<{
        partyId: string;
    }>()
);

export const getLastActivityForPartySuccess = createAction(
    '[Activities] Get Last Activity For Party Success',
    props<{
        partyId: string;
        activity: Activity;
    }>()
);

export const getLastActivityForPartyFailure = createAction(
    '[Activities] Get Last Activity For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getNextActivityForParty = createAction(
    '[Activities] Get Next Activity For Party',
    props<{
        partyId: string;
    }>()
);

export const getNextActivityForPartySuccess = createAction(
    '[Activities] Get Next Activity For Party Success',
    props<{
        partyId: string;
        activity: Activity;
    }>()
);

export const getNextActivityForPartyFailure = createAction(
    '[Activities] Get Next Activity For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getForClientProduct = createAction(
    '[Activities] Get For Client Product',
    props<{
        clientProductId: string;
        reset: boolean;
    }>()
);

export const getForClientProductSuccess = createAction(
    '[Activities] Get For Client Product Success',
    props<{
        activities: PaginatedApiResponse<Activity>;
        clientProductId: string;
    }>()
);

export const getForClientProductFailure = createAction(
    '[Activities] Get For Client Product Failure',
    props<{
        clientProductId: string;
        error: Error;
    }>()
);

export const getForClient = createAction(
    '[Activities] Get For Client',
    props<{
        clientId: string;
        reset: boolean;
    }>()
);

export const getForClientSuccess = createAction(
    '[Activities] Get For Client Success',
    props<{
        clientActivities: PaginatedApiResponse<Activity>;
        clientId: string;
    }>()
);

export const getForClientFailure = createAction(
    '[Activities] Get For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getForId = createAction(
    '[Activities] Get For Id',
    props<{
        activityId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Activities] Get For Id Success',
    props<{
        activityId: string;
        activity: Activity;
    }>()
);

export const getForIdFailure = createAction(
    '[Activities] Get For Id Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const setStatusForId = createAction(
    '[Activities] Set Status For Id',
    props<{
        activityId: string;
        status: ActivityStatusType;
    }>()
);

export const setStatusForIdSuccess = createAction(
    '[Activities] Set Status For Id Success',
    props<{
        activity: Activity;
    }>()
);

export const setStatusForIdFailure = createAction(
    '[Activities] Set Status For Id Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const getHistoryForId = createAction(
    '[Activities] Get History for Id',
    props<{
        activityId: string;
    }>()
);

export const getHistoryForIdSuccess = createAction(
    '[Activities] Get History for Id Success',
    props<{
        activityId: string;
        activityHistory: ActivityHistory[];
    }>()
);

export const getHistoryForIdFailure = createAction(
    '[Activities] Get History for Id Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const setDueDate = createAction(
    '[Activities] Set Due Date',
    props<{
        activityId: string;
        date: Date;
    }>()
);

export const setDueDateSuccess = createAction(
    '[Activities] Set Due Date Success',
    props<{
        activity: Activity;
    }>()
);

export const setDueDateFailure = createAction(
    '[Activities] Set Due Date Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const cancelActivity = createAction(
    '[Activities] Cancel Activity',
    props<{
        activityId: string;
    }>()
);

export const cancelActivitySuccess = createAction(
    '[Activities] Cancel Activity Success',
    props<{
        activity: Activity;
    }>()
);

export const cancelActivityFailure = createAction(
    '[Activities] Cancel Activity Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const uploadAttachment = createAction(
    '[Activities] File',
    props<{
        activityId: string;
        file: File;
    }>()
);

export const uploadAttachmentCancel = createAction(
    '[Activities] File Cancel',
    props<{
        activityId: string;
    }>()
);

export const uploadAttachmentReset = createAction(
    '[Activities] File Reset',
    props<{
        activityId: string;
    }>()
);

export const uploadAttachmentStarted = createAction(
    '[Activities] File Started',
    props<{
        activityId: string;
    }>()
);

export const uploadAttachmentProgress = createAction(
    '[Activities] File Progress',
    props<{
        activityId: string;
        progress: number;
    }>()
);

export const uploadAttachmentDownloading = createAction(
    '[Activities] File Downloading',
    props<{
        activityId: string;
    }>()
);

export const uploadAttachmentComplete = createAction(
    '[Activities] File Complete',
    props<{
        activityId: string;
        fileIndex: FileIndex;
    }>()
);

export const uploadAttachmentFailure = createAction(
    '[Activities] File Failure',
    props<{
        activityId: string;
        error: string;
    }>()
);

export const getVisibilityForId = createAction(
    '[Activities] Get Visibility For Id',
    props<{
        id: string;
    }>()
);

export const getVisibilityForIdSuccess = createAction(
    '[Activities] Get Visibility For Id Success',
    props<{
        id: string;
        visibility: AccessVisibility[];
    }>()
);

export const getVisibilityForIdFailure = createAction(
    '[Activities] Get Visibility For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);
