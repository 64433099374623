<ng-container *ngIf="form">
    <div
        class="form-action-buttons px-4 py-2 active-component d-flex align-items-center"
    >
        <div *ngIf="entityId && appId" class="fw-bold me-2">
            {{ completionSummary?.completed }} of
            {{ completionSummary?.expected }} fields completed
        </div>

        <div class="ms-auto d-flex align-items-center">
            <wdx-badge
                *ngIf="saveDraftMode && (isDraftForm || isPublishedForm)"
                class="me-2"
                [themeColor]="
                    (isDraftForm && 'info') || (isPublishedForm && 'success')
                "
            >
                {{ formData?.status }}
            </wdx-badge>
            <button
                class="btn btn-outline-primary"
                data-cy="btn-cancel"
                (click)="onCancelClick()"
            >
                Cancel
            </button>

            <button
                *ngIf="saveCompleteMode"
                class="btn btn-primary ms-2"
                data-cy="btn-save-complete"
                [disabled]="isSaving || fullFormLock"
                (click)="onSaveClick('complete=true')"
            >
                <wdx-spinner *ngIf="isSaving" small="true"></wdx-spinner>
                Save and Complete
            </button>

            <button
                *ngIf="saveMode && !saveDraftMode"
                class="btn btn-primary ms-2"
                data-cy="btn-save"
                [disabled]="isSaving || fullFormLock"
                (click)="onSaveClick()"
            >
                <wdx-spinner *ngIf="isSaving" small="true"></wdx-spinner>
                Save
            </button>

            <wdx-split-button
                *ngIf="saveMode && saveDraftMode"
                class="ms-2"
                [isLoading]="isSaving"
                [disabled]="fullFormLock"
                [menuItems]="splitButtonMenuItems"
                (menuItemClicked)="onSplitMenuItemClicked($event)"
            >
                <button
                    splitbutton
                    class="btn btn-primary"
                    data-cy="btn-save"
                    [disabled]="isSaving || fullFormLock"
                    (click)="onSaveClick()"
                >
                    <wdx-spinner *ngIf="isSaving" small="true"></wdx-spinner>
                    Save
                </button>
            </wdx-split-button>
        </div>
    </div>

    <div class="form-container flex-grow-1 overflow-hidden">
        <atom-async-data
            class="form__async-data"
            [isLoading]="isSaving"
            [hasError]="hasError"
            [overlayContent]="true"
        >
            <div class="h-100 d-flex flex-column overflow-hidden">
                <div *ngIf="form?.invalid && validationSummary?.length > 0">
                    <molecule-validation-summary
                        *ngIf="submitAttempted"
                        class="d-block mb-2"
                        data-cy="lbl-message"
                        [message]="validationMessage"
                        [validationSummary]="validationSummary"
                        (errorClicked)="onErrorClicked($event)"
                    >
                        <button
                            *ngIf="saveDraftMode"
                            class="btn btn-outline-primary text-nowrap ms-2"
                            data-cy="btn-save-draft"
                            (click)="onDraftSaveClick()"
                        >
                            Save as Draft
                        </button></molecule-validation-summary
                    >
                </div>

                <wdx-ff-pending-changes
                    *ngIf="formData?.pendingChanges?.length"
                    data-cy="pending-changes"
                    [pendingChanges]="formData?.pendingChanges"
                    [pendingChangesSubForm]="
                        formPendingChangesService?.pendingChangesSubFormCounter
                    "
                ></wdx-ff-pending-changes>

                <wdx-alert
                    *ngIf="messageDefinitionLength"
                    class="remove-margin-bottom"
                    themeColor="warning"
                >
                    <wdx-icon
                        class="pe-3 align-self-start"
                        icon="info-circle"
                    ></wdx-icon>
                    <span
                        *ngFor="
                            let messageDefinition of messageDefinition
                                | keyvalue;
                            trackBy: trackByMessage
                        "
                        [innerHTML]="
                            messageDefinition?.value.label
                                | markdownToHtml
                                | safeHtml
                        "
                    ></span>
                </wdx-alert>

                <molecule-form-version-info *ngIf="showFormSwitcherPanel">
                    <ng-container
                        *ngTemplateOutlet="formVersionInfo"
                    ></ng-container>
                </molecule-form-version-info>

                <div *ngIf="formData?.lock">
                    <molecule-form-lock-summary
                        [formData]="formData"
                    ></molecule-form-lock-summary>
                </div>

                <ng-container
                    *ngIf="summaryInfoCards$ | ngrxPush; let summaryInfoCards"
                >
                    <div *ngIf="summaryInfoCards.length" class="border-bottom">
                        <clmi-form-summary-info-cards
                            [infoCards]="summaryInfoCards"
                        ></clmi-form-summary-info-cards>
                    </div>
                </ng-container>

                <form
                    class="form flex-grow-1 overflow-hidden"
                    autocomplete="off"
                    [formGroup]="form"
                >
                    <div class="d-block h-100 overflow-auto scroll-smooth">
                        <ngb-accordion
                            #accordion
                            class="accordion form-accordion accordion-flush"
                            [activeIds]="activePanelIds"
                            [destroyOnHide]="false"
                            [closeOthers]="false"
                        >
                            <ng-container
                                *ngFor="
                                    let formSectionLayoutDefinition of condService.layoutAndDefinition;
                                    let i = index;
                                    let first = first;
                                    trackBy: trackSection
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        !formSectionLayoutDefinition?.section
                                            ?.isHidden
                                    "
                                >
                                    <ngb-panel
                                        id="reactive-form-{{ i }}"
                                        [cardClass]="
                                            'form-section bg-muted border-bottom-0' +
                                            (first ? '' : ' mt-1') +
                                            (condService.layoutAndDefinition
                                                .length > 1
                                                ? ''
                                                : ' no-panel-toggle')
                                        "
                                    >
                                        <ng-template
                                            ngbPanelHeader
                                            let-opened="opened"
                                        >
                                            <button
                                                *ngIf="
                                                    condService
                                                        .layoutAndDefinition
                                                        .length > 1
                                                "
                                                ngbPanelToggle
                                                class="form-section__header accordion-header accordion-button d-flex align-items-center bg-muted"
                                                [attr.data-cy]="
                                                    'panel-toggle-' +
                                                    formSectionLayoutDefinition
                                                        .section.name
                                                "
                                            >
                                                <div
                                                    class="d-flex align-items-center flex-grow-1"
                                                >
                                                    <h6>
                                                        {{
                                                            formSectionLayoutDefinition
                                                                .section.label
                                                        }}
                                                    </h6>

                                                    <wdx-icon
                                                        *ngIf="
                                                            submitAttempted &&
                                                            sectionsInError?.includes(
                                                                formSectionLayoutDefinition
                                                                    .section
                                                                    .name
                                                            )
                                                        "
                                                        class="text-danger ms-2"
                                                        icon="exclamation-triangle"
                                                    >
                                                    </wdx-icon>
                                                </div>
                                            </button>
                                        </ng-template>

                                        <ng-template ngbPanelContent>
                                            <div class="form-section__body">
                                                <h6
                                                    *ngIf="
                                                        formSectionLayoutDefinition
                                                            .section
                                                            .guidanceText
                                                    "
                                                    class="form-section__body__blurb small"
                                                >
                                                    {{
                                                        formSectionLayoutDefinition
                                                            .section
                                                            .guidanceText
                                                    }}
                                                </h6>

                                                <div
                                                    class="form-section__body__fields form__fields grid"
                                                >
                                                    <ng-container
                                                        *ngFor="
                                                            let elementDefinition of formSectionLayoutDefinition.layoutAndDefinition;
                                                            let iLayout = index;
                                                            trackBy: trackElement
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="
                                                                !elementDefinition.isHidden &&
                                                                (elementDefinition.elementType ||
                                                                    elementDefinition.fieldType)
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    elementDefinition.forcesNewRow &&
                                                                    !elementDefinition.forcesFullWidth
                                                                "
                                                                class="g-col-12"
                                                            ></div>

                                                            <div
                                                                class="g-col-12 input form-input"
                                                                [class.g-col-lg-6]="
                                                                    !elementDefinition.forcesFullWidth &&
                                                                    elementDefinition.fieldType !==
                                                                        FORM_FIELD_TYPE.Address &&
                                                                    (!elementDefinition.forcesNewRow ||
                                                                        !elementDefinition.forcesFullWidth)
                                                                "
                                                                [attr.data-cy]="
                                                                    'lbl-' +
                                                                    elementDefinition.name
                                                                "
                                                                [attr.data-form-control]="
                                                                    elementDefinition.name
                                                                "
                                                            >
                                                                <ng-container
                                                                    *ngIf="
                                                                        ARRAY_CONTROL_TYPES.includes(
                                                                            elementDefinition?.fieldType
                                                                        );
                                                                        else standardControls
                                                                    "
                                                                >
                                                                    <h6
                                                                        *ngIf="
                                                                            elementDefinition
                                                                                .children[0]
                                                                                .section
                                                                                .guidanceText
                                                                        "
                                                                        class="form-section__body__blurb small"
                                                                    >
                                                                        {{
                                                                            elementDefinition
                                                                                .children[0]
                                                                                .section
                                                                                .guidanceText
                                                                        }}
                                                                    </h6>
                                                                    <organism-reactive-form-array
                                                                        [entityId]="
                                                                            entityId
                                                                        "
                                                                        [formArrayName]="
                                                                            elementDefinition.name
                                                                        "
                                                                        [elementDefinition]="
                                                                            elementDefinition
                                                                        "
                                                                        [fbService]="
                                                                            fbService
                                                                        "
                                                                        [formContext]="
                                                                            formData?.context
                                                                        "
                                                                        [parentData]="
                                                                            formData.data
                                                                        "
                                                                    ></organism-reactive-form-array>
                                                                </ng-container>

                                                                <ng-template
                                                                    #standardControls
                                                                >
                                                                    <clmi-reactive-form-controls
                                                                        [entityId]="
                                                                            entityId
                                                                        "
                                                                        [formElement]="
                                                                            elementDefinition
                                                                        "
                                                                        [formContext]="
                                                                            formData?.context
                                                                        "
                                                                    ></clmi-reactive-form-controls>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ng-container>
                            </ng-container>
                        </ngb-accordion>
                    </div>
                </form>

                <ng-template #formVersionInfo>
                    <div>
                        You are viewing a
                        <span class="fw-bold">{{ formData.status }}</span>
                        version of this form (by
                        <button
                            class="btn btn-link"
                            (click)="
                                openLinkInNewTab(
                                    formData?.context?.lastUpdatedBy?.partyType,
                                    formData?.context?.lastUpdatedBy?.id
                                )
                            "
                        >
                            {{
                                formData?.lastUpdatedBy || 'unknown user'
                            }}</button
                        >,
                        {{
                            formData?.lastUpdatedOn
                                | displayDate : { relative: true }
                        }}) -
                        <button
                            class="btn btn-link"
                            (click)="switchFormVersion()"
                        >
                            Click here to view the
                            {{
                                formData.status === FORM_DATA_STATUS.Draft
                                    ? 'previously saved'
                                    : 'draft'
                            }}
                            version
                        </button>
                    </div>
                </ng-template>
            </div>
        </atom-async-data>
    </div>
</ng-container>
