<atom-modal-wrapper
    [modalId]="IMPORT_ENTITIES_MODAL_ID"
    [modalTemplate]="entitiesModal"
>
    <ng-template #entitiesModal>
        <organism-modal
            modalTitle="Import Data"
            (modalClosed)="onModalClosed()"
        >
            <div
                wdxListGroup
                [flush]="true"
                [wdxIsLoading]="importEntitiesIsLoading$ | ngrxPush"
                [hasError]="importEntitiesHasError$ | ngrxPush"
            >
                <button
                    wdxListGroupItem
                    *ngFor="let entity of importEntities$ | ngrxPush"
                    [wdxListGroupAction]="true"
                    (click)="onImport(entity)"
                >
                    <wdx-icon
                        class="me-2"
                        icon="file-import"
                        [wdxListGroupFlush]="true"
                    ></wdx-icon>
                    {{ entity | titleCase }}
                </button>
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
