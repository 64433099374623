import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { MoleculeValidationSummaryComponent } from './molecule-validation-summary.component';

@NgModule({
    declarations: [MoleculeValidationSummaryComponent],
    imports: [
        CommonModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
        WdxIconModule,
    ],
    exports: [MoleculeValidationSummaryComponent],
})
export class MoleculeValidationSummaryModule {}
