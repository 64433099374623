import { Location } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    Article,
    EntityPermissionType,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/api-models';

import { Avatar } from '@wdx/clmi/utils/models';
import {
    Icon,
    KeyValueObject,
    Severity,
    fadeInOutAnimation,
} from '@wdx/shared/utils';
import { ICON_EDIT } from '../../../../constants/icons.constants';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { FormSetup } from '../../../../models/form-setup.model';
import { IdList } from '../../../../models/id-list.model';
import { InfoCardData } from '../../../../models/info-card-data.model';
import { ListItem } from '../../../../models/list-item.model';
import { Modal } from '../../../../models/modal.model';
import { IExternalLink } from '../../../../models/routing-link.model';
import { TagData } from '../../../../models/tag-data.model';
import { EntityRouteOpenNewTabPipe } from '../../../../pipes/entity-route-open-new-tab.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-info-card-dumb',
    templateUrl: './molecule-info-card-dumb.component.html',
    styleUrls: ['./molecule-info-card-dumb.component.scss'],
    animations: [fadeInOutAnimation],
})
export class MoleculeInfoCardDumbComponent implements OnInit {
    @Input() primaryInfo: string;
    @Input() primaryInfoIcon: Icon | string;
    @Input() avatars: Avatar[];
    @Input() icon: Icon | string;
    @Input() iconClass: string;
    @Input() avatarIconDivider: boolean;
    @Input() severity: Severity;
    @Input() size: string;
    @Input() secondaryInfo: TagData[] | TagData;
    @Input() tertiaryInfo: ListItem[] | ListItem;
    @Input() idList: IdList;
    @Input() textColor: string;
    @Input() routerLinkData: string[];
    @Input() openInNewTab: boolean;
    @Input() entityType: SystemEntity;
    @Input() entityId: string;
    @Input() queryParamsData: KeyValueObject;
    @Input() subInfoCards: InfoCardData[];
    @Input() canDelete = false;
    @Input() canSelect = false;
    @Input() isSelected = false;
    @Input() modalData: Modal;
    @Input() article: Article;
    @Input() showEditForm: boolean;
    @Input() formSetup: FormSetup;
    @Input() hasLinkClickedObservers: boolean;
    @Input() ignoreLinkClickedObservers = false;
    @Input() compact = false;
    @Input() fillHorizontalSpace = true;
    @Input() linkWholeCard = false;
    @Input() cySelector: string;
    @Input() cyValue: string;
    @Input() externalLink: IExternalLink;

    @Output() linkClicked = new EventEmitter();
    @Output() subInfoCardLinkClicked = new EventEmitter<InfoCardData>();
    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() selected = new EventEmitter();
    @Output() deselected = new EventEmitter();

    @ViewChild('link') link: ElementRef<any>;

    secondaryInfoTags: TagData[];

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        if (this.subInfoCards && this.subInfoCards.length) {
            this.renderer.addClass(
                this.elementRef.nativeElement,
                'info-card--sub-info-cards'
            );
        }
        if (this.secondaryInfo) {
            if (Array.isArray(this.secondaryInfo)) {
                this.secondaryInfoTags = this.secondaryInfo;
            } else {
                this.secondaryInfoTags = [{ ...this.secondaryInfo } as TagData];
            }
        }
        if (this.tertiaryInfo && typeof this.tertiaryInfo === 'string') {
            this.tertiaryInfo = [this.tertiaryInfo];
        }
        if (typeof this.icon !== 'string' && !this.icon?.icon) {
            this.icon = null;
        }
    }

    formSetupAsEditActionButton(formSetup: FormSetup): ActionButton {
        return {
            mode: ActionButtonMode.IconButton,
            icon: ICON_EDIT.icon,
            formSetup: formSetup,
            permission: EntityPermissionType.Edit,
            cySelector: 'info-card-pencil',
        };
    }

    onLinkClicked(event: Event): void {
        event.stopPropagation();
        if (this.openInNewTab) {
            event.preventDefault();
            this.openLinkInNewTab(this.entityType, this.entityId);
        }
        this.linkClicked.emit();
    }

    openLinkInNewTab(entity: string, id: string): void {
        new EntityRouteOpenNewTabPipe().transform(
            SystemEntity[entity] || UserActionEntity[entity],
            this.router,
            this.route,
            this.location,
            id
        );
    }

    onSubInfoCardLinkClicked(subInfoCard: InfoCardData): void {
        this.subInfoCardLinkClicked.emit(subInfoCard);
    }

    onEdit(): void {
        this.edit.emit();
    }

    onDelete(): void {
        if (!this.canDelete) {
            return;
        }
        this.delete.emit();
    }

    onClick() {
        if (!this.linkWholeCard) {
            return;
        }
        this.renderer.selectRootElement(this.link.nativeElement, true).click();
    }

    onSelect(): void {
        if (!this.canSelect) {
            return;
        }

        this.isSelected = !this.isSelected;

        if (this.isSelected) {
            this.selected.emit();
        } else {
            this.deselected.emit();
        }
    }
}
