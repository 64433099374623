import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeSearchBarModule } from '../../../components/molecules/molecule-search-bar/molecule-search-bar.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ImportEntitiesModule } from '../../../shared/components/import-entities';
import { ImportExportModule } from '../../../shared/components/import-export/import-export.module';
import { FormFiltersModule } from '../../../shared/features/form-filters/form-filters.module';
import { MoleculeSearchAndFilterBarComponent } from './molecule-search-and-filter-bar.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormFiltersModule,
        MoleculeSearchBarModule,
        OrganismModalModule,
        PipesModule,
        RouterModule,
        ImportExportModule,
        ImportEntitiesModule,
    ],
    declarations: [MoleculeSearchAndFilterBarComponent],
    exports: [MoleculeSearchAndFilterBarComponent],
})
export class MoleculeSearchAndFilterBarModule {}
