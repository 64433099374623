import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FieldDefinition } from '@wdx/clmi/api-models';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import {
    ICON_CHEVRON_DOWN,
    ICON_CHEVRON_RIGHT,
} from '../../../../../constants/icons.constants';
import { BaseReactiveFormControlClass } from '../../../../../features/reactive-forms/abstract-classes/base-reactive-form-control.class';
import { ExtendedFieldDefinitionOption } from '../../../../../models/extended-field-definition-option.model';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services';

@Component({
    selector: 'clmi-reactive-hierarchical-control',
    templateUrl: './reactive-hierarchical-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveHierarchicalControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit
{
    @ViewChild('hierarchySelect') hierarchySelect: NgSelectComponent;
    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService
    ) {
        super(controlContainer, dynamicDataService);
    }

    idLookup$ = new Subject<string>();

    finalLevel: number;
    finalLevelOptions: ExtendedFieldDefinitionOption[];
    firstLevelOptions: ExtendedFieldDefinitionOption[];
    allOptions: ExtendedFieldDefinitionOption[];
    activeHierarchy: ExtendedFieldDefinitionOption[] = [];
    isHierarchyLookup = false;

    readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN.icon;
    readonly ICON_CHEVRON_RIGHT = ICON_CHEVRON_RIGHT.icon;

    get hierarchyLookupPlaceholder() {
        return 'Select an option to explore hierarchy';
    }

    get idLookupPlaceholder() {
        return !this.formControl?.disabled ? 'Enter ID' : '';
    }

    get fieldDefinition() {
        return this.formElement as FieldDefinition;
    }

    ngOnInit(): void {
        this.dynamicDataService
            .getSelectApiSourceOptions(this.formElement.selectSource)
            .pipe(
                take(1),
                map((options) =>
                    options.sort((a, b) => a.label.localeCompare(b.label))
                )
            )
            .subscribe((options) => {
                this.allOptions = options;

                this.firstLevelOptions = options.filter(
                    (option) => option.level === 0
                );

                this.finalLevel = options[options.length - 1].level;

                this.finalLevelOptions = options
                    .filter((option) => option.level === this.finalLevel)
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map((option) => ({
                        ...option,
                        category: this.firstLevelOptions.find((o) =>
                            option.value.startsWith(o.value)
                        )?.label,
                    }));

                this.options$.next(null);

                this.subscribeToIdLookup();
            });
    }

    subscribeToIdLookup() {
        this.idLookup$
            .pipe(
                takeUntil(this.destroyed$),
                debounceTime(50),
                filter((searchText) => Boolean(searchText))
            )
            .subscribe((searchText) => {
                if (searchText?.length > 0) {
                    const options = this.finalLevelOptions.filter((item) => {
                        if (searchText.length === 1) {
                            return (
                                item.value
                                    .toLowerCase()
                                    .indexOf(searchText.toLowerCase()) > -1
                            );
                        }
                        return (
                            item.value
                                .toLowerCase()
                                .indexOf(searchText.toLowerCase()) > -1 ||
                            item.label
                                .toLowerCase()
                                .indexOf(searchText.toLowerCase()) > -1
                        );
                    });
                    this.options$.next(options);
                } else {
                    this.options$.next(null);
                }
            });
    }

    closeHieracySelect() {
        this.hierarchySelect.close();
    }

    reset() {
        this.options$.next([]);
        this.activeHierarchy = [];
        this.formControl.setValue(null);
    }

    onChangeHierarchy(item: ExtendedFieldDefinitionOption) {
        if (item) {
            this.hierarchySelect.searchTerm = '';

            if (item.level === this.finalLevel) {
                this.closeHieracySelect();
            } else {
                this.formControl.setValue(null);
                this.activeHierarchy.push(item);
                this.options$.next(
                    this.allOptions.filter((option) => {
                        return (
                            option.value.startsWith(item.value) &&
                            option.level === item.level + 1
                        );
                    })
                );
            }
        }
    }

    onActiveHierarchyLevelSelect(item: ExtendedFieldDefinitionOption) {
        this.activeHierarchy = this.activeHierarchy.filter(
            (selection) => selection.level <= item.level
        );
        this.options$.next(
            this.allOptions.filter((option) => {
                return (
                    option.value.startsWith(item.value) &&
                    option.level === item.level + 1
                );
            })
        );
    }

    onClearHierarchy() {
        this.reset();
        this.options$.next(this.firstLevelOptions);
        this.closeHieracySelect();
    }

    onSwitchToHierarchyLookup() {
        this.isHierarchyLookup = true;
        this.reset();
        this.options$.next(this.firstLevelOptions);
    }

    onSwitchToIdLookup() {
        this.isHierarchyLookup = false;
        this.reset();
        this.options$.next(null);
    }
}
