import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, UntypedFormControl } from '@angular/forms';
import { FilterOperator } from '@wdx/clmi/api-models';
import { DATE_TO_INT, INPUT_NUMBER } from './filter-date.static';

@Component({
    selector: 'clmi-filter-date',
    templateUrl: './filter-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDateComponent {
    conditionalType: string;

    @Input() type: string;
    @Input() set operator(value: FilterOperator) {
        if (DATE_TO_INT.includes(value)) {
            this.clearFormControlValue(INPUT_NUMBER);
            this.conditionalType = INPUT_NUMBER;
        }

        if (!DATE_TO_INT.includes(value)) {
            this.clearFormControlValue(this.type);
            this.conditionalType = this.type;
        }
    }
    @Input() formControlObj: UntypedFormControl;

    constructor(public controlContainer: ControlContainer) {}

    clearFormControlValue(type: string): void {
        if (this.conditionalType && this.conditionalType !== type) {
            this.controlContainer.control.setValue([null]);
            this.controlContainer.control.updateValueAndValidity();
        }
    }
}
