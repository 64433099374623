import { Action, createReducer, on } from '@ngrx/store';
import {
    DocumentRequirement,
    DocumentRequirementType,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as documentChecklistActions from './document-checklist.actions';

export interface State {
    documentRequirements?: CrudStateObject<DocumentRequirement>;
    documentRequirementTypes?: CrudState<DocumentRequirementType>;
}

export const initialState: State = {
    documentRequirements: {},
    documentRequirementTypes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentChecklistActions.getDocumentRequirements,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementsSuccess,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.documentRequirements,
                        filteredList: props.documentRequirements,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementsFailure,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.filterDocumentRequirementsForClient,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.clientId]: {
                    ...state.documentRequirements[props.clientId],
                    filteredList:
                        props.searchText.length < 2
                            ? state.documentRequirements[props.clientId].list
                            : state.documentRequirements[props.clientId].list
                                  .filter(
                                      (documentRequirement) =>
                                          documentRequirement.requirementType.name
                                              .toLowerCase()
                                              .indexOf(
                                                  props.searchText.toLowerCase()
                                              ) > -1
                                  )
                                  .slice(0, 10),
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypes,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypesSuccess,
        (state, props): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.documentRequirementTypes,
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypesFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: true,
                list: null,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
