<div class="input-wrapper__content">
    <molecule-input-tags
        [formInputData]="formInputData"
        [initialValue]="initialValue"
        [validationErrorMessage]="validationErrorMessage"
        [isMulti]="isMulti"
        [tags]="tags"
        (valueChanged)="onValueChanged($event)"
    >
    </molecule-input-tags>
</div>
