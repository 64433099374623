<div class="accordion-item">
    <div class="accordion-header">
        <button
            data-cy="accordion-body-button"
            class="accordion-button custom-header justify-content-between"
            [class.collapsed]="!open"
            (click)="toggleAccordion($event)"
        >
            <span class="flex-grow-1 accordion-title">
                <ng-content></ng-content>
            </span>
            <div wdxUtilsStopPropagation>
                <ng-content select="[wdxAccordionHeader]"></ng-content>
            </div>
        </button>
    </div>

    <div
        class="accordion-collapse collapse"
        [class.show]="open"
        data-cy="accordion-content"
    >
        <div
            data-cy="accordion-body"
            class="accordion-body"
            [class.p-0]="removePadding"
            [class.bg-muted]="bodyStyles === 'muted'"
        >
            <ng-content select="[wdxAccordionContent]"></ng-content>
        </div>
    </div>

    <div
        data-cy="accordion-body-footer"
        class="accordion-footer hide-when-empty"
        [class.show]="open"
    >
        <ng-content select="[wdxAccordionFooter]"></ng-content>
    </div>
</div>
