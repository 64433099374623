import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormDataHistory, Team, TeamMember, User } from '@wdx/clmi/api-models';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class TeamService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getForId(teamId: string): Observable<Team> {
        return this.http.get<Team>(
            `${this.config.getConfiguration().API_BASE}/team/${teamId}`
        );
    }

    getTeamMembersForId(teamId: string): Observable<TeamMember[]> {
        return this.http.get<User[]>(
            `${this.config.getConfiguration().API_BASE}/team/${teamId}/member`
        );
    }

    deleteTeamMember(teamId: string, memberId: string): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/team/${teamId}/member/${memberId}`
        );
    }

    getHistoryForId(teamId: string): Observable<FormDataHistory[]> {
        return this.http.get<FormDataHistory[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/teamform/${teamId}/history`
        );
    }
}
