import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { FileSizePipe } from '@wdx/shared/utils';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeDocumentInfoComponent } from './molecule-document-info.component';

@NgModule({
    imports: [
        AtomIconButtonModule,
        CommonModule,
        PipesModule,
        RouterModule,
        WdxInfoCardModule,
        WdxIconModule,
        WdxIconContainerModule,
        FileSizePipe,
    ],
    declarations: [MoleculeDocumentInfoComponent],
    exports: [MoleculeDocumentInfoComponent],
})
export class MoleculeDocumentInfoModule {}
