import { createSelector } from '@ngrx/store';
import * as notificationsReducer from './notifications.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    notifications: notificationsReducer.State;
}): notificationsReducer.State => state.notifications;

/**
 * Reducer property selectors
 */
export const getIsLoadingPage = createSelector(
    getState,
    (state: notificationsReducer.State, props: any) =>
        state.notifications[props.id]?.isLoadingPage
);
export const getHasLoadingPageError = createSelector(
    getState,
    (state: notificationsReducer.State, props: any) =>
        state.notifications[props.id]?.hasLoadingPageError
);
export const getInfinityPaging = createSelector(
    getState,
    (state: notificationsReducer.State, props: any) =>
        state.notifications[props.id]?.infinity?.paging
);
export const getInfinityCombinedList = createSelector(
    getState,
    (state: notificationsReducer.State, props: any) =>
        state.notifications[props.id]?.infinity?.combinedList
);
export const getUnreadNotifications = createSelector(
    getState,
    (state: notificationsReducer.State) => state.unreadNotifications
);
