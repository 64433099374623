import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AtomIconButtonModule } from '../../../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
    declarations: [FileUploadComponent],
    imports: [
        AtomIconButtonModule,
        CommonModule,
        NgbProgressbarModule,
        NgxDropzoneModule,
        PushModule,
    ],
    exports: [FileUploadComponent],
})
export class FileUploadModule {}
