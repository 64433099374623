import { FormDataHistory, Team, TeamMember } from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const getForId = createAction(
    '[Team] Get For ID',
    props<{
        teamId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Team] Get For ID Success',
    props<{
        team: Team;
    }>()
);

export const getForIdFailure = createAction(
    '[Team] Get For ID Failure',
    props<{
        teamId: string;
        error: Error;
    }>()
);

export const getTeamMembersForId = createAction(
    '[Team] Get Team Members For ID',
    props<{
        teamId: string;
    }>()
);

export const getTeamMembersForIdSuccess = createAction(
    '[Team] Get Team Members For ID Success',
    props<{
        teamId: string;
        teamMembers: TeamMember[];
    }>()
);

export const getTeamMembersForIdFailure = createAction(
    '[Team] Get Team Members For ID Failure',
    props<{
        teamId: string;
        error: Error;
    }>()
);

export const getHistoryForId = createAction(
    '[Team] Get History For ID',
    props<{
        teamId: string;
    }>()
);

export const getHistoryForIdSuccess = createAction(
    '[Team] Get History For ID Success',
    props<{
        teamId: string;
        history: FormDataHistory[];
    }>()
);

export const getHistoryForIdFailure = createAction(
    '[Team] Get History For ID Failure',
    props<{
        teamId: string;
        error: Error;
    }>()
);

export const deleteTeamMember = createAction(
    '[Team] Delete Team Member',
    props<{
        teamId: string;
        memberId: string;
    }>()
);

export const deleteTeamMemberSuccess = createAction(
    '[Team] Delete Team Member Success',
    props<{
        teamId: string;
        memberId: string;
    }>()
);

export const deleteTeamMemberFailure = createAction(
    '[Team] Delete Team Member Failure',
    props<{
        error: Error;
        teamId: string;
        memberId: string;
    }>()
);
