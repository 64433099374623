import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Case, CompletedStatusType, SystemEntity } from '@wdx/clmi/api-models';
import { WdxSize } from '@wdx/shared/utils';

import { Paging } from '@wdx/clmi/api-services/models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { Severity } from '@wdx/shared/utils';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { COMPLETED_STATUS_SEVERITY_MAP } from '../../../constants/completed-status.constants';
import { CASE_FILTER_FORM_ID } from '../../../constants/dynamic-form.constants';
import { SORT_MENU_CASES } from '../../../constants/sort-menu.constants';
import { ActionButton } from '../../../models/action-button.model';
import { CardType } from '../../../models/card-type.model';
import { CaseCardItem } from '../../../models/case-list-item.model';
import { ScrollMode } from '../../../models/scroll-mode.model';
import { EntityRoutePipe } from '../../../pipes/entity-route.pipe';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-cases-list',
    templateUrl: './organism-cases-list.component.html',
})
export class OrganismCasesListComponent implements OnInit {
    @Input() cases: Case[];
    @Input() casesPaging: Paging;
    @Input() caseTypesActionButton: ActionButton;
    @Input() caseCardActionButton: ActionButton;
    @Input() casesPerRow: number;
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() hasFilterSearch = false;
    @Input() filterHandler: FilterHandler;
    @Input() showFilters: boolean;
    @Input() showFilterBar = true;
    @Input() showSearchBar = true;
    @Input() showFavourite = false;
    @Input() showLegend = true;
    @Input() showNoDataSVG = true;
    @Input() noDataSVGSize: WdxSize;
    @Input() noDataMessage: string;
    @Input() scrollMode: ScrollMode;
    @Input() limit: number;
    @Input() totalRecordCount: number;
    @Input() isSuggestedCardList = false;
    @Input() linkToEntityType: SystemEntity;
    @Input() linkToEntityId: string;

    @Output() infinityScrollFired = new EventEmitter();
    @Output() toggleFavourite = new EventEmitter<{
        id: string;
        isFavourite: boolean;
    }>();

    readonly CASE_FILTER_FORM = CASE_FILTER_FORM_ID;

    readonly CONTEXT_MENU_OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB';
    readonly CONTEXT_MENU_ASSIGN_TO = 'ASSIGN_TO';
    readonly CONTEXT_MENU_DELEGATE_ACCESS = 'DELEGATE_ACCESS';
    readonly CONTEXT_MENU_CANCEL = 'CANCEL';

    readonly SORT_MENU_CASES = SORT_MENU_CASES;

    instanceId: string;

    cardType: CardType = CardType.Case;
    filtersModalId = 'cases-filter';
    searchType = 'Case';
    searchTypePlural = 'Cases';
    searchPlaceholder = 'Search Cases';

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
        this.filtersModalId = new RandomStringPipe().transform();
    }

    casesAsCaseCardItems(
        cases: Case[],
        isSuggestedCardList: boolean,
        linkToEntityType: SystemEntity,
        linkToEntityId: string,
        limit: number
    ): CaseCardItem[] {
        const caseCards =
            cases?.length && limit > 0 ? cases.slice(0, limit) : cases;
        return caseCards?.map((targetCase) => ({
            id: targetCase.id,
            totalActivities: targetCase.totalActivities,
            subject: targetCase.subject,
            routerLink: new EntityRoutePipe().transform(
                SystemEntity.Case,
                targetCase.id
            ),
            ...(targetCase.regarding
                ? {
                      regarding: targetCase.regarding.name,
                      regardingRouterLink: new EntityRoutePipe().transform(
                          targetCase.regarding.type,
                          targetCase.regarding.partyId
                              ? targetCase.regarding.partyId
                              : targetCase.regarding.id
                      ),
                  }
                : {}),
            type: targetCase.caseTypeName,
            dateStarted: targetCase.dateStarted,
            completion: {
                completed: targetCase.completedActivities,
                total: targetCase.totalActivities,
                isComplete:
                    targetCase.completedActivities ===
                    targetCase.totalActivities,
            },
            completedDate: targetCase.dateCompleted,
            dueDate: targetCase.nextDueDate,
            avatars: [new PartyToAvatarPipe().transform(targetCase.owner)],
            isFavourite: targetCase.isFavourite,
            completedStatus: targetCase.completedStatus,
            severity: targetCase.nextAction
                ? targetCase.nextAction.issueCount ||
                  targetCase.completedStatus === CompletedStatusType.Overdue ||
                  new Date(targetCase.nextAction.due) < new Date()
                    ? Severity.Danger
                    : // calc 'soon' stutus - if next action due date is within 2 days
                    Math.floor(
                          (new Date(targetCase.nextAction.due) as any) -
                              (new Date() as any) / 1000 / 60 / 60 / 24
                      ) <= 2
                    ? Severity.Warning
                    : COMPLETED_STATUS_SEVERITY_MAP[targetCase.completedStatus]
                : COMPLETED_STATUS_SEVERITY_MAP[targetCase.completedStatus],
            status: targetCase.status,
            slaStatus: targetCase.slaStatus,
            nextActionSeverity: targetCase.nextAction
                ? targetCase.nextAction.issueCount ||
                  new Date(targetCase.nextAction.due) < new Date()
                    ? Severity.Danger
                    : // calc 'soon' stutus - if next action due date is within 2 days
                    Math.floor(
                          (new Date(targetCase.nextAction.due) as any) -
                              (new Date() as any) / 1000 / 60 / 60 / 24
                      ) <= 2
                    ? Severity.Warning
                    : Severity.Success
                : Severity.Success,
            nextAction: targetCase.nextAction,
            internalIdentifier: targetCase.internalIdentifier,
            isSuggestedCardList: isSuggestedCardList,
            linkToEntityType: linkToEntityType,
            linkToEntityId: linkToEntityId,
        }));
    }

    onSearch(value: string): void {
        this.filterHandler.setSearchText(value);
    }

    onToggleFavourite(event: any): void {
        this.toggleFavourite.emit(event);
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }
}
