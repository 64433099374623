import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MenuItem } from '../../../../../models/menu-item.model';

@Component({
    selector: 'clmi-filter-table-responsive-card-template',
    templateUrl: './responsive-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableResponsiveCardTemplateComponent {
    @Input() data: any;

    @Output() toggleChange = new EventEmitter<any>();
    @Output() menuItemSelected = new EventEmitter<any>();

    onToggleChange(event: MouseEvent): void {
        this.toggleChange.emit(event);
    }

    onMenuItemSelected(menuItem: MenuItem): void {
        this.menuItemSelected.emit(menuItem);
    }
}
