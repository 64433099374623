import { Directive, HostBinding, Input, OnInit } from '@angular/core';

import { WdxButtonSize, WdxButtonStyle } from './wdx-button.interface';

@Directive({
    selector: 'button[wdxButton]',
})
export class WdxButtonDirective implements OnInit {
    /**
     * sets the button type
     */
    @Input() wdxButton: WdxButtonStyle | '' = '';

    /**
     * Specify the size of the button
     */
    @Input() wdxButtonSize: WdxButtonSize = WdxButtonSize.Normal;

    // a whole lot of HostBindings ... this way we don't have to touch the elementRef directly
    @HostBinding('class.btn') get baseClass() {
        return true;
    }

    @HostBinding('class.btn-primary') get primaryButton() {
        return this.wdxButton === WdxButtonStyle.Primary;
    }

    @HostBinding('class.btn-danger') get dangerButton() {
        return this.wdxButton === WdxButtonStyle.Danger;
    }

    @HostBinding('class.btn-outline-primary') get outlinePrimaryButton() {
        return this.wdxButton === WdxButtonStyle.OutlinePrimary;
    }

    @HostBinding('class.btn-link') get link() {
        return this.wdxButton === WdxButtonStyle.Link;
    }

    @HostBinding('class.btn-sm') get smallSize() {
        return this.wdxButtonSize === WdxButtonSize.Sm;
    }

    @HostBinding('class.btn-lg') get largeSize() {
        return this.wdxButtonSize === WdxButtonSize.Lg;
    }

    ngOnInit(): void {
        if (!this.wdxButton) {
            this.wdxButton = WdxButtonStyle.OutlinePrimary;
        }
    }
}
