<ng-container *ngIf="activity | activityAsToDoItem; let toDoItem">
    <div class="d-flex align-items-center" data-cy="activity-header">
        <div *ngIf="showResponsiveBackButton" class="d-block d-lg-none">
            <atom-icon-button
                class="me-2"
                btnClass="btn-ghost"
                icon="chevron-left"
                size="sm"
                (click)="onBackClicked($event)"
            ></atom-icon-button>
        </div>
        <div>
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 d-flex align-items-center">
                    <wdx-icon
                        class="me-2 icon-shift-up"
                        [icon]="
                            ACTIVITY_ICON_MAP[
                                toDoItem?.data.activity.activityType
                            ]
                        "
                    ></wdx-icon>
                    <div class="d-flex align-items-baseline">
                        <h5 class="mb-0" data-cy="lbl-activity-subject">
                            {{ toDoItem?.data.activity.subject }}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-1">
                <div class="divider-end divider--short">
                    <molecule-avatar-group
                        size="sm"
                        [avatars]="toDoItem?.avatars"
                        [onMutedBg]="true"
                    ></molecule-avatar-group>
                </div>
                <div class="divider-end divider--short">
                    <div
                        *ngIf="
                            toDoItem.filedUnder?.length &&
                            toDoItem.filedUnder?.length >= 1
                        "
                        class="to-do-card__filed-under d-flex align-items-center mt-1 text-truncate"
                        data-cy="card-to-do"
                    >
                        <wdx-icon
                            class="me-1 d-flex justify-content-center align-items-center"
                            icon="folder-open"
                        ></wdx-icon>

                        <ng-container
                            *ngIf="
                                toDoItem.filedUnder[0].data?.id;
                                let fileUnderId
                            "
                        >
                            <span
                                *ngIf="fileUnderId === entityId"
                                class="small"
                            >
                                {{ toDoItem.filedUnder[0].label }}
                            </span>
                            <a
                                *ngIf="fileUnderId !== entityId"
                                class="fw-normal small"
                                [routerLink]="
                                    toDoItem.filedUnder[0].data?.type
                                        | entityRoute
                                            : toDoItem.filedUnder[0].data?.id
                                "
                            >
                                {{ toDoItem.filedUnder[0].label }}
                            </a>
                        </ng-container>

                        <div
                            *ngIf="toDoItem.filedUnder.length > 1"
                            class="ms-1 small"
                        >
                            <span
                                >+{{
                                    toDoItem.filedUnder.length - 1
                                }}&nbsp;more</span
                            >
                        </div>
                    </div>

                    <div
                        *ngIf="!toDoItem.filedUnder?.length"
                        class="d-flex align-items-center small my-2 align-self-end"
                    >
                        <wdx-icon
                            class="me-1 justify-content-center align-items-center"
                            icon="triangle-exclamation"
                        ></wdx-icon>
                        <div class="text-nowrap">Not Filed</div>
                    </div>
                </div>
                <div class="divider-end divider--short">
                    <div
                        class="to-do-card__tertiary-info__attachments d-flex align-items-center"
                    >
                        <wdx-icon
                            class="me-1 justify-content-center align-items-center"
                            icon="paperclip"
                        ></wdx-icon>
                        <span class="small">{{ toDoItem.attachments }}</span>
                    </div>
                </div>
                <div
                    class="d-flex align-items-center divider-end divider--short"
                >
                    <clmi-comment-button
                        data-cy="btn-comment"
                        [entityId]="toDoItem?.data.activity.id"
                        [activityType]="toDoItem?.data?.activity?.activityType"
                        [count]="toDoItem?.commentCount"
                        [hasIssue]="toDoItem?.hasIssue"
                        [modalTitle]="toDoItem?.data.activity.subject"
                    ></clmi-comment-button>
                </div>
                <div
                    class="divider--short"
                    data-cy="a-status"
                    [class.divider-end]="toDoItem?.dueDate"
                >
                    <clmi-activity-status-tag
                        class="mt-1 align-self-end"
                        [activity]="activity"
                    ></clmi-activity-status-tag>
                </div>

                <ng-container *ngIf="canEdit$ | ngrxPush; else nonEditable">
                    <input
                        ngbDatepicker
                        #dueDatePicker="ngbDatepicker"
                        type="text"
                        datepickerClass="datepicker"
                        class="visually-hidden"
                        [minDate]="minDueDate"
                        [maxDate]="maxDueDate"
                        [startDate]="startDueDate"
                        (dateSelect)="onDueDatePickerSelected($event)"
                    />

                    <button
                        data-cy="btn-activity-due-date"
                        class="btn btn-link text-nowrap me-2 d-flex align-items-center"
                        wdxUtilsStopPropagation
                        click-prevent-default
                        (click)="onDueDateClicked(dueDatePicker)"
                    >
                        <wdx-icon
                            class="me-1 text-muted"
                            [icon]="activity | activityDateIcon"
                            size="fit"
                        ></wdx-icon>
                        {{ activity | activityDateValue }}
                    </button>
                </ng-container>

                <ng-template #nonEditable>
                    <div
                        data-cy="btn-activity-due-date"
                        class="d-flex align-items-center text-nowrap fw-bold"
                    >
                        <wdx-icon
                            class="me-1 text-muted"
                            [icon]="activity | activityDateIcon"
                            size="fit"
                        ></wdx-icon>
                        <span>{{ activity | activityDateValue }}</span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-container>
