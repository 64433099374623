import { Action, createReducer, on } from '@ngrx/store';
import { PartyAvatarInfo } from '@wdx/clmi/api-models';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as avatarInfoActions from './avatar-info.actions';

export interface State {
    avatarInfo?: CrudState<PartyAvatarInfo>;
}

export const initialState: State = {
    avatarInfo: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        avatarInfoActions.getForId,
        (state): State => ({
            ...state,
            avatarInfo: {
                ...state.avatarInfo,
                isLoadingSingle: true,
                hasLoadingSingleError: false,
            },
        })
    ),

    on(
        avatarInfoActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            avatarInfo: {
                ...state.avatarInfo,
                isLoadingSingle: false,
                hasLoadingSingleError: false,
                single: props.avatarInfo,
            },
        })
    ),

    on(
        avatarInfoActions.getForIdFailure,
        (state): State => ({
            ...state,
            avatarInfo: {
                ...state.avatarInfo,
                isLoadingSingle: false,
                hasLoadingSingleError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
