import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormGroup,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    DateRestrictionType,
    FieldDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
} from '@wdx/clmi/api-models';
import { ValidationSummary } from '../../../../models/form-validation.model';
import {
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../../../models/reactive-dynamic.model';
import {
    ARRAY_FIELD_TYPES_FOR_VALIDATORS,
    CURRENCY_FIELD_TYPES,
    NUMERIC_FIELD_TYPES,
    STRING_FIELD_TYPES,
} from '../../constants/field-types';
import { MANUAL_FORM_GROUP } from '../form-builder/form-builder.static';
import { ARRAY_VALIDATOR } from './../../constants/field-types';
import { BespokeValidators } from '@wdx/shared/infrastructure/form-framework';

@Injectable()
export class FormValidationService {
    getValidators(
        formFieldDefinition: FieldDefinition,
        layout?: FormElementLayoutDefinition
    ) {
        const requiredValidator = formFieldDefinition.isRequired
            ? [Validators.required]
            : [];

        const minValidator = this.getMinValidator(
            formFieldDefinition.fieldType,
            formFieldDefinition?.min
        );
        const maxValidator = this.getMaxValidator(
            formFieldDefinition.fieldType,
            formFieldDefinition?.max
        );

        const futureDateValidator =
            formFieldDefinition.dateRestrictionType ===
            DateRestrictionType.FutureOnly
                ? [BespokeValidators.dateMustBeAfter(new Date())]
                : [];

        const historicDateValidator =
            formFieldDefinition.dateRestrictionType ===
            DateRestrictionType.HistoricOnly
                ? [BespokeValidators.dateMustBeBefore(new Date())]
                : [];

        const dateRangeValidator =
            [FormFieldType.DateRange, FormFieldType.DateTimeRange].includes(
                formFieldDefinition.fieldType
            ) && !layout?.elementStyle?.includes('StartOnly')
                ? [BespokeValidators.datesMustBeValidRange()]
                : [];

        const regexValidator = formFieldDefinition.inputMaskRegEx
            ? [BespokeValidators.regex(formFieldDefinition.inputMaskRegEx)]
            : [];

        return [
            ...requiredValidator,
            ...minValidator,
            ...maxValidator,
            ...futureDateValidator,
            ...historicDateValidator,
            ...dateRangeValidator,
            ...regexValidator,
        ];
    }

    getMaxValidator(fieldType, amount: number) {
        return typeof amount === 'number' && amount > 0
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType)
                      ? [Validators.maxLength(amount)]
                      : []),
                  ...(ARRAY_VALIDATOR.includes(fieldType)
                      ? [Validators.maxLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType)
                      ? [Validators.max(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(fieldType)
                      ? [BespokeValidators.maxArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType)
                      ? [BespokeValidators.maxCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getMinValidator(fieldType, amount) {
        return typeof amount === 'number' && amount > 0
            ? [
                  ...(STRING_FIELD_TYPES.includes(fieldType)
                      ? [Validators.minLength(amount)]
                      : []),
                  ...(ARRAY_VALIDATOR.includes(fieldType)
                      ? [Validators.minLength(amount)]
                      : []),
                  ...(NUMERIC_FIELD_TYPES.includes(fieldType)
                      ? [Validators.min(amount)]
                      : []),
                  ...(ARRAY_FIELD_TYPES_FOR_VALIDATORS.includes(fieldType)
                      ? [BespokeValidators.minArrayLength(amount)]
                      : []),
                  ...(CURRENCY_FIELD_TYPES.includes(fieldType)
                      ? [BespokeValidators.minCurrencyAmount(amount)]
                      : []),
              ]
            : [];
    }

    getArrayErrors(
        sectionLayoutDefinitions: ReactiveFormElement[],
        formGroups: UntypedFormGroup[]
    ) {
        return formGroups.reduce((previous, current) => {
            const sectionErrors = this.getValidationSummary(
                sectionLayoutDefinitions,
                current.controls
            );
            return [
                ...previous,
                ...(sectionErrors.sections?.length > 0
                    ? this.getValidationSummary(
                          sectionLayoutDefinitions,
                          current.controls
                      ).sections
                    : [null]),
            ];
        }, []);
    }

    getValidationSummary(
        sectionLayoutDefinitions: ReactiveFormElement[],
        controls: Record<string, AbstractControl>
    ): ValidationSummary {
        return sectionLayoutDefinitions.reduce(
            (previous, current, currentIndex) => {
                const elementLayoutDefinitions = current.layoutAndDefinition;
                const definitionErrors = this.getDefinitionErrors(
                    elementLayoutDefinitions,
                    controls
                );

                const subsections = elementLayoutDefinitions.reduce(
                    (previousChild, currentChild) => {
                        if (currentChild.fieldType === FormFieldType.Array) {
                            return [
                                ...previousChild,
                                ...(controls[currentChild.name]?.invalid
                                    ? this.getSubSectionErrors(
                                          currentChild,
                                          controls[currentChild.name]
                                      )
                                    : []),
                            ];
                        }
                        return [];
                    },
                    []
                );

                const completionSummary = this.getCompletionSummary(
                    elementLayoutDefinitions,
                    controls,
                    current?.section?.isHidden
                );

                return {
                    completionSummary: {
                        expected:
                            previous.completionSummary.expected +
                            completionSummary.expected,
                        completed:
                            previous.completionSummary.completed +
                            completionSummary.completed,
                    },
                    sections: [
                        ...previous.sections,
                        ...[
                            {
                                name: current.section?.name,
                                label: current.section.label,
                                errors: definitionErrors?.map((error) => ({
                                    ...error,
                                    sectionIndex: currentIndex,
                                })),
                                subsections: subsections,
                            },
                        ],
                    ],
                };
            },
            {
                completionSummary: {
                    expected: 0,
                    completed: 0,
                },
                sections: [],
            }
        );
    }

    getCompletionSummary(
        elementLayoutDefinitions: ReactiveFormLayoutAndDefinition[],
        controls: Record<string, AbstractControl>,
        isSectionHidden?: boolean
    ) {
        if (!isSectionHidden) {
            return elementLayoutDefinitions.reduce(
                (previous, current) => {
                    const control = controls[current.name];
                    if (MANUAL_FORM_GROUP.includes(current.fieldType)) {
                        const enabledControls = Object.keys(
                            (control as FormGroup).controls
                        )
                            .map((key) => (control as FormGroup).controls[key])
                            .filter((control) => control.enabled);

                        const controlsWithValues = enabledControls.filter(
                            (control) => !this.isNullOrEmpty(control?.value)
                        );
                        return {
                            expected:
                                previous.expected + enabledControls?.length,
                            completed:
                                previous.completed + controlsWithValues?.length,
                        };
                    }

                    if (current.fieldType === FormFieldType.Array) {
                        const subFormSummaryCompletion = (
                            control as FormArray
                        ).controls.reduce(
                            (subPrevious, subCurrent) => {
                                const subFormSummary =
                                    this.getCompletionSummary(
                                        current.children[0].layoutAndDefinition,
                                        (subCurrent as FormGroup).controls
                                    );
                                return {
                                    expected:
                                        subPrevious.expected +
                                        subFormSummary.expected,
                                    completed:
                                        subPrevious.completed +
                                        subFormSummary.completed,
                                };
                            },
                            { expected: 0, completed: 0 }
                        );
                        return {
                            expected:
                                previous.expected +
                                subFormSummaryCompletion.expected,
                            completed:
                                previous.completed +
                                subFormSummaryCompletion.completed,
                        };
                    } else {
                        const expected =
                            !current.isHidden && !current.isDisabled
                                ? previous.expected + 1
                                : previous.expected;

                        const completed =
                            !current.isHidden &&
                            !current.isDisabled &&
                            !this.isNullOrEmpty(control?.value)
                                ? previous.completed + 1
                                : previous.completed;

                        return {
                            expected,
                            completed,
                        };
                    }
                },
                { expected: 0, completed: 0 }
            );
        }

        return { expected: 0, completed: 0 };
    }

    getDefinitionErrors(
        elementLayoutDefinitions: ReactiveFormLayoutAndDefinition[],
        controls
    ) {
        let eleLayoutDefinitions = elementLayoutDefinitions;
        if (Array.isArray(eleLayoutDefinitions[0])) {
            eleLayoutDefinitions = eleLayoutDefinitions[0];
        }

        return eleLayoutDefinitions.reduce((previous, current) => {
            return [
                ...previous,
                ...(controls[current.name]?.invalid
                    ? [
                          {
                              fieldLabel: current.label,
                              name: current.name,
                          },
                      ]
                    : []),
            ];
        }, []);
    }

    getSubSectionErrors(SubSection: any, formGroup: any) {
        if (formGroup.controls.length) {
            return this.getArrayErrors(
                SubSection?.children,
                formGroup.controls
            );
        }

        return [];
    }

    scrollToError(fieldDefinitionElement: any): void {
        fieldDefinitionElement?.scrollIntoView({
            behavior: 'smooth',
        });
        const errorElement = fieldDefinitionElement?.querySelector(
            'input,select,textarea'
        );
        if (errorElement) {
            setTimeout(() => errorElement.focus(), 1);
        }
    }

    isNullOrEmpty(value: any) {
        return value === null || value?.length === 0;
    }
}
