import { Component, Input } from '@angular/core';
import { FileIndex } from '@wdx/clmi/api-models';
import { ICON_DOWNLOAD } from '../../../constants/icons.constants';
import { FilesFacadeService } from '../../../shared/features/files/services/files-facade/files-facade.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-document-info',
    templateUrl: './molecule-document-info.component.html',
})
export class MoleculeDocumentInfoComponent {
    // the fileIndex of the document
    @Input() fileIndex: FileIndex;

    // label override (defaults to fileIndex.name)
    @Input() label: string;

    // subtitle override(defaults to fileIndex.sizeInBytes)
    @Input() subtitle: string;

    // remove container
    @Input() noContainer = false;

    readonly ICON_DOWNLOAD = ICON_DOWNLOAD.icon;

    constructor(private filesFacadeService: FilesFacadeService) {}

    downloadFile(): void {
        this.filesFacadeService.downloadFile(this.fileIndex);
    }
}
