<div class="px-4" data-cy="dlg-confirm">
    <h5 class="text-center mt-5 mb-4" data-cy="lbl-confirm-title">
        {{ dialogMode | fn : getTitle }}
    </h5>
    <div
        class="d-flex align-items-center justify-content-center pb-3 mb-3 border-bottom"
    >
        <div class="dialog-body m-3">
            <ng-container [ngSwitch]="dialogMode">
                <ng-container *ngSwitchCase="DIALOG_MODE.CancelCase">
                    Note that cancelling will close all the open work items and
                    close the case as Cancelled.
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.CancelActivity">
                    Are you sure you want to cancel this activity?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.RemoveTeamMember">
                    Are you sure you want to remove
                    <strong>{{ data?.name }}</strong> from this team?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteOpportunity">
                    Are you sure you want to delete opportunity
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteFiledDocument">
                    <p>
                        Note that this document is linked to
                        <strong *ngFor="let item of data; let last = last">
                            {{ item?.name
                            }}<span *ngIf="!last">, </span></strong
                        >. Deleting it here will also remove it from the linked
                        records.
                    </p>

                    <p>Are you sure you want to delete this document?</p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteBenchmark">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteMandate">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteMandateGroup">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteObjective">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteRiskLevel">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteFeeStructure">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteSalutation">
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteDocumentTemplate"
                >
                    Are you sure you want to delete
                    <strong>{{ data }}</strong
                    >?
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteDocumentRequirementType"
                >
                    Are you sure you want to delete
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteBatchList">
                    Are you sure you want to delete this batch
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteBatchMessageRow">
                    Are you sure you want to delete this message row
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteView">
                    Are you sure you want to delete this view:
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ReplayMessageBatch">
                    Are you sure you want to mark this message for replay
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteMultipleMessage">
                    Are you sure you want to delete these message rows
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ReplayMultipleMessage">
                    Are you sure you want to mark these messages for replay
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteDocument">
                    Are you sure you want to delete document
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container
                    *ngSwitchCase="
                        DIALOG_MODE.HasAtLeastOneMessageCannotBeReplayed
                    "
                >
                    There are some message which cannot be replayed:
                    <strong>{{ data?.name }}</strong
                    >?
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteDashboard">
                    Are you sure you want to delete this dashboard?
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.RunPepAndSanctionsCheck"
                >
                    Are you sure that you want to run a PEP and Sanctions check
                    for this record?
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <div class="dialog-footer mt-4 mb-5">
            <button class="btn" data-cy="btn-no" (click)="dismiss()">No</button>
            <button
                class="btn btn-danger ms-4"
                data-cy="btn-yes"
                (click)="confirm()"
            >
                Yes
            </button>
        </div>
    </div>
</div>
