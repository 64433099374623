import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services/dynamic-data';
import { TelephoneService } from '../../../../../shared/services/telephone/telephone.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Country, Locale } from '@wdx/clmi/api-models';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseReactiveFormControlClass } from '../../../abstract-classes/base-reactive-form-control.class';

@Component({
    selector: 'clmi-reactive-telephone-control',
    templateUrl: './reactive-telephone-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveTelephoneControlComponent
    extends BaseReactiveFormControlClass
    implements OnInit, AfterViewInit
{
    @ViewChild('countrySelect') countrySelect: NgSelectComponent;

    countries$: Observable<Country[]>;
    mask: string;
    telephoneFormGroup = new UntypedFormGroup({
        country: new UntypedFormControl(null),
        number: new UntypedFormControl(null),
        type: new UntypedFormControl(null),
    });

    locale$: Observable<Locale>;

    numberControl: AbstractControl;
    countryControl: AbstractControl;
    typeControl: AbstractControl;

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: ReactiveFormDynamicDataService,
        private telephoneService: TelephoneService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.locale$ = this.dynamicDataService.getMeLocale();
        this.countries$ = this.dynamicDataService.getCountries();

        this.numberControl = this.telephoneFormGroup.get('number');
        this.countryControl = this.telephoneFormGroup.get('country');
        this.typeControl = this.telephoneFormGroup.get('type');
    }

    ngAfterViewInit(): void {
        const defaultNumber = this.formControl?.value?.number;

        if (defaultNumber) {
            this.numberControl.setValue(defaultNumber);
        }

        combineLatest([this.countries$, this.options$])
            .pipe(take(1))
            .subscribe(([countries, options]) => {
                const defaultType =
                    this.formControl?.value?.type || options[0].value;
                this.typeControl.setValue(defaultType);

                const defaultCountry = this.formControl?.value
                    ? countries.find(
                          (country) =>
                              country.isoCode ===
                              this.formControl?.value?.country
                      )
                    : countries[0];
                this.countryControl.setValue(defaultCountry.isoCode);
                this.setMask(
                    this.telephoneService.getCountryPattern(
                        this.typeControl.value,
                        defaultCountry
                    )
                );
            });

        this.countryControl.valueChanges
            .pipe(
                switchMap((isoCode) =>
                    this.countries$.pipe(
                        map((countries) =>
                            countries.find(
                                (country) => country.isoCode === isoCode
                            )
                        )
                    )
                )
            )
            .pipe(
                takeUntil(this.destroyed$),
                tap((country) => {
                    this.setMask(
                        this.telephoneService.getCountryPattern(
                            this.typeControl.value,
                            country
                        )
                    );
                }),
                delay(0)
            )
            .subscribe(() => {
                if (this.numberControl.value) {
                    this.numberControl.setValue(this.numberControl.value);
                    this.numberControl.markAsTouched();
                    this.numberControl.updateValueAndValidity();
                }
            });

        this.telephoneFormGroup.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                if (value.number) {
                    this.formControl?.setValue({
                        country: value.country,
                        number: value.number,
                        type: value.type,
                    });
                } else {
                    this.formControl?.setValue(null);
                }
                this.formControl.markAsDirty();
            });
    }

    setMask(phonePattern: string) {
        this.mask = phonePattern || '0*';
    }
}
