import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line max-len
import {
    ICON_DOCUMENT,
    ICON_FILE_CODE,
    ICON_FILE_CSV,
    ICON_FILE_EXCEL,
    ICON_FILE_IMAGE,
    ICON_FILE_PDF,
    ICON_FILE_POWERPOINT,
    ICON_FILE_WORD,
} from '../constants/icons.constants';
import { FileType } from '../models/file-type.model';
import { MIMETypeToFileFormat } from '../models/mime-type-to-file-format.model';
import { Icon } from '@wdx/shared/utils';

@Pipe({ name: 'fileTypeAsIcon' })
export class FileTypeAsIconPipe implements PipeTransform {
    transform(contentOrFileType: string, isFileType = false): Icon {
        if (!contentOrFileType) {
            return ICON_DOCUMENT.icon;
        }

        let fileType: string;
        if (isFileType) {
            fileType = contentOrFileType;
        } else {
            Object.keys(MIMETypeToFileFormat).forEach((key) => {
                if (key === contentOrFileType?.toLowerCase()) {
                    fileType = MIMETypeToFileFormat[key];
                }
            });
        }

        switch (fileType?.toLowerCase()) {
            case FileType.csv:
                return { fileType: 'csv', icon: ICON_FILE_CSV.icon };
            case FileType.excel:
                return { fileType: 'excel', icon: ICON_FILE_EXCEL.icon };
            case FileType.html:
                return { fileType: 'html', icon: ICON_FILE_CODE.icon };
            case FileType.xml:
                return { fileType: 'xml', icon: ICON_FILE_CODE.icon };
            case FileType.image:
                return { fileType: 'image', icon: ICON_FILE_IMAGE.icon };
            case FileType.json:
                return { fileType: 'json', icon: ICON_FILE_CODE.icon };
            case FileType.pdf:
                return { fileType: 'pdf', icon: ICON_FILE_PDF.icon };
            case FileType.powerpoint:
                return {
                    fileType: 'powerpoint',
                    icon: ICON_FILE_POWERPOINT.icon,
                };
            case FileType.word:
                return { fileType: 'word', icon: ICON_FILE_WORD.icon };
            default:
                return { fileType: '', icon: ICON_DOCUMENT.icon };
        }
    }
}
