import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import {
    WdxButtonModule,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxDialogService } from './wdx-dialog.service';

@Component({
    selector: 'wdx-dialog',
    templateUrl: './wdx-dialog.component.html',
    styleUrls: ['./wdx-dialog.component.scss'],
    imports: [
        CommonModule,
        WdxButtonModule,
        NgbModalModule,
        WdxIconModule,
        WdxIconButtonModule,
    ],
    providers: [NgbActiveModal],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxDialogComponent {
    @Input() title!: string;
    @Input() defaultButtons = true;
    @Input() actionButtonStyle: WdxButtonStyle = WdxButtonStyle.OutlinePrimary;

    @Output() wdxOnClick = new EventEmitter();

    public wdxDialogService = inject(WdxDialogService);

    actionClick(): void {
        this.wdxOnClick.emit();
        this.wdxDialogService.close();
    }
}
