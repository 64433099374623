<div
    class="form-action-buttons px-4 py-2 active-component d-flex align-items-center"
>
    <div class="ms-auto d-flex align-items-center">
        <molecule-action-button
            *ngIf="showQuickCreateButton && createActionButton"
            class="me-2"
            data-cy="btn-create-new"
            btnClass="btn-outline-primary"
            [actionButton]="createActionButton"
        >
            <span class="action-button-standard">
                {{ CREATE_NEW }}
                {{ activeLookupSourceDefinition?.type | translateEntity }}
            </span>
        </molecule-action-button>
        <button
            *ngIf="lookupMode === LOOKUP_MODE.Standard"
            class="btn btn-primary flex-shrink-0"
            data-cy="btn-confirm"
            (click)="onConfirmClicked()"
        >
            {{ CONFIRM }}
        </button>
    </div>
</div>

<div *ngIf="multipleLookupEnabled" class="px-2 pt-3 pb-1">
    <ng-select
        bindLabel="type"
        [searchable]="false"
        [clearable]="false"
        [items]="formInputData.lookupSources"
        data-cy="dropdown-SourceType"
        [(ngModel)]="activeLookupSourceDefinition"
        (change)="onLookupSourceTypeChange($event)"
    >
        <ng-template ng-label-tmp let-item="item">
            <span class="fw-bold"
                >Search {{ item.type | translateEntity : true }}</span
            >
            (or select dropdown to change)
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.type | translateEntity : true }}
        </ng-template>
    </ng-select>
</div>

<div
    id="info-card-search"
    class="bg-muted offcanvas-body flex-shrink-0 d-flex flex-grow-0"
>
    <molecule-input-search
        class="flex-grow-1"
        cySelector="info-card-lookupSearch"
        [formInputData]="{ id: 'lookupSearch' }"
        [placeholder]="placeholder"
        [autoFocus]="true"
        (valueChanged)="
            onSearch($event, formInputData?.minimumSearchCharacters)
        "
    ></molecule-input-search>

    <atom-icon-button
        *ngIf="lookupMode === LOOKUP_MODE.DocumentTemplate"
        class="ms-2"
        size="sm"
        icon="download"
        [disabled]="!value"
        (click)="onGenerateAndDownloadDocument()"
    ></atom-icon-button>
    <atom-icon-button
        *ngIf="lookupMode === LOOKUP_MODE.DocumentTemplate"
        class="ms-2"
        size="sm"
        icon="code"
        [disabled]="!value"
        (click)="onPreviewAvailableDocumentData()"
    ></atom-icon-button>
</div>

<div
    class="flex-shrink-0 flex-grow-0 bg-muted offcanvas-body d-flex align-items-center pt-0"
    *ngIf="bannerInfo"
>
    <wdx-icon class="me-1" size="md" [icon]="ICON_INFO"></wdx-icon>
    <span>
        {{ bannerInfo }}
    </span>
</div>
<ng-content></ng-content>

<div class="flex-grow-1 overflow-hidden" data-cy="search-result">
    <atom-async-data [isLoading]="isLoading" [hasError]="hasError">
        <div class="list-group list-group-flush h-100 overflow-auto">
            <ng-container
                *ngFor="
                    let infoCardData of clientSideSearch
                        | fn : parsedInfoCards : infoCards : searchText
                "
            >
                <label
                    role="button"
                    class="list-group-item list-group-item-action d-flex align-items-center"
                    data-cy="lbl-info-card-search"
                    [class.active]="
                        mode === MODE.Select
                            ? value === infoCardData.data
                            : value?.includes(infoCardData.data)
                    "
                    [for]="infoCardData.id"
                >
                    <ng-container [ngSwitch]="mode">
                        <input
                            *ngSwitchCase="MODE.Select"
                            class="form-check-input flex-shrink-0"
                            type="radio"
                            [id]="infoCardData.id"
                            [name]="inputName"
                            [value]="infoCardData.data"
                            [ngModel]="value"
                            (ngModelChange)="onChange($event)"
                        />
                        <input
                            *ngSwitchCase="MODE.MultiSelect"
                            class="form-check-input flex-shrink-0"
                            type="checkbox"
                            [id]="infoCardData.id"
                            [name]="inputName"
                            [ngModel]="
                                value?.length &&
                                value?.includes(infoCardData.data)
                            "
                            (ngModelChange)="onToggle(infoCardData.data)"
                        />
                    </ng-container>
                    <molecule-info-card
                        class="text-truncate ms-2"
                        [primaryInfo]="infoCardData.primaryInfo"
                        [routerLinkData]="infoCardData.routerLinkData"
                        [secondaryInfo]="infoCardData.secondaryInfo"
                        [tertiaryInfo]="infoCardData.tertiaryInfo"
                        [avatars]="infoCardData.avatars"
                        [icon]="infoCardData.icon"
                        [dynamicFormSetup]="infoCardData.formSetup"
                    ></molecule-info-card>
                </label>
            </ng-container>
        </div>
    </atom-async-data>
</div>
