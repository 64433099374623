import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { NgxMaskModule } from 'ngx-mask';

import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { WdxFormFrameworkModule } from '@wdx/shared/infrastructure/form-framework';
import { WdxMarkdownToHtmlPipe } from '@wdx/shared/utils';

import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomHighlightTextModule } from '../../../components/atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeAvatarGroupModule } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeDocumentInfoModule } from '../../../components/molecules/molecule-document-info/molecule-document-info.module';
import { MoleculeSummaryFieldModule } from '../../../components/molecules/molecule-summary-field/molecule-summary-field.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { FormSummaryInfoCardsComponent } from './components/form-summary-info-cards/form-summary-info-cards.component';
import { MoleculeFormLockSummaryComponent } from './components/molecule-form-lock-summary/molecule-form-lock-summary.component';
import { MoleculeFormVersionInfoComponent } from './components/molecule-form-version-info/molecule-form-version-info.component';
import { MoleculeSubformInfoComponent } from './components/molecule-subform-info/molecule-subform-info.component';
import { OrganismFormSummaryContentComponent } from './components/organism-form-summary/organism-form-summary-content/organism-form-summary-content.component';
import { OrganismFormSummaryComponent } from './components/organism-form-summary/organism-form-summary.component';
import { FormSummaryPipesModule } from './pipes/form-summary-pipes.module';

@NgModule({
    declarations: [
        OrganismFormSummaryComponent,
        OrganismFormSummaryContentComponent,
        MoleculeFormVersionInfoComponent,
        MoleculeFormLockSummaryComponent,
        MoleculeSubformInfoComponent,
        FormSummaryInfoCardsComponent,
    ],
    imports: [
        AtomAsyncDataModule,
        AtomHighlightTextModule,
        CommonModule,
        FormSummaryPipesModule,
        MoleculeAvatarGroupModule,
        MoleculeDocumentInfoModule,
        MoleculeSummaryFieldModule,
        NgbAccordionModule,
        NgxMaskModule.forRoot(),
        PipesModule,
        PushModule,
        WdxBadgeModule,
        WdxIconModule,
        WdxInfoCardModule,
        WdxMarkdownToHtmlPipe,
        WdxFormFrameworkModule,
        WdxIconContainerModule,
    ],
    exports: [
        OrganismFormSummaryComponent,
        OrganismFormSummaryContentComponent,
        MoleculeFormVersionInfoComponent,
        MoleculeFormLockSummaryComponent,
        MoleculeSubformInfoComponent,
        FormSummaryInfoCardsComponent,
    ],
})
export class FormSummaryModule {}
