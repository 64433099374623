import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Paging } from '@wdx/clmi/api-services/models';
import { BaseWidgetClass } from '../../../classes/base-widget.class';
import { IFilterHandler } from '../../../interfaces/filter-handler.interface';
import { ActionButton } from '../../../models/action-button.model';
import { CheckboxStyle } from '../../../models/checkbox-style.model';
import { LegendItem } from '../../../models/legend-item.model';
import { MenuItem } from '../../../models/menu-item.model';
import { MultiselectPayload } from '../../../models/multiselect-payload.model';
import { TableDisplayMode } from '../../../models/table-display-mode.model';
import { TableRow } from '../../../models/table-row.model';
import { Table, TableRowDragEvent } from '../../../models/table.model';
import { ToggleEvent } from '../../../models/toggle-event.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'widget-table',
    templateUrl: './widget-table.component.html',
})
export class WidgetTableComponent extends BaseWidgetClass implements OnInit {
    @Input() table: Table;
    @Input() displayMode: TableDisplayMode;
    @Input() tablePaging: Paging;
    @Input() filterHandler: IFilterHandler;
    @Input() filterFormId: string;
    @Input() searchBarActionButtons: ActionButton;
    @Input() legend: LegendItem[];
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() searchPlaceholder: string;
    @Input() highlightFirstColumn: boolean;
    @Input() canSelectRows: boolean;
    @Input() rowSelectorStyle = CheckboxStyle.Check;
    @Input() multiselectBannerButtons: ActionButton[];
    @Input() resetTableData = false;
    @Input() canDragRows = false;
    @Input() showSearchBar = true;
    @Input() showSearch = true;
    @Input() initialSelectedRows: TableRow[];
    @Input() hasMobileFilter: boolean;
    @Input() hasFilterSearch: boolean;

    @Output() actionButtonClicked = new EventEmitter<ActionButton>();
    @Output() multiselectActionButtonClicked =
        new EventEmitter<MultiselectPayload>();
    @Output() pageChanged = new EventEmitter<number>();
    @Output() infinityScrollFired = new EventEmitter();
    @Output() rowSelectChange = new EventEmitter<ToggleEvent>();
    @Output() downloadDocument = new EventEmitter<MenuItem>();
    @Output() rowDragged = new EventEmitter<TableRowDragEvent>();
    @Output() searchText = new EventEmitter<string>();

    ngOnInit() {
        super.ngOnInit();
        if (this.resetTableData) {
            this.resetTableDataOnLoad();
        }
    }

    resetTableDataOnLoad(): void {
        if (this.table && this.tablePaging) {
            this.table = null;
            this.tablePaging = null;
        }
    }

    onActionButtonClicked(actionButton: ActionButton): void {
        this.actionButtonClicked.emit(actionButton);
    }

    onDownloadDocument(documentMenuItem: MenuItem) {
        this.downloadDocument.emit(documentMenuItem);
    }

    onMultiselectActionButtonClicked(multiselectPayload: MultiselectPayload) {
        this.multiselectActionButtonClicked.emit(multiselectPayload);
    }

    onPageChanged(page: number): void {
        this.pageChanged.emit(page);
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }

    onRowDragged(event: any): void {
        this.rowDragged.emit(event);
    }

    onSearchText(searchText: string): void {
        this.searchText.emit(searchText);
    }

    onRowSelectChange(event: ToggleEvent): void {
        this.rowSelectChange.emit(event);
    }
}
