import { Injectable } from '@angular/core';
import { Colour, Palette, Theme } from '@wdx/clmi/api-models';
import {
    Icon,
    KeyValueObject,
    LocalStorageKey,
    LocalStorageService,
} from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';
import {
    ICON_THEME_DARK,
    ICON_THEME_LIGHT,
    ICON_THEME_OS,
} from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { CLMI_DARK_THEME_VARS } from './clmi-dark-theme-vars.constants';
import { DARK_THEME_VARS } from './dark-theme-vars.constants';
import { iThemeSetting } from './theme-setting.enum';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    themeCSSVariables: string;
    themeSetting: iThemeSetting;
    themeSettingActionButton: ActionButton;

    currentTheme$ = new BehaviorSubject<iThemeSetting>(iThemeSetting.Light);

    private darkThemeCSSVariables: string;
    private osTheme: MediaQueryList & {
        removeAllListeners?: (eventName?: string) => void;
    };

    private VARIABLE_PREFIX = 'bs-';
    private LOCAL_STORAGE_KEY = LocalStorageKey.ThemeSetting;

    constructor(private localStorageService: LocalStorageService) {
        this.getThemeSettingFromLocalStorage();

        this.darkThemeCSSVariables = [
            this.setKeyValueObjectAsCSSVariables(DARK_THEME_VARS),
            this.setKeyValueObjectAsCSSVariables(CLMI_DARK_THEME_VARS, 'clmi-'),
        ].join(' ');
    }

    set(theme: Theme) {
        this.setThemeAsCSSVariables(theme);
        this.updateThemeSetting(this.themeSetting);
    }

    getThemeValue(themeVariable: string): string {
        return window
            .getComputedStyle(document.documentElement, null)
            .getPropertyValue(`--bs-${themeVariable}`);
    }

    private setToLight(): void {
        this.destroyOSThemeListener();
        this.applyThemeToDocument(false);
    }

    private setToDark(): void {
        this.destroyOSThemeListener();
        this.applyThemeToDocument(true);
    }

    private setToOSTheme(): void {
        this.getOperatingSystemTheme();
    }

    private updateThemeSetting(themeSetting: iThemeSetting): void {
        this.themeSetting = themeSetting;
        let activeIcon: Icon;
        switch (this.themeSetting) {
            case iThemeSetting.Light:
                activeIcon = ICON_THEME_LIGHT.icon;
                this.setToLight();
                break;
            case iThemeSetting.Dark:
                activeIcon = ICON_THEME_DARK.icon;
                this.setToDark();
                break;
            case iThemeSetting.OS:
                activeIcon = ICON_THEME_OS.icon;
                this.setToOSTheme();
        }
        this.themeSettingActionButton = {
            mode: ActionButtonMode.DropdownButtonMenuWithLabel,
            label: 'Change Theme',
            icon: activeIcon,
            dropdownMenu: [
                {
                    label: 'Light',
                    icon: ICON_THEME_LIGHT.icon,
                    selected: this.themeSetting === iThemeSetting.Light,
                    callback: () => {
                        this.updateThemeSetting(iThemeSetting.Light);
                    },
                },
                {
                    label: 'Dark (BETA)',
                    icon: ICON_THEME_DARK.icon,
                    selected: this.themeSetting === iThemeSetting.Dark,
                    callback: () => {
                        this.updateThemeSetting(iThemeSetting.Dark);
                    },
                },
                {
                    label: 'OS Default (BETA)',
                    icon: ICON_THEME_OS.icon,
                    selected: this.themeSetting === iThemeSetting.OS,
                    callback: () => {
                        this.updateThemeSetting(iThemeSetting.OS);
                    },
                },
            ],
        };
        this.updateLocalStorage();
    }

    private getOperatingSystemTheme(): void {
        this.osTheme = window.matchMedia('(prefers-color-scheme: dark)');
        this.applyThemeToDocument(this.osTheme.matches);
        this.osTheme.addEventListener('change', (event) => {
            this.applyThemeToDocument(event.matches);
        });
    }

    private destroyOSThemeListener(): void {
        if (this.osTheme) {
            this.osTheme.removeAllListeners();
        }
    }

    private applyThemeToDocument(darkTheme: boolean): void {
        const theme = [
            this.themeCSSVariables,
            ...(darkTheme ? [this.darkThemeCSSVariables] : []),
        ].join(' ');
        document.documentElement.setAttribute('style', theme);
        this.updateCurrentTheme(
            darkTheme ? iThemeSetting.Dark : iThemeSetting.Light
        );
    }

    private setThemeAsCSSVariables(theme: Theme): void {
        const cssVariables = [
            this.setPaletteAsCSSVariables(theme.base),
            this.setPaletteAsCSSVariables(theme.light, 'light'),
            this.setPaletteAsCSSVariables(theme.dark, 'dark'),
        ];
        this.themeCSSVariables = cssVariables.join(' ');
    }

    private setPaletteAsCSSVariables(
        palette: Palette,
        suffix?: string
    ): string {
        if (!palette) {
            return '';
        }
        return Object.keys(palette)
            .map((color) => this.setColorRgb(color, suffix, palette[color]))
            .join(' ');
    }

    private setColorRgb(color: string, suffix: string, rgb: Colour): string {
        if (!rgb) {
            return '';
        }
        const variable = `--${this.VARIABLE_PREFIX}${color}${
            suffix ? '-' + suffix : ''
        }`;
        return [
            `${variable}-red: ${rgb.r};`,
            `${variable}-green: ${rgb.g};`,
            `${variable}-blue: ${rgb.b};`,
        ].join(' ');
    }

    private setKeyValueObjectAsCSSVariables(
        obj: KeyValueObject,
        keyPrefix?: string,
        valuePrefix?: string
    ): string {
        if (!obj) {
            return '';
        }
        return Object.keys(obj)
            .map(
                (color) =>
                    `--${keyPrefix || this.VARIABLE_PREFIX}${color}: var(--${
                        valuePrefix || this.VARIABLE_PREFIX
                    }${obj[color]});`
            )
            .join(' ');
    }

    private getThemeSettingFromLocalStorage(): void {
        this.themeSetting =
            iThemeSetting[
                this.localStorageService.getStringKey(this.LOCAL_STORAGE_KEY)
            ] || iThemeSetting.Light;
    }

    private updateLocalStorage(): void {
        this.localStorageService.setStringKey(
            this.LOCAL_STORAGE_KEY,
            this.themeSetting
        );
    }

    private updateCurrentTheme(theme: iThemeSetting): void {
        this.currentTheme$.next(theme);
    }
}
