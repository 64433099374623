import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { BaseSearchBarClass } from '../../../classes/base-search-bar.class';
import { FormDisplayMode } from '../../../models/form-display-mode.model';
import { MenuItem } from '../../../models/menu-item.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { IMPORT_ENTITY_FEATURE_FLAGS_MAP } from '../../../shared/components/import-entities/import-entities.constant';
import { IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP } from '../../../shared/components/import-export/import-export.constant';
import { FilterQueryService } from '../../../shared/features/form-filters';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { RouteDataService } from '../../../shared/services/route-data/route-data.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-search-and-filter-bar',
    templateUrl: './molecule-search-and-filter-bar.component.html',
})
export class MoleculeSearchAndFilterBarComponent
    extends BaseSearchBarClass
    implements OnInit
{
    @Output() sort = new EventEmitter<string>();

    instanceId: string;
    showImportExport: boolean;
    showImport: boolean;

    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly FEATURE_FLAG = FeatureFlag;

    constructor(
        public routeDataService: RouteDataService,
        private featuresService: FeaturesService,
        private filterQueryService: FilterQueryService
    ) {
        super();
    }

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();

        this.showImportExport = Boolean(
            (this.featuresService.hasFeature(FeatureFlag.Exports) &&
                this.routeDataService?.exportPrivilege) ||
                Object.values(IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP).some(
                    (featureFlag) =>
                        this.featuresService.hasFeature(
                            featureFlag as FeatureFlag
                        )
                )
        );

        this.showImport = Object.keys(IMPORT_ENTITY_FEATURE_FLAGS_MAP).some(
            (entity) =>
                this.filterQueryService?.viewType === entity &&
                this.featuresService.hasFeature(
                    IMPORT_ENTITY_FEATURE_FLAGS_MAP[entity]
                )
        );
    }

    onSort(sortMenuItem: MenuItem): void {
        this.sort.emit(sortMenuItem?.id);
    }
}
