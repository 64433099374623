import { Action, createReducer, on } from '@ngrx/store';
import { DocumentTemplate } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as documentTemplateActions from './document-templates.actions';

export interface State {
    documentTemplates: CrudStateObject<DocumentTemplate>;
    availableData: CrudStateObject<any>;
}

export const initialState: State = {
    documentTemplates: {},
    availableData: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentTemplateActions.getDocumentTemplates,
        (state, props): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [props.entityType || props.documentTypeCode]: {
                    ...(state.documentTemplates[
                        props.entityType || props.documentTypeCode
                    ] || ({} as CrudState<DocumentTemplate>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.getDocumentTemplatesSuccess,
        (state, props): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [props.entityType || props.documentTypeCode]: {
                    ...state.documentTemplates[
                        props.entityType || props.documentTypeCode
                    ],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    list: props.documentTemplates,
                },
            },
        })
    ),

    on(
        documentTemplateActions.getDocumentTemplatesFailure,
        (state, props): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [props.entityType || props.documentTypeCode]: {
                    ...state.documentTemplates[
                        props.entityType || props.documentTypeCode
                    ],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        documentTemplateActions.getDocument,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.getDocumentSuccess,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTemplates[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.getDocumentFailure,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentTemplates[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        documentTemplateActions.activateDocumentTemplate,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.activateDocumentTemplateSuccess,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.activateDocumentTemplateFailure,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        documentTemplateActions.inactivateDocumentTemplate,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.inactivateDocumentTemplateSuccess,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        documentTemplateActions.inactivateDocumentTemplateFailure,
        (state): State => ({
            ...state,
            documentTemplates: {
                ...state.documentTemplates,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentTemplates[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentTemplate>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
