import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { WebcamModule } from 'ngx-webcam';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeInputGroupModule } from '../../../components/molecules/molecule-input-group/molecule-input-group.module';
import { MoleculeInputWrapperModule } from '../../../components/molecules/molecule-input-wrapper/molecule-input-wrapper.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeInputUploadDumbComponent } from './components/molecule-input-upload-dumb/molecule-input-upload-dumb.component';
import { MoleculeInputUploadComponent } from './containers/molecule-input-upload/molecule-input-upload.component';
// eslint-disable-next-line max-len
import { MoleculeInputContactAvatarUploadComponent } from '../../../components/molecules/molecule-input-upload/containers/molecule-input-contact-avatar-upload/molecule-input-contact-avatar-upload.component';
// eslint-disable-next-line max-len
import { PushModule } from '@ngrx/component';
import { MoleculeDocumentInfoModule } from '../../../components/molecules/molecule-document-info/molecule-document-info.module';
import { MoleculeInputProfileAvatarUploadComponent } from '../../../components/molecules/molecule-input-upload/containers/molecule-input-profile-avatar-upload/molecule-input-profile-avatar-upload.component';

@NgModule({
    imports: [
        AtomIconButtonModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeDocumentInfoModule,
        MoleculeInputGroupModule,
        MoleculeInputWrapperModule,
        NgbProgressbarModule,
        NgxDropzoneModule,
        OrganismModalModule,
        PipesModule,
        PushModule,
        WebcamModule,
    ],
    declarations: [
        MoleculeInputUploadComponent,
        MoleculeInputContactAvatarUploadComponent,
        MoleculeInputProfileAvatarUploadComponent,
        MoleculeInputUploadDumbComponent,
    ],
    exports: [
        MoleculeInputUploadComponent,
        MoleculeInputContactAvatarUploadComponent,
        MoleculeInputProfileAvatarUploadComponent,
    ],
})
export class MoleculeInputUploadModule {}
