import { Component, ElementRef, Input } from '@angular/core';
import { BaseInputClass } from '../../../classes/base-input-class';
import { InfoCardData } from '../../../models/info-card-data.model';
import { InfoCardSearchMode } from '../../../models/info-card-search-mode.model';
import { LookupSearchEvent } from '../../../models/lookup-options.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../services/modal-management.service';
import { SimpleTag } from '@wdx/clmi/api-models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-tags',
    templateUrl: './molecule-input-tags.component.html',
})
export class MoleculeInputTagsComponent extends BaseInputClass {
    @Input() isMulti: boolean;

    @Input() tagsIsLoading: boolean;
    @Input() tagsHasError: boolean;
    @Input() tags: SimpleTag[];

    searchText = '';

    modalId: string;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;

    constructor(
        elementRef: ElementRef,
        modalManagementService: ModalManagementService
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            modalManagementService,
        });

        this.modalId = new RandomStringPipe().transform();
    }

    tagsAsAsInfoCardData(tags: SimpleTag[]): InfoCardData[] {
        return tags?.map((tag) => ({
            primaryInfo: tag.tag,
            id: tag.tag,
            data: tag.tag,
        }));
    }

    valueAsArray(value: any): any[] {
        return !value || Array.isArray(value) ? value : [value];
    }

    valueAsArrayToString(valueAsArray: any[]): string {
        return valueAsArray?.join(', ');
    }

    filteredTags(
        tags: SimpleTag[],
        searchEvent: LookupSearchEvent
    ): SimpleTag[] {
        return searchEvent
            ? tags?.filter((tag) =>
                  tag.tag
                      .toLowerCase()
                      .includes(searchEvent?.searchText?.toLowerCase())
              )
            : tags;
    }

    onSearch(searchText: string): void {
        this.searchText = searchText;
    }

    onConfirmClicked() {
        this.modalManagementService.closeActiveModal();
    }

    deselectTag(tag: string): void {
        this.onToggle(tag);
    }
}
