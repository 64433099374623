import { Action, createReducer, on } from '@ngrx/store';
import { Document, SystemEntity } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { FileUploadState } from '../../models/file-upload-state.model';
import { FileUploadStatus } from '../../models/file-upload-status.model';
import * as documentsActions from './documents.actions';

export interface State {
    documents?: CrudStateObject<Document>;
    document?: any; // todo refactor for proper type
    clientProductDocuments?: CrudStateObject<Document>;
    [SystemEntity.Party]: DocumentsState;
    [SystemEntity.PartyRole]: DocumentsState;
}

export interface DocumentsState {
    [entityId: string]: {
        [dMSProvider: string]: CrudStateObject<Document>;
    };
}

export const initialState: State = {
    documents: {},
    document: {},
    clientProductDocuments: {},
    [SystemEntity.Party]: {},
    [SystemEntity.PartyRole]: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentsActions.getDocumentsForClientProduct,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...(state.clientProductDocuments[props.clientProductId] ||
                        ({} as CrudState<Document>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.clientProductDocuments[props.clientProductId]
                              ?.infinity,
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForClientProductSuccess,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...state.clientProductDocuments[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.clientProductDocuments.paging,
                        combinedList: [
                            ...(state.clientProductDocuments[
                                props.clientProductId
                            ].infinity?.combinedList || []),
                            ...props.clientProductDocuments.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForClientProductFailure,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...state.clientProductDocuments[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocument,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...(state.documents[props.id] ||
                        ({} as CrudState<Document>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocumentSuccess,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...state.documents[props.id],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocumentFailure,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...state.documents[props.id],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocument,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...(state.document[props.entityId] ||
                        ({} as FileUploadState)),
                    [props.id]: {
                        ...((state.document[props.entityId] &&
                            state.document[props.entityId][props.id]) ||
                            ({} as FileUploadState)),
                        uploadStatus: FileUploadStatus.Ready,
                        progress: 0,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocumentComplete,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...(state.document[props.entityId] ||
                        ({} as FileUploadState)),
                    [props.id]: {
                        ...((state.document[props.entityId] &&
                            state.document[props.entityId][props.id]) ||
                            ({} as FileUploadState)),
                        uploadStatus: FileUploadStatus.Completed,
                        fileIndex: props.fileIndex,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocumentFailure,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...state.document[props.entityId],
                    [props.id]: {
                        ...(state.document[props.entityId] &&
                            state.document[props.entityId][props.id]),
                        uploadStatus: FileUploadStatus.Failed,
                        error: props.error,
                    },
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
