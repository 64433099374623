import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { IFormControlData } from '../../../../models/reactive-dynamic.model';
import { Trigger } from '@wdx/clmi/api-models';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormStaticService } from '../form-static/form-static.class';
import { StringifyComparePipe } from '../../../../pipes/stringify-compare.pipe';

@Injectable()
export class FormControlService {
    constructor(private formStaticService: FormStaticService) {}

    /**
     *  This splits the string on period symbol and returns a array of strings
     *
     * @param {String} name This is the name from the form condition
     * @return {String[]}
     */
    nameToArray(name: string): string[] {
        return name.split('.');
    }

    /**
     *
     * @param {string} name This is the name of the form control e.g 'firstName', 'arrayName.hair'
     * @param {number} indexes  This is the index of the items in the form array. This is optional
     *
     * @returns {IFormControlData[]} This returns an array of IFormControlData
     */
    getFormControlData(name: string, indexes?: number[]): IFormControlData[] {
        const FORM_CONTROLS: {
            index?: number;
            formControl: UntypedFormControl;
        }[] = [];
        const TRIGGERS = this.nameToArray(name);
        const IS_ARRAY_TRIGGER = TRIGGERS.length >= 2;
        const ALL_FORM_CONTROLS = this.formStaticService.form;
        const FORM_CONTROLS_GET = ALL_FORM_CONTROLS?.get(TRIGGERS[0]);

        if (!FORM_CONTROLS_GET) {
            return [];
        }

        if (IS_ARRAY_TRIGGER) {
            const FORM_ARRAY = FORM_CONTROLS_GET as UntypedFormArray;

            const INDEXES = indexes?.length;

            const MAX = INDEXES ? INDEXES : FORM_ARRAY.controls?.length;
            let count = 0;

            while (count < MAX) {
                const INDEX = INDEXES ? indexes[count] : count;

                if (FORM_ARRAY.at(INDEX)) {
                    FORM_CONTROLS.push({
                        index: INDEX,
                        formControl: FORM_ARRAY.at(INDEX).get(
                            TRIGGERS[1]
                        ) as UntypedFormControl,
                    });
                }

                count = count + 1;
            }
        }

        if (!IS_ARRAY_TRIGGER) {
            if (FORM_CONTROLS) {
                FORM_CONTROLS.push({
                    formControl: FORM_CONTROLS_GET as UntypedFormControl,
                });
            }
        }

        return FORM_CONTROLS;
    }

    /**
     *
     * @param {Trigger} trigger This is the trigger object from the form conditions
     * @param {IFormControlData} FORM_CONTROL_DATA This is an object of IFormControlData that as  form control witch will be watched for changes
     * @param {(trigger: Trigger, formControlData: IFormControlData)} fn This is a function that gets called when the form control emits a change. The 2 properties above are passed to the function
     */
    setValueChangeListener(
        trigger: Trigger,
        FORM_CONTROL_DATA: IFormControlData,
        fn: (trigger: Trigger, formControlData: IFormControlData) => void
    ): void {
        FORM_CONTROL_DATA.formControl.valueChanges
            .pipe(
                distinctUntilChanged((x, y) =>
                    new StringifyComparePipe().transform(x, y)
                ),
                takeUntil(this.formStaticService.destroyed$),
                debounceTime(0)
            )
            .subscribe((_) => {
                fn(trigger, FORM_CONTROL_DATA);
            });
    }
}
