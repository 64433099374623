import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';

import * as investmentDataActions from './investment-data.actions';
import * as investmentDataSelectors from './investment-data.selectors';
import { InvestmentDataService } from './investment-data.service';

@Injectable()
export class InvestmentDataEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private investmentDataService = inject(InvestmentDataService);

    getValuationHistoryForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getValuationHistoryForClientProduct),
            mergeMap((action) =>
                this.investmentDataService
                    .getValuationHistoryForClientProduct(action.clientProductId)
                    .pipe(
                        map((valuationHistory) =>
                            investmentDataActions.getValuationHistoryForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    valuationHistory,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getValuationHistoryForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getMovements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getMovements),
            withLatestFrom(
                this.store$.select(
                    investmentDataSelectors.getMovementsInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID }
                )
            ),
            switchMap(([action, paging]) =>
                this.investmentDataService
                    .getMovements(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.valuationLevel,
                        action.valuationEntityId
                    )
                    .pipe(
                        map((movements) =>
                            investmentDataActions.getMovementsSuccess({
                                movements,
                                valuationEntityId: action.valuationEntityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getMovementsFailure({
                                    valuationEntityId: action.valuationEntityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPerformanceForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getPerformanceForClientProduct),
            mergeMap((action) =>
                this.investmentDataService
                    .getPerformanceForClientProduct(
                        action.clientProductId,
                        action.period
                    )
                    .pipe(
                        map((performance) =>
                            investmentDataActions.getPerformanceForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    performance,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getPerformanceForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getAssetAllocationByCountryForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                investmentDataActions.getAssetAllocationByCountryForClientProduct
            ),
            mergeMap((action) =>
                this.investmentDataService
                    .getAssetAllocationByCountryForClientProduct(
                        action.clientProductId
                    )
                    .pipe(
                        map((allocation) =>
                            investmentDataActions.getAssetAllocationByCountryForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    allocation,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getAssetAllocationByCountryForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getAssetAllocationByGeographicForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                investmentDataActions.getAssetAllocationByGeographicForClientProduct
            ),
            mergeMap((action) =>
                this.investmentDataService
                    .getAssetAllocationByGeographicForClientProduct(
                        action.clientProductId
                    )
                    .pipe(
                        map((allocation) =>
                            investmentDataActions.getAssetAllocationByGeographicForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    allocation,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getAssetAllocationByGeographicForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getAssetAllocationBySectorForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                investmentDataActions.getAssetAllocationBySectorForClientProduct
            ),
            mergeMap((action) =>
                this.investmentDataService
                    .getAssetAllocationBySectorForClientProduct(
                        action.clientProductId
                    )
                    .pipe(
                        map((allocation) =>
                            investmentDataActions.getAssetAllocationBySectorForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    allocation,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getAssetAllocationBySectorForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getAssetAllocationByAssetClassForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                investmentDataActions.getAssetAllocationByAssetClassForClientProduct
            ),
            mergeMap((action) =>
                this.investmentDataService
                    .getAssetAllocationByAssetClassForClientProduct(
                        action.clientProductId
                    )
                    .pipe(
                        map((allocation) =>
                            investmentDataActions.getAssetAllocationByAssetClassForClientProductSuccess(
                                {
                                    clientProductId: action.clientProductId,
                                    allocation,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getAssetAllocationByAssetClassForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getSecurityPositions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getSecurityPositions),
            withLatestFrom(
                this.store$.select(
                    investmentDataSelectors.getSecurityPositionsInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID }
                )
            ),
            switchMap(([action, paging]) =>
                this.investmentDataService
                    .getSecurityPositions(
                        {
                            pageSize: 200,
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.valuationLevel,
                        action.valuationEntityId
                    )
                    .pipe(
                        map((securityPositions) =>
                            investmentDataActions.getSecurityPositionsSuccess({
                                securityPositions,
                                valuationEntityId: action.valuationEntityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getSecurityPositionsFailure(
                                    {
                                        valuationEntityId:
                                            action.valuationEntityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getCashSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getCashSummary),
            switchMap((action) =>
                this.investmentDataService
                    .getCashSummary(
                        action.valuationLevel,
                        action.valuationEntityId
                    )
                    .pipe(
                        map((cashSummaries) =>
                            investmentDataActions.getCashSummarySuccess({
                                cashSummaries,
                                valuationEntityId: action.valuationEntityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getCashSummaryFailure({
                                    valuationEntityId: action.valuationEntityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getAssetClass$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getAssetClass),
            switchMap((action) =>
                this.investmentDataService
                    .getAssetClass(action.clientProductId)
                    .pipe(
                        map((valuationSummary) =>
                            investmentDataActions.getAssetClassSuccess({
                                valuationSummary,
                                clientProductId: action.clientProductId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getAssetClassFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getTopMovers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentDataActions.getTopMovers),
            switchMap((action) =>
                this.investmentDataService
                    .getTopMovers(action.clientProductId)
                    .pipe(
                        map((movements) =>
                            investmentDataActions.getTopMoversSuccess({
                                movements,
                                clientProductId: action.clientProductId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentDataActions.getTopMoversFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
