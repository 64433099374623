import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Location } from '@angular/common';
import {
    EntityPermissionType,
    PartyTeamMember,
    SystemEntity,
} from '@wdx/clmi/api-models';
import {
    EDIT_MENU_ITEM,
    REMOVE_MENU_ITEM,
} from '../../../../../../constants/menu.constants';
import { ActionButton } from '../../../../../../models/action-button.model';
import { FormInitialisationMode } from '../../../../../../models/form-setup.model';
import { InfoCardData } from '../../../../../../models/info-card-data.model';
import { DisplayDatePipe } from '../../../../../../pipes/display-date.pipe';
import { EntityRoutePipe } from '../../../../../../pipes/entity-route.pipe';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Injectable()
export class TeamMembersTransformService {
    assignTeamMemberAsOwner$ = new Subject();
    removeTeamMember$ = new Subject();

    constructor(private location: Location) {}

    partyToTeam(
        partyTeamMember: PartyTeamMember,
        entity: string,
        systemEntity: SystemEntity,
        permission: EntityPermissionType,
        teamMemberForm: string,
        isOwner = false
    ): { infoCard: InfoCardData; actionButton: ActionButton } {
        const ACTION_BTN: ActionButton = {
            mode: 1,
            operationsSetup: {
                entityType: systemEntity,
                entityId: entity,
            },
            permission: permission,
            ignoreActions: true,
            dropdownMenu: [
                {
                    ...EDIT_MENU_ITEM,
                    formSetup: {
                        formId: teamMemberForm,
                        entityId: partyTeamMember.id,
                        initialisationMode: FormInitialisationMode.Edit,
                    },
                    permission: permission,
                },
                {
                    ...REMOVE_MENU_ITEM,
                    disabled: false,
                    callback: () =>
                        this.removeTeamMember$.next(partyTeamMember?.id),
                    permission: permission,
                },
            ],
        };

        return {
            infoCard: this.setUpInfoCard(
                partyTeamMember,
                isOwner,
                systemEntity
            ),
            actionButton: ACTION_BTN,
        };
    }

    setUpInfoCard(partyTeamMember, isOwner, systemEntity): InfoCardData {
        return {
            primaryInfo: partyTeamMember?.party?.name,
            secondaryInfo: isOwner
                ? { label: 'Owner' }
                : { label: partyTeamMember?.role?.label },
            tertiaryInfo:
                !isOwner && partyTeamMember?.addedOn
                    ? [
                          {
                              label: `Added: ${new DisplayDatePipe().transform(
                                  partyTeamMember?.addedOn,
                                  { relative: true }
                              )}`,
                          },
                      ]
                    : null,
            routerLink: new EntityRoutePipe(this.location).transform(
                systemEntity === SystemEntity.Team
                    ? SystemEntity.User
                    : SystemEntity[partyTeamMember?.party?.partyType],
                partyTeamMember?.party?.id
            ),
            avatars: [new PartyToAvatarPipe().transform(partyTeamMember.party)],
        };
    }
}
