import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import { ICON_CLOSE } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-multiselect-banner',
    templateUrl: './molecule-multiselect-banner.component.html',
    styleUrls: ['./molecule-multiselect-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoleculeMultiselectBannerComponent implements OnInit, OnDestroy {
    @Input() selectedCount = 0;
    @Input() totalItems: number;
    @Input() contentProjectActions = false;
    @Input() actionButtons: ActionButton[];
    @Input() position: 'sticky' | 'fixed';
    @Input() container: ElementRef;
    @Input() title: string;

    @Output() actionButtonClicked = new EventEmitter<ActionButton>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter();

    observer: ResizeObserver;
    allSelected = false;

    readonly ICON_CLOSE = ICON_CLOSE;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    ngOnInit(): void {
        if (this.position === 'fixed') {
            this.observer = new ResizeObserver(() => this.fixPosition());
            this.observer.observe(this.container?.nativeElement);
        }
    }

    fixPosition() {
        const container = this.container.nativeElement;
        const containerPos = container.getBoundingClientRect();
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'left',
            containerPos.left + 'px'
        );
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'width',
            container.clientWidth + 'px'
        );
        this.renderer.setStyle(container, 'padding-bottom', '75px');
    }

    onActionButtonClicked(button: ActionButton): void {
        this.actionButtonClicked.emit(button);
    }

    onClose(): void {
        this.close.emit();
    }

    ngOnDestroy() {
        if (this.position === 'fixed') {
            this.observer.unobserve(this.container.nativeElement);
            this.renderer.setStyle(
                this.container.nativeElement,
                'padding-bottom',
                '0'
            );
        }
    }
}
