import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { AppLanguage, MenuItem, Theme, Version } from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';
import { Build, Features, SystemSettings, Translations } from './models';
import { SystemUrlSegment } from './system-url-segment';

@Injectable({ providedIn: 'root' })
export class SystemApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getFeatures(): Observable<Features> {
        return this.get<Features>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Feature}`
        );
    }

    getVersion(): Observable<Version> {
        return this.get<Version>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Version}`
        );
    }

    getAppLanguages(): Observable<AppLanguage[]> {
        return this.get<AppLanguage[]>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.AppLanguage}`
        );
    }

    getSystemSettings(): Observable<SystemSettings> {
        return this.get<SystemSettings>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Setting}`
        );
    }

    getTranslations(): Observable<Translations> {
        return this.get<Translations>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Translations}`
        );
    }

    getTheme(): Observable<Theme> {
        return this.get<Theme>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Theme}`
        );
    }

    getMenu(): Observable<MenuItem[]> {
        return this.get<MenuItem[]>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.Menu}`
        );
    }

    getBuild(includeVersion = true): Observable<Build> {
        return this.getRelative<Build>('build-info.json', {
            params: includeVersion ? { v: new Date().toISOString() } : {},
        });
    }

    getTokenisedUrl(url: string): Observable<Blob> {
        return this.patch<Blob>(
            `${SystemUrlSegment.System}/${SystemUrlSegment.TokeniseUrl}`,
            url,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob',
            }
        );
    }
}
