import { Action, createReducer, on } from '@ngrx/store';
import { EntityOperations } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import { OperationsSetup } from '../../models/operations.model';
import * as operationsActions from './operations.actions';

export interface State {
    operations: {
        [entityType: string]: CrudStateObject<EntityOperations>;
    };
    latestRouteSetups?: CrudState<OperationsSetup>;
}

export const initialState: State = {
    operations: {},
    latestRouteSetups: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        operationsActions.getOperationsForEntity,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
        })
    ),

    on(
        operationsActions.getOperationsForEntitySuccess,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        single: props.operations,
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                    },
                },
            },
        })
    ),

    on(
        operationsActions.getOperationsForEntityFailure,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [GLOBAL_STATE_INDEX_ID]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
        })
    ),

    on(
        operationsActions.getOperationsForId,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
        })
    ),

    on(
        operationsActions.getOperationsForIdSuccess,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        single: props.operations,
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                    },
                },
            },
        })
    ),

    on(
        operationsActions.getOperationsForIdFailure,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.entityType]: {
                    ...state.operations[props.entityType],
                    [props.entityId]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
