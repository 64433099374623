import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ReactiveFormLayoutAndDefinition } from '../../../../models/reactive-dynamic.model';

@Pipe({ name: 'validationErrorMessage' })
export class ValidationErrorMessagePipe implements PipeTransform {
    transform(
        validationErrors: ValidationErrors,
        formElement?: ReactiveFormLayoutAndDefinition
    ): string {
        if (!validationErrors) {
            return null;
        }

        if (validationErrors['required']) {
            return 'This is a required field';
        }

        if (validationErrors['mask']) {
            return `Must be in the format ${validationErrors['mask'].requiredMask}`;
        }

        if (validationErrors['min']) {
            return `${formElement.min} is the minimum value allowed`;
        }

        if (validationErrors['minlength']) {
            return `${formElement.min} is the minimum character limit.`;
        }

        if (validationErrors['minArrayLength']) {
            return `${formElement.min} is the minimum required.`;
        }

        if (validationErrors['max']) {
            return `${formElement.max} is the maximum value allowed`;
        }

        if (validationErrors['maxlength']) {
            return `${formElement.max} is the maximum character limit.`;
        }

        if (validationErrors['maxArraylength']) {
            return `${formElement.max} is the maximum allowed.`;
        }

        if (validationErrors['dateMustBeBefore']) {
            return 'Must be in the past';
        }

        if (validationErrors['dateMustBeAfter']) {
            return 'Must be in the future';
        }

        if (validationErrors['datesMustBeValidRange']) {
            return 'Must be a valid date range';
        }

        if (validationErrors['mustContainRequiredFields']) {
            return 'Must contain all required fields';
        }

        if (validationErrors['isTrue']) {
            return 'This field must be selected';
        }

        if (validationErrors['regex']) {
            return formElement.inputMaskDescription;
        }

        return 'The field is invalid';
    }
}
