import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '@wdx/clmi/api-models';
import * as userReducer from './user.reducer';
import * as userSelectors from './user.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserFacadeService {
    store$ = inject(Store<userReducer.State>);

    me: User | undefined;

    getMe$(): Observable<User | undefined> {
        return this.store$.select(userSelectors.getMeSelector).pipe(
            tap((me) => {
                if (me) {
                    this.me = me;
                }
            })
        );
    }

    isAdminUser$(): Observable<boolean | undefined> {
        return this.store$.select(userSelectors.isSystemAdminSelector);
    }
}
