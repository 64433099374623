import { Injectable } from '@angular/core';
import { IFormPendingChangeSubForm } from '@wdx/shared/infrastructure/form-framework';
import {
    IFormDataResult,
    IFormLayout,
} from '../../../../../features/reactive-forms/constants/control-types';
import {
    PENDING_CHANGE_REG,
    PENDING_CHANGE_SPLIT,
    UPDATE_PENDING_CHANGE_COUNTER,
} from '../../../../../features/reactive-forms/services/form-pending-changes/form-pending-changes.helper';
import {
    ESubFormPendingChange,
    PENDING_CHANGE_LOCK_ALL,
} from '../../../../../features/reactive-forms/services/form-pending-changes/form-pending-changes.interface';

@Injectable({
    providedIn: 'any',
})
export class FormSummaryPendingChangesService {
    pendingChangesSubForm = {};
    pendingChangesSubFormCounter: IFormPendingChangeSubForm = {};

    updatePendingChangesArray(formDetails, data): void {
        const PENDING_CHANGES = formDetails?.pendingChanges;
        const removeItems = [];

        PENDING_CHANGES?.forEach((pendingChange, index) => {
            // Check if sub form field is been updated
            if (pendingChange.field.match(PENDING_CHANGE_REG)) {
                removeItems.push(index);
                const PENDING = PENDING_CHANGE_SPLIT(pendingChange.field);

                if (PENDING.length > 2) {
                    const INDEX = parseInt(PENDING[1], 10);
                    const FORM_DATA_ROW = data[PENDING[0]]?.[INDEX];

                    if (
                        !PENDING_CHANGE_LOCK_ALL.includes(FORM_DATA_ROW.status)
                    ) {
                        this.pendingChangesSubForm = {
                            ...this.pendingChangesSubForm,
                            [PENDING[0]]: {
                                ...this.pendingChangesSubForm[PENDING[0]],
                                [INDEX]: {
                                    [PENDING[2]]: {
                                        ...pendingChange,
                                        field: PENDING[2],
                                        name: PENDING[2],
                                    },
                                },
                            },
                        };

                        if (FORM_DATA_ROW) {
                            this.updatePendingChangeCounter(
                                PENDING[0],
                                ESubFormPendingChange.Updating
                            );

                            FORM_DATA_ROW.status =
                                ESubFormPendingChange.Updating;
                        }
                    }
                }
            }
        });

        removeItems.reverse().forEach((index) => {
            formDetails?.pendingChanges.splice(index, 1);
        });
    }

    updatePendingData(
        layoutAndDefinition: IFormLayout,
        formDetails: IFormDataResult,
        data: any
    ): void {
        this.findPendingChangesInFormData(data);
        this.updatePendingChangesArray(formDetails, data);

        if (formDetails?.pendingChanges?.length) {
            layoutAndDefinition.sectionLayoutDefinitions?.forEach(
                (sectionLayoutDefinition) => {
                    sectionLayoutDefinition.elementLayoutDefinitions.forEach(
                        (elementLayoutDefinition) => {
                            const HAS_SUB_FORM_GROUPING =
                                this.pendingChangesSubForm[
                                    elementLayoutDefinition.name
                                ];

                            if (HAS_SUB_FORM_GROUPING) {
                                this.updatePendingChangeCounter(
                                    elementLayoutDefinition.name,
                                    null,
                                    elementLayoutDefinition.label
                                );
                            }

                            const INDEX = formDetails.pendingChanges.findIndex(
                                (pendingChange) =>
                                    pendingChange.field ===
                                    elementLayoutDefinition?.name
                            );

                            if (INDEX >= 0) {
                                const pendingChange =
                                    formDetails.pendingChanges[INDEX];

                                pendingChange.name =
                                    elementLayoutDefinition.label;
                                elementLayoutDefinition.pendingChange =
                                    pendingChange;
                            }
                        }
                    );
                }
            );
        }
    }

    findPendingChangesInFormData(data): void {
        Object.keys(data).forEach((key) => {
            const FIELD = data[key];

            if (Array.isArray(FIELD)) {
                FIELD.forEach((row, index) => {
                    if (PENDING_CHANGE_LOCK_ALL.includes(row.status)) {
                        this.updatePendingChangeCounter(key, row.status);

                        this.pendingChangesSubForm = {
                            ...this.pendingChangesSubForm,
                            [key]: {
                                ...this.pendingChangesSubForm[key],
                                [index]: row,
                            },
                        };
                    }
                });
            }
        });
    }

    updatePendingChangeCounter(
        name: string,
        type: ESubFormPendingChange,
        label?: string
    ): void {
        this.pendingChangesSubFormCounter = UPDATE_PENDING_CHANGE_COUNTER(
            this.pendingChangesSubFormCounter,
            name,
            type,
            label
        );
    }
}
