<molecule-input-wrapper
    *ngIf="showReadout"
    [formInputData]="formInputData"
    [validationErrorMessage]="validationErrorMessage"
    [hideGuidanceText]="hideGuidanceText"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [value]="value | fn : valueAsString"
>
    <div class="action-buttons">
        <atom-add-button
            type="button"
            [cySelector]="'action-' + (formInputData.name || formInputData.id)"
            [modalId]="!formInputData.isDisabled ? modalId : null"
            [disabled]="formInputData.isDisabled"
            (click)="onOpen()"
        >
            Add
        </atom-add-button>
    </div>
    <atom-input-none-selected
        *ngIf="!value; else results"
        [modalId]="!formInputData.isDisabled ? modalId : null"
        [disabled]="formInputData.isDisabled"
    ></atom-input-none-selected>
    <ng-template #results>
        <div class="info-card-container">
            <ng-container
                *ngFor="
                    let infoCardData of value
                        | fn : valueAsArray
                        | fn : lookupResultsAsInfoCardData;
                    let i = index
                "
            >
                <div class="card">
                    <molecule-info-card
                        class="card-body"
                        size="sm"
                        [avatars]="infoCardData.avatars"
                        [primaryInfo]="infoCardData.primaryInfo"
                        [canDelete]="!formInputData.isDisabled"
                        (delete)="
                            isMulti ? onToggle(infoCardData.data) : onClear()
                        "
                    ></molecule-info-card>
                </div>
            </ng-container>
        </div>
    </ng-template>
</molecule-input-wrapper>

<atom-modal-wrapper [modalId]="modalId" [modalTemplate]="lookupModal">
    <ng-template #lookupModal>
        <organism-modal
            [modalTitle]="formInputData.label"
            (modalClosed)="onClose()"
        >
            <molecule-info-card-search
                [formInputData]="formInputData"
                [mode]="
                    isMulti
                        ? INFO_CARD_SEARCH_MODE.MultiSelect
                        : INFO_CARD_SEARCH_MODE.Select
                "
                [lookupMode]="lookupMode"
                [infoCards]="lookupResults | fn : lookupResultsAsInfoCardData"
                [isLoading]="lookupIsLoading"
                [hasError]="lookupHasError"
                [initialValue]="value"
                [bannerInfo]="bannerInfo"
                [placeholder]="placeholder"
                (confirm)="onConfirmClicked()"
                (search)="onSearch($event)"
                (generateDocument)="onGenerateAndDownloadDocument($event)"
                (previewAvailableDocumentData)="
                    onPreviewAvailableDocumentData($event)
                "
                (valueChanged)="onChange($event)"
            >
                <div
                    class="flex-shrink-0 flex-grow-0 bg-muted offcanvas-body d-flex align-items-center"
                    *ngIf="
                        showNoResultsMessage &&
                        lookupResults?.length === 0 &&
                        keyword
                    "
                >
                    <p>
                        The user does not exist or does not have access to view
                        this record.
                    </p>
                </div>
            </molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
