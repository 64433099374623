import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushModule } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxListGroupModule } from '@wdx/shared/components/wdx-list-group';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ImportFileUploaderModule } from '../../features/import-file-uploader';
import { ImportEntitiesDownloadModalComponent } from './import-entities-download-modal/import-entities-download-modal.component';
import { ImportEntitiesModalComponent } from './import-entities-modal/import-entities-modal.component';
import { ImportEntitiesComponent } from './import-entities.component';

@NgModule({
    declarations: [
        ImportEntitiesComponent,
        ImportEntitiesModalComponent,
        ImportEntitiesDownloadModalComponent,
    ],
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        ImportFileUploaderModule,
        AtomIconButtonModule,
        AtomModalWrapperModule,
        OrganismModalModule,
        PipesModule,
        PushModule,
        WdxIconModule,
        WdxIsLoadingModule,
        WdxListGroupModule,
    ],
    exports: [ImportEntitiesComponent],
})
export class ImportEntitiesModule {}
