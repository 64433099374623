import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MailingInstructionsUrlSegment } from './mailing-instructions-url-segment.model';
import { ApiConfigService } from '../api-config.service';
import {
    MailingInstruction,
    MailingInstructionStatusType,
    MailingInstructionUpdate,
    Query,
} from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class MailingInstructionsApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getAll(): Observable<MailingInstruction[]> {
        return this.get<MailingInstruction[]>(
            `${MailingInstructionsUrlSegment.MailingInstruction}`
        );
    }

    getForId(id: string): Observable<MailingInstruction> {
        return this.get<MailingInstruction>(
            `${MailingInstructionsUrlSegment.MailingInstruction}/${id}`
        );
    }

    getForPartyId(id: string): Observable<MailingInstruction[]> {
        return this.get<MailingInstruction[]>(
            `${MailingInstructionsUrlSegment.Party}/${id}/${MailingInstructionsUrlSegment.MailingInstruction}`
        );
    }

    getForPartyRoleId(id: string): Observable<MailingInstruction[]> {
        return this.get<MailingInstruction[]>(
            `${MailingInstructionsUrlSegment.PartyRole}/${id}/${MailingInstructionsUrlSegment.MailingInstruction}`
        );
    }

    getForClientId(id: string): Observable<MailingInstruction[]> {
        return this.get<MailingInstruction[]>(
            `${MailingInstructionsUrlSegment.Client}/${id}/${MailingInstructionsUrlSegment.MailingInstruction}`
        );
    }

    createMailingInstruction(
        mailingInstruction: MailingInstruction
    ): Observable<MailingInstruction> {
        return this.post<MailingInstruction, MailingInstruction>(
            `${MailingInstructionsUrlSegment.MailingInstruction}`,
            mailingInstruction
        );
    }

    updateMailingInstruction(
        mailingInstruction: MailingInstructionUpdate,
        id: string
    ): Observable<MailingInstruction> {
        return this.put<MailingInstruction>(
            `${MailingInstructionsUrlSegment.MailingInstruction}/${id}`,
            mailingInstruction
        );
    }

    updateMailingInstructionStatus(
        id: string,
        status: MailingInstructionStatusType
    ): Observable<MailingInstruction> {
        return this.patch<MailingInstruction>(
            `${MailingInstructionsUrlSegment.MailingInstruction}/${id}/${
                MailingInstructionsUrlSegment.Status
            }/${status.toLowerCase()}`,
            {}
        );
    }

    cloneMailingInstructions(ids: string[]): Observable<MailingInstruction[]> {
        return this.post<MailingInstruction[], string[]>(
            `${MailingInstructionsUrlSegment.MailingInstruction}/${MailingInstructionsUrlSegment.Clone}`,
            ids
        );
    }

    deleteMailingInstruction(id: string): Observable<MailingInstruction> {
        return this.delete<MailingInstruction>(
            `${MailingInstructionsUrlSegment.MailingInstruction}/${id}`
        );
    }

    query(query: Query): Observable<MailingInstruction[]> {
        return this.post<MailingInstruction[], Query>(
            `${MailingInstructionsUrlSegment.Query}/${MailingInstructionsUrlSegment.MailingInstruction}`,
            query
        );
    }
}
