import { Component, Input } from '@angular/core';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';
import { FormSummaryMode } from '../../../models/form-summary-mode.model';
import { FormDisplayMode } from '../../../models/form-display-mode.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-wrapper',
    templateUrl: './molecule-input-wrapper.component.html',
})
export class MoleculeInputWrapperComponent {
    @Input() forceShowLabel: boolean;
    @Input() labelIsHalfWidth: boolean;
    @Input() useDefaultSummary = true;
    @Input() formInputData: FormInputData;
    @Input() validationErrorMessage: string;
    @Input() hideGuidanceText: boolean;
    @Input() formDisplayMode: FormDisplayMode;
    @Input() formSummaryMode: FormSummaryMode;
    @Input() inputOnly: boolean;
    @Input() value: any;

    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly FORM_SUMMARY_MODE = FormSummaryMode;
}
