import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PushModule } from '@ngrx/component';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxMultiSelectComponent } from '@wdx/shared/components/wdx-multi-select';
import { WdxRadioModule } from '@wdx/shared/components/wdx-radio';
import { WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { TranslationPipesModule } from '@wdx/system';
import { AtomAddButtonModule } from '../../../components/atoms/atom-add-button/atom-add-button.module';
import { AtomCloseModule } from '../../../components/atoms/atom-close/atom-close.module';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { BuilderComponent } from './builder/builder.component';
import { FormFiltersComponent } from './form-filters.component';
import { FilterCurrencyComponent } from './shared/components/filter-currency/filter-currency.component';
import { FilterDateComponent } from './shared/components/filter-date/filter-date.component';
import { FilterSelectComponent } from './shared/components/filter-select/filter-select.component';
import { ViewListComponent } from './shared/components/view-list/view-list.component';
import { FilterDataProviderModule } from './shared/services/filter-data/filter-data-provider.module';
import { SimpleFilterViewComponent } from './simple-view/simple-view.component';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';

@NgModule({
    declarations: [
        SimpleFilterViewComponent,
        FormFiltersComponent,
        ViewListComponent,
        BuilderComponent,
        FilterSelectComponent,
        FilterDateComponent,
        FilterCurrencyComponent,
    ],
    imports: [
        AtomAddButtonModule,
        AtomCloseModule,
        AtomIconButtonModule,
        CommonModule,
        NgbDropdownModule,
        NgSelectModule,
        PipesModule,
        PushModule,
        ReactiveFormsModule,
        TranslationPipesModule,
        WdxCheckboxModule,
        WdxDropdownModule,
        WdxFormFieldModule,
        WdxIconModule,
        WdxInfoCardModule,
        WdxInputTextModule,
        WdxRadioModule,
        WdxMultiSelectComponent,
        FilterDataProviderModule,
        WdxSafeHtmlPipe,
        WdxToggleButtonComponent,
    ],
    exports: [FormFiltersComponent],
})
export class FormFiltersModule {}
