<ng-container *ngFor="let fragment of fragments">
    <ng-container *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.Text">{{
        fragment.value
    }}</ng-container>

    <ng-container *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.Link">
        <button
            class="btn btn-link"
            (click)="onLinkSelected(fragment.url)"
            [attr.data-cy]="'notification-link-' + fragment.value"
        >
            {{ fragment.value }}
        </button>
    </ng-container>

    <ng-container
        *ngIf="fragment.type === NOTIFICATION_FRAGMENT_TYPE.FileDownload"
    >
        <button class="btn btn-link" (click)="onDownload(fragment)">
            {{ fragment.value }}
        </button>
    </ng-container>
</ng-container>
