import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable()
export class FileDownloadService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getFile(fileIndex: string): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(
            `${this.config.getConfiguration().API_BASE}/file/${fileIndex}`,
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    deleteFile(fileIndex: string) {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/file/${fileIndex}`,
            {
                params: { failSilently: true },
            }
        );
    }

    downloadFile(name: string, response: HttpResponse<Blob>) {
        const blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, name);
    }
}
