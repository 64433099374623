import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MailingInstruction, SystemEntity } from '@wdx/clmi/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import * as mailingInstructionsActions from './mailing-instructions.actions';

export const mailingInstructionsStore = 'mailingInstructions';

export interface State extends EntityState<MailingInstruction> {
    status: CrudStatus;
    entityType?: SystemEntity;
    entityId?: string;
}

export function selectId(mailingInstruction: MailingInstruction): string {
    return mailingInstruction.id;
}

export const adapter: EntityAdapter<MailingInstruction> =
    createEntityAdapter<MailingInstruction>({
        selectId,
    });

export const initialState: State = adapter.getInitialState({
    entityType: null,
    entityId: null,
    status: CrudStatus.Initial,
});

export const reducer = createReducer(
    initialState,
    on(mailingInstructionsActions.getForEntityId, (state, props) => ({
        ...initialState,
        status: CrudStatus.Loading,
        entityType: props.entityType,
        entityId: props.entityId,
    })),
    on(mailingInstructionsActions.getForEntityIdSuccess, (state, props) => {
        const newState = {
            ...state,
            status: CrudStatus.Success,
            entityType: props.entityType,
            entityId: props.entityId,
        };
        return adapter.addMany(props.mailingInstructions, newState);
    }),
    on(mailingInstructionsActions.getForEntityIdFailure, (state, props) => ({
        ...state,
        status: CrudStatus.Error,
        entityType: props.entityType,
        entityId: props.entityId,
    })),
    on(mailingInstructionsActions.updateMailingInstructionsStatus, (state) => ({
        ...state,
        status: CrudStatus.Loading,
    })),
    on(
        mailingInstructionsActions.updateMailingInstructionsStatusSuccess,
        (state, props) => {
            const newState = {
                ...state,
                status: CrudStatus.Success,
            };
            return adapter.upsertMany(props.mailingInstructions, newState);
        }
    ),
    on(
        mailingInstructionsActions.updateMailingInstructionsStatusFailure,
        (state) => ({
            ...state,
            status: CrudStatus.Error,
        })
    ),
    on(mailingInstructionsActions.duplicateMailingInstructions, (state) => ({
        ...state,
        status: CrudStatus.Loading,
    })),
    on(
        mailingInstructionsActions.duplicateMailingInstructionSuccess,
        (state, props) => {
            const newState = {
                ...state,
                status: CrudStatus.Success,
            };
            return adapter.addMany(props.mailingInstructions, newState);
        }
    ),
    on(
        mailingInstructionsActions.duplicateMailingInstructionFailure,
        (state) => ({
            ...state,
            status: CrudStatus.Error,
        })
    ),
    on(mailingInstructionsActions.resetMailingInstructions, () => ({
        ...initialState,
    }))
);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAllMailingInstructions = selectAll;
export const selectMailingInstructionsById = selectEntities;
