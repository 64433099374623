import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WdxBaseInputLabelIdClass } from '@wdx/shared/components/abstract-classes';

@Component({
    selector: 'wdx-radio-button',
    templateUrl: './wdx-radio-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: { '[class.btn-group]': 'group' },
})
export class WdxRadioButtonComponent extends WdxBaseInputLabelIdClass {
    @Input() label?: string;
    @Input() icon?: string;
    @Input() group?: boolean;
    @Input() set value(val: string) {
        this._value = val;
    }

    get value(): string {
        return this._value as string;
    }

    override prefix = 'radio-button';
}
