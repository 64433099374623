import { Pipe, PipeTransform } from '@angular/core';
import { DocumentData } from '../models/document-data.model';
import { Document } from '@wdx/clmi/api-models';
import { DisplayDatePipe } from './display-date.pipe';
import { FileSizePipe } from '@wdx/shared/utils';

@Pipe({ name: 'documentsToDocumentData' })
export class DocumentsToDocumentDataPipe implements PipeTransform {
    transform(documents: Document[]): DocumentData[] {
        return documents
            ? documents.map((document) => ({
                  primaryInfo: document.fileIndex.name,
                  secondaryInfo: `${
                      document.documentType.name
                  } - ${new FileSizePipe().transform(
                      document.fileIndex.sizeInBytes
                  )}`,
                  tertiaryInfo: [
                      `Uploaded ${new DisplayDatePipe().transform(
                          document.dateUpdated,
                          {
                              relative: true,
                              relativeStyle: 'long',
                          }
                      )}`,
                  ],
                  fileType: document.fileIndex.contentType,
              }))
            : [];
    }
}
