import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of, partition } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    pairwise,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import { OperationsSetup } from '../../models/operations.model';
import * as operationsActions from './operations.actions';
import { OperationsService } from './operations.service';

@Injectable()
export class OperationsEffects {
    private actions$ = inject(Actions);
    private operationsService = inject(OperationsService);
    private toastService = inject(WdxToastService);

    getOperationsForId$ = partition(
        this.actions$.pipe(
            ofType(operationsActions.getOperationsForId),
            startWith({} as OperationsSetup),
            pairwise()
        ),
        ([previousAction, action]) =>
            previousAction.entityId === action.entityId &&
            previousAction.entityType === action.entityType
    );

    getOperationsForIdSwitch$ = createEffect(() =>
        this.getOperationsForId$[0].pipe(
            switchMap(([_, action]) => this.getOperationsForIdActions(action))
        )
    );

    getOperationsForIdMerge$ = createEffect(() =>
        this.getOperationsForId$[1].pipe(
            mergeMap(([_, action]) => this.getOperationsForIdActions(action))
        )
    );

    patchActionWithURL$ = createEffect(() =>
        this.actions$.pipe(
            ofType(operationsActions.patchActionWithURL),
            mergeMap((action) =>
                this.operationsService
                    .patchActionWithURL(
                        action.entityType,
                        action.entityId,
                        action.url
                    )
                    .pipe(
                        map(() =>
                            operationsActions.patchActionWithURLSuccess({
                                entityType: action.entityType,
                                entityId: action.entityId,
                                url: action.url,
                            })
                        ),
                        catchError((error) =>
                            of(operationsActions.patchWithURLFailure({ error }))
                        )
                    )
            )
        )
    );

    patchActionWithURLSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(operationsActions.patchActionWithURLSuccess),
                tap(() => {
                    this.toastService.show({
                        body: ['Action completed successfully!'],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    getOperationsForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(operationsActions.getOperationsForEntity),
            mergeMap((action) =>
                this.operationsService
                    .getOperationsForEntity(action.entityType)
                    .pipe(
                        map((operations) =>
                            operationsActions.getOperationsForEntitySuccess({
                                entityType: action.entityType,
                                operations,
                            })
                        ),
                        catchError((error) =>
                            of(
                                operationsActions.getOperationsForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getOperationsForIdActions = (action) =>
        this.operationsService
            .getOperationsForId(action.entityType, action.entityId)
            .pipe(
                map((operations) =>
                    operationsActions.getOperationsForIdSuccess({
                        entityType: action.entityType,
                        entityId: action.entityId,
                        operations,
                    })
                ),
                catchError((error) =>
                    of(
                        operationsActions.getOperationsForIdFailure({
                            entityType: action.entityType,
                            entityId: action.entityId,
                            error,
                        })
                    )
                )
            );
}
