import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PushModule } from '@ngrx/component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxCheckboxTileModule } from '@wdx/shared/components/wdx-checkbox-tile';
import { WdxCheckboxTileGroupModule } from '@wdx/shared/components/wdx-checkbox-tile-group';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxInputDateModule } from '@wdx/shared/components/wdx-input-date';
import { WdxInputDateRangeModule } from '@wdx/shared/components/wdx-input-date-range';
import { WdxInputGroupModule } from '@wdx/shared/components/wdx-input-group';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxInputTimeModule } from '@wdx/shared/components/wdx-input-time';
import { WdxLabelModule } from '@wdx/shared/components/wdx-label';
import { WdxRadioModule } from '@wdx/shared/components/wdx-radio';
import { WdxRadioButtonModule } from '@wdx/shared/components/wdx-radio-button';
import { WdxRadioButtonGroupModule } from '@wdx/shared/components/wdx-radio-button-group';
import {
    WdxRadioTileGroupModule,
    WdxRadioTileModule,
} from '@wdx/shared/components/wdx-radio-tile';
import { customCurrencyMaskConfig } from '@wdx/shared/utils';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { FormInputControlComponent } from './form-input-control/form-input-control.component';
import { ValidationErrorMessagePipe } from '../../pipes';
import { FormBooleanControlComponent } from './form-boolean-control/form-boolean-control.component';
import { FormCheckboxesControlComponent } from './form-checkboxes-control/form-checkboxes-control.component';
import { FormCurrencyControlComponent } from './form-currency-control/form-currency-control.component';
import { FormDateControlComponent } from './form-date-control/form-date-control.component';
import { FormRadioButtonsControlComponent } from './form-radio-buttons-control/form-radio-buttons-control.component';

@NgModule({
    declarations: [
        FormInputControlComponent,
        FormBooleanControlComponent,
        FormCheckboxesControlComponent,
        FormCurrencyControlComponent,
        FormDateControlComponent,
        FormRadioButtonsControlComponent,
    ],
    imports: [
        CommonModule,
        EditorModule,
        NgSelectModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxMaskModule.forRoot(),
        PushModule,
        ReactiveFormsModule,
        WdxCheckboxModule,
        WdxCheckboxModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileModule,
        WdxCheckboxTileModule,
        WdxFormFieldModule,
        WdxIconModule,
        WdxIconModule,
        WdxIconStackModule,
        WdxInputDateModule,
        WdxInputDateRangeModule,
        WdxInputTextModule,
        WdxInputTimeModule,
        WdxLabelModule,
        WdxRadioButtonGroupModule,
        WdxRadioButtonModule,
        WdxRadioModule,
        WdxRadioTileGroupModule,
        WdxRadioTileModule,
        WdxInputGroupModule,
        ValidationErrorMessagePipe,
    ],
    providers: [
        // TODO: re-enable when related control is migrated
        // { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    exports: [
        FormInputControlComponent,
        FormBooleanControlComponent,
        FormCheckboxesControlComponent,
        FormCurrencyControlComponent,
        FormDateControlComponent,
        FormRadioButtonsControlComponent,
    ],
})
export class FormControlsModule {}
