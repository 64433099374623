<wdx-form-field
    [locked]="formElement?.isLocked"
    [label]="formElement?.label"
    [guidanceText]="formElement?.guidanceText"
    [isSensitive]="formElement?.isSensitive"
    [isRequired]="formElement?.isRequired"
    [labelForId]="inputId"
    [validationErrorMessage]="
        formControl?.touched && formControl?.errors
            | validationErrorMessage : formElement
    "
    [warningMessage]="warningText"
>
    <molecule-document-info
        *ngIf="fileIndex; else fileUpload"
        class="d-block mb-1 w-100"
        [fileIndex]="fileIndex"
    >
        <atom-icon-button
            *ngIf="!formControl?.disabled"
            class="ms-2"
            size="sm"
            icon="trash-alt"
            (onClick)="onDeleteFile(fileIndex)"
        ></atom-icon-button>
    </molecule-document-info>

    <ng-template #fileUpload>
        <clmi-file-upload
            data-cy="document-upload"
            [disabled]="formControl?.disabled"
            [imageUpload]="
                formElement.fieldType === FORM_FIELD_TYPE.ImageUpload
            "
            (fileUploaded)="onFileUploaded($event)"
        ></clmi-file-upload>
    </ng-template>
</wdx-form-field>
