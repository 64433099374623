import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {
    FieldDefinition,
    FormDataResult,
    FormLayout,
    FormSaveMode,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';
import { FormDisplayMode } from './form-display-mode.model';

export interface FormSetup {
    formTemplateId?: string;
    formTemplateEntityId?: string;
    formTemplateEntityType?: SystemEntity;
    formId?: string;
    entityId?: string;
    appId?: string;
    initialisationParams?: KeyValueObject;
    queryParams?: KeyValueObject;
    initialisationMode?: FormInitialisationMode;
    emitInsteadOfPost?: boolean;
    emitData?: any;
    initialFormValue?: KeyValueObject;
    formDisplayMode?: FormDisplayMode;
    isQuickCreate?: boolean;
    preserveActiveModal?: boolean;
}

export interface StaticFormSetup {
    formTitle: string;
    formDefinition: {
        saveModes?: FormSaveMode[];
        schema?: StaticFieldDefinition[];
    };
    formLayout?: FormLayout;
    formData?: FormDataResult;
    saveAction: FormActionCreator;
    successAction: FormActionCreator;
}

export interface StaticFieldDefinition extends FieldDefinition {
    isEqualTo?: string;
    autocomplete?: string;
}

export enum FormInitialisationMode {
    Add = 'Add',
    Edit = 'Edit',
    Delete = 'Delete',
}

type FormActionCreator = ActionCreator<
    string,
    (props: KeyValueObject) => KeyValueObject & TypedAction<string>
>;

export interface FormStateData {
    value: KeyValueObject;
    queryParams?: string;
}
